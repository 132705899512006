import { Storage } from "aws-amplify";
import uuid from "react-uuid";
import imageCompression from "browser-image-compression";

export default class StorageManager {
  static loadImage(name) {
    //return Storage.get("toolImages/" + name);
  }

  static loadImageFile(name) {
    console.log("USER PHOTO", name);

    return Storage.get(name);
  }

  static loadImages(pictures) {
    return pictures?.map((pic) => {
      return (
        "https://toolsreact7e1f37e8e3d54bb7a97fd8acb2a3bc1b60152-release.s3.ca-central-1.amazonaws.com/public/toolImages/" +
        pic
      );
    });
  }
  // static loadImages(pictures) {
  //   return pictures?.map((pic) => {
  //     return (
  //       "https://toolsreact7e1f37e8e3d54bb7a97fd8acb2a3bc1b151756-dev.s3.ca-central-1.amazonaws.com/public/toolImages/" +
  //       pic
  //     );
  //   });
  //
  //   //return Promise.all(pictures.map(StorageManager.loadImage));
  // }

  static async uploadImage(file) {
    let fileName = uuid() + file.name;
    let name = fileName;

    await Storage.put(name, file, {
      ACL: "public-read",
      visibility: "public",
      level: "public",
      access: "public",
    });

    return fileName;
  }

  static async upload(file) {
    let fileName = uuid() + file.name;
    let name = "toolImages/" + fileName;

    await Storage.put(name, file, {
      ACL: "public-read",
      visibility: "public",
      level: "public",
      access: "public",
    });

    return fileName.replaceAll(" ", "%20");
  }

  static async compressImage(imageFile) {
    const options = {
      maxSizeMB: 0.2,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };
    try {
      return await imageCompression(imageFile, options);
    } catch (error) {
      // console.log(error);
    }
  }

  static async uploadToolPictures(pictures) {
    let files = await Promise.all(pictures.map(StorageManager.compressImage));
    return Promise.all(files.map(StorageManager.upload));
  }
}
