import ImageUpload from "../../shared/ImageUpload";
import React, { useContext, useEffect, useState } from "react";
import { FormGroup } from "reactstrap";

import { Controller } from "react-hook-form";
import { Alert, Form } from "react-bootstrap";
import toolsContext from "../../../context/tools/toolsContext";

import "../../../scss/posttool/rent-user-tool.scss";

export default function Step5({ data }) {
  const [reviewImg, setReviewImg] = useState(null);

  useEffect(() => {
    const reader = new FileReader();
    reader.readAsDataURL(data?.pictures?.[0]);
    reader.onload = function (e) {
      setReviewImg(e.target.result);
    };
  }, []);

  const reviewInformation = () => {
    return (
      <div className="">
        {/*<h1>REVIEWWW</h1>*/}
        <div className="row">
          <div className="col-12 col-sm-4 ml-0 ">
            {/*<ProductGallery images={data?.toolPictures} />*/}
            <img src={reviewImg} className="revieww-imagee" alt="thumbnail" />
          </div>
          <div className="col-12 col-sm-6 pt-3 pt-sm-0">
            <div>
              <h6 className="font-weight-light">
                <b className="font-weight-bold">Name: </b>
                {data?.name ?? ``}
              </h6>
              <h6 className="font-weight-light">
                <b className="font-weight-bold">Description: </b>
                {data?.desc ?? ``}
              </h6>
              <h6 className="font-weight-light">
                <b className="font-weight-bold">Brand: </b>
                {data?.brand ?? ``}
              </h6>
              <h6 className="font-weight-light">
                <b className="font-weight-bold">Model: </b>
                {data?.model ?? ``}
              </h6>
              <h6 className="font-weight-light">
                <b className="font-weight-bold">Condition: </b>
                {data?.condition === "1"
                  ? `Average`
                  : data?.condition === "2"
                  ? `Good`
                  : data?.condition === "3"
                  ? `Perfect`
                  : ``}
              </h6>
              {/*{ console.log(`tool condition: ${typeof(data?.condition) }`) }*/}
              <h6 className="font-weight-light">
                <b className="font-weight-bold">Price: </b>$
                {data?.hourlyRate * 10 ?? ``}
              </h6>
            </div>
          </div>
          {/*<div className="col-12 col-lg-3">{pricingtable(false, false)}</div>*/}
        </div>
      </div>
    );
  };

  return <FormGroup>{reviewInformation()}</FormGroup>;
}
