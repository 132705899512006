// react
import React, { useContext } from "react";

// third-party
import classNames from "classnames";
import { Link, matchPath, Redirect, Switch, Route } from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";
// pages
import AccountPageAddresses from "./AccountPageAddresses";
import AccountPageDashboard from "./AccountPageDashboard";
import AccountPageOrderDetails from "./AccountPageOrderDetails";
import AccountPagePassword from "./AccountPagePassword";
import AccountPageProfile from "./AccountPageProfile";
import AccountTransactions from "./AccountTransactions";

import GetPaid from "./payouts/GetPaid";
import AddLocalbankAccount from "./payouts/AddLocalBankAccount";

//MESSAGES COMPONENT
import AccountPageMessages from "./AccountPageMessages";
import authContext from "../../context/auth/authContext";
import AccountListings from "./AccountListings";

export default function AccountLayout(props) {
  const { match, location } = props;

  const breadcrumb = [
    { title: "Home", url: "" },
    { title: "My Account", url: "" },
  ];

  const links = [
    { title: "Dashboard", url: "dashboard" },
    // { title: "Messages", url: "messages" },
    { title: "Edit Profile", url: "profile" },
    { title: "My Listings", url: "listings" },
    { title: "Bookings", url: "bookings" },
    { title: "Transactions", url: "transactions" },

    { title: "Get Paid", url: "get-paid" },
    { title: "Addresses", url: "addresses" },
    // { title: "Edit Address", url: "addresses/5" },
    { title: "Password", url: "password" },
  ].map((link) => {
    const url = `${match.url}/${link.url}`;
    const isActive = matchPath(location.pathname, { path: url, exact: true });
    const classes = classNames("account-nav__item", {
      "account-nav__item--active": isActive,
    });

    return (
      <li key={link.url} className={classes}>
        <Link to={url}>{link.title}</Link>
      </li>
    );
  });

  const { user, balance, login, logout, isLoggedIn } = useContext(authContext);
  const LogOut = () => {
    logout();
    //window.location.reload();
  };

  return (
    <React.Fragment>
      {isLoggedIn ? (
        <>
          <PageHeader header="My Account" breadcrumb={breadcrumb} />

          <div className="block">
            <div className="container">
              <div className="row">
                <div className="col-12 col-lg-3 d-flex">
                  <div className="account-nav flex-grow-1">
                    <h4 className="account-nav__title">Navigation</h4>
                    <ul>{links}</ul>
                    <div className="account-nav__item">
                      <Link
                        onClick={() => {
                          LogOut();
                        }}
                      >
                        Logout
                      </Link>
                    </div>
                  </div>
                </div>
                <div className="col-12 col-lg-9 mt-4 mt-lg-0">
                  <Switch>
                    <Redirect
                      exact
                      from={match.path}
                      to={`${match.path}/dashboard`}
                    />
                    <Route
                      exact
                      path={`${match.path}/dashboard`}
                      component={AccountPageDashboard}
                    />

                    {/*/!* MESSAGES ROUTE*!/*/}
                    {/*<Route*/}
                    {/*  exact*/}
                    {/*  path={`/messages`}*/}
                    {/*  component={AccountPageMessages}*/}
                    {/*/>*/}
                    {/* MESSAGES ROUTE*/}
                    <Route
                      exact
                      path={`${match.path}/profile`}
                      component={AccountPageProfile}
                    />
                    <Route
                      exact
                      path={`${match.path}/get-paid`}
                      component={GetPaid}
                    />
                    <Route
                      exact
                      path={`${match.path}/get-paid/add-local-bank-account`}
                      component={AddLocalbankAccount}
                    />
                    <Route
                      exact
                      path={`${match.path}/bookings`}
                      component={AccountPageOrderDetails}
                    />
                    <Route
                      exact
                      path={`${match.path}/transactions`}
                      component={AccountTransactions}
                    />
                    <Route
                      exact
                      path={`${match.path}/addresses`}
                      component={AccountPageAddresses}
                    />
                    <Route
                      exact
                      path={`${match.path}/listings`}
                      component={AccountListings}
                    />
                    <Route
                      exact
                      path={`${match.path}/password`}
                      component={AccountPagePassword}
                    />
                  </Switch>
                </div>
              </div>
            </div>
          </div>
        </>
      ) : (
        <Redirect to="/" />
      )}
    </React.Fragment>
  );
}
