import { useEffect, useReducer, useState } from "react";
import LayoutContext from "./layoutContext";
import layoutReducer from "./layoutReducer";
import { REDIRECT_TO_TOOL } from "../types";

const LayoutState = (props) => {
  const initialState = {
    redirectToTool: "",
  };
  const [state, dispatch] = useReducer(layoutReducer, initialState);

  const setRedirectToTool = (val) => {
    dispatch({ type: REDIRECT_TO_TOOL, payload: val });
  };

  return (
    <LayoutContext.Provider
      value={{
        redirectToTool: state.redirectToTool,
        setRedirectToTool,
      }}
    >
      {props.children}
    </LayoutContext.Provider>
  );
};

export default LayoutState;
