import AuthService from "./AuthService";
import { CachingService } from "./CachingService";

export class LocationManager {
  static async loadZipCodes(zipCode) {
    let url = `https://therovecompany.com/Toology/search.php?query=${zipCode}`;

    // console.log("Fetching location");
    let data = await fetch(url);
    let jsonData = await data.json();
    // console.log(jsonData);
    let results = jsonData;

    if (results == null) {
      throw new Error("No results");
    }

    return results;
  }

  static async getZipCodeFromLocation(lat, long) {
    const apiKey = "AIzaSyA0Aly5Oai_7A88FbFkaXXc0hiQHZQ1ai0";

    // let lat = '24.909973'
    // let long = '67.105615'
    let url = `https://maps.googleapis.com/maps/api/geocode/json?latlng=${lat},${long}&key=${apiKey}`;
    let data = await fetch(url);
    let jsonData = await data.json();

    let results = jsonData?.results;

    for (let x = 0; x < results?.length; x++) {
      let address = results?.[x]?.["address_components"];
      let codes = address?.filter((component) => {
        return component?.types?.includes("postal_code");
      });
      let postal_code = codes?.[0];
      if (codes?.length > 0)
        return postal_code?.["long_name"] ?? postal_code?.["short_name"];
    }

    return "Current Location";
  }

  static getDistanceFromTool(tool, mylocation) {
    // let user = await AuthService.getUser()

    let lat = parseFloat(mylocation?.location?.lat ?? "0");
    let long = parseFloat(mylocation?.location?.long ?? "0");

    let toolLat = parseFloat(tool.lat ?? "0");
    let toolLong = parseFloat(tool.long ?? "0");

    if (isNaN(toolLat) || isNaN(toolLong)) {
      return 0;
    }

    console.log("Tool: ", tool);
    console.log("My Location: ", mylocation);

    // if (lat == null || long == null) {
    //   throw new Error("Failed to get user location");
    // }

    // console.log("asdadsads" + JSON.stringify([lat,long]))

    let distance = this.calcCrow(lat, long, toolLat, toolLong);
    console.log("LOCATIONS", lat, long, toolLat, toolLong);
    console.log("Tool Distance: ", distance);

    // console.log(distance + " " + tool?.name);
    let d = Math.round(distance * 100) / 100;
    return isNaN(d) ? 0 : d;
  }

  //This function takes in latitude and longitude of two location and returns the distance between them as the crow flies (in km)
  static calcCrow(lat1, lon1, lat2, lon2) {
    function toRad(Value) {
      return (Value * Math.PI) / 180;
    }

    var R = 6371; // km
    var dLat = toRad(lat2 - lat1);
    var dLon = toRad(lon2 - lon1);
    var lat1 = toRad(lat1);
    var lat2 = toRad(lat2);

    var a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
    var c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    var d = R * c;
    return d;
  }

  // static calcCrow(locationA, locationB) {
  //   let latA = locationA?.[0];
  //   let lonA = locationA?.[1];
  //
  //   let latB = locationB?.[0];
  //   let lonB = locationB?.[1];
  //
  //   function toRad(Value) {
  //     return (Value * Math.PI) / 180;
  //   }
  //
  //   let R = 6371; // km
  //   let dLat = toRad(latB - latA);
  //   let dLon = toRad(lonB - lonA);
  //   let lat1 = toRad(latA);
  //   let lat2 = toRad(latB);
  //
  //   let a =
  //     Math.sin(dLat / 2) * Math.sin(dLat / 2) +
  //     Math.sin(dLon / 2) * Math.sin(dLon / 2) * Math.cos(lat1) * Math.cos(lat2);
  //   let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  //   let d = R * c;
  //   return d;
  // }

  // Converts numeric degrees to radians

  static getLocationFromBrowser() {
    console.log("asjhyu", "executing getting location");
    return new Promise(async function (resolve, reject) {
      navigator.geolocation.getCurrentPosition(
        async function (position) {
          resolve(position);
        },
        function (error) {
          reject(error);
          console.error(error);
        },
        { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
      );
    });
  }

  static async getCurrentLocation() {
    // console.log("Getting Location");

    const position = await this.getLocationFromBrowser();

    let location = position?.coords;
    let MyLocation = {
      lat: location?.latitude,
      long: location?.longitude,
    };
    let lat = location?.lat; //?? "51.073166374";
    let long = location?.long; //?? "-114.126499494";

    // alert("Your Location is: "+JSON.stringify(MyLocation))

    return new Promise(async function (resolve, reject) {
      LocationManager.getZipCodeFromLocation(lat, long)
        .then((code) => {
          resolve(MyLocation, code);
        })
        .catch(() => {
          resolve(MyLocation, null);
        });
    });
  }
}
