import {
  APPLY_PROMO_CODE,
  LOAD_CARDS_ON_FILE,
  REDIRECT_TO_TOOL,
  REMOVE_PROMO_CODE,
  SET_CHECKOUT_INFO,
  SET_DAMAGE_PROTECTION,
  SET_INTENT_IDS,
  SET_NO_PAYMENT_REQUIRED,
  SET_ORDER_TOTAL,
  SET_PAYPAL_REF,
  SET_PROCESSING_FEES,
  SET_PROMO_DATA,
  SET_SELECTED_CARD,
  SET_USE_CREDITS,
} from "../types";

const checkoutReducer = (state, action) => {
  switch (action.type) {
    case SET_CHECKOUT_INFO:
      const info = action.payload;
      return {
        ...state,
        billingInfo: info?.billingInfo,
        tool: info?.tool,
        bookingTime: info?.bookingTime,
      };

    case LOAD_CARDS_ON_FILE:
      return { ...state, cardsOnFile: action.payload };

    case SET_INTENT_IDS:
      return {
        ...state,
        stripeID: action.payload.stripe,
        paypalID: action.payload.paypal,
      };

    case SET_NO_PAYMENT_REQUIRED:
      return { ...state, noPaymentRequired: action.payload };

    case SET_SELECTED_CARD:
      return { ...state, selectedCard: action.payload };

    case APPLY_PROMO_CODE:
      return { ...state, appliedPromoCode: action.payload };

    case SET_PROMO_DATA:
      return { ...state, promoData: action.payload };
    case SET_ORDER_TOTAL:
      return { ...state, orderTotal: action.payload };
    case SET_USE_CREDITS:
      return { ...state, useCredits: action.payload };
    case SET_DAMAGE_PROTECTION:
      return { ...state, damageProtection: action.payload };

    case SET_PROCESSING_FEES:
      return { ...state, processingFees: action.payload };

    case REMOVE_PROMO_CODE:
      return { ...state, promoData: null, appliedPromoCode: null };

    case SET_PAYPAL_REF:
      return {
        ...state,
        onSuccess: action.payload.success,
      };

    default:
      return state;
  }
};
export default checkoutReducer;
