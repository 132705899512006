export default {
  name: "Toology",
  fullName: "Toology - why buy when you can rent?",
  url: "https://toology.ca",
  author: {
    name: "Rove",
    profile_url: "https://roveapps.com",
  },
  contacts: {
    address: "",
    email: "",
    phone: "",
  },
};
