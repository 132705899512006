import React, { useState, useRef, useEffect, useContext } from "react";

import { ScreenSizeContext } from "../../context/context";

// application
import Search from "../header/Search";

import { Fade, Hidden } from "@material-ui/core";

import InputBase from "@material-ui/core/InputBase";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";

import SendIcon from "@material-ui/icons/Send";
import SearchIcon from "@material-ui/icons/Search";
import BackIcon from "@material-ui/icons/ArrowBackIos";

import "../../scss/pages/AccountPageMessages.scss";
import DataManager from "../../api/DataManager";
import { dateToFromNowDaily, truncate } from "../../api/Helper";
import authContext from "../../context/auth/authContext";
import AccountLoginPage from "./AccountLoginPage";
import { Image } from "react-bootstrap";
import { Link } from "react-router-dom";

export default function AccountPageMessages() {
  const { user } = useContext(authContext);

  // const [play] = useSound(notification);
  const messagesContainerRef = useRef();

  const [openConversation, setOpenConversation] = useState(false);

  const [currentConversation, setCurrentConversation] = useState(null);
  const [messages, setMessages] = useState([]);
  const [allConversations, setAllConversations] = useState([]);
  const [filteredConversations, setFilteredConversations] = useState([]);

  const [typedMessage, setTypedMessage] = useState("");
  const [conversationSearchText, setConversationSearchText] = useState("");

  const { setHideFooter } = useContext(ScreenSizeContext);

  const { notifications, clearNotifications } = useContext(authContext);

  useEffect(() => {
    messagesContainerRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [messages]);

  const sendMessage = () => {
    // console.log(currentConversation?.id);
    let rcvrID = currentConversation?.rcvrID;

    DataManager.postMessage(typedMessage, currentConversation?.id, rcvrID)
      .then((message) => {
        setMessages((messages) => [...messages, message]);
      })
      .catch((error) => {
        // console.log(error);
      });
    setTypedMessage("");
  };

  useEffect(() => {
    clearNotifications();
  }, [typedMessage]);

  //MARK: - Update conversations
  useEffect(() => {
    let latestMessage = messages?.[messages?.length - 1];
    updateConversation(latestMessage?.conversationID, latestMessage);
  }, [messages]);

  useEffect(() => {
    filterConversations();
  }, [allConversations, conversationSearchText]);

  const loadConversations = () => {
    DataManager.loadConversation().then((convos) => {
      setAllConversations(convos);
      // console.log("CONVERSATIONS");
      // console.log(convos);

      setFilteredConversations(convos);
      if (currentConversation == null) {
        setCurrentConversation(convos?.[0]);
      }
    });
  };

  useEffect(() => {
    loadConversations();
    setHideFooter(true);

    return () => {
      setHideFooter(false);
    };
  }, []);

  const updateConversation = (convoID, message) => {
    let index = -1;

    allConversations?.forEach((convo, i) => {
      if (convo?.id === convoID) index = i;
    });

    let conversation = [...allConversations]?.[index];

    if (conversation === undefined || conversation == null) {
      loadConversations();
      return;
    }

    conversation.lastMessage = message?.content;
    conversation.messageDate = message?.messageDate;

    let newConversations = [...allConversations];
    newConversations[index] = conversation;
    setAllConversations(newConversations);
  };

  useEffect(() => {
    let latestMessage = notifications?.[notifications?.length - 1];

    if (latestMessage?.conversationID === currentConversation?.id) {
      setMessages((messages) => [...messages, latestMessage]);
    }
  }, [notifications]);

  useEffect(() => {
    DataManager.getConversationMessages(currentConversation?.id).then(
      (messages) => {
        setMessages(messages);
      }
    );
  }, [currentConversation]);

  const filterConversations = () => {
    if (conversationSearchText === "") {
      setFilteredConversations(allConversations);
      return;
    }
    setFilteredConversations(
      allConversations
        ?.sort((a, b) => {
          return a?.messageDate < b?.messageDate ? 1 : -1;
        })
        ?.filter((convo) => {
          return (
            convo?.lastMessage
              ?.toUpperCase()
              ?.includes(conversationSearchText) ||
            convo?.userName?.toUpperCase()?.includes(conversationSearchText)
          );
        })
    );
  };

  const searchBar = () => {
    return (
      <div className="search-field flex flex-row items-center">
        <div className="search-icon">
          <SearchIcon style={{ opacity: "0.5" }} />
        </div>
        <InputBase
          fullWidth
          type="search"
          color="#1B1C1E"
          place
          placeholder="Search"
          className="search-text-field"
          onChange={(e) => {
            let text = e.target.value?.toUpperCase();
            setConversationSearchText(text);

            filterConversations();
          }}
        />
      </div>
    );
  };

  const conversationItem = (conversation) => {
    // let lastMessage = conversation?.lastMessage;

    return (
      <div
        className="conversation-item-container"
        onClick={() => {
          setCurrentConversation(conversation);
          setOpenConversation(!openConversation);
        }}
      >
        <div
          className={
            "conversation-item flex flex-row items-center" +
            (conversation?.id === currentConversation?.id
              ? " active-conversation"
              : "")
          }
        >
          <div
            className={
              "user-photo" +
              (conversation?.id === currentConversation?.id
                ? " active-conversation-user-photo"
                : "")
            }
          >
            <div className="user-photo-text">
              {conversation?.userName?.[0] + conversation?.userName?.[1]}
            </div>
          </div>

          <div className="flex flex-col px-2 conversation-text">
            <h1
              className={
                "conversation-sender" +
                (conversation?.id === currentConversation?.id
                  ? " active-conversation-sender"
                  : "")
              }
            >
              {conversation?.userName ?? "Alexander D'Lan"}
            </h1>

            <h1 className="conversation-message">
              {truncate(conversation?.lastMessage, 30)}
            </h1>

            <h1 className="conversation-date">
              {dateToFromNowDaily(new Date(conversation?.messageDate))}
            </h1>
          </div>
        </div>
        {/*<hr />*/}
      </div>
    );
  };

  const conversationsList = () => {
    return (
      <div className="side-bar items-center">
        {searchBar()}
        <div className="conversation-items-list">
          {filteredConversations?.map((item) => {
            return conversationItem(item);
          })}
        </div>
      </div>
    );
  };

  const conversationContainerHeader = () => {
    return (
      <div className="conversation-header flex flex-row align-items-center">
        <div className="left-stufff">
          <Hidden mdUp>
            <IconButton>
              <BackIcon
                className="back-button"
                color="#f6f6f6"
                fontSize="default"
                onClick={() => {
                  setOpenConversation(!openConversation);
                }}
              />
            </IconButton>
          </Hidden>

          <h3 className="user-title">{currentConversation?.userName}</h3>
        </div>
      </div>
    );
  };

  const messageBubble = (item, index) => {
    return (
      <div
        className={
          `message-bubble ` +
          (item?.userID === user?.username
            ? `message-sent`
            : `message-received`)
        }
        // ref={ index +1 === messages?.length ? messagesContainerRef : null }
      >
        <p className="message-text">{item?.content}</p>
        <h1
          className={
            "message-date" +
            (item?.userID !== user?.username ? ` message-date-received` : "")
          }
        >
          {dateToFromNowDaily(new Date(item?.createdAt))}
        </h1>
      </div>
    );
  };

  const inputBar = () => {
    return (
      <div
        className="input-bar w-full flex flex-row items-center"
        style={{ zIndex: 2 }}
      >
        <textarea
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              sendMessage();
              e.preventDefault();
            }
          }}
          // color="#1B1C1E"
          value={typedMessage}
          rows="1"
          placeholder="Type a message"
          className="input-bar-text-field"
          autoFocus
          spellCheck
          wrap="soft"
          onChange={(e) => {
            setTypedMessage(e.target.value);
          }}
        />

        <div className="flex flex-row items-center justify-center">
          {/*<IconButton>*/}
          {/*<SendIcon  />*/}
          {/*</IconButton>*/}
          <IconButton disabled={typedMessage?.length === 0}>
            <SendIcon
              className={
                "input-bar-send-icon" + typedMessage?.length === 0
                  ? " disabled-icon"
                  : ""
              }
              onClick={() => {
                sendMessage();
              }}
              //className="input-bar-send-button"
            >
              Send
            </SendIcon>
          </IconButton>
        </div>
      </div>
    );
  };

  const activeConversation = () => {
    if (currentConversation == null) {
      return "";
    }

    return (
      <div className="conversations-container w-full">
        {" "}
        {/* active conversation */}
        {conversationContainerHeader()}
        <div className="conversations-container-main">
          {messages?.map((item, index) => {
            return messageBubble(item, index);
          })}
          <div
            style={{ float: "left", clear: "both" }}
            ref={messagesContainerRef}
          />
        </div>
        {inputBar()}
      </div>
    );
  };

  const emptyState = () => {
    return (
      <div className="d-flex flex-column justify-content-center align-content-center w-100 text-center">
        <Image
          src="/images/emptyStates/empty-inbox.png"
          style={{ width: "auto", height: "8rem" }}
          className="mb-5 mx-auto"
        />

        <h5>Oops... Looks like you haven't rented any tools yet.</h5>
        <p>
          When you rent a tool, you'll be able to chat with the owner here to
          schedule a pick up time
        </p>
        <Link to={"/"}>Start Renting Now</Link>
      </div>
    );
  };

  const renderPage = () => {
    return (
      <div className="main-container flex flex-row h-screen w-full pb-16">
        {/*{ console.log(`Convos: ${JSON.stringify(allConversations)}`) }*/}
        {allConversations?.length > 0 ? (
          <>
            <Hidden mdDown>
              {conversationsList()}
              {activeConversation()}
            </Hidden>

            <Hidden mdUp>
              {!openConversation ? conversationsList() : activeConversation()}
            </Hidden>
          </>
        ) : (
          emptyState()
        )}
      </div>
    );
  };

  return user !== null ? renderPage() : <AccountLoginPage />;
}
