// react
import React, { useContext, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { useForm, Controller } from "react-hook-form";
import { Form, Card, Image, InputGroup } from "react-bootstrap";

// application
import PageHeader from "../shared/PageHeader";
import "../../scss/pages/_support.scss";

// blocks
import BlockMap from "../blocks/BlockMap";

// data stubs
import theme from "../../data/theme";
import showError from "../../api/Helper";
import { Person, AttachFile } from "@material-ui/icons";

// context
import authContext from "../../context/auth/authContext";

export default function SitePageDisputes() {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(authContext);

  const onSubmit = (values) => {
    setIsLoading(true);

    // console.log(values);
  };

  const categories = [
    "Your Bookings",
    "Your Account,",
    "Cancellation & Refunds",
    "Payment Problems",
    "Damages & Breakdowns",
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Disputes — ${theme.name}`}</title>
      </Helmet>
      <h3 className="text-center mt-5 mb-4">Open a Dispute</h3>
      <div className="block">
        <section className="col-12 col-lg-6 border py-5 px-3 mx-auto">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <Form.Group>
              <Form.Label>Category</Form.Label>
              <InputGroup className="border">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      as="select"
                      className="border-0"
                      // type="select"
                      required
                      placeholder="Select a Category"
                      {...field}
                      // isInvalid={!!errors.name}
                    >
                      {categories.map((category) => {
                        return (
                          <option className="text-capitalize">
                            {category}
                          </option>
                        );
                      })}
                    </Form.Control>
                  )}
                  name="Booking"
                  rules={{
                    required: "Dispute category number cannot be empty",
                  }}
                />
                {errors && errors.booking && (
                  <Form.Control.Feedback type="invalid">
                    {errors.booking.category}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Booking Number</Form.Label>
              <InputGroup className="border">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      className="border-0"
                      type="Text"
                      required
                      placeholder="Enter booking number"
                      {...field}
                      // isInvalid={!!errors.name}
                    />
                  )}
                  name="Booking"
                  rules={{
                    required: "Booking number cannot be empty",
                  }}
                />
                {errors && errors.booking && (
                  <Form.Control.Feedback type="invalid">
                    {errors.booking.message}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>
            <Form.Group>
              <Form.Label>Details</Form.Label>
              <InputGroup className="border">
                <Controller
                  control={control}
                  render={({ field }) => (
                    <Form.Control
                      className="border-0"
                      type="Text"
                      as="textarea"
                      required
                      placeholder="Please explain why you are disputing this booking"
                      {...field}
                      // isInvalid={!!errors.name}
                      style={{ minHeight: "20ch" }}
                    />
                  )}
                  name="detail"
                  rules={{
                    required: "Details cannot be empty",
                  }}
                />
                {errors && errors.detail && (
                  <Form.Control.Feedback type="invalid">
                    {errors.detail.message}
                  </Form.Control.Feedback>
                )}
              </InputGroup>
            </Form.Group>
            <span>
              <button
                type="submit"
                className="btn btn-light border text-primary px-5 py-2"
              >
                <AttachFile fontSize="small" className="mr-2" />
                Attach file
              </button>
              <p
                className="text-muted d-inline ml-3"
                style={{ fontSize: "1.1rem" }}
              >
                Up to 25 MB
              </p>
            </span>
            <br />
            <br />
            <button type="submit" className="btn btn-primary px-5 py-2">
              Open Dispute
            </button>
          </Form>
        </section>
      </div>
    </React.Fragment>
  );
}
