import Amplify, { Auth, Hub } from "aws-amplify";
import DataManager from "./DataManager";
import { LocationManager } from "./LocationManager";
import { CachingService } from "./CachingService";
import { API, graphqlOperation } from "@aws-amplify/api/lib";
import * as customQueries from "../graphql/customQueries";

export default class AuthService {
  static async getUserDetails() {
    if (CachingService.user == null) {
      CachingService.user = await Auth.currentAuthenticatedUser();
    }

    let user = CachingService.user;

    user.latitude = user.attributes?.["custom:latitude"];
    user.longitude = user.attributes?.["custom:longitude"];
    return user;
    // return Auth.currentAuthenticatedUser();
  }

  static async getUsername() {
    let user = await AuthService.getUserDetails();
    let userID = user?.username;
    // console.log(user);
    //authenticationFlowType: "USER_SRP_AUTH"
    if (user?.authenticationFlowType === "USER_SRP_AUTH") {
      userID = user?.attributes?.sub ?? "";
    }
    return userID;
  }

  static async forgotPassword(recoverEmail) {
    try {
      return Auth.forgotPassword(recoverEmail);
    } catch (error) {
      return error;
    }
  }

  static async forgotPasswordSubmit(recoverEmail, newPassword, recoveryCode) {
    try {
      return Auth.forgotPasswordSubmit(recoverEmail, newPassword, recoveryCode);
    } catch (error) {
      return error;
    }
  }

  static async isLoggedIn() {
    let user = await this.getUserInfo();

    return user != null;
  }

  static getUserInfo() {
    try {
      return Auth.currentUserInfo();
    } catch (error) {
      // console.log(error);
    }
  }

  static async updateAddress(address) {
    let userID = await AuthService.getUsername();
  }

  static signOut() {
    try {
      CachingService.clearCache();
      return Auth.signOut();
    } catch (error) {
      // console.log("error signing in", error);
    }
  }

  static signIn(username, password) {
    try {
      // console.log("i am here");
      return Auth.signIn(username, password);
    } catch (error) {
      // console.log("error signing in", error);
      return error;
    }
  }

  static async confirmUser(username, code, password) {
    // try {
    await Auth.confirmSignUp(username, code);
    return AuthService.signIn(username, password);

    //   // console.log(user);
    // } catch (error) {
    //   console.log("error confirming user: ", error);
    // }
  }

  static async signUp(
    username,
    password,
    country,
    number,
    fName,
    lName,
    referall
  ) {
    // //  let location = await LocationManager.getCoordinatesFromZipCode(zipCode,country)
    //   let lat = location?.lat ?? "0"
    //   let long = location?.lng ?? "0"

    let att = {
      phone_number: number,
      name: fName,
      family_name: lName,
      "custom:country": country,
      // other custom attributes
    };

    if (referall != null) {
      att["custom:referralID"] = referall;
    }

    // console.log(att);

    let user = await Auth.signUp({
      username,
      password,
      attributes: att,
    });
    return user;
  }

  static errorToString(error) {
    switch (error.code) {
      case "UsernameExistsException":
        return "Your account already exists";
      case "UserNotFoundException":
        return "User doesn't exist";
      default:
        return "Your username or password seem to be incorrect";
    }
  }
}
