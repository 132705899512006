// react
import React, { Component, useState, useEffect, useContext } from "react";

// third-party
import PropTypes from "prop-types";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";
import { connect, useSelector } from "react-redux";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { IntlProvider } from "react-intl";
import { ScrollContext } from "react-router-scroll-4";
import { Amplify, Auth, Hub } from "aws-amplify";

// application
import languages from "../i18n";
import { localeChange } from "../store/locale";

// pages
import Layout from "./Layout";
import HomePageOne from "./home/HomePageOne";

//context

import {
  ScreenSizeContext,
  TriggerContext,
  BookingFlowContext,
} from "../context/context";

import AuthState from "../context/auth/authState";
import ToolsState from "../context/tools/toolsState";
import AddressState from "../context/address/addressState";
import LayoutState from "../context/layout/layoutState";

function Root(props) {
  const [user, setUser] = useState(null);
  const [screen, setScreen] = useState(null);
  const [hideFooter, setHideFooter] = useState(false);

  const [notification, setNotification] = useState([]);
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [preTrigger, setPreTrigger] = useState(false);
  const [accountBalance, setAccountBalance] = useState(0);

  const [bookingFlow, setBookingFlow] = useState({
    location: "",
    isActive: false,
  });

  const screenChange = () => {
    setScreen(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", screenChange);

    // Hub.listen("auth", (data) => {
    //   if (data.payload.event === "signOut") {
    //     setUser(null);
    //   }
    //   if (data.payload.event === "signIn") {
    //     setUser(data.payload.data);
    //   }
    // });
    // preloader

    const { localeChange: changeLocale } = props;
    const direction = new URLSearchParams(window.location.search).get("dir");

    if (direction !== null) {
      changeLocale(direction === "rtl" ? "ar" : "en");
    }
  }, []);

  const shouldUpdateScroll = (prevRouterProps, { location }) =>
    prevRouterProps && location.pathname !== prevRouterProps.location.pathname;

  const { locale } = props;
  const { messages, direction } = languages[locale];

  return (
    <IntlProvider locale={locale} messages={messages}>
      <LayoutState>
        <AuthState>
          <AddressState>
            <ToolsState>
              <ScreenSizeContext.Provider
                value={{ screen, setScreen, hideFooter, setHideFooter }}
              >
                <TriggerContext.Provider value={{ preTrigger, setPreTrigger }}>
                  <BookingFlowContext.Provider
                    value={{ bookingFlow, setBookingFlow }}
                  >
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                      <HelmetProvider>
                        <Helmet
                          htmlAttributes={{ lang: locale, dir: direction }}
                        />
                        <ScrollContext shouldUpdateScroll={shouldUpdateScroll}>
                          <Switch>
                            <Route
                              path="/"
                              render={(props) => (
                                <Layout
                                  {...props}
                                  headerLayout="default"
                                  homeComponent={HomePageOne}
                                />
                              )}
                            />
                            <Redirect to="/" />
                          </Switch>
                        </ScrollContext>
                      </HelmetProvider>
                    </BrowserRouter>
                  </BookingFlowContext.Provider>
                </TriggerContext.Provider>
              </ScreenSizeContext.Provider>
            </ToolsState>
          </AddressState>
        </AuthState>
      </LayoutState>
    </IntlProvider>
  );
}

Root.propTypes = {
  /** current locale */
  locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

const mapDispatchToProps = {
  localeChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(Root);
