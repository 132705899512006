// react
import React, { useContext, useState, useEffect } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link, useHistory, useLocation } from "react-router-dom";

// application
import AsyncAction from "./AsyncAction";
import Currency from "./Currency";
import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from "../../svg";
import { compareAddItem } from "../../store/compare";
import { quickviewOpen } from "../../store/quickview";
import { url } from "../../services/utils";
import { wishlistAddItem } from "../../store/wishlist";
import DataManager from "./../../api/DataManager";

import {
  TriggerContext,
  LoginContext,
  BookingFlowContext,
} from "../../context/context";
import { formatDistance } from "../../api/Helper";
import authContext from "../../context/auth/authContext";

export default function ProductCard({ product }) {
  const history = useHistory();
  const [avgRating, setAvgRating] = useState(0);

  const { userLocation } = useContext(authContext);
  // const { product } = props;

  // useEffect(() => {
  //   console.log("tool distanc product updated", product);
  // }, [product?.distance]);

  useEffect(() => {
    DataManager.getToolReviews(product.id)
      .then((reviews) => {
        let pr = reviews;
        const ratingArray = pr.reduce((a, b) => a + b.rating, 0);

        const averageRating = ratingArray / pr.length;
        setAvgRating(averageRating);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  const distanceBadge = () => {
    // console.log("TOOL DISTANCES VALS", product?.distance);
    return (
      <div
        style={{
          width: "80px",
          height: "30px",
          marginTop: "-4px",
          marginRight: "-4px",
        }}
        className="p-1 ml-auto justify-content-center align-items-center bg-secondary shadow-lg  rounded  text-center p-auto"
      >
        <h6 className="text-black">{product.distance !== -1.0 && formatDistance(product?.distance)}</h6>
      </div>
    );
  };

  // useEffect(() => {
  //   console.log("USER LOCATION", userLocation?.location);
  // }, [userLocation]);

  return (
    <div
      className={"product-image-top-level w-100 mx-2 mx-lg-3 mb-4 mb-md-0"}
      onClick={() => {
        history.push(url.product(product));
      }}
    >
      <div
        className="w-100 h-100 rounded-lg "
        style={{
          backgroundImage: `url(${product.images[0]})`,
          backgroundSize: "contain",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          // border: "0.5px solid rgba(27, 28, 30, 0.1)",
        }}
      >
        {product?.distance != null &&
          userLocation?.location?.lat != null &&
          distanceBadge()}
      </div>

      <div className="product-image-low-level">
        <h5 className="text-primary font-weight-bold product-card-title text-center ">
          {product.name}
        </h5>

        <div className="product-rating-div">
          <h6 className="">
            <span className="mb-1">
              <b>${product?.hourlyRate}</b> /day
            </span>
            <Rating value={Math.round(avgRating)} />
          </h6>
        </div>
      </div>
    </div>
  );
}

ProductCard.propTypes = {
  /**
   * product object
   */
  product: PropTypes.object.isRequired,
  /**
   * product card layout
   * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
   */
  layout: PropTypes.oneOf([
    "grid-sm",
    "grid-nl",
    "grid-lg",
    "list",
    "horizontal",
  ]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
  quickviewOpen,
};

// export default connect(mapStateToProps, mapDispatchToProps)(ProductCard);
