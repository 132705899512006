import { Alert, Form, InputGroup, Row } from "react-bootstrap";
import React, { useContext, useEffect, useState } from "react";
import { Controller, useWatch } from "react-hook-form";
import AutoCompleteField from "../../shared/AutoCompleteField";
import DataManager from "../../../api/DataManager";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { FormGroup } from "reactstrap";
import toolsContext from "../../../context/tools/toolsContext";

export default function Step1({ control, errors, onSelectModel, data }) {
  const [toolBrand, setToolBrand] = useState(null);
  const [brands, setBrands] = useState([]);

  const { allSubCategories } = useContext(toolsContext);

  useEffect(() => {
    DataManager.getBrands().then((brans) => {
      setBrands(brans);
      //console.log(brans)
    });

    // PaymentService.setupBilling();
  }, []);

  const queryTools = (query, setValue) => {
    setValue(allSubCategories);
    if (query?.length === 0) {
      setValue(allSubCategories);
      return;
    }
    query = query?.toLowerCase();
    const filteredTools = allSubCategories?.filter((tool) => {
      return tool?.name?.toLowerCase().includes(query) ?? false;
    });
    setValue([...filteredTools, { name: query, id: "Other" }]);
    // alert(filteredTools.length);
  };

  const queryToolModels = (query, setValue) => {
    if (query?.length < 1) {
      //      setModels([]);
      setValue([]);
      return;
    }

    let brand = toolBrand;
    let model = query?.toUpperCase();
    // console.log(brand);
    // console.log(model);
    DataManager.getToolModels(brand, model)
      .then((data) => {
        let models = [...data, { name: query, id: "Other" }];
        // setModelsList(data);

        // console.log(models);
        //setModels(models);
        setValue(models);
      })
      .then((error) => {
        // console.log(error);
      });
  };

  const queryBrands = (query, setValue) => {
    query = query?.toLowerCase();

    if (query === undefined || query === "") {
      setValue(brands);
      return;
    }

    let relevantBrands = brands?.filter((tool) => {
      return tool?.name?.toLowerCase().includes(query) ?? false;
    });
    setValue([...relevantBrands, { name: query?.toUpperCase(), id: "-1" }]);
  };

  const toolCondition = () => {
    return (
      <FormGroup>
        <h6 className="text-black-50">
          What kind of condition is your tool in?
        </h6>
        <Controller
          control={control}
          name="condition"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <RadioGroup
              onChange={onChange}
              // defaultValue={`${data?.condition}` ?? "1"}
              className="flex flex-row mt-16 mb-32"
            >
              <Row className="ml-md-1">
                <FormControlLabel
                  value="1"
                  control={<Radio size={"small"} />}
                  className="inline"
                  label={
                    <b className="post-tool-condition-radio-button">Average</b>
                  }
                />
                <FormControlLabel
                  value="2"
                  control={<Radio className="payment-radio-button " />}
                  className="inline "
                  label={
                    <b className="post-tool-condition-radio-button">Good</b>
                  }
                />

                <FormControlLabel
                  value="3"
                  control={<Radio className="payment-radio-button" />}
                  className="inline "
                  label={
                    <b className="post-tool-condition-radio-button">Perfect</b>
                  }
                />
              </Row>
            </RadioGroup>
          )}
          rules={{
            required: "Please specify your tool's condition",
          }}
        />
        {errors && errors.condition && (
          <Alert variant="danger">{errors.condition.message}</Alert>
        )}
      </FormGroup>
    );
  };

  return (
    <>
      <Form.Group>
        <h6 className="text-black-50">Which brand is it?</h6>

        <Controller
          control={control}
          name="brand"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <AutoCompleteField
              textFieldProps={{
                textTransform: "uppercase",
                width: "100%",
                border: "1px solid #CED4DA",
                padding: "4px",
              }}
              insertable
              innerProps={{ padding: "2px" }}
              queryOptions={queryBrands}
              getValue={(val) => {
                return [val?.name];
              }}
              defaultValue={data?.brand ? [{ id: "1", name: data?.brand }] : []}
              placeHolder="Select brand"
              didSelect={(option, e) => {
                const value = { ...e, target: { value: option?.name } };
                // onChange(value);
                // console.log("option", option);

                setToolBrand(option?.id);
                onChange(value);
              }}
              // onChange={onChange}
            />
          )}
          rules={{
            required: "Please select a brand for your tool",
          }}
        />
        {/*{errors && errors.brand && (*/}
        {/*  <Alert variant="danger">{errors.brand.message}</Alert>*/}
        {/*)}*/}
      </Form.Group>
      <Form.Group>
        <h6 className="text-black-50">What type of tool do you own?</h6>

        <Controller
          control={control}
          name="type"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <AutoCompleteField
              textFieldProps={{
                textTransform: "uppercase",
                width: "100%",
                border: "1px solid #CED4DA",
                padding: "4px",
              }}
              insertable
              innerProps={{ padding: "2px" }}
              queryOptions={queryTools}
              getValue={(val) => {
                return [val?.name];
              }}
              defaultValue={data?.toolType ? [data?.toolType] : []}
              placeHolder="Select Tool"
              didSelect={(option, e) => {
                const value = { ...e, target: { value: option?.name } };
                // console.log("option", option);
                onChange(value);
              }}

              // onChange={onChange}
            />
          )}
          rules={{
            required: "Please select a tool type",
          }}
        />
        {errors && errors.type && (
          <Alert variant="danger">{errors.type.message}</Alert>
        )}
      </Form.Group>

      <Form.Group>
        <h6 className="text-black-50">Please specify the model number</h6>

        <Controller
          control={control}
          name="model"
          render={({ field: { onChange, onBlur, value, ref } }) => (
            <AutoCompleteField
              textFieldProps={{
                textTransform: "uppercase",
                width: "100%",
                border: "1px solid #CED4DA",
                padding: "4px",
              }}
              insertable
              innerProps={{ padding: "2px" }}
              queryOptions={queryToolModels}
              getValue={(val) => {
                return [val?.name];
              }}
              defaultValue={data?.model ? [{ id: "1", name: data?.model }] : []}
              placeHolder="Select Model"
              didSelect={(option, e) => {
                const value = { ...e, target: { value: option?.name } };
                // onChange(value);
                onSelectModel(option?.price);
                onChange(value);
              }}
              // onChange={onChange}
            />
          )}
          rules={{
            required: "Please select a tool type",
          }}
        />
        {/*{errors && errors.model && (*/}
        {/*  <Alert variant="danger">{errors.model.message}</Alert>*/}
        {/*)}*/}
      </Form.Group>
      <Form.Group>
        <h6 className="text-black-50">Give your tool a title</h6>
        <Controller
          control={control}
          render={({ field }) => (
            <Form.Control
              type="text"
              placeholder="E.g drill machine with extra bits"
              {...field}
              isInvalid={!!errors.name}
            />
          )}
          name="name"
          rules={{
            required: "Please enter a name for your tool",
          }}
        />
        {errors && errors.name && (
          <Alert variant="danger">{errors.name.message}</Alert>
        )}
      </Form.Group>

      <Form.Group>
        <h6 className="text-black-50">Describe your tool</h6>
        <Controller
          control={control}
          render={({ field }) => (
            <Form.Control
              rows={3}
              as={"textarea"}
              type="desc"
              placeholder="Optional"
              {...field}
            />
          )}
          name="desc"
        />
      </Form.Group>

      {toolCondition()}
    </>
  );
}
