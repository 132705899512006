import ToolsContext from "./toolsContext";
import toolsReducer from "./toolsReducer";
import { useContext, useEffect, useReducer } from "react";
import authReducer from "../auth/authReducer";
import DB from "../../api/DB";
import {
  LOAD_ALL_CATEGORIES,
  LOAD_ALL_SUBCATEGORIES,
  LOAD_ALL_TOOLS,
} from "../types";
import { SearchEngine } from "../../api/SearchEngine";
import { getProductsList } from "../../api/Helper";
import authContext from "../auth/authContext";
import { LocationManager } from "../../api/LocationManager";

const ToolsState = (props) => {
  const initialState = {
    allCategories: [],
    allTools: [],
    allSubCategories: [],
    popularTools: [],
    latestTools: [],
    searchResults: [],
    nearByTools: [],
    isLoading: true,
  };
  const [state, dispatch] = useReducer(toolsReducer, initialState);
  const { userLocation } = useContext(authContext);

  useEffect(() => {
    loadCategories();
    loadAllCustomerTools();
    loadSubCategories();
  }, []);

  useEffect(() => {
    dispatch({
      type: LOAD_ALL_TOOLS,
      payload: { tools: state.allTools, userLocation: userLocation },
    });
  }, [userLocation]);

  const loadSubCategories = () => {
    DB.getSubCategories().then((subCats) => {
      // console.log("sub-cats");
      // console.log(subCats);
      dispatch({ type: LOAD_ALL_SUBCATEGORIES, payload: subCats });
    });
  };

  const loadAllCustomerTools = () => {
    DB.loadCustomerTools().then((tools) => {
      dispatch({
        type: LOAD_ALL_TOOLS,
        payload: { tools: tools, userLocation: userLocation },
      });
    });
  };

  const loadCategories = () => {
    DB.getCategories().then((cats) => {
      dispatch({ type: LOAD_ALL_CATEGORIES, payload: cats });
    });
  };

  const getCustomerToolsByCategory = async (id) => {
    // console.log("Category Slug");
    // console.log(id);
    let tools = await DB.getToolsByCategory(id);
    return Promise.all(tools.map(DB.parseTool));
  };

  const getCustomerTool = async (id) => {
    let tool = await DB.getCustomerTool(id);
    tool.distance =
        (userLocation?.location?.lat && userLocation?.location?.long)
            ? LocationManager.getDistanceFromTool(tool, userLocation)
            : -1;

    return tool;
  };

  const getRelatedProducts = async (productSlug, limit) => {
    return state.allTools;
  };

  const getSuggestions = async (query, options = {}) => {


    //console.log("aalo1",userLocation);
    // console.log(query);
    // console.log("CURRENT LOCATION");
    // console.log(userLocation);
    return SearchEngine.findTool(
      query,
      { ...options, location: userLocation },
      state.allTools
    );
  };

  const searchTool = async (query, options = {}, filters) => {
    // console.log(options);
    // console.log(query);
    options = { ...options, location: userLocation };
    let tools = await SearchEngine.findTool(query, options, state.allTools);
    return getProductsList(tools, options, filters);
  };

  return (
    <ToolsContext.Provider
      value={{
        allCategories: state.allCategories,
        allTools: state.allTools,
        popularTools: state.popularTools,
        searchResults: state.searchResults,
        nearByTools: state.nearByTools,
        latestTools: state.latestTools,
        allSubCategories: state.allSubCategories,
        isLoading: state.isLoading,
        getCustomerTool,
        getRelatedProducts,
        searchTool,
        getSuggestions,
        getCustomerToolsByCategory,
      }}
    >
      {props.children}
    </ToolsContext.Provider>
  );
};

export default ToolsState;
