// react
import React, { useContext, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { useForm, Controller } from "react-hook-form";
import { Form, Card, Image, InputGroup } from "react-bootstrap";

// application
import PageHeader from "../shared/PageHeader";
import "../../scss/pages/_support.scss";
import showError, { showSuccess } from "../../api/Helper";

// blocks
import BlockMap from "../blocks/BlockMap";

// data stubs
import theme from "../../data/theme";
import { Person } from "@material-ui/icons";

// context
import authContext from "../../context/auth/authContext";
import Button from "@material-ui/core/Button";

function SitePageSupport() {
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors, isSubmitted },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const { user } = useContext(authContext);

  const onSubmit = async (values) => {
    setIsLoading(true);
    reset({ detail: "", subject: "" });
    //
    showSuccess(
      "Message sent. One of our support agents will get in touch with you soon."
    );

    setIsLoading(false);
    console.log(values);
  };

  const helpCard = (imgPath, title, text) => {
    return (
      <div className="my-3">
        <Card
          className="help-card my-auto"
          style={{ width: "19rem", height: "5rem" }}
        >
          <Card.Body className="h-100" style={{ padding: "5px" }}>
            <div className="row p-1 align-items-center ">
              <div className="col-3 align-middle">
                <Image
                  src={imgPath}
                  className="bg-red-600 w-full"
                  style={{ width: "50px", height: "50px" }}
                />
              </div>
              <div className="col-9">
                <Card.Title className="m-0">{title}</Card.Title>
                <Card.Text style={{ fontSize: "0.8rem" }}>{text}</Card.Text>
              </div>
            </div>
          </Card.Body>
        </Card>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Contact Us — ${theme.name}`}</title>
      </Helmet>

      {/*<Image*/}
      {/*    style={{ height: "30rem", width: "100%" }}*/}
      {/*    src="/images/slides/5.jpg"*/}
      {/*    className="bg-red-600 w-full h-64"*/}
      {/*/>*/}

      <section className="mt-3">
        <PageHeader header="Support" />
      </section>

      <div className="block">
        <div className="mx-0 mx-sm-5">
          <div className="card mb-0">
            <div className="card-body contact-us">
              <div className="contact-us__container">
                <div className="row">
                  <section className="col-12 col-lg-6 pr-md-3">
                    <h4 className="contact-us__header card-title">
                      We're always ready to help you out.
                    </h4>
                    <p className="pr-lg-5">
                      Fill out the form and a support agent will get in touch
                      with you to help resolve your issue within no time! &nbsp;
                    </p>
                    <span>
                      <strong>Email:</strong>{" "}
                      <a href="mailto:support@toology.ca">support@toology.ca</a>
                    </span>

                    <section className="d-flex flex-row flex-wrap justify-content-center justify-content-sm-between">
                      {helpCard(
                        "/images/support/insurance.png",
                        "Online Safety",
                        "Your personal information is protected"
                      )}
                      {helpCard(
                        "/images/support/help.png",
                        "Get Product Help",
                        "Have a project but confused what tools to rent? Shoot us a message"
                      )}
                      {helpCard(
                        "/images/support/coin.png",
                        "Earn Toology Credits",
                        "Earn Toology Credits and cash them out whenever you want"
                      )}
                      {helpCard(
                        "/images/support/safe.png",
                        "Fraud Protection",
                        "All tool owners and renters go are verified to prevent fraud"
                      )}
                    </section>
                  </section>
                  <section className="col-12 col-lg-6 border py-5 px-3">
                    <Form onSubmit={handleSubmit(onSubmit)}>
                      <Form.Group className="border">
                        <InputGroup>
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Form.Control
                                className="border-0"
                                type="Name"
                                required
                                placeholder={
                                  user?.id != null
                                    ? `${user?.fname} ${user?.lname}`
                                    : "Name"
                                }
                                disabled={user?.fname != null}
                                {...field}
                                // isInvalid={!!errors.name}
                              />
                            )}
                            name="name"
                            rules={{
                              required: "Name cannot be empty",
                            }}
                          />
                          {errors && errors?.name && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.name?.message}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="border">
                        <InputGroup>
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Form.Control
                                className="border-0"
                                type="Email"
                                required
                                placeholder={user?.email ?? "Email"}
                                disabled={user?.email != null}
                                {...field}
                                // isInvalid={!!errors.name}
                              />
                            )}
                            name="email"
                            rules={{
                              required: "Email cannot be empty",
                            }}
                          />
                          {errors && errors?.email && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.email?.message}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="border">
                        <InputGroup>
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Form.Control
                                className="border-0"
                                type="Text"
                                required
                                placeholder="Enter a subject"
                                {...field}
                                // isInvalid={!!errors.name}
                              />
                            )}
                            name="subject"
                            rules={{
                              required: "Subject cannot be empty",
                            }}
                          />
                          {errors && errors?.subject && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.subject?.message}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>

                      <Form.Group className="border">
                        <InputGroup>
                          <Controller
                            control={control}
                            render={({ field }) => (
                              <Form.Control
                                className="border-0"
                                type="Text"
                                as="textarea"
                                required
                                placeholder="Describe the issue you are facing"
                                {...field}
                                // isInvalid={!!errors.name}
                                style={{ minHeight: "20ch" }}
                              />
                            )}
                            name="detail"
                            rules={{
                              required: "Please enter your query",
                            }}
                          />
                          {errors && errors?.detail && (
                            <Form.Control.Feedback type="invalid">
                              {errors?.detail?.message}
                            </Form.Control.Feedback>
                          )}
                        </InputGroup>
                      </Form.Group>

                      <button
                        type="submit"
                        className="btn btn-primary px-5 py-2"
                        onClick={() => {
                          // showSuccess("Message sent. One of our support agents will get in touch with you soon.");
                          onSubmit();
                        }}
                      >
                        {isLoading && (
                          <span
                            className="spinner-border spinner-border-sm"
                            role="status"
                            aria-hidden="true"
                          />
                        )}

                        {!isLoading && "Submit"}
                      </button>
                    </Form>
                  </section>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SitePageSupport;
