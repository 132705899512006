/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const getConversation = /* GraphQL */ `
  query GetConversation($id: ID!) {
    getConversation(id: $id) {
      id
      members
      memberIDs
      toolRentalID
      createdAt
      updatedAt
      conversations {
        nextToken
      }
      messages {
        nextToken
      }
    }
  }
`;
export const listConversations = /* GraphQL */ `
  query ListConversations(
    $filter: ModelConversationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConversations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        members
        memberIDs
        toolRentalID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getMessage = /* GraphQL */ `
  query GetMessage($id: ID!) {
    getMessage(id: $id) {
      id
      userID
      receiverID
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      content
      conversationID
      createdAt
      updatedAt
      messageDate
    }
  }
`;
export const listMessages = /* GraphQL */ `
  query ListMessages(
    $filter: ModelMessageFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listMessages(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        receiverID
        content
        conversationID
        createdAt
        updatedAt
        messageDate
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      fname
      lname
      zipCode
      email
      number
      latitude
      longitude
      profileImage
      userTools {
        nextToken
      }
      convos {
        nextToken
      }
      stripeCustomerID
      stripeConnectID
      paypalLinkedEmail
      bankAccountLinked
      reviews {
        nextToken
      }
      billingAddressID
      billingAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      shippingAddressID
      shippingAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      UserAddressList {
        nextToken
      }
      toolsRented {
        nextToken
      }
      toolsLended {
        nextToken
      }
      transactions {
        nextToken
      }
      referredByUserID
      isReferralComplete
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAddress = /* GraphQL */ `
  query GetUserAddress($id: ID!) {
    getUserAddress(id: $id) {
      id
      userID
      addressLine1
      addressLine2
      city
      state
      postcode
      firstName
      lastName
      phoneNumber
      lat
      lng
      createdAt
      updatedAt
    }
  }
`;
export const listUserAddresss = /* GraphQL */ `
  query ListUserAddresss(
    $filter: ModelUserAddressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAddresss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomerTool = /* GraphQL */ `
  query GetCustomerTool($id: ID!) {
    getCustomerTool(id: $id) {
      id
      name
      des
      userID
      imageList
      rate
      toolID
      toolType {
        id
        name
        image
        description
        suggestedRate
        categoryID
        createdAt
        updatedAt
      }
      brand
      condition
      model
      defects
      year
      lat
      long
      reviews {
        nextToken
      }
      addressID
      userAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      createdAt
      userBlockedDays {
        nextToken
      }
      updatedAt
    }
  }
`;
export const listCustomerTools = /* GraphQL */ `
  query ListCustomerTools(
    $filter: ModelCustomerToolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomerTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        des
        userID
        imageList
        rate
        toolID
        brand
        condition
        model
        defects
        year
        lat
        long
        addressID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserBlockedDays = /* GraphQL */ `
  query GetUserBlockedDays($id: ID!) {
    getUserBlockedDays(id: $id) {
      id
      customerToolID
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const listUserBlockedDayss = /* GraphQL */ `
  query ListUserBlockedDayss(
    $filter: ModelUserBlockedDaysFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserBlockedDayss(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        customerToolID
        startDate
        endDate
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTool = /* GraphQL */ `
  query GetTool($id: ID!) {
    getTool(id: $id) {
      id
      name
      image
      description
      customerTools {
        nextToken
      }
      suggestedRate
      categoryID
      createdAt
      updatedAt
    }
  }
`;
export const listTools = /* GraphQL */ `
  query ListTools(
    $filter: ModelToolFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTools(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        image
        description
        suggestedRate
        categoryID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCategory = /* GraphQL */ `
  query GetCategory($id: ID!) {
    getCategory(id: $id) {
      id
      image
      name
      tools {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCategorys = /* GraphQL */ `
  query ListCategorys(
    $filter: ModelCategoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCategorys(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        image
        name
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getToolRental = /* GraphQL */ `
  query GetToolRental($id: ID!) {
    getToolRental(id: $id) {
      id
      buyerID
      sellerID
      bookingType
      bookedStartDate
      bookedEndDate
      daysRented
      totalToolBaseRentPrice
      totalCompleteToolRentPrice
      customerToolID
      tool {
        id
        name
        des
        userID
        imageList
        rate
        toolID
        brand
        condition
        model
        defects
        year
        lat
        long
        addressID
        createdAt
        updatedAt
      }
      bookingNumber
      invoiceJSON
      linkedTransactions {
        nextToken
      }
      conversationID
      conversation {
        id
        members
        memberIDs
        toolRentalID
        createdAt
        updatedAt
      }
      autoPayoutToSeller
      payoutDone
      createdAt
      updatedAt
    }
  }
`;
export const listToolRentals = /* GraphQL */ `
  query ListToolRentals(
    $filter: ModelToolRentalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listToolRentals(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        buyerID
        sellerID
        bookingType
        bookedStartDate
        bookedEndDate
        daysRented
        totalToolBaseRentPrice
        totalCompleteToolRentPrice
        customerToolID
        bookingNumber
        invoiceJSON
        conversationID
        autoPayoutToSeller
        payoutDone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPayment = /* GraphQL */ `
  query GetPayment($id: ID!) {
    getPayment(id: $id) {
      id
      userID
      amount
      stripePaymentID
      stripeWithdrawalTransferID
      stripeWithdrawalPayoutID
      paypalSenderBatchID
      paypalPaymentID
      paymentType
      promoCodeID
      promoCode {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const listPayments = /* GraphQL */ `
  query ListPayments(
    $filter: ModelPaymentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPayments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        amount
        stripePaymentID
        stripeWithdrawalTransferID
        stripeWithdrawalPayoutID
        paypalSenderBatchID
        paypalPaymentID
        paymentType
        promoCodeID
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTransaction = /* GraphQL */ `
  query GetTransaction($id: ID!) {
    getTransaction(id: $id) {
      id
      amount
      transactionCategory
      transactionType
      transactionStatus
      toolRentalID
      linkedToolRental {
        id
        buyerID
        sellerID
        bookingType
        bookedStartDate
        bookedEndDate
        daysRented
        totalToolBaseRentPrice
        totalCompleteToolRentPrice
        customerToolID
        bookingNumber
        invoiceJSON
        conversationID
        autoPayoutToSeller
        payoutDone
        createdAt
        updatedAt
      }
      paymentID
      linkedPayment {
        id
        userID
        amount
        stripePaymentID
        stripeWithdrawalTransferID
        stripeWithdrawalPayoutID
        paypalSenderBatchID
        paypalPaymentID
        paymentType
        promoCodeID
        date
        createdAt
        updatedAt
      }
      metadata
      userID
      date
      createdAt
      updatedAt
    }
  }
`;
export const listTransactions = /* GraphQL */ `
  query ListTransactions(
    $filter: ModelTransactionFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTransactions(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        amount
        transactionCategory
        transactionType
        transactionStatus
        toolRentalID
        paymentID
        metadata
        userID
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPaymentIntent = /* GraphQL */ `
  query GetPaymentIntent($id: ID!) {
    getPaymentIntent(id: $id) {
      id
      userID
      toolID
      toolOwnerID
      bookedStartDate
      bookedEndDate
      amount
      transferReferralUserID
      transferReferalAmount
      promoCodeID
      createdAt
      updatedAt
    }
  }
`;
export const listPaymentIntents = /* GraphQL */ `
  query ListPaymentIntents(
    $filter: ModelPaymentIntentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPaymentIntents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        toolID
        toolOwnerID
        bookedStartDate
        bookedEndDate
        amount
        transferReferralUserID
        transferReferalAmount
        promoCodeID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserReview = /* GraphQL */ `
  query GetUserReview($id: ID!) {
    getUserReview(id: $id) {
      id
      userID
      rating
      comment
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserReviews = /* GraphQL */ `
  query ListUserReviews(
    $filter: ModelUserReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userID
        rating
        comment
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getToolReview = /* GraphQL */ `
  query GetToolReview($id: ID!) {
    getToolReview(id: $id) {
      id
      customerToolID
      rating
      comment
      userID
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listToolReviews = /* GraphQL */ `
  query ListToolReviews(
    $filter: ModelToolReviewFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listToolReviews(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        customerToolID
        rating
        comment
        userID
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConstant = /* GraphQL */ `
  query GetConstant($id: ID!) {
    getConstant(id: $id) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const listConstants = /* GraphQL */ `
  query ListConstants(
    $filter: ModelConstantFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConstants(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getPromoCode = /* GraphQL */ `
  query GetPromoCode($id: ID!) {
    getPromoCode(id: $id) {
      id
      code
      type
      applyType
      description
      quantity
      discount
      minRentDays
      maxDiscount
      discountType
      activeDate
      expiryDate
      firstRentalApplicableOnly
      createdAt
      updatedAt
    }
  }
`;
export const listPromoCodes = /* GraphQL */ `
  query ListPromoCodes(
    $filter: ModelPromoCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listPromoCodes(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserRedeemedPromoCode = /* GraphQL */ `
  query GetUserRedeemedPromoCode($id: ID!) {
    getUserRedeemedPromoCode(id: $id) {
      id
      promoCodeID
      userID
      linkedPromoCode {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const listUserRedeemedPromoCodes = /* GraphQL */ `
  query ListUserRedeemedPromoCodes(
    $filter: ModelUserRedeemedPromoCodeFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserRedeemedPromoCodes(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        promoCodeID
        userID
        date
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getToolPriceFactor = /* GraphQL */ `
  query GetToolPriceFactor($id: ID!) {
    getToolPriceFactor(id: $id) {
      id
      durationDays
      factor
      createdAt
      updatedAt
    }
  }
`;
export const listToolPriceFactors = /* GraphQL */ `
  query ListToolPriceFactors(
    $filter: ModelToolPriceFactorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listToolPriceFactors(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        durationDays
        factor
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTestModel = /* GraphQL */ `
  query GetTestModel($id: ID!) {
    getTestModel(id: $id) {
      id
      testData
      createdAt
      updatedAt
    }
  }
`;
export const listTestModels = /* GraphQL */ `
  query ListTestModels(
    $filter: ModeltestModelFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTestModels(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        testData
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const toolRentalByBuyerId = /* GraphQL */ `
  query ToolRentalByBuyerId(
    $buyerID: ID
    $sortDirection: ModelSortDirection
    $filter: ModelToolRentalFilterInput
    $limit: Int
    $nextToken: String
  ) {
    toolRentalByBuyerID(
      buyerID: $buyerID
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        buyerID
        sellerID
        bookingType
        bookedStartDate
        bookedEndDate
        daysRented
        totalToolBaseRentPrice
        totalCompleteToolRentPrice
        customerToolID
        bookingNumber
        invoiceJSON
        conversationID
        autoPayoutToSeller
        payoutDone
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const searchCustomerTools = /* GraphQL */ `
  query SearchCustomerTools(
    $filter: SearchableCustomerToolFilterInput
    $sort: SearchableCustomerToolSortInput
    $limit: Int
    $nextToken: String
    $from: Int
  ) {
    searchCustomerTools(
      filter: $filter
      sort: $sort
      limit: $limit
      nextToken: $nextToken
      from: $from
    ) {
      items {
        id
        name
        des
        userID
        imageList
        rate
        toolID
        brand
        condition
        model
        defects
        year
        lat
        long
        addressID
        createdAt
        updatedAt
      }
      nextToken
      total
    }
  }
`;
