// react
import React, { useContext, useEffect } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
import theme from "../../data/theme";
import authContext from "../../context/auth/authContext";
import { Form } from "react-bootstrap";
import moment from "moment";
import { PanoramaFishEye, RemoveRedEye } from "@material-ui/icons";

export default function AccountTransactions() {
  const { transactions, getUserSuccessfulTransactions } =
    useContext(authContext);

  useEffect(() => {
    getUserSuccessfulTransactions();
  }, []);

  const rentals = (list) => {
    return (
      <div className="card">
        <div className="order-header">
          <h5 className="order-header__title">Transactions </h5>
        </div>
        <div className="card-divider" />
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Type</th>
                  <th>Date</th>
                  <th>View</th>
                </tr>
              </thead>
              <tbody className="card-table__body card-table__body--merge-rows p-2">
                {list?.map((tool, index) => {
                  return (
                    <tr>
                      <td>{index}</td>
                      <td>{tool?.transactionType}</td>
                      <td>{moment(tool?.createdAt).format("MM/DD/YYYY")}</td>

                      <td>
                        <RemoveRedEye />
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Order Details — ${theme.name}`}</title>
      </Helmet>

      {rentals(transactions)}
      <br />
    </React.Fragment>
  );
}
