import { useCallback, useState } from "react";

export default function UploadedImages({ images, removeImage }) {
  return (
    <div className="d-flex flex-row">
      {images?.length > 0 &&
        images.map((image, index) => {
          return (
            <div className="d-flex flex-column align-items-center justify-content-center">
              <img
                src={image}
                className=" m-2 border"
                style={{
                  width: "100px",
                  height: "100px",
                  objectFit: "contain",
                }}
              />
              <p
                onClick={() => {
                  removeImage(index);
                }}
                className="text-danger my-auto cursor-pointer"
              >
                Remove
              </p>
            </div>
          );
        })}
    </div>
  );
}
