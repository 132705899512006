// react
import React from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import AsyncAction from "../shared/AsyncAction";
import Currency from "../shared/Currency";
import { Cart16Svg } from "../../svg";
import { cartAddItem } from "../../store/cart";
import { url } from "../../services/utils";
import { formatDistance } from "../../api/Helper";

function Suggestions(props) {
  const { context, className, products, cartAddItem } = props;
  const rootClasses = classNames(
    `suggestions suggestions--location--${context}`,
    className
  );

  const list =
    products &&
    products.map((product) => (
      <li key={product.id} className="suggestions__item">
        {product.images && product.images.length > 0 && (
          <div className="suggestions__item-image product-image">
            <div className="product-image__body">
              <img
                className="product-image__img"
                src={product.images[0]}
                alt=""
              />
            </div>
          </div>
        )}
        <div
          className="suggestions__item-info d-flex flex-column justify-content-center font-weight-bold"
          style={{ fontSize: "1.05rem" }}
        >
          <Link className="suggestions__item-name" to={url.product(product)}>
            {product.name}
          </Link>
          <div className="suggestions__item-meta"></div>
        </div>
        <div className="d-flex flex-column align-items-end">
          <div className="suggestions__item-price text-primary">
              {console.log("aalo",product.distance)}
            {product.distance !== -1.0 && formatDistance(product.distance)}
          </div>
          <div
            className="suggestions__item-price"
            style={{ fontSize: "1.1rem" }}
          >
            ${product.hourlyRate}/day
          </div>
        </div>
        {context === "header" && (
          <div className="suggestions__item-actions"></div>
        )}
      </li>
    ));

  return (
    <div className={rootClasses}>
      <ul className="suggestions__list">{list}</ul>
    </div>
  );
}

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  cartAddItem,
};

export default connect(mapStateToProps, mapDispatchToProps)(Suggestions);
