import { API } from "@aws-amplify/api";
import { loadStripe } from "@stripe/stripe-js";
import moment from "moment";
import { getNumberOfDaysFromDate } from "./Helper";
import AuthService from "./AuthService";
import DataManager from "./DataManager";
export class PaymentService {
  static parsePayload = (payload) => {
    return payload?.paymentIntent?.status === "succeeded";
  };

  static paymentStatus = {
    NOTNEEDED: "NOTNEEDED",
    STRIPE: "STRIPE",
  };

  // static stripeKey =
  //   "pk_test_51IuOYTGY0fVtieFHdPLXy6iRenrerOoQNd8OYTCKDLwsRZ1I9uvoOB7FGv7X0BdxGqgHf2dtTzgI4A0zF55CxQgQ00cKfh4NOn";

  //Rove test key
  // static stripeKey = "pk_test_51I4bjKBcHWvWz0kbgMBw7NKwWnm0rj2TznTyCboydkc8LZCLu2Ewrqib3R4gjGy57l4VgQP8MzB4Ebqtodt1sSNt00ytPKPp2K";

  //Toology Live key
  static stripeKey = "pk_live_51IuOYTGY0fVtieFHzub9vQl28hlHuFDIRMYhciz6w3pvyguOoK6nFaEqEcsQ0wbmTFwMThg4cnsM58xUxZQ1iHkP0005lM7gux";

  static async bookToolWithCredits(
    tool,
    value,
    useCredits,
    useDamageProtection,
    promocode
  ) {
    let bookingStartDate = value?.[0]?.getTime();
    let bookingEndDate = value?.[1]?.getTime() ?? bookingStartDate;

    let toolID = tool?.id;
    let toolOwnerID = tool?.userID;

    let userID = await AuthService.getUsername();
    const apiName = "PaymentServiceApi";
    const path = "/BookTool";
    let myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        // OPTIONAL
        userID: userID,
        toolID: toolID,
        toolOwnerID: toolOwnerID,
        bookingStart: bookingStartDate,
        bookingEnd: bookingEndDate,
        bookingConfig: {
          useCredits: useCredits,
          useDamageProtection: useDamageProtection,
        },
      },
    };
    //APPLY PROMO CODE IF ADDED
    if (promocode != null) {
      myInit["body"]["bookingConfig"]["usePromoCode"] = promocode;
    }

    console.log("PAYING WITH CREDITS", myInit);

    //{stripeWithCredits: "pi_1IxcS5BcHWvWz0kbbtkRAgR6_secret_PT6PJbEGNSsM6HONEDjqRymJS", paypalSecretCredits:
    let result = await API.post(apiName, path, myInit);
    // console.log("Result");
    console.log("PAYING WITH CREDITS SUCCES ", result);
    return result?.data?.success === true;
  }

  static async payWithCardOnFile(
    tool,
    value,
    card,
    useCredits,
    useDamageProtection,
    promocode
  ) {
    let bookingStartDate = value?.[0]?.getTime();
    let bookingEndDate = value?.[1]?.getTime() ?? bookingStartDate;

    let toolID = tool?.id;
    let toolOwnerID = tool?.userID;

    let userID = await AuthService.getUsername();
    const apiName = "PaymentServiceApi";
    const path = "/CreateStripeCardPaymentIntent";
    let myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        // OPTIONAL
        userID: userID,
        toolID: toolID,
        toolOwnerID: toolOwnerID,
        bookingStart: bookingStartDate,
        bookingEnd: bookingEndDate,
        useStripe: "true",
        cardID: card,
        bookingConfig: {
          useCredits: useCredits,
          useDamageProtection: useDamageProtection,
        },
      },
    };
    //APPLY PROMO CODE IF ADDED
    if (promocode != null) {
      myInit["body"]["bookingConfig"]["usePromoCode"] = promocode;
    }

    let result = await API.post(apiName, path, myInit);

    return {
      clientSecret: result?.data?.stripe?.response?.clientSecret,
      paypalSecret: result?.data?.paypal?.response?.id,

      billingInfo: result?.data?.billingInfo,
    };
  }

  static async openPayment(userID, tool, startDate, endDate, images) {
    const stripePromise = loadStripe(
      "pk_test_51I4bjKBcHWvWz0kbgMBw7NKwWnm0rj2TznTyCboydkc8LZCLu2Ewrqib3R4gjGy57l4VgQP8MzB4Ebqtodt1sSNt00ytPKPp2K"
    );

    let stripe = await stripePromise;
    let request = await API.get("StripeCheckoutApi", "/checkout", {
      // OPTIONAL

      queryStringParameters: {
        userID: userID,
        tool: JSON.stringify(tool),
        startDateString: moment(startDate).format("MM/DD/YYYY"),
        endDateString: moment(endDate).format("MM/DD/YYYY"),
        startDate: startDate,
        endDate: endDate,
        numberOfDays: getNumberOfDaysFromDate(startDate, endDate),
        images: JSON.stringify(images),
      },
      headers: {}, // OPTIONAL
      response: false, // OPTIONAL (return the entire Axios response object instead of only response.data)
    });

    let id = request.id;

    const result = await stripe.redirectToCheckout({
      sessionId: id,
    });

    return result;
  }

  static async getPaid(amount, stripeConnectID) {
    const apiName = "StripePayoutApi";
    const path = "/RequestPayout";

    let userID = await AuthService.getUsername();

    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        amount: parseFloat(amount) * 100,
        userID: userID,
        stripeConnectID: stripeConnectID,
      },
    };
    // console.log(myInit);
    let result = await API.post(apiName, path, myInit);
    return result;
  }

  static async getPaidPaypal(amount, email) {
    const apiName = "PaypalPayoutApi";
    const path = "/RequestPaypalPayout";

    let userID = await AuthService.getUsername();

    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        amount: parseFloat(amount) * 100,
        userID: userID,
        paypalEmail: email,
      },
    };
    // console.log(myInit);
    let result = await API.post(apiName, path, myInit);
    return result;
  }

  static async getConnectedAccount(stripeConnectID) {
    const apiName = "StripePayoutApi";
    const path = "/GetConnectedAccount";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: { stripeConnectID: stripeConnectID },
    };

    // console.log(myInit);
    let result = await API.get(apiName, path, myInit);
    return result;
  }

  static async setupBilling(data, address, userData) {
    const apiName = "StripePayoutApi";
    const path = "/createConnectedAccount";

    let userID = await AuthService.getUsername();

    //console.log('userID '+userID)
    //101 9 Ave SW, Calgary, AB T2P 1J9, Canada

    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        // OPTIONAL
        userID: userID,
        email: userData?.email ?? "hassananjum331@gmail.com",
        firstName: data?.fname ?? "Hassan",
        lastName: data?.lname ?? "Jamal",
        phoneNumber: userData?.number ?? "+14165550134",
        day: data?.day ?? "2",
        month: data?.month ?? "1",
        year: data?.year ?? "1998",
        line1Address: address?.addressLine1 ?? "101 9 Ave SW",
        postalCode: address?.postcode ?? "T2P1J9",
        city: address?.city ?? "Calgary",
        state: address?.state ?? "Alberta",
        accountNo: data?.accountNumber ?? "000123456789",
        institutionNo:
          data?.transitNumber + "-" + data?.instNumber ??
          "11000-000" + data?.transitNumber,
      },
    };

    // console.log("myInit", myInit);

    let result = await API.post(apiName, path, myInit);

    // console.log("result");
    //
    // console.log(result);
    return result;
  }

  static async getStripeDetails(stripeID) {
    // let stripeID = await DataManager.getStripeID();
    const apiName = "PaymentServiceApi";
    const path = "/GetStripeCustomerPaymentDetails";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      queryStringParameters: {
        // OPTIONAL
        stripeCustomerID: stripeID,
      },
    };

    let result = await API.get(apiName, path, myInit);

    let cardData = result?.data?.response?.data;
    // console.log(cardData);
    //
    // console.log("cardData");
    let cards = cardData?.map((data) => {
      let card = data?.card;
      card.id = data?.id;
      return card;
    });
    return cards?.slice(0, Math.min(cards?.length, 4));
  }

  //PAYPAL
  static async onAuthroizePaypal(paymentID, payerID) {
    const apiName = "PaymentServiceApi";
    const path = "/PaypalExecutePayment";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        // OPTIONAL
        paymentID: paymentID,
        payerID: payerID,
      },
    };

    let result = await API.post(apiName, path, myInit);
    // console.log("PAYPAL");
    // console.log(result);
    return result?.data;
  }

  static async onBookingInitiate(tool, bookingTiming) {
    let bookingStartDate = bookingTiming?.[0]?.getTime();
    let bookingEndDate = bookingTiming?.[1]?.getTime() ?? bookingStartDate;

    let toolID = tool?.id;
    let userID = await AuthService.getUsername();
    const apiName = "PaymentServiceApi";
    const path = "/GenerateBookingDetails";
    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        userID: userID,
        toolID: toolID,
        bookingStart: bookingStartDate,
        bookingEnd: bookingEndDate,
      },
    };

    let result = await API.post(apiName, path, myInit);
    return result?.data?.billingInfo;
  }

  static async getPaymentIntent(
    tool,
    bookingTiming,
    useCredits,
    useDamageProtection,
    promocode
  ) {
    let bookingStartDate = bookingTiming?.[0]?.getTime();
    let bookingEndDate = bookingTiming?.[1]?.getTime() ?? bookingStartDate;

    let toolID = tool?.id;
    let userID = await AuthService.getUsername();
    const apiName = "PaymentServiceApi";
    const path = "/CreatePaymentIntent";
    let myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        userID: userID,
        toolID: toolID,
        bookingStart: bookingStartDate,
        bookingEnd: bookingEndDate,
        toolOwnerID: tool?.userID,
        bookingConfig: {
          useCredits: useCredits,
          useDamageProtection: useDamageProtection,
        },
      },
    };

    //APPLY PROMO CODE IF ADDED
    if (promocode != null) {
      myInit["body"]["bookingConfig"]["usePromoCode"] = promocode;
    }

    console.log("SEDING REQUEST", myInit);

    let result = await API.post(apiName, path, myInit);
    result = result?.data;
    return result;
  }

  static async validatePromoCode(code, bookingTimings) {
    let userID = await AuthService.getUsername();
    const apiName = "PaymentServiceApi";
    const path = "/ValidatePromoCode";

    let bookingStartDate = bookingTimings?.[0]?.getTime();
    let bookingEndDate = bookingTimings?.[1]?.getTime() ?? bookingStartDate;

    const myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        userID: userID,
        promoCode: code,
        bookingStartDate:bookingStartDate,
        bookingEndDate:bookingEndDate,
      },
    };

    let result = await API.post(apiName, path, myInit);

    console.log("Validating PromoCode", result);
    return result?.data;
  }
}
