import React, { useContext, useEffect } from "react";
import "../../scss/posttool/posting-success.scss";
import moment from "moment";
import { Link } from "react-router-dom";
import { ScreenSizeContext } from "../../context/context";
import { Image } from "react-bootstrap";

export default function PostingSuccess({ postedTool }) {
  const { setHideFooter } = useContext(ScreenSizeContext);

  useEffect(() => {
    setHideFooter(true);

    return () => {
      setHideFooter(false);
    };
  }, []);

  return (
    <div className="w-100 h-100 text-center py-4">
      <h1>Your tool has been published!</h1>
      <div className="booking-sucess-tool-data">
        <Image
          src={postedTool?.images?.[0]}
          className="booking-success-tool-image mb-3"
          style={{ objectFit: "contain" }}
        />
        <row>
          <p className="booking-success-thankyou">
            Congratulations! Your tool has been posted.
          </p>
          <p className="booking-success-booking-duration">
            <br />
            You have posted <b>{postedTool?.name}</b>
            <br />
            Now all that's left is to wait for your first booking!
          </p>

          <Link
            className="booking-success-nessage-button px-5 py-2"
            to={"/shop/products/" + postedTool?.id}
          >
            View Tool as Renter
          </Link>
          <br />
          <br />
          <Link to={"/"}>Return to Homepage</Link>

          <br />
        </row>
      </div>
    </div>
  );
}
