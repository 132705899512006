import { useEffect, useReducer, useState } from "react";
import AuthContext from "./authContext";
import authReducer from "./authReducer";
import AuthService from "../../api/AuthService";
import Amplify, { Auth, Hub } from "aws-amplify";

import {
  LIST_POSTED_TOOLS,
  LOAD_PAYMENT_DETAILS,
  LOAD_USER_ADDRESS,
  LOAD_USER_LENDINGS,
  LOAD_USER_RENTALS,
  LOAD_USER_TRANSACTIONS,
  SET_ACCOUNT_BALANCE,
  SET_USER_LOCATION,
  UPDATE_ACCOUNT_BALANCE,
  UPDATE_NOTIFICATIONS,
  UPDATE_USER_DEFAULT_ADDRESS,
  USER_LOGGED_IN,
} from "../types";
import DataManager, {
  monitorAllChats,
  monitorTransactions,
} from "../../api/DataManager";
import { PaymentService } from "../../api/PaymentService";
import DB from "../../api/DB";

const AuthState = (props) => {
  const initialState = {
    address: {},
    user: null,
    userData: null,
    isLoggedIn: false,
    balance: 0,
    notifications: [],
    userLocation: null,
    billingInfo: null,
    isUploadingPhoto: false,
    toolsRented: [],
    toolsLended: [],
    transactions: [],
    postedTools: [],
    defaultAddress: null,
  };
  const [state, dispatch] = useReducer(authReducer, initialState);

  const [messageSubscription, setMessageSubscription] = useState(null);
  const [transactionSubscription, setTransactionSubscription] = useState(null);

  useEffect(() => {
    dispatch({
      type: UPDATE_USER_DEFAULT_ADDRESS,
      payload: state?.user?.billingAddressID,
    });
  }, [state.user]);

  const updateProfilePhoto = () => {};

  const setUserLocation = (location, zipCode) => {
    dispatch({
      type: SET_USER_LOCATION,
      payload: { location: location, zipCode: zipCode },
    });
  };

  const getPaid = (amount) => {
    return PaymentService.getPaid(amount, state.user?.stripeConnectID);
  };

  const getPaidPaypal = (amount, email) => {
    return PaymentService.getPaidPaypal(amount, email);
  };

  const hideLoading = () => {
    const preloader = document?.querySelector(".site-preloader");

    preloader?.addEventListener("transitionend", (event) => {
      if (event?.propertyName === "opacity") {
        preloader?.parentNode?.removeChild(preloader);
      }
    });
    preloader?.classList?.add("site-preloader__fade");
  };

  const updateAddress = async (address) => {};

  const loadAddress = async () => {
    let address = await DataManager.getUserAddress();
    dispatch({ type: LOAD_USER_ADDRESS, payload: address });
  };

  const setupBillingInfo = async (address, data) => {
    return PaymentService.setupBilling(data, address, state.user);
  };

  const getBillingInfo = async () => {
    return PaymentService.getConnectedAccount(state.user?.stripeConnectID);
  };

  const loadUserBillingInfo = async () => {
    const billingInfo = await DB.getBillingInfo();
    // console.log(billingInfo);
    dispatch({ type: LOAD_PAYMENT_DETAILS, payload: billingInfo });
  };

  const loadInitialData = async () => {
    await loadUser();
    hideLoading();
  };

  useEffect(() => {
    Hub.listen("auth", async ({ payload: { event, data } }) => {
      switch (event) {
        case "signIn":
          await loadUser();
          break;
        case "signOut":
          // this.setState({ user: null });
          break;
        case "customOAuthState":
          await loadUser();
        // this.setState({ customState: data });
      }
    });

    Auth.currentAuthenticatedUser()
      .then((user) => loadUser())
      .catch(() => {});
  }, []);

  const getToolsRented = () => {
    DB.getToolsRented()
      .then((rentals) => {
        console.log("GETTING RENTALS", rentals);

        dispatch({ type: LOAD_USER_RENTALS, payload: rentals });
      })
      .catch((error) => {
        console.log("GETTING RENTALS ERROR", error);
      });
  };

  const getToolLendings = () => {
    DB.getToolsLendings()
      .then((rentals) => {
        console.log("GETTING LENDINGS", rentals);

        dispatch({ type: LOAD_USER_LENDINGS, payload: rentals });
      })
      .catch((error) => {
        console.log("GETTING LENDINGS ERROR", error);
      });
  };

  const getUserSuccessfulTransactions = () => {
    DB.getUserSuccessfulTransactions()
      .then((trans) => {
        console.log("GETTING trans", trans);

        dispatch({ type: LOAD_USER_TRANSACTIONS, payload: trans });
      })
      .catch((error) => {
        console.log("GETTING trans ERROR", error);
      });
  };

  const listMyPostedTools = () => {
    DB.getMyPostedTools()
      .then((items) => {
        dispatch({ type: LIST_POSTED_TOOLS, payload: items });
      })
      .catch((error) => {});
  };

  const loadUser = async () => {
    const user = await AuthService.isLoggedIn();

    if (!user) {
      dispatch({
        type: USER_LOGGED_IN,
        payload: { isLoggedIn: false, user: null },
      });
    } else {
      // let userData = await AuthService.getUserInfo();
      const userCognito = await AuthService.getUserInfo();
      let userData = await DataManager.loadUserDetails();
      console.log("LOADING USER DETAILS", userData);
      await loadAddress();

      // console.log("GETTING USER DATA");
      // console.log(userData);
      dispatch({
        type: USER_LOGGED_IN,
        payload: { isLoggedIn: true, user: userData, userCognito: userCognito },
      });
    }
  };

  const login = async (username, password) => {
    let user = await AuthService.signIn(username, password);

    if (user === undefined) {
      // console.log("trouble");
      // setIsLoginLoading(false);
      return;
    }
    await loadUser();
  };

  const signUpUser = async (
    username,
    password,
    number,
    fname,
    lname,
    referall
  ) => {
    return AuthService.signUp(
      username,
      password,
      "",
      number,
      fname,
      lname,
      referall
    );
  };

  const confirmSignUp = async (userSub, code, password) => {
    // console.log("userSub");
    // console.log(userSub);
    return AuthService.confirmUser(userSub, code, password);
  };

  const updateUserDetails = (data) => {
    return DataManager.updateUserDetails(data);
  };

  const blockDates = (data) => {
    return DB.blockToolDays(data);
  };

  const updateProfileImage = (photo) => {
    return DataManager.updateProfilePicture(photo);
  };

  const forgotPassword = async (email) => {
    let data = await AuthService.forgotPassword(email);
    return data !== undefined;
  };

  const verifyForgotPasswordCode = async (
    recoverEmail,
    recoveryCode,
    newPassword
  ) => {
    let data = await AuthService.forgotPasswordSubmit(
      recoverEmail,
      recoveryCode,
      newPassword
    );
    return data === undefined;
  };

  const logout = async () => {
    AuthService.signOut()
      .then(() => {
        console.log("LOGGED OUT");
        localStorage.clear();

        window.location.reload();
      })
      .catch((error) => {
        console.log(error);
      });
    //TODO: Add dispatch to logout user
  };

  const updateToolsAddress = async (address) => {
    await listMyPostedTools();
    const tools = state?.postedTools;
    console.log("TOOLS to updated", tools);

    await Promise.all(
      tools.map((tool) => {
        return DB.updateToolAddress(tool?.id, address?.id);
      })
    );

    // for (const tool in tools) {
    //   console.log("UPDATING TOOL", tool);
    //   console.log("To address", address);
    //
    //   await DB.updateToolAddress(tool, address?.id);
    // }
  };

  const updateUserDefaultAddress = (id) => {
    dispatch({ type: UPDATE_USER_DEFAULT_ADDRESS, payload: id });
  };

  const clearNotifications = () => {};

  useEffect(() => {
    DataManager.getTransactions()
      .then((balance) => {
        dispatch({ type: SET_ACCOUNT_BALANCE, payload: balance });
      })
      .catch((error) => {
        // console.log(error);
      });
  }, [state.user]);

  useEffect(() => {
    monitorTransactions((amount) => {
      dispatch({ type: UPDATE_ACCOUNT_BALANCE, payload: amount });
    }).then((sub) => {
      setTransactionSubscription(sub);
    });

    return () => {
      transactionSubscription?.unsubscribe();
    };
  }, [state.user]);

  useEffect(() => {
    monitorAllChats((message) => {
      dispatch({ type: UPDATE_NOTIFICATIONS, payload: message });
      // setNotification((notifications) => [...notifications, message]);
      //alert("New message");
    }).then((sub) => {
      setMessageSubscription(sub);
    });

    return () => {
      messageSubscription?.unsubscribe();
    };
  }, [state.user]);

  return (
    <AuthContext.Provider
      value={{
        address: state.address,
        user: state.user,
        userData: state.userData,
        isLoggedIn: state.isLoggedIn,
        balance: state.balance,
        notifications: state.notifications,
        userLocation: state.userLocation,
        billingInfo: state.billingInfo,
        toolsRented: state.toolsRented,
        toolsLended: state.toolsLended,
        transactions: state.transactions,
        isUploadingPhoto: state.isUploadingPhoto,
        postedTools: state.postedTools,
        defaultAddress: state.defaultAddress,
        updateProfilePhoto,
        loadUser,
        login,
        signUpUser,
        logout,
        clearNotifications,
        forgotPassword,
        verifyForgotPasswordCode,
        updateUserDetails,
        loadAddress,
        updateAddress,
        loadInitialData,
        setupBillingInfo,
        getBillingInfo,
        loadUserBillingInfo,
        setUserLocation,
        confirmSignUp,
        getToolsRented,
        getToolLendings,
        getPaid,
        getPaidPaypal,
        updateProfileImage,
        getUserSuccessfulTransactions,
        listMyPostedTools,
        blockDates,
        updateToolsAddress,
        updateUserDefaultAddress,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
