export default [
  {
    avatar: "images/avatars/avatar-1.jpg",
    author: "Samantha Smith",
    rating: 4,
    date: "27 May, 2018",
    text:
      "Phasellus id mattis nulla. Mauris velit nisi, imperdiet vitae sodales in, maximus ut lectus. Vivamus commodo " +
      "scelerisque lacus, at porttitor dui iaculis id. Curabitur imperdiet ultrices fermentum.",
  },
  {
    avatar: "images/avatars/avatar-2.jpg",
    author: "Adam Taylor",
    rating: 3,
    date: "12 April, 2018",
    text:
      "Aenean non lorem nisl. Duis tempor sollicitudin orci, eget tincidunt ex semper sit amet. Nullam neque justo, " +
      "sodales congue feugiat ac, facilisis a augue. Donec tempor sapien et fringilla facilisis. Nam maximus consectetur " +
      "diam. Nulla ut ex mollis, volutpat tellus vitae, accumsan ligula.",
  },
  {
    avatar: "images/avatars/avatar-3.jpg",
    author: "Helena Garcia",
    rating: 5,
    date: "2 January, 2018",
    text:
      "Duis ac lectus scelerisque quam blandit egestas. Pellentesque hendrerit eros laoreet suscipit ultrices.",
  },
];
