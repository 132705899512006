// react
import React, { useContext, useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import { Form, Button, Table, Row, Col } from "react-bootstrap";

// data stubs
import theme from "../../data/theme";
import authContext from "../../context/auth/authContext";
import moment from "moment";
import { PanoramaFishEye, RemoveRedEye } from "@material-ui/icons";

import DB from "../../api/DB";
import showError, { showSuccess } from "../../api/Helper";

export default function AccountPageOrderDetails() {
  const { toolsRented, toolsLended, getToolsRented, getToolLendings } =
    useContext(authContext);

  const [isLoading, setIsloading] = useState(false);

  useEffect(() => {
    getToolLendings();
    getToolsRented();
  }, []);

  const getStatus = {
    ACTIVE: "Active",
    CANCELLED_BY_BUYER: "Cancelled by Renter",
    CANCELLED_BY_SELLER: "Cancelled by Owner",
  };

  const handleCancel = (ID) => {
    setIsloading(true);
    DB.cancelBooking(ID)
      .then((response) => {
        showSuccess("Booking cancelled succesfully.");
        console.log("Cancel success", response);
      })
      .catch((error) => {
        showError("Unable to cancel booking. Please try again in a while.");
        console.log("Cancel fail", error);
      })
      .finally(() => {
        setIsloading(false);
      });
  };

  const rentals = (type, list) => {
    return (
      <div className="card">
        <div className="order-header">
          <h5 className="order-header__title">My {`${type}`} </h5>
        </div>
        <div className="card-divider" />
        <div className="card-table">
          {list?.length === 0 && (
            <Row className=" text-center d-flex flex-column align-items-center justify-content-center">
              <Col>
                <p className="mt-2 font-weight-bold">
                  You don't have any {type?.toLowerCase()}.
                </p>
              </Col>
            </Row>
          )}
          {list?.length > 0 && (
            <div
              className="table-responsive-sm overflow-auto"
              style={{ height: "40vh" }}
            >
              <Table striped hover>
                <thead className="sticky-top bg-white">
                  <tr className="text-center">
                    <th>Booking ID</th>
                    <th>Tool Name</th>
                    <th>From</th>
                    <th>To</th>
                    <th>Status</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody
                  className={
                    list?.length === 0
                      ? "d-flex flex-column justify-content-center align-content-center"
                      : "card-table__body card-table__body--merge-rows p-2"
                  }
                >
                  {console.log("LISTT:", list)}
                  {list?.map((tool, index) => {
                    return (
                      <tr className="text-center">
                        {console.log("TOOLLL", tool)}
                        <td className="py-2 align-middle">
                          {tool?.bookingNumber}
                        </td>
                        <td className="py-2 align-middle">
                          {tool?.tool?.name}
                        </td>
                        <td className="py-2 align-middle">
                          {moment(tool?.bookedStartDate).format("MM/DD/YYYY")}
                        </td>
                        <td className="py-2 align-middle">
                          {moment(tool?.bookedEndDate).format("MM/DD/YYYY")}
                        </td>
                        <td
                          className={`py-2 align-middle 
                                ${
                                  getStatus[
                                    tool?.bookingType
                                  ]?.toLowerCase() === "active" ||
                                  getStatus[
                                    tool?.bookingType
                                  ]?.toLowerCase() === "completed"
                                    ? "text-success"
                                    : getStatus[tool?.bookingType]?.includes(
                                        "Cancelled"
                                      )
                                    ? "text-danger"
                                    : ""
                                }`}
                        >
                          {getStatus[tool?.bookingType]}
                        </td>
                        <td className="py-2 align-middle">
                          {tool?.bookingType?.toLowerCase() === "active" && (
                            <Button
                              variant="outline-danger"
                              className="text-danger"
                              onClick={() => handleCancel(tool?.id)}
                            >
                              Cancel
                            </Button>
                          )}
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </Table>
            </div>
          )}
        </div>
      </div>
    );
  };

  // console.log("lent: ", toolsLended);
  return (
    <React.Fragment>
      <Helmet>
        <title>{`Bookings — ${theme.name}`}</title>
      </Helmet>

      {rentals("Rentals", toolsRented)}
      <br />
      {rentals("Bookings", toolsLended)}
    </React.Fragment>
  );
}
