// react
import React, { Component, useContext, useEffect, useState } from "react";

// context
import authContext from "../../context/auth/authContext";
import layoutContext from "../../context/layout/layoutContext";

// api
import { PaymentService } from "../../api/PaymentService";
import DataManager from "../../api/DataManager";
import {
  calculateToolPrice,
  calculateToolRate,
  getMaxDate,
  formatDistance,
} from "../../api/Helper";

// third-party
import LocationOnIcon from "@material-ui/icons/LocationOn";
import PropTypes from "prop-types";
import { useHistory, useLocation } from "react-router-dom";

import "react-dates/initialize";
import "react-dates/lib/css/_datepicker.css";

import CircularProgress from "@material-ui/core/CircularProgress";

import { BookingFlowContext } from "../../context/context";

// components

// application
import Currency from "./Currency";
import ProductGallery from "./ProductGallery";
import { cartAddItem } from "../../store/cart";
import { compareAddItem } from "../../store/compare";
import { wishlistAddItem } from "../../store/wishlist";

import Checkout from "./checkout/Checkout";
import "./../../scss/components/_product.scss";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js/pure";

import DayPickerRangeController from "react-dates/esm/components/DayPickerRangeController";

import PricingTable from "./PricingTable";
import moment from "moment";
import BookingSuccess from "./BookingSuccess";
import CheckoutState from "../../context/checkout/checkoutState";
import Rating from "./Rating";
import { forEach } from "react-bootstrap/ElementChildren";
import { Person20Svg } from "../../svg";

function Product(props) {
  // const {user} = useContext(AuthContext)

  const [avgRating, setAvgRating] = useState(3.7);

  const history = useHistory();
  const location = useLocation();
  const [anchorEl, setAnchorEl] = useState(null);
  const [screen, setScreen] = useState(null);

  const { isLoggedIn } = useContext(authContext);

  const { setRedirectToTool } = useContext(layoutContext);

  const [bookedDates, setBookedDates] = useState([]);
  const [calculatedPrice, setCalculatedPrice] = useState(props?.product?.price);
  //
  // const [stripeId, setStripeId] = useState(null);
  // const [paypalSecret, setPaypalSecret] = useState(null);

  const [paymentIntent, setPaymentIntent] = useState(null);

  // const [billingInfo, setBillingInfo] = useState(null);

  const [value, setValue] = React.useState([null, null]);
  const [focusedInput, setFocusedInput] = useState("startDate");
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [bookingData, setBookingData] = useState(null);

  const [bookedTool, setBookedTool] = useState(null);

  const owner = props?.product?.userAddress;
  let postalCode = props?.product?.userAddress?.postcode;

  // console.log(`screen in product is ${screen}`);

  useEffect(() => {
    console.log("THE PRODUCT IS: ", props.product);
  }, [props.product]);

  useEffect(() => {
    localStorage.removeItem("redirect");
    // setRedirectToTool(null);
  }, []);

  const screenChange = () => {
    hideFooter();
    setScreen(window.innerWidth);
  };

  useEffect(() => {
    getPrice();
  }, [value]);

  const hideFooter = () => {
    props.setShowPageFooter(paymentIntent == null && bookedTool == null);
  };
  useEffect(() => {
    window.addEventListener("resize", screenChange);

    getPrice();
  }, []);

  useEffect(() => {
    hideFooter();
  }, [bookedTool]);

  useEffect(() => {
    DataManager.getExistingBookings(product.id)
      .then((bookings) => {
        setBookedDates(bookings);
      })
      .catch((error) => {
        //  console.log(error);
      });
    hideFooter();
  }, [paymentIntent]);

  const getPrice = () => {
    let days = Math.max(0, numDays() - 1);
    calculateToolRate(product.price, days).then((price) => {
      setCalculatedPrice(price);
    });

    // let factor = 1 - (pricingFactors?.[days - 1]?.factor ?? 0);
    // // console.log("Factor" + factor);
    // return (parseInt(product.price) * numDays() * factor).toFixed(2);
  };

  const compareDates = (dayA, dayB) => {
    return dayA.isSameOrAfter(dayB, "day");
  };

  const disableDate = (date) => {
    //date = date?.toDate();
    if (date == null) {
      // console.log("DATE IS NULL")
      return true;
    }

    if (
      compareDates(moment(new Date()), date) ||
      compareDates(date, moment(getMaxDate()))
    ) {
      return true;
    }

    //console.log("DDDD")
    //console.log(date)
    for (let x = 0; x < bookedDates.length; x++) {
      let booking = bookedDates?.[x];
      let startDate = moment(booking?.bookedStartDate);
      let endDate = moment(booking?.bookedEndDate ?? booking?.bookedStartDate);
      if (compareDates(date, startDate) && compareDates(endDate, date)) {
        return true;
      }
    }
    // console.log(bookedDates)
  };

  const numDays = () => {
    let start = value?.[0];
    let end = value?.[1] ?? start;
    if (start == null || end == null) {
      return 0;
    }
    return parseInt((end?.getTime() - start?.getTime()) / (3600000 * 24) + 1);
  };

  const isBookingValid = () => {
    let start = value?.[0];
    let end = value?.[1];

    //
    if (start == null) {
      return false;
    }

    let numberOfDays = parseInt(
      (end?.getTime() - start?.getTime()) / (3600000 * 24)
    );
    // console.log("endDate"+endDate?.getTime())
    // console.log("startDate"+startDate?.getTime())
    //
    // console.log("numberOFDays" + numberOfDays);
    for (let x = 0; x < numberOfDays + 1; x++) {
      let newDate = start;

      newDate = moment(newDate.setDate(start?.getDate())).add(x, "days");
      // console.log("newDate " + newDate.toDate());

      if (disableDate(newDate)) {
        // console.log("DISABLING ", newDate);
        // console.log(newDate);
        return false;
      }
    }
    return true;
  };

  const sendPaymentRequest = () => {
    PaymentService.onBookingInitiate(product, value)
      .then((data) => {
        setPaymentIntent(data);
        console.log("PaymentInfo: ", data);

        setBookingData({ data: value, tool: product });
      })
      .catch((error) => {
        console.log(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    sendPaymentRequest();
  };

  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;
  const { product, layout } = props;
  let prices;

  // console.log(product)

  if (product.compareAtPrice) {
    prices = (
      <React.Fragment>
        <span className="product__new-price">
          <Currency value={product.hourlyRate} />
        </span>{" "}
        <span className="product__old-price">
          <Currency value={product.compareAtPrice} />
        </span>
      </React.Fragment>
    );
  } else {
    prices = <Currency value={product.hourlyRate} />;
  }

  // selected tool injection for Booking Confirmation page
  // useEffect(() => {
  //   setBookedTool({ data: value, tool: product });
  // }, []);

  if (bookedTool != null) {
    return <BookingSuccess bookedTool={bookedTool} />;
  }

  if (paymentIntent != null) {
    const stripePromise = loadStripe(PaymentService.stripeKey);
    return (
      <Elements stripe={stripePromise}>
        <CheckoutState
          billingInfo={paymentIntent}
          tool={product}
          bookingTime={value}
        >
          <Checkout
            // bookingTime={value}
            // tool={product}
            // billingInfo={paymentIntent}
            closePage={() => {
              setPaymentIntent(null);
            }}
            onToolBook={() => {
              setBookedTool(bookingData);
            }}
          />
        </CheckoutState>
      </Elements>
    );
  }

  return (
    <div className={`product product--layout --${layout}`}>
      {/*<div className="product__content">*/}
      <div
        className={`${
          window.innerWidth > 800
            ? "product-page-main"
            : "product-page-main-mob"
        }`}
      >
        <div>
          <ProductGallery
            layout={layout}
            reviews={product.reviews}
            name={product.name}
            images={product.images}
            rating={product.rating}
          />

          <div
            style={{ boxShadow: `0 0 12px 8px rgb(0 0 0 / 3%)` }}
            className="mr-3 w-100 mt-4 rounded shadow-div p-3 d-flex flex-row align-items-center"
          >
            <div
              className="bg-secondary rounded-circle mr-3 align-items-center justify-content-center d-flex flex-column"
              style={{ width: "4rem", height: "4rem" }}
            >
              <Person20Svg />
            </div>

            <div className="ml-2 h-100 d-flex flex-column justify-content-center">
              {/*{ console.log(`Mai hoon Don: ${product?.userAddress}`) }*/}
              <p className="mb-0">{`${
                owner?.firstName ?? "Details hidden"
              }`}</p>
              <Rating value={Math.round(avgRating)} />
            </div>

            <div className="ml-auto d-flex flex-column justify-content-center align-items-center">
              <LocationOnIcon className="text-primary mb-1" />
              <p
                className="mb-0"
                style={{ fontSize: "0.8rem" }}
              >{`${postalCode.substr(0, 3)} ${postalCode.substr(
                3,
                postalCode.length
              )}`}</p>
              <p className="mb-0" style={{ fontSize: "0.8rem" }}>
                { `${formatDistance(product?.distance) ?? "N/A"} km away`}
              </p>
            </div>
          </div>
        </div>

        <div>
          <div className="product-date-picker" style={{ minWidth: "60vw" }}>
            <h1 className="product--layout-information-heading">
              {product?.name ?? `Tool`}
            </h1>
            <hr color="black" />
            <div className="row d-flex flex-column flex-md-row justify-content-around w-100">
              <section className="product--layout-information-tool col-12 col-lg-5">
                {product?.des != null && (
                  <div className="product--layout-information-tool-item tool-deets mb-4 ">
                    {`Description: ${product?.des ?? `N/A`}`}
                  </div>
                )}

                <div className="product--layout-information-tool-item tool-deets">
                  {`Brand:  ${product?.brand ?? `N/A`}`}
                </div>
                <div className="product--layout-information-tool-item tool-deets">
                  {`Model: ${product?.model ?? `N/A`}`}
                </div>
                <div className="product--layout-information-tool-item tool-deets">
                  {`Condition: ${
                    product?.condition === 1
                      ? `Poor`
                      : product?.condition === 2
                      ? `Average`
                      : product?.condition === 3
                      ? `Perfect`
                      : `N/A`
                  }`}
                </div>
                <br />
                <div className="product--layout-information-tool-item tool-deets">
                  {console.log("aalo", product)}
                  {product?.distance !== -1.0 &&
                    `Distance: ${formatDistance(product?.distance)}`}
                </div>
                <br />
                <br />
                <PricingTable product={product} days={[1, 3, 7, 14, 30]} />
              </section>
              <section
                className="mb-5 col-12 col-lg-5 mt-5 mt-sm-0 border-left"
                style={{
                  borderColor: "rgba(0,0,0,0.1)",
                  paddingLeft: "2%",
                }}
              >
                <div className="making-flex-rows">
                  <div style={{ color: "#0d597d", fontWeight: "400" }}>
                    Rent for
                  </div>
                  <div
                    style={{
                      color: "#0d597d",
                      fontSize: "1.6rem",
                      fontWeight: "600",
                      marginLeft: "0.5rem",
                    }}
                  >
                    {numDays() > 0 &&
                      `$${calculatedPrice} for ${numDays()} days`}

                    {numDays() === 0 &&
                      `$${calculatedPrice ?? product.price * 0.1}/day`}
                  </div>
                </div>

                <DayPickerRangeController
                  startDate={startDate} // momentPropTypes.momentObj or null,
                  endDate={endDate} // momentPropTypes.momentObj or null,
                  noBorder
                  minDate={new Date()}
                  isDayBlocked={(day) => disableDate(day)}
                  onDatesChange={(state) => {
                    if (
                      endDate != null &&
                      state?.startDate !== startDate &&
                      startDate !== null
                    ) {
                      setEndDate(null);
                      setStartDate(state?.startDate);
                      return;
                    }

                    setStartDate(state?.startDate);
                    setEndDate(state?.endDate);
                    setValue([
                      state?.startDate?.toDate(),
                      state?.endDate?.toDate(),
                    ]);
                    getPrice();
                  }} // PropTypes.func.isRequired,
                  focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
                  onFocusChange={(focusedInput) => {
                    //      console.log(focusedInput)
                    setFocusedInput(focusedInput ?? "startDate");
                  }} // PropTypes.func.isRequired,
                  orientation={"horizontal"}
                  hideKeyboardShortcutsPanel={true}
                  numberOfMonths={1}
                />

                <div className="right-most-product-buttons">
                  <button
                    aria-describedby={id}
                    disabled={startDate === null || !isBookingValid()}
                    style={{ color: "white" }}
                    className="btn btn-warning"
                    onClick={(e) => {
                      setIsLoading(true);

                      if (!isLoggedIn) {
                        // bf = {
                        //   location: location.pathname,
                        //   isActive: true,
                        // };
                        // setRedirectToTool(location.pathname);
                        localStorage.setItem("redirect", location.pathname);

                        setIsLoading(false);
                        history.push("/account/login");
                      } else if (isLoggedIn) {
                        handleClick(e);
                      }
                    }}
                  >
                    {!isLoading ? (
                      "Rent Now"
                    ) : (
                      <CircularProgress color="#FFFFFF" size={24} />
                    )}
                  </button>
                </div>
              </section>
            </div>
          </div>
          <br />
        </div>
      </div>
      {product?.desc && (
        <div>
          <h5>Description</h5>
          <p>{product?.desc}</p>
          {/*{console.log(`lalalalalala ${JSON.stringify(product)}`)}*/}
        </div>
      )}
    </div>
  );
}

Product.propTypes = {
  /** product object */
  product: PropTypes.object.isRequired,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
};

Product.defaultProps = {
  layout: "standard",
};

const mapDispatchToProps = {
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
};

export default Product;
