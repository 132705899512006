// react
import React, { useState, useContext } from "react";

// third-party
import PropTypes from "prop-types";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

// application
import NavPanel from "./NavPanel";
import Search from "./Search";
import Logo from "../../assets/imgs/toology-logo-nomargins.png";
import authContext from "../../context/auth/authContext";

import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";

//auth context

function Header(props) {
  const { isLoggedIn } = useContext(authContext);

  const [showTopbar, setShowTopbar] = useState(true);

  const { layout } = props;
  let bannerSection;

  if (layout === "default") {
    bannerSection = (
      <div className="site-header__middle">
        <Link to="/">
          <div className="d-flex flex-column align-items-center justify-content-center">
            <div className="site-header__logo mb-1">
              <img src={Logo} className="site-header__logo_img" />
            </div>
            {/*<h*/}
            {/*  className="text-black w-100 text-center font-weight-normal"*/}
            {/*  style={{ fontSize: "0.9rem" }}*/}
            {/*>*/}
            {/*  Why buy, when you can rent*/}
            {/*</h>*/}
          </div>
        </Link>
        <div className="site-header__search">
          <Search context="header" />
        </div>
        {!isLoggedIn && (
          <Link to="/account/login" className="site-header-login">
            Login | Rent Your Tool
          </Link>
        )}
        {isLoggedIn && (
          <Link to="/rent-tool" className="site-header-post-btn ">
            Rent Your Tool
          </Link>
        )}
      </div>
    );
  }

  const topbar = (show) => {
    if (show) {
      return (
        <section
          className="w-100 bg-warning text-center text-white"
          style={{ height: "2.25rem" }}
        >
          <span className="w-100 h-100 d-flex flex-row justify-content-center align-items-center">
            <h6 className="ml-auto mb-0">
              We're only operating in Ontario at the moment. Drop us your email
              address and we'll let you know when we reach your city!
            </h6>
            <IconButton
              style={{ fontSize: "0.8rem" }}
              className="cursor-pointer hover-underline text-white ml-auto mr-2 mb-0"
              onClick={() => setShowTopbar(!showTopbar)}
            >
              <CloseIcon fontSize="small" />
            </IconButton>
          </span>
        </section>
      );
    }
  };

  return (
    <div className="site-header">
      {showTopbar && topbar(true)}
      {bannerSection}
      <div className="site-header__nav-panel">
        <NavPanel layout={layout} />
      </div>
    </div>
  );
}

Header.propTypes = {
  /** one of ['default', 'compact'] (default: 'default') */
  layout: PropTypes.oneOf(["default", "compact"]),
};

Header.defaultProps = {
  layout: "default",
};

export default Header;
