// react
import React, { useContext, useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
import addresses from "../../data/accountAddresses";
import allOrders from "../../data/accountOrders";
import theme from "../../data/theme";

//context
import { Auth } from "aws-amplify";
import authContext from "../../context/auth/authContext";
import AddressEdit from "../shared/AddressEdit";
import { Button } from "@material-ui/core";
import addressContext from "../../context/address/addressContext";
import profileIcon from "../../svg/person-20.svg";
import { showSuccess } from "../../api/Helper";
import { Person20Svg } from "../../svg";
import PersonIcon from "@material-ui/icons/Person";

export default function AccountPageDashboard() {
  const { user, balance, loadUser, updateProfileImage } =
    useContext(authContext);
  const { addresses } = useContext(addressContext);

  const [openAddressModal, setOpenAddressModal] = useState(false);

  const [photoURL, setPhotoURL] = useState(null);
  const [photo, setPhoto] = useState(null);

  useEffect(() => {
    if (photoURL === null) {
      return;
    }
    updateProfileImage(photoURL)
      .then(() => {
        showSuccess("Your profile photo has been updated");
        loadUser();
      })
      .catch((error) => {
        console.log("FAILED TO UPDATE PROFILE ", error);
      });

    const reader = new FileReader();
    reader.readAsDataURL(photoURL);
    reader.onload = function (e) {
      setPhoto(e.target.result);
    };
  }, [photoURL]);

  return (
    <div className="dashboard">
      <Helmet>
        <title>{`My Account — ${theme.name}`}</title>
      </Helmet>

      <AddressEdit
        address={addresses?.[0]}
        open={openAddressModal}
        onClose={() => {
          setOpenAddressModal(false);
        }}
      />
      <div className="dashboard__profile card profile-card">
        <div className="card-body profile-card__body">
          <input
            id="file-upload"
            type="file"
            style={{ opacity: "0", position: "relative" }}
            accept=".jpeg,.jpg,.png"
            onChange={(e) => {
              setPhotoURL(e.target.files?.[0]);
            }}
          />
          <div className="profile-card__avatar bg-primary d-flex flex-col align-items-center justify-content-center">
            {!(photo || user?.profileImage) && (
              <PersonIcon style={{ fontSize: "5rem", color: "white" }} />
            )}
            {(photo || user?.profileImage) && (
              <img
                src={photo ?? user?.profileImage ?? profileIcon}
                alt="profile photo"
                className="bg-white w-100 h-100 border-2"
                style={{ border: "4px solid black" }}
              />
            )}
          </div>

          <div
            className="text-primary mb-2 cursor-pointer"
            onClick={() => {
              window.document.getElementById("file-upload").click();
            }}
          >
            Change Photo
          </div>

          <div className="profile-card__name">
            {`${user?.fname} ${user?.lname}`}
          </div>
          <div className="profile-card__email">{user?.email}</div>
          <div className="profile-card__edit">
            <Link to="profile" className="btn btn-secondary btn-sm">
              Edit Profile
            </Link>
          </div>
        </div>
      </div>
      <div className="dashboard__address card address-card address-card--featured">
        {addresses?.[0]?.default && (
          <div className="address-card__badge">Default Address</div>
        )}
        <div className="address-card__body">
          <div
            className="address-card__name"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              alignItems: "flex-start",
            }}
          >
            <h6> {`${user?.fname} ${user?.lname}`} </h6>
            <h5>{`Balance : $${balance.toFixed(2)}`}</h5>
          </div>

          {addresses?.[0] != null && (
            <div className="address-card__row">
              {addresses?.[0]?.state}
              <br />
              {addresses?.[0]?.postcode},{addresses?.[0]?.city}
              <br />
              {addresses?.[0]?.address}
            </div>
          )}

          {user?.number != null && (
            <div className="address-card__row">
              <div className="address-card__row-title">Phone Number</div>
              <div className="address-card__row-content">
                {user?.number || ""}
              </div>
            </div>
          )}
          <div className="address-card__row">
            <div className="address-card__row-title">Email Address</div>
            <div className="address-card__row-content">{user?.email}</div>
          </div>
          <div className="address-card__footer">
            <Link
              onClick={() => {
                setOpenAddressModal(true);
              }}
            >
              {addresses?.[0] == null ? "Add Address" : "Edit Address"}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
