import { LOGIN, LOGOUT } from "./authActionTypes";

const initialState = {
  user: null,
  loggedIn: false,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case LOGIN:
      state.user = action.payload;
      state.loggedIn = true;
      return state;

    case LOGOUT:
      state.user = null;
      state.loggedIn = false;
      return state;

    default:
      return state;
  }
}
