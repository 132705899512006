import { graphqlOperation, API } from "@aws-amplify/api/lib";
import * as queries from "../graphql/queries";
import StorageManager from "./StorageManager";

import * as customQueries from "../graphql/customQueries";
import * as customMutations from "../graphql/customMutations";
import * as mutations from "../graphql/mutations";
import * as customSubscriptions from "../graphql/customSubscriptions";
import AuthService from "./AuthService";
import { CachingService } from "./CachingService";
import { LocationManager } from "./LocationManager";
import { calculateToolRate, toTitleCase } from "./Helper";
import { listExistingActiveBookings } from "../graphql/customQueries";

export default class DataManager {
  static async parseTool(tool) {
    if (tool == null) {
      return null;
    }

    tool.images = await StorageManager.loadImages(tool.imageList);
    tool.distance = LocationManager.getDistanceFromTool(tool) ?? 0;
    tool.price = parseFloat(tool.rate ?? "0") / 100;
    let toolHourlyRate =
      (await calculateToolRate(tool?.price, 0)) ?? tool.price * 0.1;
    tool.hourlyRate = toolHourlyRate;

    return tool;
  }

  static async getToolReviews(id) {
    let toolReview = await API.graphql({
      query: customQueries.listToolReviews,
      variables: { id: id },
    });

    return toolReview?.data?.listToolReviews?.items;
  }

  // static async getToolsByToolID(id) {
  //   console.log("GETTING TOOLS BY ID");
  //
  //   let data = await API.graphql({
  //     query: customQueries.getToolsByToolID,
  //     variables: { id: id },
  //   });
  //   return data?.data?.getTool?.customerTools?.items;
  // }

  // static async getToolsByCategory(id) {
  //   let cats = await API.graphql({
  //     query: customQueries.getToolsByCategory,
  //     variables: { id: id },
  //   });
  //   cats = cats?.data?.getCategory?.tools?.items;
  //
  //   let customerTools = [];
  //
  //   cats?.forEach((tool) => {
  //     let toolcustomerTools = tool?.customerTools?.items ?? [];
  //     customerTools = [...customerTools, ...toolcustomerTools];
  //   });
  //
  //   console.log("TOOLS BY CATS");
  //   // console.log(cats)
  //   console.log(customerTools);
  //
  //   return customerTools;
  // }

  static async createToolReview(comment, id, rating, userid) {
    return API.graphql({
      query: customMutations.createToolReview,
      variables: { comment: comment, id: id, userid: userid },
    });
  }

  static async uploadToolToDB(toolData, pictures, id = null) {
    let userID = await AuthService.getUsername();
    let data = {
      name: toTitleCase(toolData.name),
      brand: toolData.brand,
      imageList: pictures,
      addressID: toolData?.addressID,
      condition: toolData.condition,
      des: toolData?.desc,
      // defects: toolData?.toolCondition?.defect ?? "",
      model: toolData.model,
      rate: Math.floor(parseFloat(toolData.hourlyRate) * 1000),
      userID: userID,
      lat: toolData?.lat ?? "0",
      long: toolData?.long ?? "0",
      toolID: toolData.toolID,
    };

    if (id != null) {
      data["id"] = id;
    }

    const operation =
      id == null ? mutations.createCustomerTool : mutations.updateCustomerTool;
    console.log(data);

    return API.graphql({
      query: operation,
      variables: { input: data },
    });
  }

  static async postTool(data, id = null) {
    let pictures = data.pictures;

    let urls = await StorageManager.uploadToolPictures(pictures);

    urls = [...urls, ...(data?.oldImages ?? [])];

    let uploadedData = await DataManager.uploadToolToDB(data, urls, id);
    let tool = uploadedData?.data?.createCustomerTool;
    return DataManager.parseTool(tool);
  }

  static async loadConversation() {
    let userID = await AuthService.getUsername();

    // console.log("USERNAMEL:: " + userID);
    let convos = await API.graphql(
      graphqlOperation(customQueries.getUserConversations, { id: userID })
    );
    // console.log(convos.data);

    let conversationList = convos?.data?.getUser?.convos?.items?.map(
      (convos) => {
        let convoData = convos?.conversation;
        let lastMessage = convoData?.messages?.items?.[0];
        let index = convoData?.memberIDs?.indexOf(userID);

        let userName = convoData?.members?.[index === 0 ? 1 : 0];

        let rcvrID = convoData?.memberIDs?.[index === 0 ? 1 : 0];

        let convo = {
          id: convoData?.id,
          lastMessage: lastMessage?.content,
          messageDate: lastMessage?.messageDate,
          userName: userName,
          rcvrID: rcvrID,
        };
        return convo;
      }
    );

    return conversationList?.sort((a, b) => {
      return a?.messageDate < b?.messageDate ? 1 : -1;
    });
  }

  static async postMessage(message, convo, rcvrID) {
    let userID = await AuthService.getUsername();

    let data = {
      input: {
        conversationID: convo,
        userID: userID,
        content: message,
        messageDate: new Date().getTime(),
        receiverID: rcvrID,
      },
    };

    let messageData = await API.graphql(
      graphqlOperation(mutations.createMessage, data)
    );
    return messageData?.data.createMessage;
  }

  static async getConversationMessages(convoID) {
    let data = await API.graphql(
      graphqlOperation(customQueries.getConvoMessages, { id: convoID })
    );
    let messages = data?.data.getConversation?.messages?.items;
    messages = messages.sort((a, b) =>
      a.messageDate > b.messageDate ? 1 : -1
    );

    // console.log(messages)
    return messages;
  }

  static async loadVideoSuggestions(keywords) {
    const apiKey = "AIzaSyA0Aly5Oai_7A88FbFkaXXc0hiQHZQ1ai0";
    let url = `https://www.googleapis.com/youtube/v3/search?part=snippet&maxResults=5&q=${keywords}&type=video&key=${apiKey}`;
    let data = await fetch(url);
    let jsonData = await data.json();

    let videoURl = "https://www.youtube.com/embed/";

    return jsonData?.items?.map((data) => {
      let videoId = data?.id?.videoId;
      return videoURl + videoId;
    });

    // console.log(jsonData)
  }

  static async getBrands() {
    let url = `https://therovecompany.com/Toology/Brands.php`;
    let data = await fetch(url);
    let jsonData = await data.json();

    return jsonData.map((bran) => {
      let brand = bran;
      brand.name = brand?.name?.toUpperCase();
      return brand;
    });
  }

  static async getToolModels(brand, query) {
    // console.log("called me");

    let url = `https://therovecompany.com/Toology/search-tool.php?brand=${brand}&query=${query}`;
    let data = await fetch(url);
    let jsonData = await data.json();
    return jsonData;
  }

  static async getExistingBookings(toolID) {
    let data = await API.graphql(
      graphqlOperation(customQueries.listExistingActiveBookings, { id: toolID })
    );

    let bookings = data?.data?.listToolRentals?.items;
    bookings = bookings?.filter((booking) => {
      return (
        booking?.bookingType === "ACTIVE" ||
        booking?.bookingType === "USER_BLOCKED"
      );
    });
    return bookings;
  }

  static async pricingData() {
    if (CachingService.pricingData == null) {
      let data = await API.graphql(
        graphqlOperation(queries.listToolPriceFactors)
      );
      let items = data?.data?.listToolPriceFactors?.items;
      CachingService.pricingData = items.sort((a, b) => {
        return a?.durationDays > b?.durationDays ? 1 : -1;
      });
    }

    return CachingService.pricingData;
  }

  // static async getStripeID() {
  //   let userID = await AuthService.getUsername();
  //   let data = await API.graphql(
  //     graphqlOperation(queries.getUser, { id: userID })
  //   );
  //   let appsyncUser = data?.data?.getUser;
  //   return appsyncUser?.stripeCustomerID;
  // }

  static async getTransactions() {
    // console.log("Get transactions");
    let userID = await AuthService.getUsername();

    let data = await API.graphql(
      graphqlOperation(customQueries.getUserTransactions, { userID: userID })
    );

    let transactions = data?.data?.listTransactions?.items ?? [];
    let balance = 0;

    transactions?.forEach((tran) => {
      let type = tran?.transactionType;
      let amount = tran?.amount * (type === "CREDIT" ? 1 : -1);
      balance += amount;
    });
    // console.log(data);

    return balance / 100;
  }

  static async addAddress(address) {
    let userID = await AuthService.getUsername();
    const {
      addressLine1,
      addressLine2,
      city,
      postalcode,
      state,
      firstName,
      lastName,
      phone,
    } = address;

    // console.log(address);

    let data = {
      input: {
        addressLine1: addressLine1,
        addressLine2: addressLine2,
        city: city,
        postcode: postalcode,
        state: state,
        firstName: firstName,
        lastName: lastName,
        phoneNumber: phone,
      },
    };

    let result = await API.graphql(
      graphqlOperation(mutations.createUserAddress, data)
    );

    let addressData = result?.data?.createUserAddress;

    let addressID = addressData?.id;
    // console.log(addressData);

    // console.log(addressID);

    let userData = {
      input: {
        billingAddressID: addressID,
        id: userID,
      },
    };
    // console.log(userData);

    let userResult = await API.graphql(
      graphqlOperation(mutations.updateUser, userData)
    );
    // console.log(userResult);

    return addressData;

    //data?.data?.createUserAddress?.id
  }

  static async getUserAddress() {
    let userID = await AuthService.getUsername();

    let data = await API.graphql(
      graphqlOperation(customQueries.getUserAddress, { id: userID })
    );

    let address = data?.data?.getUser?.billingAddress;

    // console.log(data);

    return address;
  }

  static async loadUserDetails() {
    let userID = await AuthService.getUsername();
    //
    // console.log("USERID ");
    // console.log(userID);

    let data = await API.graphql(
      graphqlOperation(customQueries.getUserDetails, { id: userID })
    );

    let userData = data?.data?.getUser;

    if (userData?.profileImage != null) {
      userData.profileImage = await StorageManager.loadImageFile(
        userData?.profileImage
      );
    }

    console.log("USER DETAILS", userData);

    return userData;
  }

  static async updateProfilePicture(photo) {
    let userID = await AuthService.getUsername();

    let profileCompressedImage = await StorageManager.compressImage(photo);
    let profileImage = await StorageManager.uploadImage(profileCompressedImage);
    console.log("USER PHOTO Uploading", profileImage);

    await API.graphql(
      graphqlOperation(mutations.updateUser, {
        input: { id: userID, profileImage: profileImage },
      })
    );
  }

  static async updateUserDetails(data) {
    let userID = await AuthService.getUsername();

    await API.graphql(
      graphqlOperation(mutations.updateUser, { input: { id: userID, ...data } })
    );
  }
}

export async function monitorAllChats(callback) {
  let userID = await AuthService.getUsername();

  CachingService?.messagesSub?.unsubscribe();

  CachingService.messagesSub = API.graphql(
    graphqlOperation(customSubscriptions.onNewMessageToReceiverUser, {
      receiverID: userID,
    })
  ).subscribe({
    next: ({ provider, value }) => {
      let convoData = value?.data?.onNewMessageToReceiverUser;
      callback(convoData);
      // console.log(convoData);
      // console.log({ provider, value });
      //
      // console.log({ provider, value });
    },
    error: (error) => console.warn(error),
  });
  return CachingService.messagesSub;
}

export async function monitorTransactions(callback) {
  let userID = await AuthService.getUsername();

  CachingService?.transactionSub?.unsubscribe();

  CachingService.transactionSub = API.graphql(
    graphqlOperation(customSubscriptions.onNewTransaction, {
      id: userID,
    })
  ).subscribe({
    next: ({ provider, value }) => {
      let tran = value?.data?.onNewTransaction;
      // console.log("JUST GOT SOME MONEY");

      // console.log(tran);

      let type = tran?.transactionType;
      let amount = (tran?.amount * (type === "CREDIT" ? 1 : -1)) / 100;

      callback(amount);
    },
    error: (error) => console.warn(error),
  });
  return CachingService.transactionSub;
}
