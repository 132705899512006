// react
import React from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";

// application
import {
  ArrowRoundedLeft7x11Svg,
  ArrowRoundedLeft8x13Svg,
  ArrowRoundedRight7x11Svg,
  ArrowRoundedRight8x13Svg,
} from "../../svg";

function BlockHeader(props) {
  const { title, groups, onPrev, onNext, onGroupClick } = props;
  let { arrows } = props;

  if (arrows) {
    arrows = <div className="block-header__arrows-list"></div>;
  }

  let groupsList;

  if (groups.length > 0) {
    groupsList = groups.map((group, index) => {
      const classes = classNames("block-header__group", {
        "block-header__group--active": group.current,
      });

      return (
        <li key={index}>
          <button
            type="button"
            className={classes}
            onClick={() => onGroupClick(group)}
          >
            {group.name}
          </button>
        </li>
      );
    });

    groupsList = <ul className="block-header__groups-list">{groupsList}</ul>;
  }

  return (
    <div className="block-header">
      {/*<div*/}
      {/*  className="block-header__arrow block-header__arrow--left"*/}
      {/*  type="button"*/}
      {/*  onClick={onPrev}*/}
      {/*>*/}
      {/*  <ArrowRoundedLeft8x13Svg className="arrow-icon" />*/}
      {/*</div>*/}
      {/*<div className="block-header__divider" />*/}
      <h4 className=" mx-auto text-center mb-2">{title}</h4>
      {/*<div className="block-header__divider" />*/}
      {/*<div*/}
      {/*  className="block-header__arrow block-header__arrow--right"*/}
      {/*  type="button"*/}
      {/*  onClick={onNext}*/}
      {/*>*/}
      {/*  <ArrowRoundedRight8x13Svg className="arrow-icon" />*/}
      {/*</div>*/}
    </div>
  );
}

BlockHeader.propTypes = {
  title: PropTypes.string,
  arrows: PropTypes.bool,
  groups: PropTypes.array,
  onNext: PropTypes.func,
  onPrev: PropTypes.func,
  onGroupClick: PropTypes.func,
};
BlockHeader.defaultProps = {
  groups: [],
  onGroupClick: () => {},
};

export default BlockHeader;
