// react
import React, {
  Component,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import Indicator from "../header/Indicator";
import { Menu18x14Svg, Search20Svg, Heart20Svg } from "../../svg";
import { mobileMenuOpen } from "../../store/mobile-menu";
import Search from "../header/Search";

import MobileMenuLinks from "../../data/mobileMenu";

// bootstrap
import {
  Navbar,
  Nav,
  DropDown,
  Container,
  Image,
  Button,
} from "react-bootstrap";

import Logo from "../../assets/imgs/toology-logo-nomargins.png";
import authContext from "../../context/auth/authContext";

function MobileHeader(props) {
  const [searchOpen, setSearchOpen] = useState(false);
  const searchInput = useRef();

  //
  //   this.state = {
  //     searchOpen: true,
  //   };
  //   this.searchInput = React.createRef();
  // }

  useEffect(() => {
    if (searchOpen && searchInput.current) {
      searchInput.current.focus();
    }
  }, [searchOpen]);

  // componentDidUpdate(prevProps, prevState) {
  //   const { searchOpen } = this.state;
  //
  // }
  const { isLoggedIn } = useContext(authContext);

  const handleOpenSearch = () => {
    setSearchOpen(true);

    // this.setState(() => ({ searchOpen: true }));
  };

  const handleCloseSearch = () => {
    setSearchOpen(false);
    // this.setState(() => ({ searchOpen: false }));
  };

  const { openMobileMenu } = props;

  const searchClasses = classNames("mobile-header__search", {
    "mobile-header__search--open": searchOpen,
  });

  return (
    <Navbar
      expand="xxl"
      bg="light"
      sticky="top"
      className={`navbar-stick-dark stick`}
      data-navbar="sticky"
    >
      <div className="d-flex my-2  w-100">
        {/*<Menu18x14Svg*/}
        {/*  style={{ color: "white" }}*/}
        {/*  onClick={openMobileMenu}*/}
        {/*  className="mr-2 my-auto"*/}
        {/*/>*/}
        <section className="navbar-mobile">
          <Nav as="nav" className="mx-auto nav-navbar" navbar={false}>
            {MobileMenuLinks?.map((navItem, index) => {
              return (
                <>
                  {navItem.children === null && (
                    <Link to={navItem?.url}>
                      <Nav.Link
                        // active={isActive(navItem?.url)}
                        className={`text-capitalize`}
                      >
                        {navItem?.title ?? "NAV_LINK"}
                      </Nav.Link>
                    </Link>
                  )}
                </>
              );
            })}
          </Nav>
        </section>

        <div className="d-flex flex-column ml-1  w-100 ">
          <div className="d-flex flex-row   mt-1 mb-1  align-items-center mx-2">
            {/*<Link to="/" className="ml-2">*/}
            <div
              className="d-flex flex-row mb-2 align-items-center"
              style={{ minWidth: "100%" }}
            >
              <Menu18x14Svg
                style={{ color: "white" }}
                onClick={openMobileMenu}
                className="mr-2 my-auto"
              />
              <Link
                to="/"
                style={{ maxWidth: "35%", objectFit: "contain" }}
                className="ml-2"
              >
                <Image src={Logo} className="w-100" />
              </Link>

              <div style={{ maxWidth: "70%" }} className="ml-auto ">
                {!isLoggedIn && (
                  <Link to="/account/login" className="site-header-login">
                    Login | Rent Your Tool
                  </Link>
                )}
                {isLoggedIn && (
                  <Link to="/rent-tool" className="site-header-post-btn ">
                    Rent Your Tool
                  </Link>
                )}
              </div>

              {/*<div*/}
              {/*  style={{ minWidth: "60%", fontSize: "0.8rem" }}*/}
              {/*  className="text-primary mt-1 ml-2 text-black w-25 text-center font-weight-normal "*/}
              {/*>*/}
              {/*  Why buy, when you can rent*/}
              {/*</div>*/}
            </div>
          </div>

          <div className="d-flex flex-row mr-2">
            <Search
              context="mobile-header"
              className={`${searchClasses} w-100 ml-auto border`}
              inputRef={searchInput}
              onClose={handleCloseSearch}
            />
          </div>
        </div>
      </div>
    </Navbar>
  );
}

const mapStateToProps = (state) => ({
  cart: state.cart,
  wishlist: state.wishlist,
});

const mapDispatchToProps = {
  openMobileMenu: mobileMenuOpen,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileHeader);
