import React, { useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";

import IconButton from "@material-ui/core/IconButton";

import "../../../scss/payouts/AddPaymentMethod.scss";

import CloseIcon from "@material-ui/icons/Close";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { Controller } from "react-hook-form";
import { PaymentService } from "../../../api/PaymentService";
import showError, { showSuccess } from "../../../api/Helper";
import authContext from "../../../context/auth/authContext";

export default function ConfirmWithdrawModal({ control, show, onClose }) {
  const { balance, getPaid } = useContext(authContext);
  const [amount, setAmount] = useState(null);

  const handleSubmit = () => {
    getPaid(amount)
      .then(() => {
        showSuccess("Your payment has been transferred");
        onClose();
      })
      .catch((error) => {
        // console.log("Error", error);
        showError(
          "Payment failed. Please check the amount you are withdrawing"
        );
      });
  };

  return (
    <Dialog
      transition={Slide}
      open={show}
      style={{ opacity: "0.8" }}
      onDismiss={() => {
        onClose();
      }}
    >
      <DialogContent style={{ backgroundColor: "#000", minWidth: "100%" }}>
        <Dialog
          transition={Slide}
          open={show}
          style={{ opacity: "1" }}
          onDismiss={() => {
            onClose();
          }}
        >
          <DialogContent className="">
            <div className="add-payout-method" style={{ height: "250px" }}>
              <div className="d-flex justify-content-between align-middle">
                <h5 className="main-add-payment-method-title">
                  Enter the amount you wish to withdraw
                </h5>

                <IconButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>
              <h6>{`Your current account balance is $${balance}`}</h6>

              <Form className="mt-4">
                <Form.Group>
                  <h6 className="text-black-50">
                    The amount should be less than or equals to your account
                    balance
                  </h6>
                  <InputGroup className="border-0 border-primary bg-light post-tool-add-price mt-3">
                    <InputGroup.Prepend>
                      <InputGroup.Text className="bg-primary text-white">
                        <b>$</b>
                      </InputGroup.Text>
                    </InputGroup.Prepend>
                    <Form.Control
                      type="number"
                      className="form-control"
                      placeholder=""
                      value={amount}
                      onChange={(e) => {
                        setAmount(e.target.value);
                      }}
                      // value={toolPrice}
                      //{...register("toolPrice")}
                      //{...field}
                      // isInvalid={!!errors.toolPrice}
                    />
                  </InputGroup>
                  {/*{errors && errors.toolPrice && (*/}
                  {/*    <Alert variant="danger">{errors.toolPrice.message}</Alert>*/}
                  {/*)}*/}
                </Form.Group>
                <Button
                  onClick={() => {
                    handleSubmit();
                  }}
                >
                  Get Paid now
                </Button>
              </Form>
            </div>
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
}
