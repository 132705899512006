// react
import React from "react";
import { Link } from "react-router-dom";

// application
import FooterContacts from "./FooterContacts";
import FooterLinks from "./FooterLinks";
import FooterSocials from "./FooterSocials";
import ToTop from "./ToTop";

// data stubs
import theme from "../../data/theme";

// images
import Logo from "../../assets/imgs/toology-logo-nomargins.png";

export default function Footer() {
  const policyLinks = [
    { title: "Privacy Policy", url: "/legal/#privacy" },
    { title: "Rental Policy", url: "/rental-policy" },
    { title: "Terms of Service", url: "/legal/#terms" },
    { title: "Cookie Policy", url: "/cookies" },
  ];

  const quickLinks = [
    { title: "About Us", url: "/about-us" },
    { title: "Support", url: "/support" },
    { title: "Disputes", url: "/disputes" },
    // { title: "Site Map", url: "" },
  ];

  const accountLinks = [
    { title: "Dashboard", url: "/account/dashboard" },
    { title: "Get Paid", url: "/account/get-paid" },
    { title: "Addresses", url: "/account/addresses" },
  ];

  const socialLinks = [
    {
      iconPath: "/images/social/facebook.png",
      url: "https://www.facebook.com/toology.ca/",
      title: "Facebook",
    },
    {
      iconPath: "/images/social/instagram.png",
      url: "https://instagram.com/toology.ca",
      title: "Instagram",
    },
  ];

  return (
    <div className="site-footer">
      <div className="container">
        <div className="site-footer__widgets">
          <div className="row">
            <div className="col-12 col-lg-4 d-flex flex-column justify-content-center align-content-center">
              <Link to="/" className="site-footer__logo">
                <img src={Logo} className="site-footer__logo_img mb-0 pb-2" />
              </Link>
              {/*<p className="footer-links__tagline text-center">Why buy when you can rent</p>*/}
            </div>
            <div className="col-12 col-lg-2">
              <FooterLinks title="Quick Links" items={quickLinks} />
            </div>
            <div className="col-12 col-lg-2">
              <FooterLinks title="My Account" items={accountLinks} />
            </div>
            <div className="col-12 col-lg-2">
              <FooterLinks title="Policies" items={policyLinks} />
            </div>
            <div className="col-12 col-lg-2">
              <FooterSocials title="Follow Us" items={socialLinks} />
            </div>
          </div>
        </div>

        <div className="site-footer__bottom">
          <div className="site-footer__copyright">
            <div className="site-footer-toology">
              Copyright © 2021{" "}
              <a href="/" rel="noopener noreferrer" target="_blank">
                Toology &trade;
              </a>{" "}
              A trademark of K Squared Tech Inc. All Rights Reserved.
            </div>

            <div className="site-footer-managed">
              Managed by{" "}
              <a
                href="https://roveapps.com/"
                rel="noopener noreferrer"
                target="_blank"
              >
                Rove.
              </a>
            </div>
          </div>
        </div>
      </div>
      {/*<ToTop />*/}
    </div>
  );
}
