import { Dialog } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import React, { useEffect, useState } from "react";
import DB from "../../api/DB";
import ProductGallery from "./ProductGallery";
import { Button } from "reactstrap";
import { Link } from "react-router-dom";
import { conditionToText } from "../../api/Helper";

export default function ViewListing({
  tool,
  show,
  onClose,
  showChangeAvailability,
}) {
  const [loadedTool, setLoadedTool] = useState(null);

  useEffect(() => {
    if (tool === null) {
      return;
    }
    DB.parseTool(tool).then((toolLoaded) => {
      setLoadedTool(toolLoaded);
    });
  }, [tool]);

  if (loadedTool === null) {
    return "";
  }

  return (
    <Dialog
      open={show}
      onClose={() => onClose()}
      className="vw-100 px-lg-4"
      fullWidth
      onKeyDown={(e) => {}}
    >
      <div className="px-2 py-4 d-flex flex-column align-items-center ">
        <div className="d-flex flex-row w-100">
          <CloseIcon
            className="ml-auto cursor-pointer"
            onClick={() => {
              onClose();
            }}
          />
        </div>
      </div>

      <div className="p-4 d-flex flex-column">
        <ProductGallery
          // layout={layout}
          reviews={loadedTool?.reviews}
          name={loadedTool?.name}
          images={loadedTool?.images}
          rating={loadedTool?.rating}
        />
        <br />
        <h4>
          {loadedTool?.name} - {loadedTool?.brand}
        </h4>
        <p>{loadedTool?.desc}</p>
        <p>
          <strong>Model: </strong>
          {loadedTool?.model}
        </p>
        <p>
          <strong>Condition: </strong>
          {conditionToText(loadedTool?.condition)}
        </p>
        <Button className="btn-block bg-primary text-white ">
          <Link
            target="_blank"
            className="text-white"
            to={`/edit/${loadedTool?.id}`}
          >
            Edit
          </Link>
        </Button>

        <Button
          className="btn-block bg-primary text-white"
          type="submit"
          onClick={() => {
            onClose();
            showChangeAvailability(loadedTool);
          }}
        >
          Change Availability
        </Button>

        <Button
          className="btn-block bg-danger text-white"
          type="submit"
          onClick={() => {
            onClose();
            showChangeAvailability(loadedTool);
          }}
        >
          Delete tool
        </Button>
        {/*<br />*/}
        {/*<Button*/}
        {/*  onClick={() => {*/}
        {/*    onClose();*/}
        {/*    showChangeAvailability(loadedTool);*/}
        {/*  }}*/}
        {/*>*/}
        {/*  Edit*/}
        {/*</Button>*/}
      </div>
    </Dialog>
  );
}
