// react
import React, { useContext } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";

// application
import AppLink from "../shared/AppLink";
import languages from "../../i18n";
import Megamenu from "./Megamenu";
import Menu from "./Menu";
import { ArrowRoundedDown9x6Svg, ChatIcon } from "../../svg";
import Msgicon from "./../../assets/imgs/chat@2x.png";

// data stubs
import navLinks from "../../data/headerNavigation";
import IndicatorAccount from "./IndicatorAccount";
import { Link } from "react-router-dom";
import Indicator from "./Indicator";
import authContext from "../../context/auth/authContext";

function NavLinks(props) {
  const { isLoggedIn, notifications } = useContext(authContext);

  const handleMouseEnter = (event) => {
    const { locale } = props;
    const { direction } = languages[locale];

    const item = event.currentTarget;
    const megamenu = item.querySelector(".nav-links__megamenu");

    if (megamenu) {
      const container = megamenu.offsetParent;
      const containerWidth = container.getBoundingClientRect().width;
      const megamenuWidth = megamenu.getBoundingClientRect().width;
      const itemOffsetLeft = item.offsetLeft;

      if (direction === "rtl") {
        const itemPosition =
          containerWidth -
          (itemOffsetLeft + item.getBoundingClientRect().width);

        const megamenuPosition = Math.round(
          Math.min(itemPosition, containerWidth - megamenuWidth)
        );

        megamenu.style.left = "";
        megamenu.style.right = `${megamenuPosition}px`;
      } else {
        const megamenuPosition = Math.round(
          Math.min(itemOffsetLeft, containerWidth - megamenuWidth)
        );

        megamenu.style.right = "";
        megamenu.style.left = `${megamenuPosition}px`;
      }
    }
  };

  const linksList = navLinks.map((item, index) => {
    let arrow;

    let submenu;

    if (item.submenu) {
      arrow = <ArrowRoundedDown9x6Svg className="nav-links__arrow" />;
    }

    if (item.submenu && item.submenu.type === "menu") {
      submenu = (
        <div className="nav-links__menu">
          <Menu items={item.submenu.menu} />
        </div>
      );
    }

    if (item.submenu && item.submenu.type === "megamenu") {
      submenu = (
        <div
          className={`nav-links__megamenu nav-links__megamenu--size--${item.submenu.menu.size}`}
        >
          <Megamenu menu={item.submenu.menu} />
        </div>
      );
    }

    const classes = classNames("nav-links__item", {
      "nav-links__item--with-submenu": item.submenu,
    });

    return (
      <li key={index} className={classes} onMouseEnter={handleMouseEnter}>
        <AppLink to={item.url} {...item.props}>
          <span>
            {item.title}
            {arrow}
          </span>
        </AppLink>
        {submenu}
      </li>
    );
  });

  return (
    <ul className="nav-links__list">
      {linksList}

      {isLoggedIn && (
        <div className="nav-links-icons">
          <Indicator
            url="/messages"
            dropdown={false}
            icon={
              <div>
                {notifications?.length > 0 && (
                  <div className="chat-badge-count">
                    {<div>{notifications?.length}</div>}
                  </div>
                )}
                <ChatIcon />
              </div>
            }
          />
          <IndicatorAccount />
        </div>
      )}
    </ul>
  );
}

NavLinks.propTypes = {
  /** current locale */
  locale: PropTypes.string,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(NavLinks);
