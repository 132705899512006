import ImageUpload from "../../shared/ImageUpload";
import React from "react";
import { FormGroup } from "reactstrap";

import { Controller } from "react-hook-form";
import { Alert, Form } from "react-bootstrap";
export default function Step2({ control, errors, prevData, required = true }) {
  return (
    <FormGroup>
      <Controller
        control={control}
        name="pictures"
        render={({ field: { onChange, onBlur, value, ref } }) => (
          <ImageUpload
            // prevData={prevData}
            updateData={(pics) => {
              const value = { target: { value: pics } };

              onChange(value);
            }}
          />
        )}
        rules={
          required
            ? {
                required: "Please upload at least one photo of your tool",
              }
            : {}
        }
      />
      {errors && errors.pictures && (
        <Alert variant="danger">{errors.pictures.message}</Alert>
      )}
    </FormGroup>
  );
}
