export const createMessage = /* GraphQL */ `
  mutation CreateMessage($input: CreateMessageInput!) {
    createMessage(input: $input) {
      id
    }
  }
`;

export const createToolReview = `
mutation CreateToolReview(
  $comment: comment!
  $id: ID!
  $rating: rating!
  $userid: userID!
) {
  createToolReview(input: {comment: {eq: $comment}, customerToolID: {eq: $id}, rating: {eq: $rating}, userID: {eq: $userid}}) {
    comment
    createdAt
    customerToolID
    rating
  }
}
`;

export const createUserAddress = /* GraphQL */ `
  mutation CreateUserAddress(
    $input: CreateUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    createUserAddress(input: $input, condition: $condition) {
      id
    }
  }
`;

export const updateUserAddress = /* GraphQL */ `
  mutation UpdateUserAddress(
    $input: UpdateUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    updateUserAddress(input: $input, condition: $condition) {
      id
    }
  }
`;
export const deleteUserAddress = /* GraphQL */ `
  mutation DeleteUserAddress(
    $input: DeleteUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    deleteUserAddress(input: $input, condition: $condition) {
      id
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
    }
  }
`;
export const createToolRental = /* GraphQL */ `
  mutation CreateToolRental(
    $input: CreateToolRentalInput!
    $condition: ModelToolRentalConditionInput
  ) {
    createToolRental(input: $input, condition: $condition) {
      id

      bookingType
      bookedStartDate
      bookedEndDate
    }
  }
`;

export const updateCustomerTool = /* GraphQL */ `
  mutation UpdateCustomerTool(
    $input: UpdateCustomerToolInput!
    $condition: ModelCustomerToolConditionInput
  ) {
    updateCustomerTool(input: $input, condition: $condition) {
      id
    }
  }
`;
