export class CachingService {
  static user = null;
  static allUserTools = null;
  static searchText = "";
  static categories = null;
  static allTools = null;
  static filterTools = null;

  static currentLocation = null;

  static allToolsWithCategories = null;

  static pricingData = null;

  static messagesSub = null;
  static transactionSub = null;

  static clearCache() {
    this.user = null;
    this.allUserTools = null;
  }
}
