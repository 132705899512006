// export const onNewMessage = /* GraphQL */ `
//   subscription onNewMessage(
//     $input: ID!
//   )  {
//     onNewMessage(conversationID: $input) {
//       id
//       userID
//
//       messageDate
//       createdAt
//       content
//       conversationID
//     }
//   }
// `;

export const onNewMessage = /* GraphQL */ `
  subscription OnNewMessage($conversationID: ID!) {
    onNewMessage(conversationID: $conversationID) {
      id
      userID
      messageDate
      createdAt
      content
    }
  }
`;

export const onNewMessageToUser = /* GraphQL */ `
  subscription OnNewMessageToUser($userID: ID!) {
    onNewMessageToUser(userID: $userID) {
      id
      userID
      content
      conversationID
      createdAt
      updatedAt
      messageDate
    }
  }
`;

export const onNewMessageToReceiverUser = /* GraphQL */ `
  subscription OnNewMessageToReceiverUser($receiverID: ID!) {
    onNewMessageToReceiverUser(receiverID: $receiverID) {
      id
      userID
      receiverID
      user {
        id
        fname
        lname
      }
      content
      conversationID
      createdAt
      updatedAt
      messageDate
    }
  }
`;

export const onNewTransaction = `
subscription onNewTransaction($id:ID!) {
  onNewTransaction(transactionStatus: SUCCESSFUL, userID: $id) {
    amount
    transactionType
  }
}
`;
