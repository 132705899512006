import {
    useCallback,
    useContext,
    useEffect,
    useReducer,
    useRef,
    useState,
} from "react";
import CheckoutContext from "./checkoutContext";
import checkoutReducer from "./checkoutReducer";
import {
    APPLY_PROMO_CODE,
    LOAD_CARDS_ON_FILE,
    REDIRECT_TO_TOOL,
    REMOVE_PROMO_CODE,
    SET_CHECKOUT_INFO,
    SET_DAMAGE_PROTECTION,
    SET_INTENT_IDS,
    SET_NO_PAYMENT_REQUIRED,
    SET_ORDER_TOTAL,
    SET_PAYPAL_REF,
    SET_PROCESSING_FEES,
    SET_PROMO_DATA,
    SET_SELECTED_CARD,
    SET_USE_CREDITS,
} from "../types";
import {PaymentService} from "../../api/PaymentService";
import showError, {applyPromoCode} from "../../api/Helper";
import authContext from "../auth/authContext";
import {CardElement, useElements, useStripe} from "@stripe/react-stripe-js";

const CheckoutState = (props) => {
    const initialState = {
        toolPrice: 0,
        processingFees: 0,
        orderTotal: 0,
        billingInfo: null,
        tool: null,
        bookingTime: null,
        onSuccess: null,
        paypalID: null,
        stripeID: null,
        cardsOnFile: [],
        selectedCard: null,
        useCredits: null,
        damageProtection: null,
        noPaymentRequired: false,
        promoData: null,
        appliedPromoCode: null,
    };
    const [state, dispatch] = useReducer(checkoutReducer, initialState);
    const paypalRef = useRef();

    const {user} = useContext(authContext);

    const stripe = useStripe();
    const elements = useElements();

    const validatePromoCode = (promo) => {
        PaymentService.validatePromoCode(promo, state.bookingTime)
            .then((data) => {
                console.log("PROMO DATA", data);
                dispatch({type: SET_PROMO_DATA, payload: data?.validationResult});

                if (data?.validationResult?.promoInfo != null) {
                    dispatch({type: APPLY_PROMO_CODE, payload: promo});
                }

                // setPromoData();
            })
            .catch((error) => {
                console.log("PROMO ERROR", error);
            });
    };

    const calcaulateDiscount = useCallback(() => {
        if (state.promoData?.promoInfo?.applyType === "CASHBACK") return 0;

        return applyPromoCode(
            state.promoData?.promoInfo,
            state.billingInfo?.daysRented,
            state.billingInfo?.toolRentPrice
        );
    }, [state.promoData, state.billingInfo]);

    const calcaulateTotalAmountPreProcessing = useCallback(() => {
        let total = state.billingInfo?.toolRentPrice;

        if (state.promoData?.promoInfo != null) {
            const discount = calcaulateDiscount();
            if (!isNaN(discount)) {
                total -= discount;
            }
        }

        total +=
            state.damageProtection === true
                ? state.billingInfo?.damageProtectionPrice
                : 0;

        return total;
    }, [state.useCredits, state.damageProtection, calcaulateDiscount]);

    const calculateOrderTotal = useCallback(() => {
        let total = calcaulateTotalAmountPreProcessing();
        const processingFees = total * state.billingInfo?.processingFeesPercentage;

        total += processingFees;

        return total;
    }, [state.useCredits, state.damageProtection, calcaulateDiscount]);

    useEffect(() => {
        let total = calcaulateTotalAmountPreProcessing();

        console.log("AMOUNT TOAL", total);

        const processingFees = total * state.billingInfo?.processingFeesPercentage;

        dispatch({
            type: SET_PROCESSING_FEES,
            payload: processingFees / 100,
        });
    }, [state.orderTotal]);

    useEffect(() => {
        if ((state.billingInfo?.accountBalance ?? 1) === 0) {
            setUseCredits(false);
        }
    }, [state.billingInfo?.accountBalance]);

    useEffect(() => {
        let total = calculateOrderTotal();
        total -= state.useCredits ? state.billingInfo?.accountBalance : 0;

        total = total / 100;

        total = Math.max(total, 0);
        dispatch({type: SET_ORDER_TOTAL, payload: total});
    }, [calculateOrderTotal]);

    const calculateOutStandingBalance = useCallback(() => {
        if (calculateOrderTotal() < state.billingInfo?.accountBalance) {
            return (calculateOrderTotal() / 100).toFixed(2);
        }
        return state.billingInfo?.accountBalance / 100;
    }, [calculateOrderTotal, state.orderTotal]);

    useEffect(() => {
        // noPaymentRequired
        dispatch({
            type: SET_NO_PAYMENT_REQUIRED,
            payload: state.orderTotal === 0,
        });
        console.log("NO PAYMENT", state.orderTotal);
    }, [state.orderTotal]);

    useEffect(() => {
        dispatch({type: SET_CHECKOUT_INFO, payload: props});
    }, [props]);

    useEffect(() => {
        PaymentService.getStripeDetails(user?.stripeCustomerID).then((cards) => {
            dispatch({type: LOAD_CARDS_ON_FILE, payload: cards});
        });
    }, []);

    //MARK: Create Payment Intent
    useEffect(() => {
        if (state.damageProtection == null || state.useCredits == null) {
            return;
        }
        if (state.useCredits && state.noPaymentRequired) {
            return;
        }

        PaymentService.getPaymentIntent(
            state.tool,
            state.bookingTime,
            state.useCredits,
            state.damageProtection,
            state.appliedPromoCode
        )
            .then((data) => {
                console.log("Payment Intent: ", data);
                const stripeID = data?.stripeIntent?.response?.clientSecret;
                const paypalID = data?.paypalIntent?.response?.id;
                console.log("STRIPE ID", stripeID);
                dispatch({
                    type: SET_INTENT_IDS,
                    payload: {stripe: stripeID, paypal: paypalID},
                });
            })
            .catch((error) => {
                console.log("Payment Intent Error", error);
            });
    }, [
        state.useCredits,
        state.damageProtection,
        state.noPaymentRequired,
        state.appliedPromoCode,
    ]);

    const removePromo = () => {
        dispatch({type: REMOVE_PROMO_CODE});
    };

    const setSelectedCard = (card) => {
        dispatch({type: SET_SELECTED_CARD, payload: card});
    };

    const setUseCredits = (use) => {
        dispatch({type: SET_USE_CREDITS, payload: use});
    };

    const setDamageProtection = (use) => {
        dispatch({type: SET_DAMAGE_PROTECTION, payload: use});
    };

    const initPaypal = (success) => {
        dispatch({type: SET_PAYPAL_REF, payload: {success: success}});
    };

    //MARK: Paypal
    useEffect(() => {
        if (paypalRef == null) {
            return;
        }
        console.log("GENERATING BUTTON");

        try {
            const myNode = document.getElementsByClassName("paypal-button");
            if (myNode != null) {
                for (let x = 0; x < myNode?.length; x++) {
                    myNode?.[x].remove();
                }
            }
            console.log("REF IS NOT NULL");

            window.paypal.Button.render(
                {
                    env: "production",
                    payment: function (data, actions) {
                        return state.paypalID;
                    },

                    onAuthorize: function (data, actions) {
                        return PaymentService.onAuthroizePaypal(
                            data.paymentID,
                            data.payerID
                        )
                            .catch((error) => {
                                console.log(error);
                            })
                            .then(() => {
                                state.onSuccess();
                            });
                    },
                    onCancel: function () {
                    },
                },
                paypalRef.current
            );
        } catch (exception) {
            console.log("AGAB " + exception);
        }
    }, [state.paypalID, paypalRef]);

    //MARK: - Process Payments
    const pay = () => {
        if (state.noPaymentRequired) {
            return payCredits();
        }
        if (state.selectedCard != null) {
            return paySavedCard();
        }
        return payCard();
    };

    const paySavedCard = () => {
        return PaymentService.payWithCardOnFile(
            state.tool,
            state.bookingTime,
            state.selectedCard,
            state.useCredits,
            state.damageProtection,
            state.appliedPromoCode
        );
    };

    const payCredits = () => {
        return PaymentService.bookToolWithCredits(
            state.tool,
            state.bookingTime,
            state.useCredits,
            state.damageProtection,
            state.appliedPromoCode
        );
    };

    const payCard = async () => {
        const payload = await stripe.confirmCardPayment(state.stripeID ?? "", {
            payment_method: {
                card: elements.getElement(CardElement),
            },
        });
        if (payload.error) {
            console.log("stripe_error", payload.error)
            if (payload.error.code === "card_declined" && payload.error['payment_method'].card.funding === 'debit') {
                showError(`Payment failed: Debit cards are not supported by Toology`);
            } else {
                showError(`Payment failed ${payload.error.message}`);
            }
            throw Error("Failed");
        }
    };

    return (
        <CheckoutContext.Provider
            value={{
                orderTotal: state.orderTotal,
                processingFees: state.processingFees,
                billingInfo: state.billingInfo,
                tool: state.tool,
                bookingTime: state.bookingTime,
                cardsOnFile: state.cardsOnFile,
                selectedCard: state.selectedCard,
                damageProtection: state.damageProtection,
                useCredits: state.useCredits,
                noPaymentRequired: state.noPaymentRequired,
                promoData: state.promoData,
                appliedPromoCode: state.appliedPromoCode,
                paypalRef: paypalRef,
                setUseCredits,
                initPaypal,
                setSelectedCard,
                pay,
                calculateOrderTotal,
                calculateOutStandingBalance,
                calcaulateDiscount,
                validatePromoCode,
                setDamageProtection,
                removePromo,
            }}
        >
            {props.children}
        </CheckoutContext.Provider>
    );
};

export default CheckoutState;
