import { Dialog } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import DayPickerRangeController from "react-dates/esm/components/DayPickerRangeController";
import CircularProgress from "@material-ui/core/CircularProgress";
import moment from "moment";
import { formatDate, getMaxDate } from "../../api/Helper";
import DataManager from "../../api/DataManager";
import CloseIcon from "@material-ui/icons/Close";
import authContext from "../../context/auth/authContext";

export default function ChangeAvailability({ tool, show, onClose }) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = React.useState([null, null]);
  const [focusedInput, setFocusedInput] = useState("startDate");
  const [bookedDates, setBookedDates] = useState([]);

  const { blockDates } = useContext(authContext);

  const reset = () => {
    setStartDate(null);
    setEndDate(null);
    setValue([null, null]);
    setFocusedInput("startDate");
    setBookedDates([]);
  };

  const loadBookings = () => {
    DataManager.getExistingBookings(tool?.id)
      .then((bookings) => {
        setBookedDates(bookings);
      })
      .catch((error) => {
        //  console.log(error);
      });
  };

  const changeAvailability = () => {
    blockDates({ toolID: tool?.id, date: value })
      .then((updated) => {
        reset();
        loadBookings();
      })
      .catch((error) => {
        console.log("Failed to block", error);
      });
  };

  useEffect(() => {
    if (tool === null) {
      return;
    }
    loadBookings();
  }, [tool]);

  const compareDates = (dayA, dayB) => {
    return dayA.isSameOrAfter(dayB, "day");
  };

  const disableDate = (date) => {
    //date = date?.toDate();
    if (date == null) {
      // console.log("DATE IS NULL")
      return true;
    }

    if (
      compareDates(moment(new Date()), date) ||
      compareDates(date, moment(getMaxDate()))
    ) {
      return true;
    }

    //console.log("DDDD")
    //console.log(date)
    for (let x = 0; x < bookedDates.length; x++) {
      let booking = bookedDates?.[x];
      let startDate = moment(booking?.bookedStartDate);
      let endDate = moment(booking?.bookedEndDate ?? booking?.bookedStartDate);
      if (compareDates(date, startDate) && compareDates(endDate, date)) {
        return true;
      }
    }
    // console.log(bookedDates)
  };

  const numDays = () => {
    let start = value?.[0];
    let end = value?.[1] ?? start;
    if (start == null || end == null) {
      return 0;
    }
    return parseInt((end?.getTime() - start?.getTime()) / (3600000 * 24) + 1);
  };

  const isBookingValid = () => {
    let start = value?.[0];
    let end = value?.[1];

    //
    if (start == null) {
      return false;
    }

    let numberOfDays = parseInt(
      (end?.getTime() - start?.getTime()) / (3600000 * 24)
    );
    // console.log("endDate"+endDate?.getTime())
    // console.log("startDate"+startDate?.getTime())
    //
    // console.log("numberOFDays" + numberOfDays);
    for (let x = 0; x < numberOfDays + 1; x++) {
      let newDate = start;

      newDate = moment(newDate.setDate(start?.getDate())).add(x, "days");
      // console.log("newDate " + newDate.toDate());

      if (disableDate(newDate)) {
        // console.log("DISABLING ", newDate);
        // console.log(newDate);
        return false;
      }
    }
    return true;
  };

  return (
    <Dialog
      open={show}
      onClose={() => onClose()}
      className="vw-100 px-lg-4"
      fullWidth
      onKeyDown={(e) => {}}
    >
      <div className="px-2 py-4 d-flex flex-column align-items-center ">
        <div className="d-flex flex-row w-100 p-3">
          <h4>Change Availability</h4>
          <CloseIcon
            className="ml-auto cursor-pointer"
            onClick={() => {
              reset();
              onClose();
            }}
          />
        </div>

        <DayPickerRangeController
          startDate={startDate} // momentPropTypes.momentObj or null,
          endDate={endDate} // momentPropTypes.momentObj or null,
          noBorder
          minDate={new Date()}
          isDayBlocked={(day) => disableDate(day)}
          onDatesChange={(state) => {
            if (
              endDate != null &&
              state?.startDate !== startDate &&
              startDate !== null
            ) {
              setEndDate(null);
              setStartDate(state?.startDate);
              return;
            }

            setStartDate(state?.startDate);
            setEndDate(state?.endDate);
            setValue([state?.startDate?.toDate(), state?.endDate?.toDate()]);
            // getPrice();
          }} // PropTypes.func.isRequired,
          focusedInput={focusedInput} // PropTypes.oneOf([START_DATE, END_DATE]) or null,
          onFocusChange={(focusedInput) => {
            //      console.log(focusedInput)
            setFocusedInput(focusedInput ?? "startDate");
          }} // PropTypes.func.isRequired,
          orientation={"horizontal"}
          hideKeyboardShortcutsPanel={true}
          numberOfMonths={1}
        />
        {startDate && endDate && (
          <p>
            The tool will be unavailable from {formatDate(startDate)} to{" "}
            {formatDate(endDate)}
          </p>
        )}

        <div className="right-most-product-buttons">
          <button
            disabled={startDate === null || !isBookingValid()}
            style={{ color: "white" }}
            className="btn btn-warning"
            onClick={(e) => {
              changeAvailability();
            }}
          >
            {!isLoading ? (
              "Update Availability"
            ) : (
              <CircularProgress color="#FFFFFF" size={24} />
            )}
          </button>
        </div>
      </div>
    </Dialog>
  );
}
