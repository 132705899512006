import React, { useState, useEffect, useContext, useRef } from "react";
import { Link } from "react-router-dom";

import showError from "../../../api/Helper";

// bootstrap
import {
  Form,
  Image,
  Row,
  Col,
  Table,
  Card,
  Popover,
  OverlayTrigger,
  Badge,
  CloseButton,
  Button,
} from "react-bootstrap";

import { CardElement } from "@stripe/react-stripe-js";

// material ui
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  CircularProgress,
} from "@material-ui/core";

import moment from "moment";

// images
import VisaIcon from "../../../assets/imgs/visa-icon.png";

import "../../../scss/checkout/_checkout-page.scss";
import AccountPageAddresses from "../../account/AccountPageAddresses";
import checkoutContext from "../../../context/checkout/checkoutContext";
import CloseIcon from "@material-ui/icons/Close";
import RentalTermsConditions from "../RentalTermsConditions";
import theme from "../../../data/theme";
import { Helmet } from "react-helmet-async";

export default function Checkout({ closePage, onToolBook }) {
  const topRef = useRef();

  useEffect(() => {
    topRef?.current?.scrollIntoView({ behavior: "smooth" });
  }, []);

  const cardStyle = {
    style: {
      base: {
        color: "#32325d",
        fontFamily: "Arial, sans-serif",
        fontSmoothing: "antialiased",
        fontSize: "16px",
        "::placeholder": {
          color: "#32325d",
        },
      },
      invalid: {
        color: "#fa755a",
        iconColor: "#fa755a",
      },
    },
  };

  const [addCard, setAddcard] = useState(false);
  const [addedCard, setAddedCard] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showDamageProtection, setShowDamageProtection] = useState(false);
  const [promoCode, setPromoCode] = useState(null);
  const [termsAccepted, setTermsAccepted] = useState(false);

  const {
    billingInfo,
    tool,
    bookingTime,
    cardsOnFile,
    selectedCard,
    orderTotal,
    paypalRef,
    useCredits,
    noPaymentRequired,
    promoData,
    appliedPromoCode,
    damageProtection,
    processingFees,
  } = useContext(checkoutContext);

  const {
    pay,
    setSelectedCard,
    initPaypal,
    setUseCredits,
    calculateOutStandingBalance,
    calcaulateDiscount,
    validatePromoCode,
    setDamageProtection,
    removePromo,
  } = useContext(checkoutContext);

  useEffect(() => {
    setAddcard((val) => {
      if (val) {
        return val;
      }
      return cardsOnFile?.length === 0;
    });
  }, [cardsOnFile]);

  useEffect(() => {
    initPaypal(() => {
      setIsLoading(false);
      closePage();
      onToolBook();
    });
  }, []);

  const handleSubmit = () => {
    setIsLoading(true);

    pay()
      .then(() => {
        setIsLoading(false);
        closePage();
        onToolBook();
      })
      .catch((error) => {
        setIsLoading(false);
        showError(`Booking failed`);
        console.log("BOOKING FAILED", error);
      });
  };

  const billingAddress = () => {
    return (
      <div className="mt-4 pb-4">
        <h6 className=" ml-2">Billing Address</h6>
        <AccountPageAddresses
          onSelect={(e) => {
            // console.log(e?.target?.value);
          }}
        />
      </div>
    );
  };

  const disablePayButton = () => {
    if (!termsAccepted) {
      return true;
    }

    if (useCredits === null || damageProtection == null) {
      return true;
    }

    console.log("NO PAYMENT REQUIRED", noPaymentRequired);

    if (selectedCard === null && !noPaymentRequired && !addedCard) {
      return true;
    }
    if (noPaymentRequired) {
      return false;
    }
    return false;
  };

  const payButton = (addCardWidth = "") => {
    return (
      <Button
        disabled={disablePayButton()}
        classes={{ disabled: "disabled" }}
        onClick={() => {
          handleSubmit();
        }}
        className="add-card-button"
        style={{ width: addCardWidth }}
      >
        {!isLoading ? (
          `Book Your Tool`
        ) : (
          <CircularProgress color="#FFFFFF" size={24} />
        )}
      </Button>
    );
  };

  const paymentDetails = () => {
    return (
      <div className="mt-4">
        <br />
        <br />
        <div className="d-flex flex-row align-items-center">
          <h6 className="ml-2">Pay with Credit Card</h6>
          <img src="images/payments.png" className="ml-4 pb-2" />
        </div>
        <div className="p-3 d-flex flex-row flex-wrap">
          {cardsOnFile?.length !== 0 && (
            <RadioGroup
              value={selectedCard}
              className="d-flex flex-wrap flex-row flex-sm-col"
            >
              {/*{console.log(`Cards on file: ${JSON.stringify(cardsOnFile)}`)}*/}
              {cardsOnFile?.map((card, id) => (
                <FormControlLabel
                  value={card?.id}
                  control={<Radio />}
                  className="card-number"
                  label={
                    <div key={id} className="saved-cards">
                      <img src={VisaIcon} className="visa-logo" />

                      <h6 className="card-number">{`**** (${card?.last4})`}</h6>
                    </div>
                  }
                  onChange={() => {
                    setSelectedCard(card?.id);
                    setAddcard(false);
                  }}
                />
              ))}
            </RadioGroup>
          )}

          {!addCard && (
            <p
              className="text-primary  align-self-center cursor-pointer"
              onClick={() => {
                setSelectedCard(null);
                setAddcard(true);
              }}
            >
              Add Card
            </p>
          )}
        </div>

        <section className="add-card-details mb-3">
          {addCard && (
            <Row className="w-100">
              <Col>
                <CardElement
                  id="card-element"
                  className="w-100"
                  options={cardStyle}
                  onFocus={() => {
                    setAddedCard(true);
                  }}
                  //  onChange={handleChange}
                />
              </Col>
            </Row>
          )}
        </section>

        <section className="mt-2 w-100">{payButton()}</section>

        {/*{paymentMethod === "credits" && payButton()}*/}

        {/*{(addCard || selectedCard != null) && payButton()}*/}
        {/*<div className="w-100">*/}
        {/*  <h6 className="text-center mb-0">OR</h6>*/}
        {/*</div>*/}
        {/*<hr />*/}
        {/*<h6 className="mb-3">Pay with Paypal</h6>*/}

        {/*<div*/}
        {/*  className="paypal-button-checkout"*/}
        {/*  ref={paypalRef}*/}
        {/*  style={{ display: "block" }}*/}
        {/*/>*/}
        {/*)}*/}

        {/*<section className="mt-5 d-block d-sm-none">{payButton()}</section>*/}
      </div>
    );
  };

  const orderSummary = () => {
    const totalRent = (billingInfo?.toolRentPrice ?? 0) / 100;

    return (
      <Card className="rounded shadow-sm border-0 mt-4">
        <Card.Body className="p-3 pb-4">
          <Table responsive className="m-auto">
            <thead>
              <tr>
                <h5>Order Summary</h5>
              </tr>
            </thead>
            <tbody className="border-top">
              <tr className="">
                <td className="p-0 pt-3">Total rent</td>
                <td className="p-0 pt-3 text-right">
                  ${totalRent?.toFixed(2)}
                </td>
              </tr>
              {damageProtection && (
                <tr className="">
                  <td className="p-0">
                    Damage Protection{" "}
                    <span style={{ fontSize: "0.8rem" }}>
                      (15% of Total Rent)
                    </span>
                  </td>
                  <td className="p-0 text-right">
                    ${(totalRent * 0.15)?.toFixed(2)}
                  </td>
                </tr>
              )}

              <tr className="">
                <td className="p-0 pt-1 pb-2">Processing & handling fees</td>
                <td className="p-0 pt-1 pb-2 text-right">
                  ${processingFees?.toFixed(2)}
                </td>
              </tr>

              {appliedPromoCode && (
                <>
                  <tr className="">
                    <td className="p-0 pt-1 pb-2">
                      Promo Code (
                      <strong className="font-weight-bold text-uppercase">
                        {appliedPromoCode}
                      </strong>
                      ) :{" "}
                    </td>
                    {promoData?.promoInfo?.applyType !== "CASHBACK" && (
                      <td className="p-0 pt-1 pb-2 text-right">
                        -${(calcaulateDiscount() / 100)?.toFixed(2)}
                      </td>
                    )}
                  </tr>
                  <p
                    className="font-weight-bold"
                    style={{ fontSize: "0.8rem" }}
                  >
                    {promoData?.promoInfo?.description ??
                      "50% off on first booking"}
                  </p>
                </>
              )}

              {useCredits && (
                <tr className=" border-top">
                  <td className="p-0 p-0 pb-2 pt-2">Balance Used</td>
                  <td className="p-0 p-0 pb-2 pt-2 text-right">
                    ${calculateOutStandingBalance()}
                  </td>
                </tr>
              )}

              <tr
                className="border-top text-primary font-weight-bold"
                style={{ fontSize: "1.5rem" }}
              >
                <td className="p-0 pt-2">
                  {useCredits ? "Net" : "Order"} total
                </td>
                <td className="p-0 pt-2 text-right">
                  ${orderTotal?.toFixed(2)}
                </td>
              </tr>

              <tr className="align-items-center">
                <td className="p-0 pt-2 text-center align-middle d-flex flex-row align-items-center">
                  <CloseIcon
                    className="font-weight-bold text-primary cursor-pointer"
                    style={{ width: "10% !important" }}
                    onClick={() => {
                      removePromo();
                    }}
                  />
                  <Form.Control
                    className="border m-2 focus:outline-none"
                    style={{ width: "90% !important" }}
                    type="text"
                    required
                    placeholder="Enter promo code"
                    value={promoCode}
                    onChange={(e) => {
                      setPromoCode(e.target.value);
                    }}
                  />
                </td>
                <td
                  className="p-0 pt-2 text-center align-middle w-25"
                  // style={{ width: "20%" }}
                >
                  <Button
                    className="font-weight-bold text-white cursor-pointer"
                    onClick={() => {
                      validatePromoCode(promoCode);
                    }}
                  >
                    Apply
                  </Button>
                </td>
              </tr>

              {promoData && (
                <>
                  {promoData?.promoInfo && (
                    <Badge variant={"success"}>
                      Your promo code has been applied
                    </Badge>
                  )}

                  {(!promoData?.promoFound || !promoData?.promoValid) && (
                    <Badge variant={"danger"}>
                      {/*{console.log('promoDataLog',promoData)}*/}
                      {promoData?.error || "The promo code seems to be invalid"}
                    </Badge>
                  )}
                </>
              )}
            </tbody>
          </Table>
          {/*<section className="mt-5 d-none d-sm-block">{payButton()}</section>*/}
        </Card.Body>
      </Card>
    );
  };

  const toolSummary = () => {
    return (
      <div>
        <h5 className="title-is">Rental Summary</h5>
        <Card className="rounded shadow-sm border-0">
          <Card.Body className="d-flex flex-row p-0">
            <Image
              className="mr-3 rounded-left"
              style={{ width: "30%", height: "100%" }}
              src={tool?.images[0]}
            />
            <section className="flex flex-col my-auto">
              <h6 className="mb-1">{tool?.name}</h6>
              <h6>
                Start Date:{" "}
                <span className="tool-summary-deets">
                  {moment(bookingTime?.[0]).format("MM/DD/YYYY")}
                </span>
                <br />
                End Date:{" "}
                <span className="tool-summary-deets">
                  {moment(bookingTime?.[1]).format("MM/DD/YYYY")}
                </span>
              </h6>
            </section>
          </Card.Body>
        </Card>
        <Button
          className="mt-2 w-100 add-card-button"
          variant="light"
          onClick={closePage}
        >
          Change Booking Dates
        </Button>
      </div>
    );
  };

  const damageProtectionDetail = (
    <Popover>
      <Popover.Content>
        <b>Damage Protection</b> is an optional service offered by Toology that
        relieves the Customer of repair or replacement charges if the Equipment
        is damaged during normal use during the Rental Period. The charge for
        Damage Protection is 15% of the Total Rent and will appear as a separate
        line-item charge on the Agreement and on the invoice. The Customer must
        opt-in for Damage Protection at the time of rental.
        <br />
        <br />
        <b>Damage Protection is not insurance</b> and does not cover loss or
        damage of the Equipment caused by theft, abuse, misuse, neglect,
        intentional acts and/ or failure to follow proper use and care
        instructions provided for the Equipment.
        <br />
        <br />
        Customer expressly acknowledges and agrees that they shall be
        responsible for all repair or replacement costs not covered by Damage
        Protection.
      </Popover.Content>
    </Popover>
  );

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Checkout — ${theme.name}`}</title>
      </Helmet>

      <secton ref={topRef} className="checkout-root-container">
        <Row className="">
          <Col>
            <h3 className="checkout-heading-main">Review your order</h3>
            <h1 className="checkout-notice-terms">
              By renting a tool, you agree to Toology's
              <Link to="/legal/#privacy" className="ml-1">
                privacy policy
              </Link>
              ,
              <Link to="/legal/#terms-of-service" className="ml-1">
                terms of service
              </Link>{" "}
              and
              <Link to="/legal/#conditions-of-use" className="ml-1">
                conditions of use.
              </Link>
            </h1>
          </Col>
        </Row>

        <Row className="justify-content-between flex-row-reverse flex-sm-row mt-4">
          <Col xs={12} sm={7}>
            {/*{ console.log(`Remaining Balance: ${billingInfo?.accountBalance ?? 0}`) }*/}
            <div className="rounded border-0 pb-3">
              <div className="py-3 px-1 d-flex flex-column flex-wrap justify-content-start">
                {billingInfo?.accountBalance > 0 && (
                  <>
                    <section className="mt-2 ml-3 d-flex flex-column flex-wrap  mb-4">
                      <p
                        className=" text-primary mb-1 font-weight-bold"
                        style={{ fontSize: "1.2rem" }}
                      >
                        You have a balance of{" "}
                        <strong>
                          ${(billingInfo?.accountBalance ?? 0) / 100}
                        </strong>{" "}
                        in your account.
                      </p>
                      <p className="mb-0" style={{ fontSize: "1rem" }}>
                        * Do you want to use credits to pay for this bill?
                      </p>
                      <RadioGroup
                        defaultValue={null}
                        className="d-flex flex-row "
                        onChange={(event, value) => {
                          setUseCredits(value === "true");
                        }}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio className="text-primary" />}
                          label={"Yes"}
                          checked={useCredits != null ? useCredits : false}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio className="text-primary" />}
                          label={"No"}
                          checked={useCredits != null ? !useCredits : false}
                        />
                      </RadioGroup>
                    </section>
                  </>
                )}
                <section className="mt-2 ml-3 d-flex flex-column flex-wrap  ">
                  <div style={{ fontSize: "1rem" }}>
                    * Get Toology's special{" "}
                    <span
                      className="text-primary cursor-pointer"
                      onClick={() =>
                        setShowDamageProtection(!showDamageProtection)
                      }
                    >
                      <OverlayTrigger
                        trigger="hover"
                        placement="bottom"
                        overlay={damageProtectionDetail}
                      >
                        <span>Damage Protection</span>
                        {/*{ showDamageProtection && damageProtectionDetail() }*/}
                      </OverlayTrigger>
                    </span>{" "}
                    for your tool
                  </div>
                  <RadioGroup
                    className="d-flex flex-row "
                    onChange={(event, value) => {
                      setDamageProtection(value === "true");
                    }}
                  >
                    <FormControlLabel
                      value={true}
                      control={<Radio className="text-primary" />}
                      label={"Yes"}
                      checked={
                        damageProtection != null ? damageProtection : false
                      }
                    />
                    <FormControlLabel
                      value={false}
                      control={<Radio className="text-primary" />}
                      label={"No"}
                      checked={
                        damageProtection != null ? !damageProtection : false
                      }
                    />
                  </RadioGroup>
                </section>
              </div>
            </div>

            {billingAddress()}
            <div className="my-4">
              <RentalTermsConditions
                onAgree={(accepted) => {
                  setTermsAccepted(accepted);
                }}
              />
            </div>

            {/*<section className="d-none d-sm-block">*/}
            {/*  {*/}
            {/*    (!useCredits || !noPaymentRequired) &&*/}
            {/*    useCredits != null &&*/}
            {/*    damageProtection != null &&*/}
            {/*    paymentDetails()*/}
            {/*  }*/}
            {/*</section>*/}
          </Col>
          <Col xs={12} sm={4}>
            {toolSummary()}
            {orderSummary()}
            <section className="">
              {(!useCredits || !noPaymentRequired) &&
                useCredits != null &&
                damageProtection != null &&
                paymentDetails()}
            </section>
          </Col>
        </Row>
      </secton>
    </React.Fragment>
  );
}
