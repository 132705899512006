// react
import React, { useContext, useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { Link, matchPath, Redirect, Switch, Route } from "react-router-dom";

// components
import AddressEdit from "../../shared/AddressEdit";
import AddressView from "../../shared/AddressView";

// scss
import "../../../scss/payouts/AddLocalBankAccount.scss";
import accountAddresses from "../../../data/accountAddresses";
import Button from "@material-ui/core/Button";
import { TextField } from "@material-ui/core";
import authContext from "../../../context/auth/authContext";
import showError, { showSuccess } from "../../../api/Helper";
import { Alert, Form, InputGroup, Row } from "react-bootstrap";
import { CheckBox, Person } from "@material-ui/icons";
import { Controller, useForm } from "react-hook-form";
import AccountPageAddresses from "../AccountPageAddresses";
import addressContext from "../../../context/address/addressContext";

export default function AddLocalbankAccount() {
  const {
    address,
    user,
    setupBillingInfo,
    getBillingInfo,
    loadUser,
  } = useContext(authContext);

  const { addresses } = useContext(addressContext);

  const [selectedAddress, setSelectedAddress] = useState(addresses?.[0]);

  // useEffect(() => {
  //   getBillingInfo()
  //     .then((info) => {
  //       // console.log("BILLING INFO", info);
  //     })
  //     .catch((error) => {
  //       // console.log("BILLING INFO error", error);
  //     });
  // }, []);

  useEffect(() => {
    setSelectedAddress(addresses?.[0]);
  }, [addresses]);

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = (values) => {
    if (selectedAddress === null) {
      showError("Please add an address to setup your payout account");
      return;
    }

    setIsLoading(true);
    // console.log();
    setupBillingInfo(selectedAddress, values)
      .then(() => {
        loadUser();
        showSuccess("Your account has been connected");
      })
      .catch((error) => {
        // console.log(error);
        showError(
          "An error occurred setting up your billing account. Please verify your information is correct"
        );
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="add-local-bank-account-root-container overflow-auto">
      <h5>Set up Payments: Direct to Local Bank</h5>
      <p>Send funds directly to your bank.</p>

      <br />

      <div>
        <div style={{ marginRight: "32px" }}>
          <h6 className="pl-2 mb-3">Address Details</h6>
          {/*<AddressView userAddress={address} bill={false} />*/}
          <AccountPageAddresses
            onSelect={(id) => {
              setSelectedAddress(
                addresses?.filter((a) => {
                  return a?.id === id;
                })?.[0]
              );
            }}
          />
        </div>

        <br />
        <br />
        <Form className=" w-50" onSubmit={handleSubmit(onSubmit)}>
          <h6 className="font-weight-bold">Account Holder Information</h6>
          <Form.Label>First Name</Form.Label>
          <Form.Group className="border">
            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="fname"
                  required
                  placeholder="First Name"
                  defaultValue={user?.fname}
                  {...field}
                  isInvalid={!!errors.fname}
                />
              )}
              name="fname"
              rules={{
                required: "Please enter first name",
              }}
            />
            {errors && errors.fname && (
              <Form.Control.Feedback type="invalid">
                {errors.fname.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Label>Last Name</Form.Label>
          <Form.Group className="border">
            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="lname"
                  required
                  placeholder="Last Name"
                  defaultValue={user?.lname}
                  {...field}
                />
              )}
              name="lname"
              rules={{
                required: "Please enter last name",
              }}
            />
            {errors && errors.lname && (
              <Form.Control.Feedback type="invalid">
                {errors.lname.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Label>Birthday</Form.Label>
          <Form.Group className="border">
            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="date"
                  required
                  placeholder="Birthday"
                  {...field}
                  isInvalid={!!errors.bday}
                />
              )}
              name="bday"
              rules={{
                required: "Please enter your birthday",
              }}
            />
            {errors && errors.bday && (
              <Form.Control.Feedback type="invalid">
                {errors.bday.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          &nbsp; &nbsp;
          <p className="font-weight-bold">Account Information</p>
          <Form.Label>Account Number</Form.Label>
          <Form.Group className="border">
            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="name"
                  required
                  placeholder="Account Number"
                  {...field}
                  isInvalid={!!errors.accnumber}
                />
              )}
              name="accnumber"
              rules={{
                required: "Please enter your account number",
              }}
            />
            {errors && errors.accnumber && (
              <Form.Control.Feedback type="invalid">
                {errors.accnumber.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Label>Transit Number</Form.Label>
          <Form.Group className="border">
            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="number"
                  required
                  placeholder="Transit Number"
                  {...field}
                  isInvalid={!!errors.transitNumber}
                />
              )}
              name="transitNumber"
              rules={{
                required: "Please enter your transit number",
              }}
            />
            {errors && errors.transitNumber && (
              <Form.Control.Feedback type="invalid">
                {errors.transitNumber.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Label>Institution Number</Form.Label>
          <Form.Group className="border">
            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="number"
                  required
                  placeholder="Institution Number"
                  {...field}
                  isInvalid={!!errors.instNumber}
                />
              )}
              name="instNumber"
              rules={{
                required: "Please enter your Institution Number",
              }}
            />
            {errors && errors.instNumber && (
              <Form.Control.Feedback type="invalid">
                {errors.instNumber.message}
              </Form.Control.Feedback>
            )}
          </Form.Group>
          <Form.Group className="">
            <Controller
              control={control}
              render={({ field }) => (
                <Row className="ml-2">
                  <Form.Check {...field} />

                  <p>The information I have entered is complete and accurate</p>
                </Row>
              )}
              name="accepted"
              rules={{
                required: "Please check the above checkbox to proceed",
              }}
            />
            {errors && errors.accepted && (
              <Alert variant="danger" type="invalid">
                {errors.accepted.message}
              </Alert>
            )}
          </Form.Group>
          <Button
            className={"btn-block bg-primary text-white hover:bg-primary"}
            type="submit"
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}

            {!isLoading && "Submit"}
          </Button>
        </Form>

        <br />
        <br />
        <br />
        {/*<div className="bank-account-information">*/}
        {/*  <form action="">*/}
        {/*    <input*/}
        {/*      type="text"*/}
        {/*      className="bank-account-text-fields"*/}
        {/*      id="checkout-street-address"*/}
        {/*      placeholder="First Name"*/}
        {/*      defaultValue={fName}*/}
        {/*      onChange={(e) => {*/}
        {/*        setFName(e.target.value);*/}
        {/*      }}*/}
        {/*    />*/}

        {/*    <input*/}
        {/*      type="text"*/}
        {/*      className="bank-account-text-fields"*/}
        {/*      id="checkout-street-address"*/}
        {/*      placeholder="Last Name"*/}
        {/*      defaultValue={lName}*/}
        {/*      onChange={(e) => {*/}
        {/*        setLName(e.target.value);*/}
        {/*      }}*/}
        {/*    />*/}

        {/*    <input*/}
        {/*      type="text"*/}
        {/*      className="bank-account-text-fields"*/}
        {/*      id="checkout-street-address"*/}
        {/*      placeholder="Transit Number"*/}
        {/*      onChange={(e) => {*/}
        {/*        setTransitNumber(e.target.value);*/}
        {/*      }}*/}
        {/*      defaultValue={transitNumber}*/}
        {/*      // defaultValue={address?.firstName}*/}
        {/*    />*/}
        {/*    <input*/}
        {/*      type="text"*/}
        {/*      className="bank-account-text-fields"*/}
        {/*      id="checkout-street-address"*/}
        {/*      placeholder="Institution Number"*/}
        {/*      onChange={(e) => {*/}
        {/*        setInstNumber(e.target.value);*/}
        {/*      }}*/}
        {/*      defaultValue={instNumber}*/}
        {/*    />*/}

        {/*    <input*/}
        {/*      type="text"*/}
        {/*      className="bank-account-text-fields"*/}
        {/*      id="checkout-street-address"*/}
        {/*      placeholder="Account Number"*/}
        {/*      defaultValue={accountNumber}*/}
        {/*      onChange={(e) => {*/}
        {/*        setAccountNumber(e.target.value);*/}
        {/*      }}*/}
        {/*    />*/}

        {/*    <br />*/}
        {/*    <TextField*/}
        {/*      id="date"*/}
        {/*      label="Birthday"*/}
        {/*      type="date"*/}
        {/*      defaultValue={birthday}*/}
        {/*      onChange={(e) => {*/}
        {/*        console.log(new Date(e.target.value));*/}

        {/*        setBirthday(new Date(e.target.value));*/}
        {/*      }}*/}
        {/*      InputLabelProps={{*/}
        {/*        shrink: true,*/}
        {/*      }}*/}
        {/*    />*/}
        {/*  </form>*/}
        {/*  <br />*/}
        {/*  <br />*/}

        {/*  <Button*/}
        {/*    onClick={() => {*/}
        {/*      onClickContinue();*/}
        {/*    }}*/}
        {/*  >*/}
        {/*    Setup*/}
        {/*  </Button>*/}
        {/*</div>*/}
      </div>
    </div>
  );
}
