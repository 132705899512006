// react
import React, {useContext, useEffect, useState} from "react";

// third-party
import classNames from "classnames";
import { connect } from "react-redux";

import { Row, Col, Nav, Accordion, Badge, } from "react-bootstrap";
import PersonIcon from "@material-ui/icons/Person";

// application
import { currencyChange } from "../../store/currency";
import { localeChange } from "../../store/locale";
import { mobileMenuClose } from "../../store/mobile-menu";

// data stubs
import {Link} from "react-router-dom";

import authContext from "../../context/auth/authContext";
import toolsContext from "../../context/tools/toolsContext";

function MobileMenu(props) {
  const {
    mobileMenuState,
    closeMobileMenu,
    onClose,
  } = props;

  const { user, balance, logout, isLoggedIn, notifications, } = useContext(authContext);
  const { allCategories } = useContext(toolsContext);

  const [categories, setCategories] = useState(null);

  useEffect(() => {
    setCategories(allCategories);
  }, [allCategories]);

  const classes = classNames("mobilemenu", {
    "mobilemenu--open": mobileMenuState.open,
  });

  const handleItemClick = (item) => {
    closeMobileMenu();
    // eslint-disable-next-line no-restricted-globals
    // item && history.push(item)
  };

  const handleLogout = () => {
    closeMobileMenu();
    logout()
  }

  return (
    <div className={classes} style={{ zIndex: 1021 }}>
      {/* eslint-disable-next-line max-len */}
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */}
      <div className="mobilemenu__backdrop" onClick={closeMobileMenu} />
      <div className="mobilemenu__body">
        <div className="p-3 d-flex bg-primary text-light" style={{ height: "8rem" }}>
          <Row>
            <Col
                onClick={() => handleItemClick("#")}
                className="d-flex flex-column justify-content-center align-items-left"
            >
              <Link
                  to={isLoggedIn ? "/account/dashboard" : "/account/login"}
                  className="text-light"
              >
                <PersonIcon
                    fontSize="large"
                    className="p-1 text-light rounded-circle border border-light"
                />

                { !isLoggedIn && <h5 className="mb-0 mt-3">{`Login | Rent Your Tool`}</h5> }
                {
                  isLoggedIn &&
                      <>
                        <h5 className="mb-0 mt-3">{`${user?.fname ?? "My"} ${user?.lname ?? "Account"}`}</h5>
                        <p className="mb-0 mt-1" style={{ fontSize: "0.8rem" }}>{`Account Balance: $${balance?.toFixed(2) ?? "0.00"}`}</p>
                      </>
                }
              </Link>
            </Col>
          </Row>
        </div>
        <div className="mobilemenu__content">
          <section className="pl-2">
            <Nav.Item className="py-3" onClick={() => handleItemClick("/")}>
              <Nav.Link href="/" className="text-dark">
                <i style={{ fontSize: "1rem" }} className="text-primary mr-2 fa fa-home">&nbsp; &nbsp;</i>
                <span className="pt-1">Home</span>
              </Nav.Link>
            </Nav.Item>
            {
              isLoggedIn &&
              <>
              <Nav.Item className="py-3 pl-3" onClick={() => handleItemClick("#")}>
                <Link to="/account/dashboard" className="text-dark">
                  <i style={{ fontSize: "1rem" }} className="text-primary mr-2 fa fa-toolbox">&nbsp; &nbsp;</i>
                  Dashboard
                </Link>
              </Nav.Item>

                <Nav.Item className="py-3" onClick={() => handleItemClick("/messages")}>
                  <Nav.Link href="/messages" className="text-dark">
                    <i style={{ fontSize: "1rem" }} className="text-primary mr-2 fa fa-comment">&nbsp; &nbsp;</i>
                    Messages &nbsp;
                    {
                    notifications?.length > 0 && (
                        <Badge
                            pill
                            variant="primary"
                            className="badge-number"
                            style={{ borderRadius: "10rem", }}
                        >
                          { notifications?.length }
                        </Badge>
                    )
                  }
                  </Nav.Link>
                </Nav.Item>

                <Nav.Item className="py-3" onClick={() => handleItemClick("/rent-tool")}>
                  <Nav.Link href="/rent-tool" className="text-dark">
                    <i style={{ fontSize: "1rem" }} className="text-primary mr-2 fa fa-tools">&nbsp; &nbsp;</i>
                    Rent Your Tool
                  </Nav.Link>
                </Nav.Item>
              </>
            }

            {/*{ console.log("CATEGORIESSS: ", categories) }*/}
            {/*{ console.log("CATEGORIESSS ALL: ", allCategories) }*/}
            <Accordion defaultActiveKey={false}>
              <Accordion.Toggle
                  className="text-dark"
                  as={Nav.Link}
                  eventKey="0"
              >
                <Nav.Item className="py-3">
                  <i style={{ fontSize: "1rem" }} className="text-primary mr-2 fa fa-toolbox">&nbsp; &nbsp;</i>
                  Categories
                </Nav.Item>
              </Accordion.Toggle>
              <Accordion.Collapse className="pl-2" eventKey="0">
                <>
                  {
                    categories?.map( (category, index) => {
                      return (
                          <Nav.Item className="py-2" onClick={() => handleItemClick(category?.url)}>
                            <Nav.Link href={`${category?.url}`} className="text-dark py-3 d-flex flex-row">
                              <i style={{ fontSize: "1rem" }} className="text-primary mr-2 align-middle fa fa-tools">&nbsp; &nbsp;</i>
                              <p className="align-middle">{category?.title ?? `Mysterious Category ${index}`}</p>
                            </Nav.Link>
                          </Nav.Item>
                      )
                    })
                  }
                </>
              </Accordion.Collapse>
            </Accordion>

            {
              isLoggedIn &&
              <Nav.Item
                  className="border-top py-3"
                  style={{ borderColor: "#4e5051" }}
              >
                <Nav.Link
                    className="text-dark"
                    onClick={ handleLogout }
                >
                  <i style={{ fontSize: "1rem" }} className="text-primary mr-2 fa fa-sign-out-alt">&nbsp; &nbsp;</i>
                  Logout
                </Nav.Link>
              </Nav.Item>
            }
          </section>
          <div className="mobilemenu__footer text-center m-0 mt-auto p-2">
            <p
                className="mb-0"
                style={{ fontSize: "0.7rem" }}
            >
              Copyright <Link to="/">Toology &trade;</Link> A trademark of K Squared Tech Inc. All Rights Reserved.
              <br/>
              <span className="mt-2">Powered by <Link to="https://roveapps.com">Rove</Link></span>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  mobileMenuState: state.mobileMenu,
});

const mapDispatchToProps = {
  closeMobileMenu: mobileMenuClose,
  changeLocale: localeChange,
  changeCurrency: currencyChange,
};

export default connect(mapStateToProps, mapDispatchToProps)(MobileMenu);
