import DataManager from "./DataManager";
import { LocationManager } from "./LocationManager";

export class SearchEngine {
  static async findTool(query, options, allTools) {
    let userLocation = options?.location;

    let category = options?.category?.id;

    // console.log("category vval", options);
    // console.log("category vval", category);

    query = query?.toLowerCase();


    allTools.forEach((tool) => {
      tool.distance =
          (userLocation.location.lat && userLocation.location.long)
          ? LocationManager.getDistanceFromTool(tool, userLocation)
          : -1;
    });

    //
    // console.log("GOT TOOLSSS");
    // console.log(allTools);

    let results = allTools
      ?.filter((item) => {
        let name = item?.name?.toLowerCase();

        let toolName = item?.toolType?.name?.toLowerCase();
        let brandName = item?.brand?.toLowerCase();
        let categoryCheck =
          category != null && category !== undefined
            ? category === item?.toolType?.categoryID
            : true;

        return (
          (toolName?.includes(query) ||
            brandName?.includes(query) ||
            name?.includes(query)) &&
          categoryCheck
        );
      })
      .sort((a, b) => (a?.distance > b?.distance ? 1 : -1));
    //
    // console.log("Found results");
    // console.log(results);

    return results;
  }
}
