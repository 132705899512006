// react
import React, { useEffect, useState } from "react";
import DataManager from "../../api/DataManager";
import StroykaSlick from "../shared/StroykaSlick";

const slickSettings = {
  "grid-4": {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },
  "grid-4-sm": {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 474,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },
  "grid-5": {
    dots: false,
    arrows: true,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },
  horizontal: {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },
};

function ProductTabHowTo({ product }) {
  const [videoLinks, setVideoLinks] = useState([]);

  useEffect(() => {
    loadSuggestedVideos();
  }, []);
  const loadSuggestedVideos = () => {
    //console.log('fsga',product.model)
    let keywords =
      "how to use " + (product?.brand ?? "") + (" " + product?.model ?? "");
    DataManager.loadVideoSuggestions(keywords).then((videos) => {
      //  console.log(videos)
      setVideoLinks(videos);
    });
  };

  const video = (url) => {
    return (
      <iframe
        width="350"
        height="240"
        src={url}
        title="YouTube video player"
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
      />
    );
  };

  return (
    <div>
      <h3>Get started on your project today!</h3>
      <h6 style={{ color: "black", fontWeight: "400" }}>
        Here are some relevant guides we found for your tool
      </h6>
      <br />
      <br />

      <StroykaSlick
        // // ref={this.setSlickRef}
        {...slickSettings["grid-5"]}
      >
        {videoLinks?.map((item) => {
          return video(item);
        })}
      </StroykaSlick>
    </div>
  );
}

export default ProductTabHowTo;
