// react
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";

// third-party
import { Helmet } from "react-helmet-async";

import "../../scss/pages/_home-page.scss";
// blocks

import BlockProductsCarousel from "../blocks/BlockProductsCarousel";
import BlockSlideShow from "../blocks/BlockSlideShow";

import theme from "../../data/theme";

import toolsContext from "../../context/tools/toolsContext";
import { Image, Row, Col } from "react-bootstrap";
import IconCard from "../blocks/IconCard";
import SearchIcon from "@material-ui/icons/Search";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import authContext from "../../context/auth/authContext";
import BlockCategoriesSlideshow from "../blocks/BlockCategoriesSlideshow";
import Invite from "../shared/Invite";
import FreeDetails from "../shared/FreeDetails";
import { BuildRounded } from "@material-ui/icons";
function HomePageOne() {
  const { popularTools, nearByTools, allCategories, isLoading } =
    useContext(toolsContext);

  const { isLoggedIn } = useContext(authContext);

  const [inviteShow, setInviteShow] = useState(false);
  const [freeDetailsShow, setFreeDetailsShow] = useState(false);

  const promotion = useCallback(() => {
    if (isLoggedIn) {
      return {
        text: (
          <>
            Invite friends and get{" "}
            <strong style={{ fontSize: "1.3rem", fontWeight: "800" }}>
              $10
            </strong>{" "}
            in Credits when they book their first tool with us{" "}
          </>
        ),
        action: (
          <strong
            onClick={() => {
              setInviteShow(true);
            }}
            className="align-items-center cursor-pointer"
          >
            Invite now!
          </strong>
        ),
      };
    }

    return {
      text: (
        <>
          Enjoy your{" "}
          <strong style={{ fontSize: "1.3rem", fontWeight: "800" }}>
            first
          </strong>{" "}
          day's rent on us.
        </>
      ),
      action: (
        <strong
          onClick={() => {
            setFreeDetailsShow(true);
          }}
          className="align-items-center cursor-pointer"
        >
          Learn more
        </strong>
      ),
    };
  }, [isLoggedIn]);

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Home — ${theme.name}`}</title>
      </Helmet>
      {useMemo(
        () => (
          <BlockSlideShow />
        ),
        []
      )}
      <div className="w-full bg-primary cursor-pointer">
        <p
          style={{ fontSize: "1.2rem", fontWeight: "400" }}
          className="py-3 text-secondary w-100 text-center "
        >
          {promotion()?.text}
          <strong className="text-white" style={{ marginLeft: "1ch" }}>
            {promotion()?.action}
          </strong>
        </p>
      </div>
      <br />
      <br />
      <br />

      <div className="w-100 pb-4">
        <h3 className="text-center mb-4 mb-sm-5" style={{ fontSize: "1.5rem" }}>
          You’re just 3 steps away from your next DIY project
        </h3>
        <div
          style={{ width: "90%" }}
          className="mx-auto mb-4 d-flex flex-row justify-content-between align-items-center"
        >
          <IconCard
            width="25vw"
            height="8vw"
            withOverlay={true}
            icon={
              <SearchIcon className="text-primary mt-2" fontSize={"large"} />
            }
            title="Search"
            text="Explore tools for your project. Filters by distance, daily rate, brand and reviews. Get notified in case you can't find your tool."
          />

          <IconCard
            width="25vw"
            height="8vw"
            withOverlay={true}
            icon={
              <BuildOutlinedIcon
                className="text-primary mt-2"
                fontSize={"large"}
              />
            }
            title="Book"
            text="Found something you like? Review the details and simply pay via credit card or PayPal to confirm your booking."
          />

          <IconCard
            width="25vw"
            height="8vw"
            withOverlay={true}
            icon={
              <LocationOnOutlinedIcon
                className="text-primary mt-2"
                fontSize={"large"}
              />
            }
            title="Pick-up"
            text="You’re all set! Connect with the tool owner through the in-site chat to confirm pick up time and other details."
          />
        </div>
      </div>

      <div className="pt-4 mb-3">
        <BlockCategoriesSlideshow
          rows={2}
          title="Categories"
          layout="horizontal"
          products={[...allCategories, ...allCategories]}
          loading={isLoading}
          // onGroupClick={featuredProducts?.handleTabChange}
        />
      </div>

      <div className="pt-4 mb-3">
        <BlockProductsCarousel
          rows={2}
          title="Nearby Tools"
          layout="horizontal"
          products={nearByTools}
          loading={isLoading}
          // onGroupClick={featuredProducts?.handleTabChange}
        />
      </div>

      <div className="mb-3">
        <BlockProductsCarousel
          rows={1}
          title="Popular Tools"
          layout="horizontal"
          products={popularTools}
          loading={isLoading}
          // onGroupClick={featuredProducts?.handleTabChange}
        />
      </div>

      <br />
      <Row
        style={{
          background: `url(/images/about/drill-in-hand.jpg) no-repeat center center `,
          height: "15rem",
          backgroundSize: "cover",
          backgroundPosition: "center",
        }}
      >
        <Col
          className="text-white d-flex flex-col justify-content-center"
          style={{ height: "100%", background: "rgba(14, 89, 126, 0.9)" }}
        >
          <h3 className=" my-auto mx-auto text-center">
            All your DIY needs under one roof! &nbsp;
            <BuildRounded style={{ fontSize: "1.8rem" }} />
          </h3>
        </Col>
      </Row>
      <Invite
        open={inviteShow}
        onClose={() => {
          setInviteShow(false);
        }}
      />

      <FreeDetails
        open={freeDetailsShow}
        onClose={() => {
          setFreeDetailsShow(false);
        }}
      />
    </React.Fragment>
  );
}

export default HomePageOne;
