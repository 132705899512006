// react
import React, { useContext, useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";
import theme from "../../data/theme";
import addressContext from "../../context/address/addressContext";
import AddressEdit from "../shared/AddressEdit";
import AddressView from "../shared/AddressView";
import DB from "../../api/DB";
import showError, { showSuccess } from "../../api/Helper";
import { FormControlLabel, Radio, RadioGroup } from "@material-ui/core";
import VisaIcon from "../../assets/imgs/visa-icon.png";

export default function AccountPageAddresses({ onSelect }) {
  const { addresses, listUserAddress, save, update, deleteAddress } =
    useContext(addressContext);

  // console.log(`Addresses: ${JSON.stringify(addresses)}`)
  const [showAddressModal, setShowAddressModal] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState(null);

  useEffect(() => {
    listUserAddress();
  }, []);

  useEffect(() => {
    // console.log("addresses", addresses);
    addresses && setSelectedAddress(addresses[0]?.id);

    for (let i = 0; i < addresses.length; i++) {
      // console.log("laam" + JSON.stringify(addresses[i]));
      if (addresses[i]?.isDefault) {
        // console.log("laam MAI HUN DON")
        setSelectedAddress(addresses[i]?.id);
        break;
      }
    }
  }, [addresses]);

  const showAddress = () => {
    return (
      <RadioGroup
        value={selectedAddress}
        className="d-flex flex-wrap flex-row flex-sm-col"
      >
        {addresses?.map((address, index) => {
          return (
            <div className="mr-md-3 border p-2 m-2 address-card-custom">
              {/*{ console.log(`Address: ${JSON.stringify(address)}`) }*/}
              {onSelect && addresses?.length > 1 && (
                <FormControlLabel
                  value={address?.id}
                  control={<Radio />}
                  className="card-number"
                  // label={"Select"}
                  onChange={(e) => {
                    onSelect(e);
                    setSelectedAddress(address?.id);
                  }}
                />
              )}

              <AddressView
                onChange={(address) => {
                  update(address);
                  // onChange({ lat: address?.lat, long: address?.long });
                }}
                bill={false}
                userAddress={address}
                publish={false}
                deleteAddress={() => {
                  deleteAddress(address);
                }}
              />
            </div>
          );
        })}
      </RadioGroup>
    );
  };

  return (
    <div>
      <AddressEdit
        open={showAddressModal}
        onClose={() => {
          setShowAddressModal(false);
        }}
        onSave={(address) => {
          save(address);
        }}
      />
      <Helmet>
        <title>{`Address List — ${theme.name}`}</title>
      </Helmet>

      <div className="addresses-list">
        {showAddress()}

        <div className="addresses-list__divider" />
      </div>
      <div className="my-4">
        <div
          className="btn btn-secondary btn-sm"
          onClick={() => {
            setShowAddressModal(true);
          }}
        >
          Add New
        </div>
      </div>
      <p className="text-primary font-weight-bold cursor-pointer">
        Update address for all my tools
      </p>
    </div>
  );
}
