// react
import React, { Component, useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";

// application
import ProductTabDescription from "./ProductTabDescription";
import ProductTabSpecification from "./ProductTabSpecification";
import ProductTabReviews from "./ProductTabReviews";
import ProductTabHowTo from "./ProductTabHowTo";
import DataManager from "./../../api/DataManager";

const ProductTabs = ({ withSidebar, products, showReviews = true }) => {
  const [currentTab, setCurrentTab] = useState("how-to");
  const [screen, setScreen] = useState(null);
  const [productReviews, setProductReviews] = useState([]);

  const setTab = (newTab) => {
    setCurrentTab(newTab);
  };

  useEffect(() => {
    DataManager.getToolReviews(products.id)
      .then((reviews) => {
        // console.log(reviews);
        setProductReviews(reviews);
      })
      .catch((error) => {
        // console.log(error);
      });
  }, []);

  //  console.log(products);

  const classes = classNames("product-tabs", {
    "product-tabs--layout--sidebar": withSidebar,
  });

  const tabs = [
    {
      key: "how-to",
      title: "Guides",
      content: <ProductTabHowTo product={products} />,
    },

    // { key: 'description', title: 'Description', content: <ProductTabDescription /> },
    // { key: 'specification', title: 'Specification', content: <ProductTabSpecification /> },
    {
      key: "reviews",
      title: "Reviews",
      content: <ProductTabReviews productReviews={productReviews} />,
    },
  ];

  const tabsButtons = tabs.map((tab) => {
    const classes = classNames("product-tabs__item", {
      "product-tabs__item--active": currentTab === tab.key,
    });

    return (
      <button
        key={tab.key}
        type="button"
        onClick={() => setTab(tab.key)}
        className={classes}
      >
        {tab.title}
      </button>
    );
  });

  const tabsContent = tabs.map((tab) => {
    const classes = classNames("product-tabs__pane", {
      "product-tabs__pane--active": currentTab === tab.key,
    });

    return (
      <div key={tab.key} className={classes}>
        {tab.content}
      </div>
    );
  });

  return (
    <div className={classes}>
      {showReviews && (
        <div className={window.innerWidth > 800 ? "" : "product-tabs__list"}>
          {tabsButtons}
        </div>
      )}

      <div className="product-tabs__content">{tabsContent}</div>
    </div>
  );
};

ProductTabs.propTypes = {
  withSidebar: PropTypes.bool,
};

ProductTabs.defaultProps = {
  withSidebar: false,
};

export default ProductTabs;
