// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";

// data stubs
import theme from "../../data/theme";

function SitePageTerms() {
  const breadcrumb = [
    { title: "Home", url: "" },
    { title: "Terms And Conditions", url: "" },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Terms And Conditions — ${theme.name}`}</title>
      </Helmet>

      <div className="py-5 px-5">
        <div className="document__header">
          <h1 className="document__title">Terms of Use</h1>
          <div className="document__subtitle">
            This Agreement was last modified on 17 June 2021.
          </div>
        </div>
        <div className="document__content typography">
          <p>
            Welcome to www.Toology.ca. You can find an overview of our policies
            here. All policies, the Mobile Devices Terms, and the Privacy Policy
            are incorporated into these Terms of Use. You agree to comply with
            all the above when accessing or using our Services.
            <br />
            <br />
            Toology &trade; is a trademark of K-Squared Tech Inc.
          </p>
          <h3>Your Account</h3>
          <p>
            In order to access and use some or a portion of the Services, you
            may be required to register with us and set up an account with your
            email address and a password (your “Account”). The email address you
            provide will be your email address, and you are solely responsible
            for maintaining the confidentiality of your password. You are solely
            responsible for all activities that occur under your Account.
            Therefore, you should protect your password and make your password
            difficult for others to guess. You will not transfer your Account to
            another party without our consent.
            <br />
            <br />
            You may connect to the Services with a third-party service and you
            give us permission to access, store, and use your information from
            that service as permitted by that service and as may be described in
            our Privacy Policy. If you believe your Account may have been
            compromised or misused, contact us immediately at Toology Customer
            Support.
          </p>
          <h3>Using the Services </h3>
          <p>
            You will post in the appropriate category or area and will not do
            any of the following things:
          </p>
          <ul>
            <li>violate any laws or our Prohibited Content Policy;</li>
            <li>be false or misleading;</li>
            <li>infringe any third-party right;</li>
            <li>
              distribute or contain spam, chain letters, or pyramid schemes;
            </li>
            <li>
              distribute viruses or any other technologies that may harm Toology
              or the interests or property of Toology users;
            </li>
            <li>
              impose an unreasonable load on our infrastructure or interfere
              with the proper working of the Toology;
            </li>
            <li>copy, modify, or distribute any other person's content;</li>
            <li>
              use any robot, spider, scraper or other automated means to access
              Toology and collect content for any purpose without our express
              written permission;
            </li>
            <li>
              harvest or otherwise collect information about others, including
              email addresses, without their consent;
            </li>
            <li>
              bypass measures used to prevent or restrict access to Toology.
            </li>
          </ul>
          <h3>Abusing Toology Services</h3>
          <p>
            Please use the flagging system to tell us about any problems or
            offensive content so that together we can keep the Services site
            working properly. We may limit or terminate our Services, remove
            hosted content and take technical and legal steps to keep users off
            Toology if we think that they are creating problems or acting
            inconsistently with the letter or spirit of our policies. However,
            whether we decide to take any of these steps, remove hosted content
            or keep a user off Toology or not, we do not accept any liability
            for monitoring Toology or for unauthorized or unlawful content on
            Toology or use of Toology by users.
          </p>
          <h3>Marketplace</h3>
          <p>
            Without limitation to the rights granted below (see ‘Content’), some
            of Toology's features may display the your posts on other sites. By
            using the Services, you agree that your ads can be displayed on
            sites other than Toology. The terms for these other sites may or may
            not be similar to our terms. You may be subject to additional laws
            or other restrictions where your ad is posted. We may remove your ad
            if it is flagged on our site, or if we believe it causes problems or
            violates any law or policy.
          </p>
          <h3>Fees and Services</h3>
          <p>
            Using the Services is generally free. We may charge fees for certain
            Services. If the service you use charges a fee, you will be able to
            review and accept that charge. Our fees are quoted in Canadian
            Dollars, and we may change them from time to time. We will notify
            you of changes to our fee policy by posting such changes on the
            site. We may choose to temporarily change our fees for promotional
            events or new services; these changes are effective when we announce
            the promotional event or new service. Our fees are non-refundable,
            and you are responsible for paying them when they're due. If you
            don't, we may limit your ability to use the Services. If your
            payment method fails or your account is past due, we may collect
            fees owed using other collection mechanisms.
            <br />
            <br />
            Canadian taxes associated with our Services will be collected when
            applicable. You agree to provide accurate address information
            necessary for Toology to comply with its obligations under
            applicable law. To the extent that such information is not provided,
            Toology will in its discretion determine and collect appropriate
            taxes.
            <br />
            <br />
            You are solely responsible to collect and remit any applicable taxes
            resulting from the sale of your items or services listed on Toology.
          </p>
          <h3>Content</h3>
          <p>
            The Services contains stuff from us, you, and other users (as well
            as from our third party service providers). You agree not to copy,
            modify, resell or distribute the Services, our copyrights or
            trademarks. When you give us content, you are granting us a
            non-exclusive, worldwide, perpetual, irrevocable, royalty-free,
            sub-licensable right to exercise the copyright, publicity, and
            database rights to that content. If you believe that your rights
            have been violated, please notify Toology Customer Support and we
            will investigate the matter. We reserve the right to remove content
            where we have grounds for suspecting the violation of these terms or
            the rights of any other party.
          </p>
          <h3>Reporting Intellectual Property Infringements</h3>
          <p>
            Do not post content that infringes the rights of third parties. This
            includes, but is not limited to, content that infringes on
            intellectual property rights such as copyright and trademark (e.g.
            offering counterfeit items for rent or sale). Do not post content
            that contains any reference to or likeness of any identifiable third
            parties, unless consent has been obtained from each such individual
            as may be required. We reserve the right to remove content where we
            have grounds for suspecting the violation of these terms, our
            policies or of any party's rights. The following steps are for
            reporting a listing to Toology that you believe to be infringing on
            your intellectual property rights. Only the intellectual property
            rights owner can report of potentially infringing items or listings
            through Toology's VeRO Program. If you have a good faith belief that
            a listing on any of the Services infringes your copyright,
            trademark, or other intellectual property rights, all you need to do
            is download our Notice of Claimed Infringement (NOCI) form, fill it
            out, and email it to Toology at the email address provided on the
            form. After we receive your first NOCI, Toology will confirm your
            enrolment in our program. We reserve the right to share, in
            accordance with applicable law, the completed NOCI form with the
            third party that originally posted the potentially infringing
            listing.
          </p>
          <h3>Disclaimers and Limitations of Liability</h3>
          <p>
            The Services are provided “as is” and “as available”. You agree not
            to hold us responsible for things other users post or do. You also
            agree not to hold us responsible for the payment processing of other
            service providers such as Stripe (“Third Party Payment Processor”.
            For greater certainty, as applicable, if you link a Third Party
            Payment Processor account to your Toology Account, you acknowledge
            and agree that by making payments through Toology with that Third
            Party Payment Processor, you are bound by that Third Party Payment
            Processor’s applicable terms and conditions, and we are not liable
            for any loss, claims or damages howsoever arising in connection with
            that third party’s services). As most of the stuff on the Services
            comes from other users, we do not guarantee the accuracy,
            completeness, efficacy or timeliness of any postings or user
            communications or the quality, safety, or legality of what is
            offered. We also cannot guarantee continuous or secure access to our
            Services. Notification functionality in our Services may not occur
            in real time. Such functionality is subject to delays beyond our
            control, including without limitation, delays or latency due to your
            physical location or your wireless data service provider’s network.
            Accordingly, to the extent legally permitted we expressly disclaim
            all warranties, representations, and conditions, express or implied,
            including those of quality, merchantability, merchantable quality,
            durability, fitness for a particular purpose and those arising by
            statute. We are not liable for any loss, whether of money (including
            profit), goodwill, or reputation, or any special, indirect, or
            consequential damages arising out of your use of Toology, even if
            you advise us or we could reasonably foresee the possibility of any
            such damage occurring. Some jurisdictions do not allow the
            disclaimer of warranties or exclusion of damages, so such
            disclaimers and exclusions may not apply to you.
            <br />
            <br />
            Despite the previous paragraph, if we are found to be liable, our
            liability to you or any third party (whether in contract, tort,
            negligence, strict liability in tort, by statute or otherwise) is
            limited to the greater of (a) the total fees you pay to us in the 12
            months prior to the action giving rise to liability, and (b) 100
            Canadian Dollars.
          </p>
          <h3>Indemnification</h3>
          <p>
            You will indemnify and hold harmless Toology and our officers,
            directors, agents and employees (each an “Indemnified Party”), from
            any claim made by any third party, together with any amounts payable
            to the third party whether in settlement or as may otherwise be
            awarded, and reasonable legal costs incurred by any of the
            Indemnified Parties, arising from or relating to your use of the
            Services, any alleged violation by you of the applicable terms, and
            any alleged violation by you of any applicable law or regulation. We
            reserve the right, at our own expense, to assume the exclusive
            defense and control of any matter subject to indemnification by you
            but doing so will not excuse your indemnity obligations.
            <br />
            <br />
            Personal Information. By using Services, you agree to the
            collection, transfer, storage and use of your personal information
            by Toology Canada Ltd. (the "data controller") on servers located in
            the United States and in the European Union as further described in
            our privacy policy.
          </p>
          <h3>Reviews</h3>
          <p>
            You may be asked to leave a review when messaging with other users
            on Toology. Reviews consist of a 1-5-star rating and selected
            reasons why you’ve chosen that review. Your submitted review,
            username, profile photo (if available), and the category of the
            seller’s listing will be made public. User reviews should be left in
            good faith, ensuring that both you and the other user are depicting
            your interaction fairly. When leaving a review on another user’s
            account, the review should:
          </p>
          <ul>
            <li>be truthful to what happened between you and the other user</li>
            <li>not be left to harass or abuse another user</li>
            <li>not be in left to manipulate or mislead other users</li>
          </ul>
          <p>
            To ensure the integrity of the review system, users are not able to
            edit or remove reviews on their own profile or someone else’s.
            Toology will also not mediate review-related disputes. User reviews
            reflect the opinions of individual Toology users and do not reflect
            the opinion of Toology.
            <br />
            <br />
            We reserve the right to remove reviews which violate our policies or
            guidelines. For more information, please read our User Review
            policy.
          </p>
          <h3>General</h3>
          <p>
            These terms and the other policies posted on the Services constitute
            the entire agreement between us and you, superseding any prior
            agreements. This agreement is governed by the laws of the Province
            of Ontario and the federal laws of Canada applicable therein. We
            both submit to the jurisdiction of the courts of the Province of
            Ontario. This will not affect your statutory rights if you are a
            consumer and applicable consumer law requires application of another
            law for certain topics. If we don't enforce any particular
            provision, we are not waiving our right to do so later. If a court
            strikes down any of these terms, the remaining terms will survive.
            We may automatically assign this agreement in our sole discretion in
            accordance with the notice provision below (our assignment to an
            affiliate will not require notice). Except for notices relating to
            illegal or infringing content, your notices to us must be sent by
            registered mail to:
            <br />
            <br />
            Toology Canada Ltd.
            <br />
            2836 Stoney Creek Drive
            <br />
            Brights Grove
            <br />
            ON, N0N1C0
            <br />
            Canada
            <br />
            <br />
            Re: User Inquiries
            <br />
            <br />
            We will send notices to you via the email address you provide, or by
            registered mail. Notices sent by registered mail will be deemed
            received five days following the date of mailing. We may update this
            agreement at any time, with updates taking effect when you next use
            the site or after 30 days, whichever is sooner. No other amendment
            to this agreement will be effective unless made in writing, signed
            by users and by us. Send questions, comments or complaints to
            Toology Customer Support.
          </p>
          <h3>Mobile Devices Terms</h3>
          <p>
            If you're accessing the Services from a mobile device using a
            Toology mobile application (the "Application"), the following terms
            and conditions ("Mobile Devices Terms") apply to you in addition to
            the applicable Mobile Privacy and Legal Notice or End User License
            Agreement, as the case may be. Your use of the Application confirms
            your agreement to these Mobile Devices Terms.
          </p>
          <h3>Application Use</h3>
          <p>
            Toology grants you the right to use the Application pursuant to the
            following restrictions: you may not (a) modify, copy, publish,
            license, sell, or otherwise commercialize this Application or any
            information or software associated with this Application; (b) rent,
            lease or otherwise transfer rights to this Application; or (c) use
            this Application in any manner that could impair the Application or
            interfere with any party's use or enjoyment of the Application. You
            must comply with all applicable laws and third-party terms of
            agreement when using the Application (e.g. your wireless data
            service agreement). The Application may not contain the same
            functionality available on the Toology website (and certain
            functionality may not be available depending on which Application
            and/or mobile device you are using). Your download and use of the
            Application are at your own discretion and risk, and you are solely
            responsible for any damages to your hardware device(s) or loss of
            data that results from the download or use of the Application.
          </p>
          <h3>Intellectual Property – Applications</h3>
          <p>
            Toology owns, or is the licensee to, all right, title, and interest
            in and to its Applications, including all rights under patent,
            copyright, trade secret, trademark, and any and all other
            proprietary rights, including all applications, renewals,
            extensions, and restorations thereof. You will not modify, adapt,
            translate, prepare derivative works from, decompile,
            reverse-engineer, disassemble, or otherwise attempt to derive source
            code from any Application and you will not remove, obscure, or alter
            Toology's copyright notice, trademarks or other proprietary rights
            notices affixed to, contained within, or accessed in conjunction
            with or by any Application.
          </p>
          <h3>
            Prohibited Countries Policy and Foreign Trade Regulation –
            Applications
          </h3>
          <p>
            The Application or its underlying technology may not be downloaded
            to or exported or re-exported: (a) into (or to a resident or
            national of) any country subject to United States embargo; (b) to
            anyone on the U.S. Department of the Treasury’s list of Specially
            Designated Nationals or on the U.S. Department of Commerce’s Denied
            Party or Entity List; and (c) to any prohibited country, person,
            end-user, or entity specified by U.S. export laws. When using the
            Application, you are responsible for complying with trade
            regulations and both foreign and domestic laws (e.g., you are not
            located in a country that is subject to a U.S. government embargo,
            or that has been designated by the U.S. government as a "terrorist
            supporting" country, and you are not listed on any U.S. government
            list of prohibited or restricted parties).
          </p>
          <h3>Additional Terms</h3>
          <p>
            Additional terms and conditions that apply to you based on the
            mobile device the Application is installed on:
            <h4>iOS – Apple</h4>
            <br />
            <br />
            These terms are an agreement between you and us, and not with Apple.
            Apple is not responsible for the Application and the content
            thereof.
            <br />
            <br />
            We grant you the right to use the Application only on an iOS product
            that you own or control and as permitted by the Usage Rules set
            forth in Apple’s then-current App Store Media Services Terms and
            Conditions.
            <br />
            <br />
            Apple has no obligation whatsoever to furnish any maintenance and
            support services with respect to the Application.
            <br />
            <br />
            Apple is not responsible for the investigation, defense, settlement,
            and discharge of any third-party intellectual property infringement
            claim.
            <br />
            <br />
            Apple is not responsible for addressing any claims by you or any
            third party relating to the Application or your possession and/or
            use of the Application, including but not limited to: (a) product
            liability claims; (b) any claim that the Application fails to
            conform to any applicable legal or regulatory requirement; and (c)
            claims arising under consumer protection or similar legislation.
            <br />
            <br />
            In the event of any failure of the Application to conform to any
            applicable warranty, you may notify Apple, and Apple may refund the
            purchase price, if applicable, for the Application to you; and to
            the maximum extent permitted by applicable law, Apple will have no
            other warranty obligation whatsoever with respect to the
            Application.
            <br />
            <br />
            Apple and Apple's subsidiaries are third party beneficiaries of
            these terms, and, upon your acceptance, Apple as a third party
            beneficiary thereof will have the right (and will be deemed to have
            accepted the right) to enforce these terms against you.
            <h4>Android - Google</h4>
            <br />
            <br />
            These terms are an agreement between you and us, and not with
            Google. Google is not responsible for the Application and the
            content thereof.
            <br />
            <br />
            Your use of the Application must comply with Google’s then-current
            Google Play Store Terms of Service.
            <br />
            <br />
            Google is only a provider of the Google Play Store where you
            obtained the Application.
            <br />
            <br />
            Google has no obligation or liability to you with respect to the
            Application or these terms; and
            <br />
            <br />
            You acknowledge and agree that Google is a third-party beneficiary
            to these terms as it relates to the Application.
          </p>
        </div>
      </div>
    </React.Fragment>
  );
}

export default SitePageTerms;
