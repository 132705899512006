// react
import React from "react";

// third-party
import ReactDOM from "react-dom";
import { Provider } from "react-redux";

// application
import * as serviceWorker from "./serviceWorker";
import Root from "./components/Root";
import store from "./store";

// styles
import "slick-carousel/slick/slick.css";
import "react-toastify/dist/ReactToastify.min.css";
import "@fortawesome/fontawesome-free/css/all.min.css";
import "react-input-range/lib/css/index.css";
import "./scss/style.scss";
import "bootstrap";
import "bootstrap/dist/js/bootstrap.js";
import { Amplify } from "aws-amplify";
import awsExports from "./aws-exports";
import MessengerCustomerChat from "react-messenger-customer-chat";
import "react-toastify/dist/ReactToastify.css";
import { parseAmplifyConfig } from "./api/Helper";

Amplify.configure(parseAmplifyConfig(awsExports));

ReactDOM.render(
  (
      // (
      //     <MessengerCustomerChat
      //         pageId="104649258452745"
      //         appId="151642440137205"
      //         htmlRef={window.location.pathname}
      //         shouldShowDialog={true}
      //     />
      //     ),
      (
        // eslint-disable-next-line react/jsx-filename-extension
        <Provider store={store}>
          <Root />
          {/*<ToastContainer limit={1}/>*/}
        </Provider>
      )),
      document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
