import React, { useContext, useState } from "react";
import { Link, Redirect } from "react-router-dom";

import { CircularProgress, Dialog, DialogContent } from "@material-ui/core";
import Slide from "@material-ui/core/Slide";

import IconButton from "@material-ui/core/IconButton";

import "../../../scss/payouts/AddPaymentMethod.scss";

import CloseIcon from "@material-ui/icons/Close";
import PayPalIcon from "../../../assets/imgs/paypal-icon.png";
import BankIcon from "../../../assets/imgs/bank.svg";
import authContext from "../../../context/auth/authContext";

import { Row, Col, } from "react-bootstrap";

export default function AddPaymentMethod({
  show,
  onClose,
  bankAccountLinked,
  paypalLinkedEmail,
  confirmWithdraw,
  setupPaypal,
}) {
  const { user } = useContext(authContext);

  return (
    <Dialog
      transition={Slide}
      open={show}
      style={{ opacity: "0.8" }}
      onDismiss={() => {
        onClose();
      }}
    >
      <DialogContent style={{ backgroundColor: "#000", minWidth: "100%" }}>
        <Dialog
          transition={Slide}
          open={show}
          style={{ opacity: "1" }}
          onDismiss={() => {
            onClose();
          }}
        >
          <DialogContent className="">
            <div className="add-payout-method">
              <div className="d-flex justify-content-between align-middle">
                <h5 className="main-add-payment-method-title">
                  Add a payment method
                </h5>
                <IconButton
                  onClick={() => {
                    onClose();
                  }}
                >
                  <CloseIcon fontSize="small" />
                </IconButton>
              </div>

              <div className="get-paid-methods">
                <hr />
                {bankAccountLinked && user?.stripeConnectID != null && (
                  <p className="text-primary"> Account already setup</p>
                )}

                <Row>
                  <img
                    src={BankIcon}
                    alt="PayPal"
                    style={{ height: "80px" }}
                    className="col-lg-3 hidden lg-block fotu"
                  />
                  <div className="col-sm-8 col-lg-6 align-middle">
                    <h5 className="method-title">Direct to Local Bank</h5>
                    <p className="method-detail">
                      <b>$5</b> CAD per withdrawal
                    </p>
                    <p className="method-detail">
                      Deposit to your local bank account in CAD
                    </p>
                  </div>
                  <div className="d-flex flex-column justify-content-end col-sm-4 col-lg-3 ">
                    <Link to={`/account/get-paid/add-local-bank-account`}>
                      <button
                        className="btn btn-sm get-paid-button mb-3"
                        onClick={() => {}}
                      >
                        {bankAccountLinked && user?.stripeConnectID != null
                          ? "Edit"
                          : "Set Up"}
                      </button>
                    </Link>{" "}
                    {bankAccountLinked && user?.stripeConnectID != null && (
                      <button
                        className="btn btn-sm get-paid-button"
                        onClick={() => {
                          confirmWithdraw();
                          onClose();
                        }}
                      >
                        Withdraw
                      </button>
                    )}
                  </div>
                </Row>

                <hr />
                {paypalLinkedEmail && (
                  <p className="text-primary"> Account already setup</p>
                )}

                <Row>
                  <img
                    src={PayPalIcon}
                    alt="PayPal"
                    style={{ height: "80px" }}
                    className="col-lg-3 hidden md-block fotu"
                  />
                  <div className="col-sm-8 col-lg-6 align-middle">
                    <h5 className="method-title">Direct to Local Bank</h5>
                    <p className="method-detail">
                      <b>$5</b> CAD per withdrawal
                    </p>
                    <p className="method-detail">
                      Receive funds directly in your Paypal account
                    </p>
                  </div>
                  <div className="d-flex justify-content-end col-sm-4 col-lg-3 align-middle">
                    <Link onClick={setupPaypal}>
                      {/*<button*/}
                      {/*  className="btn btn-sm get-paid-button mb-3"*/}
                      {/*  onClick={() => {}}*/}
                      {/*>*/}
                      {/*  {paypalLinkedEmail ? "Edit" : "Set Up"}*/}
                      {/*</button>*/}

                      <button
                        className="btn btn-sm get-paid-button"
                        onClick={() => {}}
                      >
                        Withdraw
                      </button>
                    </Link>
                  </div>
                </Row>
              </div>
            </div>
          </DialogContent>
        </Dialog>
      </DialogContent>
    </Dialog>
  );
}
