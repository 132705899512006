// react
import React, { useContext, useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";
import { Redirect, Route, Switch, useHistory } from "react-router-dom";
import { ToastContainer } from "react-toastify";

//context
import { ScreenSizeContext } from "../context/context";

// application
import Footer from "./footer";
import Header from "./header";
import MobileHeader from "./mobile/MobileHeader";
import MobileMenu from "./mobile/MobileMenu";
import Quickview from "./shared/Quickview";

// pages
import AccountLayout from "./account/AccountLayout";
import AccountPageMessages from "./account/AccountPageMessages";
import PageCart from "./shop/ShopPageCart";
import PageCheckout from "./shop/ShopPageCheckout";
import PageWishlist from "./shop/ShopPageWishlist";
import ShopPageCategory from "./shop/ShopPageCategory";
import ShopPageOrderSuccess from "./shop/ShopPageOrderSuccess";
import ShopPageProduct from "./shop/ShopPageProduct";
import ShopPageTrackOrder from "./shop/ShopPageTrackOrder";
import SitePageAboutUs from "./site/SitePageAboutUs";
import SitePageComponents from "./site/SitePageComponents";
import SitePageSupport from "./site/SitePageSupport";
import SitePageContactUsAlt from "./site/SitePageContactUsAlt";
import SitePageFaq from "./site/SitePageFaq";
import SitePageNotFound from "./site/SitePageNotFound";
import SitePageTerms from "./site/SitePageTerms";
import SitePageRentalPolicy from "./site/SitePageRentalPolicy";
import SitePageTypography from "./site/SitePageTypography";
import StripeUI from "./payment/StripeUI";
import PostingSuccess from "./shared/PostingSuccess";
import SitePageDisputes from "./site/SitePageDisputes";
import AccountLoginPage from "./account/AccountLoginPage";
import RentUserTool from "./posttool/RentUserTool";
import Terms from "./shared/Terms";

// data stubs
import theme from "../data/theme";
import ShopPageSearch from "./shop/ShopPageSearch";
import authContext from "../context/auth/authContext";
import layoutContext from "../context/layout/layoutContext";
import SiteCookiePolicy from "./site/SiteCookiePolicy";
import EditUserTool from "./posttool/EditUserTool";

const categoryLayouts = [
  [
    "/shop/category-grid-3-columns-sidebar",
    { columns: 3, viewMode: "grid", sidebarPosition: "start" },
  ],
  ["/shop/category-grid-4-columns-full", { columns: 4, viewMode: "grid" }],
  ["/shop/category-grid-5-columns-full", { columns: 5, viewMode: "grid" }],
  [
    "/shop/category-list",
    { columns: 3, viewMode: "list", sidebarPosition: "start" },
  ],
  [
    "/shop/category-right-sidebar",
    { columns: 3, viewMode: "grid", sidebarPosition: "end" },
  ],
].map(([url, options]) => (
  <Route
    key={url}
    exact
    path={url}
    render={(props) => (
      <ShopPageCategory {...props} {...options} categorySlug="power-tools" />
    )}
  />
));

const productLayouts = [
  ["/shop/product-standard", { layout: "standard" }],
  ["/shop/product-columnar", { layout: "columnar" }],
  ["/shop/product-sidebar", { layout: "sidebar" }],
].map(([url, options]) => (
  <Route
    key={url}
    exact
    path={url}
    render={(props) => (
      <ShopPageProduct
        {...props}
        {...options}
        productSlug="brandix-screwdriver-screw1500acc"
      />
    )}
  />
));

function Layout(props) {
  const { match, headerLayout, homeComponent } = props;
  const { user, loadInitialData, isLoggedIn } = useContext(authContext);

  const { hideFooter, setHideFooter } = useContext(ScreenSizeContext);

  useEffect(() => {
    loadInitialData();
  }, []);

  const history = useHistory();
  //
  // useEffect(() => {
  //   function handleUpdate() {
  //     alert("UPDATING: " + localStorage.getItem("redirect"));
  //     setRedirectPage(localStorage.getItem("redirect"));
  //   }
  //
  //   window.addEventListener("storage", handleUpdate);
  //   return () => {
  //     window.removeEventListener("storage", handleUpdate);
  //   };
  // }, []);

  useEffect(() => {
    // alert("LOGGED IN: " + redirectPage ?? " :(");

    if (!isLoggedIn) {
      return;
    }

    if (localStorage.getItem("redirect") != null) {
      history.push(localStorage.getItem("redirect") ?? "");
    }
  }, [isLoggedIn]);

  //
  // {isLoggedIn && localStorage.getItem("redirect") !== null && (
  //     <Redirect to={localStorage.getItem("redirect")} />
  // )}
  //
  // {isLoggedIn && redirectToTool !== null && (
  //     <Redirect to={redirectToTool} />
  // )}

  return (
    <React.Fragment>
      <Helmet>
        <title>{theme.name}</title>
        <meta name="description" content={theme.fullName} />
      </Helmet>

      <ToastContainer autoClose={5000} hideProgressBar />

      <Quickview />

      <MobileMenu />

      <div className="site">
        <header className="site__header d-lg-none">
          <MobileHeader />
        </header>

        <header className="site__header d-lg-block d-none">
          <Header layout={headerLayout} />
        </header>

        <div className="site__body">
          <Switch>
            <Route exact path={`${match.path}`} component={homeComponent} />
            <Redirect exact from="/shop" to="/shop/catalog" />
            <Route
              exact
              path="/shop/catalog"
              render={(props) => (
                <ShopPageCategory
                  {...props}
                  columns={3}
                  viewMode="grid"
                  sidebarPosition="start"
                />
              )}
            />
            <Route
              exact
              path="/shop/catalog/:categorySlug"
              render={(props) => (
                <ShopPageCategory
                  {...props}
                  columns={3}
                  viewMode="list"
                  sidebarPosition="start"
                  categorySlug={props.match.params.categorySlug}
                />
              )}
            />
            {/*
                        // Search
                        */}
            <Redirect exact from="/shop" to="/shop/search" />
            <Route
              exact
              path="/shop/search"
              render={(props) => (
                <ShopPageSearch
                  {...props}
                  columns={4}
                  viewMode="grid-with-features"
                  sidebarPosition="start"
                />
              )}
            />
            <Route
              exact
              path="/shop/search/:searchTerm"
              render={(props) => (
                <ShopPageSearch
                  {...props}
                  columns={4}
                  viewMode="grid-with-features"
                  sidebarPosition="start"
                  searchTerm={props.match.params.searchTerm}
                />
              )}
            />
            {/* Following category layouts only for demonstration. */}
            {categoryLayouts}
            <Route
              exact
              path="/shop/products/:productSlug"
              render={(props) => (
                <ShopPageProduct
                  {...props}
                  layout="standard"
                  productSlug={props.match.params.productSlug}
                />
              )}
            />
            {/* Following product layouts only for demonstration. */}
            {productLayouts}
            <Route exact path="/shop/cart" component={PageCart} />
            <Route exact path="/shop/checkout" component={PageCheckout} />
            <Route
              exact
              path="/shop/checkout/success"
              component={ShopPageOrderSuccess}
            />
            <Route exact path="/shop/wishlist" component={PageWishlist} />
            <Route
              exact
              path="/shop/track-order"
              component={ShopPageTrackOrder}
            />
            {/*
                        // Account
                        */}
            <Route exact path="/account/login/:id">
              {/*{isLoggedIn && redirectToTool && (*/}
              {/*  <Redirect to={`/shop/products/${redirectToTool}`} />*/}
              {/*)}*/}

              {isLoggedIn ? <Redirect to="/" /> : <AccountLoginPage />}
            </Route>
            <Route exact path="/account/login">
              {/*{isLoggedIn && redirectToTool && (*/}
              {/*  <Redirect to={`/shop/products/${redirectToTool}`} />*/}
              {/*)}*/}

              {isLoggedIn ? <Redirect to="/" /> : <AccountLoginPage />}
            </Route>
            <Route exact path="/account/register">
              {isLoggedIn ? <Redirect to="/" /> : <AccountLoginPage />}
            </Route>
            <Route exact path="/messages" component={AccountPageMessages} />
            <Route path="/account" component={AccountLayout} />
            <Route path="/legal" component={SitePageTerms} />
            <Route path="/rental-policy" component={SitePageRentalPolicy} />
            <Route path="/cookies" component={SiteCookiePolicy} />
            <Route path="/disputes" component={SitePageDisputes} />
            {/* Post*/}
            <Route exact path="/rent-tool">
              {/*{!isLoggedIn ? <Redirect to="/login" /> : <PostUserTool />}*/}
              <RentUserTool />
            </Route>
            <Route exact path="/edit/:toolID">
              {/*{!isLoggedIn ? <Redirect to="/login" /> : <PostUserTool />}*/}
              <EditUserTool />
            </Route>
            {/* Success */}
            <Route exact path="/posting-success">
              <PostingSuccess />
            </Route>
            <Route exact path="/about-us" component={SitePageAboutUs} />
            <Route exact path="/support" component={SitePageSupport} />
            <Route exact path="/site/not-found" component={SitePageNotFound} />}
            <Route component={SitePageNotFound} />
          </Switch>
        </div>
        {!hideFooter && (
          <footer className="site__footer">
            <Footer />
          </footer>
        )}
      </div>
    </React.Fragment>
  );
}

Layout.propTypes = {
  /**
   * header layout (default: 'classic')
   * one of ['classic', 'compact']
   */
  headerLayout: PropTypes.oneOf(["default", "compact"]),
  /**
   * home component
   */
  homeComponent: PropTypes.elementType.isRequired,
};

Layout.defaultProps = {
  headerLayout: "default",
};

export default Layout;
