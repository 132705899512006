import { useForm, Controller } from "react-hook-form";
import authContext from "../../context/auth/authContext";
import React, { useCallback, useContext, useState } from "react";
import { Alert, Button, Form, InputGroup, Row } from "react-bootstrap";
import { Person } from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";
import { Link } from "react-router-dom";

import { EMAIL_REGEX, PASSWORD_REGEX } from "../../constants";
import LocalPhoneIcon from "@material-ui/icons/LocalPhone";

export default function AccountRegisterPage({
  confirmAccount,
  errors,
  control,
  number,
}) {
  const confirmSignup = () => {
    return (
      <div>
        <div className="text-center">
          <b>Verify your phone number</b>
          <p>
            {`We just sent you an sms to ${number} with a verification code. Please enter the
            code to verify your account`}
          </p>
        </div>

        <br />
        <Form.Group className="border">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="border-0 bg-white">
                <LockIcon className="text-black-50 text-sm" size={20} />
              </InputGroup.Text>
            </InputGroup.Prepend>

            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="number"
                  required
                  placeholder="Verification Code"
                  {...field}
                  isInvalid={!!errors.confirmCode}
                />
              )}
              name="confirmCode"
              rules={{
                required: "Please enter your verification code",
              }}
            />
            {errors && errors.confirmCode && (
              <Form.Control.Feedback type="invalid">
                {errors.confirmCode.message}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
      </div>
    );
  };

  if (confirmAccount) {
    return confirmSignup();
  }

  return (
    <div>
      <br />
      <Form.Group className="border">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className="border-0 bg-white">
              <Person className="text-black-50 text-sm" size={20} />
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Controller
            control={control}
            render={({ field }) => (
              <Form.Control
                className="border-0"
                type="fname"
                required
                placeholder="First Name"
                {...field}
                isInvalid={!!errors.fname}
              />
            )}
            name="fname"
            rules={{
              required: "Please enter your first name",
            }}
          />
          {errors && errors.fname && (
            <Form.Control.Feedback type="invalid">
              {errors.fname.message}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      </Form.Group>
      <Form.Group className="border">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className="border-0 bg-white">
              <Person className="text-black-50 text-sm" size={20} />
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Controller
            control={control}
            render={({ field }) => (
              <Form.Control
                className="border-0"
                type="name"
                required
                placeholder="Last Name"
                {...field}
                isInvalid={!!errors.lname}
              />
            )}
            name="lname"
            rules={{
              required: "Please enter your last name",
            }}
          />
          {errors && errors.lname && (
            <Form.Control.Feedback type="invalid">
              {errors.lname.message}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      </Form.Group>
      <Form.Group className="border">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className="border-0 bg-white">
              <EmailIcon className="text-black-50 text-sm" size={20} />
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Controller
            control={control}
            render={({ field }) => (
              <Form.Control
                className="border-0"
                type="email"
                required
                placeholder="Email"
                {...field}
                isInvalid={!!errors.email}
              />
            )}
            name="email"
            rules={{
              required: "Please enter your email address",
              pattern: {
                value: EMAIL_REGEX,
                message: "Please enter a valid email address",
              },
            }}
          />
          {errors && errors.email && (
            <Form.Control.Feedback type="invalid">
              {errors.email.message}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <InputGroup className="border">
          <InputGroup.Prepend>
            <InputGroup.Text className="border-0 bg-white">
              <LockIcon className="text-black-50 text-sm" size={20} />
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Controller
            control={control}
            render={({ field }) => (
              <Form.Control
                className="border-0"
                type="password"
                required
                placeholder="Password"
                {...field}
                isInvalid={!!errors.password}
              />
            )}
            name="password"
            rules={{
              required: "Please enter a secure password",
              pattern: {
                value: PASSWORD_REGEX,
                message:
                  "Password must be 8 characters long, Be a combination of letters and numbers, Include at at least one special character",
              },
            }}
          />
          {errors && errors.password && (
            <Form.Control.Feedback type="invalid">
              {errors.password.message}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <InputGroup className="border">
          <InputGroup.Prepend>
            <InputGroup.Text className="border-0 bg-white">
              <LockIcon className="text-black-50 text-sm" size={20} />
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Controller
            control={control}
            render={({ field }) => (
              <Form.Control
                className="border-0"
                type="password"
                required
                placeholder="Confirm Password"
                {...field}
                isInvalid={!!errors.confirmPassword}
              />
            )}
            name="confirmPassword"
            rules={{
              required: "Passwords do not match",
            }}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group>
        <InputGroup className="border">
          <InputGroup.Prepend>
            <InputGroup.Text className="border-0 bg-white">
              <LocalPhoneIcon className="text-black-50 text-sm" size={20} />
            </InputGroup.Text>
          </InputGroup.Prepend>

          <InputGroup.Prepend>
            <InputGroup.Text className="border-0 bg-white">
              <p>+1</p>
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Controller
            control={control}
            render={({ field }) => (
              <Form.Control
                className="border-0"
                type="phone"
                required
                placeholder="+11234567890"
                {...field}
                isInvalid={!!errors.number}
              />
            )}
            name="number"
            rules={{
              required: "Please enter a valid phone number",
            }}
          />
        </InputGroup>
      </Form.Group>

      <Form.Group className="">
        <Controller
          control={control}
          render={({ field }) => (
            <Row className="ml-2">
              <Form.Check {...field} />

              <p>
                I agree to Toology's{" "}
                <Link to="/legal/#terms" target="_blank">
                  Terms of Service
                </Link>
                .
              </p>
            </Row>
          )}
          name="accepted"
          rules={{
            required: "Please accept the terms and conditions to proceed",
          }}
        />
        {errors && errors.accepted && (
          <Alert variant="danger" type="invalid">
            {errors.accepted.message}
          </Alert>
        )}
      </Form.Group>
    </div>
  );
}
