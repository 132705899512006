// react
import React, { Component } from "react";
import Slider from "react-slick";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import departmentsAria from "../../services/departmentsArea";
import languages from "../../i18n";
import StroykaSlick from "../shared/StroykaSlick";

const slickSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 500,
  slidesToShow: 1,
  slidesToScroll: 1,

  autoplay: true,
  autoplaySpeed: window.innerWidth > 800 ? 4000 : 5000,
};

class BlockSlideShow extends Component {
  departmentsAreaRef = null;

  media = window.matchMedia("(min-width: 992px)");

  slides = [
    {
      title: "Book on Us",
      text: "You're always 3 steps away from your dream<br/>project. Search, Book, Pick-up.",
      image_full: "/images/home-page-banners/desktop/000.jpg",
      image_mobile: "/images/home-page-banners/mobile/000.jpg",
    },
    {
      title: "Convenient",
      text: "You're always 3 steps away from your dream<br/>project. Search, Book, Pick-up.",
      image_full: "/images/home-page-banners/desktop/001.jpg",
      image_mobile: "/images/home-page-banners/mobile/001.jpg",
    },
    {
      title: "Fun Filled",
      text: "Think its time to convert that tree<br/>into a house? No more excuses.",
      image_full: "/images/home-page-banners/desktop/002.jpg",
      image_mobile: "/images/home-page-banners/mobile/002.jpg",
    },
    {
      title: "Endless Options",
      text: "An ocean of tools at you disposal<br/>to tackle all kinds of jobs.",
      image_full: "/images/home-page-banners/desktop/003.jpg",
      image_mobile: "/images/home-page-banners/mobile/003.jpg",
    },
    {
      title: "Affordable",
      text: "Take projects head on without<br/>breaking the budget.",
      image_full: "/images/home-page-banners/desktop/004.jpg",
      image_mobile: "/images/home-page-banners/mobile/004.jpg",
    },
    {
      title: "Eco Friendly",
      text: "The rent-ellegent choice for the<br/>environment.",
      image_full: "/images/home-page-banners/desktop/005.jpg",
      image_mobile: "/images/home-page-banners/mobile/005.jpg",
    },
    {
      title: "Instant tools",
      text: "Time to tame that tree? Grab a<br/>chainsaw today!",
      image_full: "/images/home-page-banners/desktop/006.jpg",
      image_mobile: "/images/home-page-banners/mobile/006.jpg",
    },
    {
      title: "Community Builder",
      text: "Feel great about helping your community while<br/>attacking that long to-do list.",
      image_full: "/images/home-page-banners/desktop/007.jpg",
      image_mobile: "/images/home-page-banners/mobile/007.jpg",
    },
    {
      title: "Earn Credits",
      text: "You're always 3 steps away from your dream<br/>project. Search, Book, Pick-up.",
      image_full: "/images/home-page-banners/desktop/008.jpg",
      image_mobile: "/images/home-page-banners/mobile/008.jpg",
    },
  ];

  componentDidMount() {
    if (this.media.addEventListener) {
      this.media.addEventListener("change", this.onChangeMedia);
    } else {
      // noinspection JSDeprecatedSymbols
      this.media.addEventListener(this.onChangeMedia);
    }
  }

  componentWillUnmount() {
    departmentsAria.area = null;

    if (this.media.removeEventListener) {
      this.media.removeEventListener("change", this.onChangeMedia);
    } else {
      // noinspection JSDeprecatedSymbols
      this.media.removeEventListener(this.onChangeMedia);
    }
  }

  onChangeMedia = () => {
    if (this.media.matches) {
      departmentsAria.area = this.departmentsAreaRef;
    }
  };

  setDepartmentsAreaRef = (ref) => {
    this.departmentsAreaRef = ref;

    if (this.media.matches) {
      departmentsAria.area = this.departmentsAreaRef;
    }
  };

  render() {
    const { locale, withDepartments } = this.props;
    const { direction } = languages[locale];

    const blockClasses = classNames();

    const layoutClasses = classNames("col-12");

    const slides = this.slides.map((slide, index) => {
      const imageFull = slide.image_full;
      const imageMobile = slide.image_mobile;

      return (
        <div key={index} className="block-slideshow__slide ">
          <div
            className="block-slideshow__slide-image block-slideshow__slide-image--desktop"
            style={{
              backgroundImage: `url(${imageFull})`,
            }}
          />
          <div
            className="block-slideshow__slide-image block-slideshow__slide-image--mobile"
            style={{
              backgroundImage: `url(${imageMobile})`,
            }}
          />
        </div>
      );
    });

    return (
      <div className="block-slideshow block block-slideshow--layout--full">
        <div className="">
          <div className="row">
            <div className={layoutClasses}>
              <div className="block-slideshow__body">
                <StroykaSlick {...slickSettings}>{slides}</StroykaSlick>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

BlockSlideShow.propTypes = {
  withDepartments: PropTypes.bool,
  /** current locale */
  locale: PropTypes.string,
};

BlockSlideShow.defaultProps = {
  withDepartments: false,
};

const mapStateToProps = (state) => ({
  locale: state.locale,
});

export default connect(mapStateToProps)(BlockSlideShow);
