import React, { useState } from "react";
import { Card, Image, OverlayTrigger, Popover } from "react-bootstrap";

import "../../scss/blocks/_icon-card.scss";

export default function IconCard({
  icon,
  title,
  text,
  width,
  height,
  withOverlay = true,
}) {
  const popover = (
    <Popover>
      <Popover.Content>{text}</Popover.Content>
    </Popover>
  );

  return (
    <div
      className="rounded icon-card border p-1 p-sm-2"
      style={{ width: width && width, height: height && height }}
    >
      <div className="py-1 text-center h-100 w-100 d-flex flex-column align-items-center justify-content-center">
        {withOverlay && (
          <div className="d-flex flex-column justify-content-center align-items-center">
            {icon}

            <OverlayTrigger
              trigger="hover"
              placement="bottom"
              overlay={popover}
            >
              <h4
                style={{ fontSize: "1rem" }}
                className="mx-4 my-2 my-sm-3 font-weight-bold text-primary cursor-pointer"
              >
                {title}
              </h4>
            </OverlayTrigger>
          </div>
        )}

        {!withOverlay && (
          <div className="d-flex flex-column align-items-center">
            {icon}
            <h4 className="mx-4 my-2 my-sm-3 font-weight-bold text-primary cursor-pointer">
              {title}
            </h4>
            <p className="icon-card-text">{text}</p>
          </div>
        )}
      </div>
    </div>
  );
}
