import React, { useRef, useState, useEffect, useContext } from "react";

import "../../scss/components/_image-upload.scss";
import PhotoIcon from "@material-ui/icons/Photo";
import CloseIcon from "@material-ui/icons/Close";

import IconButton from "@material-ui/core/IconButton";

import AddAPhotoIcon from "@material-ui/icons/AddAPhoto";
import showError from "../../api/Helper";
import languages from "../../i18n";

const ImageUpload = ({ data, updateData }) => {
  const fileInputRef = useRef();
  const maxImages = 8;

  const [totalFiles, setTotalFiles] = useState([]);
  const [images, setImages] = useState([]);

  const [selectedFiles, setSelectedFiles] = useState([]);
  const [validFiles, setValidFiles] = useState(data?.pictures ?? []);
  const [error, setError] = useState(null);

  useEffect(() => {
    if (error == null) {
      return;
    }
    showError("You can only select images", true, 2500);
  }, [error]);

  const loadTotalFiles = () => {
    let files = [];
    for (let x = 0; x < maxImages; x++) {
      files.push({});
    }
    setTotalFiles(files);
  };

  useEffect(() => {
    loadTotalFiles();
  }, []);

  useEffect(() => {
    loadImages();
    // loadTotalFiles()
  }, [selectedFiles, validFiles, images]);

  useEffect(() => {
    updateData(selectedFiles);
  }, [selectedFiles]);
  // const onChange = (files) => {
  // };

  const preventDefault = (e) => {
    e.preventDefault();

    // e.stopPropagation();
  };

  const dragOver = (e) => {
    preventDefault(e);
  };

  const dragEnter = (e) => {
    preventDefault(e);
    const files = e.dataTransfer.files;
    setError(validateFile(files));
  };

  const dragLeave = (e) => {
    preventDefault(e);
    setError(false);
  };

  const fileDrop = (e) => {
    preventDefault(e);
    setError(false);

    const files = e.dataTransfer.files;
    if (files.length) {
      handleFiles(files);
    }
  };

  const filesSelected = () => {
    if (fileInputRef.current.files.length) {
      handleFiles(fileInputRef.current.files);
    }
  };

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const handleFiles = (files) => {
    const tempArray = validFiles;
    for (let i = 0; i < files.length; i++) {
      if (validateFile(files[i])) {
        setSelectedFiles((prevArray) => [...prevArray, files[i]]);
        tempArray.push(files[i]);
      }
    }
    loadTotalFiles();
    // onChange(tempArray);
    // onChange('pictures',tempArray)
  };

  const validateFile = (file) => {
    const validTypes = ["image/jpeg", "image/jpg", "image/png"];
    if (validTypes.indexOf(file.type) === -1) {
      return false;
    }

    return true;
  };

  const removeFile = (index) => {
    let files = selectedFiles;
    let imgs = images;

    files.splice(index, 1);
    imgs.splice(index, 1);
    setImages(imgs);
    setSelectedFiles(files);
    loadTotalFiles();
  };

  const openPhotoswipe = (index) => {};

  const loadImages = () => {
    let imgs = images;

    selectedFiles.forEach((file, index) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = function (e) {
        imgs[index] = e.target.result;
        setImages(imgs);
        loadTotalFiles();
        // modalImageRef.current.style.backgroundImage = `url(${e.target.result})`;
      };
    });
    setImages(imgs);
    //loadTotalFiles()
  };

  return (
    <div className="post-tool-user-section-images">
      <div
        className="post-tool-user-section-upload-image making-flex-columns"
        onDragOver={dragOver}
        onDragEnter={dragEnter}
        onDragLeave={dragLeave}
        onDrop={fileDrop}
        onClick={fileInputClicked}
      >
        <input
          ref={fileInputRef}
          className="file-input"
          type="file"
          accept=".jpeg,.jpg,.png"
          multiple
          onChange={filesSelected}
        />
        <AddAPhotoIcon />
        <br />
        <h6>Upload photos</h6>
      </div>

      <div className="uploaded-images-section">
        {totalFiles.map((data, i) => {
          let image = selectedFiles?.[i];
          return (
            <div
              onClick={() => {
                openPhotoswipe(i);
              }}
              key={i}
              style={{
                opacity: image === undefined ? "0.5" : "1",
                backgroundImage: `url(${images?.[i] ?? ""})`,
              }}
              className="uploaded-images-section-item"
            >
              {image !== undefined && (
                <div
                  onClick={() => {
                    removeFile(i);
                  }}
                  className="uploaded-images-section-item-remove"
                >
                  <CloseIcon fontSize="small" />
                </div>
              )}

              {image === undefined && <PhotoIcon />}
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default ImageUpload;
