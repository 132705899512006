// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import PageHeader from "../shared/PageHeader";

// data stubs
import theme from "../../data/theme";
import RentalAgreement from "../../constants/RentalAgreement";

function SitePageRentalPolicy() {
  const breadcrumb = [
    { title: "Home", url: "" },
    { title: "Terms And Conditions", url: "" },
  ];

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Rental Policy — ${theme.name}`}</title>
      </Helmet>

      <RentalAgreement />
    </React.Fragment>
  );
}

export default SitePageRentalPolicy;
