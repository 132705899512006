import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import { Alert, Button, Form, InputGroup } from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import AutoCompleteField from "./AutoCompleteField";
import { Dialog } from "@material-ui/core";
import React, { useContext, useState } from "react";
import { PeopleOutline, Person } from "@material-ui/icons";
import EmailIcon from "@material-ui/icons/Email";
import authContext from "../../context/auth/authContext";
import DB from "../../api/DB";
import showError, { showSuccess } from "../../api/Helper";

export default function Invite({ open, onClose }) {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm();

  const [isLoading, setIsLoading] = useState(false);
  const [currentEmails, setCurrentEmails] = useState([""]);

  const maxEmails = 4;

  const { user } = useContext(authContext);

  const onSubmit = (values) => {
    let emails = [];

    for (let x = 0; x < currentEmails?.length; x++) {
      let index = `email${x}`;
      emails.push(values?.[index]);
    }

    const fullName = `${user?.fname} ${user?.lname}`;

    setIsLoading(true);

    DB.sendInvite(fullName, emails)
      .then(() => {
        showSuccess("An invite has been sent to your friends");
        onClose();
      })
      .catch((error) => {
        console.log("ERROR", error);
        showError("We couldn't send an invite :(");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      className="vw-100 px-lg-4"
      fullWidth
      onKeyDown={(e) => {}}
    >
      <div className="w-100 px-lg-4 p-4" style={{ minWidth: "100%" }}>
        <div className="d-flex flex-row mt-2">
          <PeopleOutline className="mr-2" />
          <h5>Invite your friends</h5>
          <CloseIcon
            onClick={() => {
              onClose();
            }}
            className="ml-auto cursor-pointer"
          />
        </div>
        <p className=" mt-1 mb-4">
          Invite your friends and earn credits when they book they first tool
        </p>

        <Form onSubmit={handleSubmit(onSubmit)}>
          {/*<Form.Label>Enter your friends email</Form.Label>*/}

          {currentEmails?.map((val, index) => {
            return (
              <div className="d-flex flex-row w-100">
                <Form.Group className="border w-100 mr-2">
                  <InputGroup>
                    <InputGroup.Prepend>
                      <InputGroup.Text className="border-0 bg-white">
                        <EmailIcon
                          className="text-black-50 text-sm"
                          size={20}
                        />
                      </InputGroup.Text>
                    </InputGroup.Prepend>

                    <Controller
                      control={control}
                      render={({ field }) => (
                        <Form.Control
                          className="border-0"
                          type="email"
                          required
                          placeholder="Email"
                          {...field}
                          isInvalid={!!errors.email}
                        />
                      )}
                      name={`email${index}`}
                      rules={{
                        required: "Please enter first name",
                      }}
                    />
                    {errors && errors.email && (
                      <Form.Control.Feedback type="invalid">
                        {errors.firstName.message}
                      </Form.Control.Feedback>
                    )}
                  </InputGroup>
                </Form.Group>
                {currentEmails?.length > 1 && (
                  <CloseIcon
                    onClick={() => {
                      setCurrentEmails((emails) => {
                        // let newEmails = emails;
                        // ;

                        return emails?.slice(0, emails?.length - 1);
                      });
                    }}
                    className="ml-auto mt-2 cursor-pointer"
                  />
                )}
              </div>
            );
          })}

          {maxEmails > currentEmails?.length && (
            <div
              className="text-primary pl-2 cursor-pointer"
              onClick={() => {
                if (maxEmails === currentEmails?.length) {
                  return;
                }

                setCurrentEmails((emails) => {
                  return [...emails, ""];
                });
              }}
            >
              Add another email
            </div>
          )}

          <Button
            className="btn-block mt-4 "
            type="submit"
            disabled={isLoading}
          >
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}

            {!isLoading && "Send Invite"}
          </Button>
        </Form>
      </div>
    </Dialog>
  );
}
