export const url = {
  home: () => "/",

  catalog: () => "/shop/catalog",

  category: (category) =>
    `/shop/catalog/${category.id}` /*trigger for pre triggering calender in book*/,

  product: (product) => `/shop/products/${product.id}`,
};

export function getCategoryParents(category) {
  return category.parent
    ? [...getCategoryParents(category.parent), category.parent]
    : [];
}
