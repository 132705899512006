// react
import React from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// application
import StroykaSlick from "../shared/StroykaSlick";

// data stubs
import theme from "../../data/theme";
import IconCard from "../blocks/IconCard";
import SearchIcon from "@material-ui/icons/Search";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";
import LocationOnOutlinedIcon from "@material-ui/icons/LocationOnOutlined";

const slickSettings = {
  dots: true,
  arrows: false,
  infinite: true,
  speed: 400,
  slidesToShow: 3,
  slidesToScroll: 3,
  responsive: [
    {
      breakpoint: 767,
      settings: {
        slidesToShow: 2,
        slidesToScroll: 2,
      },
    },
    {
      breakpoint: 379,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
      },
    },
  ],
};

function SitePageAboutUs() {
  return (
    <div className="block about-us">
      <Helmet>
        <title>{`About Us — ${theme.name}`}</title>
      </Helmet>

      <div
        className="about-us__image"
        style={{ backgroundImage: 'url("images/aboutus.jpg")' }}
      />
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-12 col-xl-10">
            <div className="about-us__body">
              <h1 className="about-us__title">About Us</h1>
              <section className="about-us__text typography">
                <p>
                  Toology is Canada’s first and only marketplace for tool
                  rentals. We’re proud to provide a platform that connects
                  Canadians by helping them rent out tools in their community,
                  save money by not spending on brand new tools, make money off
                  unused tools cluttering up their garages/ basements, and most
                  importantly help the country waste less.
                </p>
                <p>
                  It is said that constraints breed creativity, and Toology was
                  born with the same creative spirit. At the start of Covid-19
                  pandemic, like many Canadians, we took on some home
                  improvement projects. With little to no experience in DIY, we
                  quickly realized that no job can be done properly without the
                  proper tools.
                </p>

                <p>
                  Watching countless online videos, standing in long lines
                  outside home improvement centres, waiting weeks and months for
                  online orders to be delivered, purchasing expensive and often
                  times wrong tools for the job, were few of the many
                  painstaking tasks that came along with these DIY projects. The
                  most frustrating part was when we had to spend significant
                  amount of money to buy tools which we likely weren’t going to
                  use ever again or at least not for a few years once the
                  project was complete. The only alternate solution is to rent
                  the tools from big box stores. However, when you look at the
                  rental rates, 9 out of 10 times you’re better off purchasing
                  the tools. Because by the time you are done using the tools,
                  you would have likely paid more than 75% of its cost, leading
                  to further frustration.
                </p>

                <p>
                  The idea behind Toology is to offer Canadians living in the
                  same area an affordable, convenient, and eco-friendly way to
                  rent all kinds of tools for their DIY needs. Its also a great
                  way for tool owners to make some extra cash by renting out the
                  tools they don’t use on a regular basis, catching dust and
                  rust while occupying space in their garages.{" "}
                </p>
              </section>

              <section className="mt-5">
                <h5>How it works:</h5>

                <p className="mb-5">
                  You’re just 3 steps away from your next DIY project
                </p>
                <div className="d-flex flex-column flex-sm-row justify-content-around align-items-center">
                  <IconCard
                    width="30%"
                    height="15rem"
                    icon={
                      <SearchIcon className="text-primary" fontSize={"large"} />
                    }
                    withOverlay={false}
                    title="Search"
                    text="Explore tools for your project. Filters by distance, daily rate, brand and reviews. Get notified in case you can't find your tool."
                  />

                  <IconCard
                    width="30%"
                    height="15rem"
                    icon={
                      <BuildOutlinedIcon
                        className="text-primary"
                        fontSize={"large"}
                      />
                    }
                    withOverlay={false}
                    title="Book"
                    text="Found something you like? Review the details and simply pay via credit card or PayPal to confirm your booking."
                  />

                  <IconCard
                    width="30%"
                    height="15rem"
                    icon={
                      <LocationOnOutlinedIcon
                        className="text-primary"
                        fontSize={"large"}
                      />
                    }
                    withOverlay={false}
                    title="Pick Up"
                    text="You’re all set! Connect with the tool owner through the in-site chat to confirm pick up time and other details."
                  />
                </div>
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SitePageAboutUs;
