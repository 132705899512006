// react
import React, { useContext, useState, useEffect } from "react";

import { Auth } from "aws-amplify";
// third-party
import { Helmet } from "react-helmet-async";

// data stubs
import theme from "../../data/theme";

//context
import CircularProgress from "@material-ui/core/CircularProgress";
import authContext from "../../context/auth/authContext";
import showError, { showSuccess } from "../../api/Helper";

export default function AccountPageProfile() {
  const { user, updateUserDetails, loadUser } = useContext(authContext);
  const [isLoading, setIsLoading] = useState(false);
  const [editAttributes, setEditAttributes] = useState({
    name: "",
    family_name: "",
    number: "",
  });
  const [msg, setMsg] = useState("");

  useEffect(() => {
    let attr = editAttributes;

    attr = {
      fname: user?.fname,
      lname: user?.lname,
      number: user?.number,
    };

    setEditAttributes(attr);
  }, []);

  // console.log(editAttributes);

  const EditProfile = (e) => {
    setIsLoading(true);
    setMsg("");
    e.preventDefault();

    updateUserDetails(editAttributes)
      .then(() => {
        loadUser();
        showSuccess("Your details have been updated!");
      })
      .catch((error) => {
        // console.log(error);
        showError("An error occurred while updating your details");
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="card">
      <Helmet>
        <title>{`Profile — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <h5>Edit Profile</h5>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <form onSubmit={EditProfile} className="col-12 col-lg-7 col-xl-6">
            {msg !== "" ? <p style={{ color: "#3b5998" }}>{msg}</p> : ""}
            <div className="form-group">
              <label htmlFor="profile-first-name">First Name</label>
              <input
                id="profile-first-name"
                type="text"
                className="form-control"
                placeholder={user.fname || "First Name"}
                onChange={(e) =>
                  setEditAttributes({
                    ...editAttributes,
                    fname: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="profile-last-name">Last Name</label>
              <input
                id="profile-last-name"
                type="text"
                className="form-control"
                placeholder={user.lname || "Last Name"}
                onChange={(e) =>
                  setEditAttributes({
                    ...editAttributes,
                    lname: e.target.value,
                  })
                }
              />
            </div>
            <div className="form-group">
              <label htmlFor="profile-email">Email Address</label>
              <input
                id="profile-email"
                type="email"
                className="form-control"
                placeholder={user?.email}
                disabled={true}
              />
            </div>
            <div className="form-group">
              <label htmlFor="profile-phone">Phone Number</label>
              <input
                id="profile-phone"
                type="text"
                className="form-control"
                placeholder={user?.number}
                disabled
                onChange={(e) =>
                  setEditAttributes({
                    ...editAttributes,
                    number: e.target.value,
                  })
                }
              />
            </div>

            <div className="form-group mt-5 mb-0">
              <button type="submit" className="btn btn-primary">
                {isLoading ? (
                  <CircularProgress
                    color="white"
                    style={{ height: "23px", width: "23px" }}
                  />
                ) : (
                  "Save"
                )}
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
}
