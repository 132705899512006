/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createConvoLink = /* GraphQL */ `
  mutation CreateConvoLink(
    $input: CreateConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    createConvoLink(input: $input, condition: $condition) {
      id
      convoLinkConversationId
      convoLinkUserId
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      conversation {
        id
        members
        memberIDs
        toolRentalID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateConvoLink = /* GraphQL */ `
  mutation UpdateConvoLink(
    $input: UpdateConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    updateConvoLink(input: $input, condition: $condition) {
      id
      convoLinkConversationId
      convoLinkUserId
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      conversation {
        id
        members
        memberIDs
        toolRentalID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteConvoLink = /* GraphQL */ `
  mutation DeleteConvoLink(
    $input: DeleteConvoLinkInput!
    $condition: ModelConvoLinkConditionInput
  ) {
    deleteConvoLink(input: $input, condition: $condition) {
      id
      convoLinkConversationId
      convoLinkUserId
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      conversation {
        id
        members
        memberIDs
        toolRentalID
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConversation = /* GraphQL */ `
  mutation CreateConversation(
    $input: CreateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    createConversation(input: $input, condition: $condition) {
      id
      members
      memberIDs
      toolRentalID
      createdAt
      updatedAt
      conversations {
        nextToken
      }
      messages {
        nextToken
      }
    }
  }
`;
export const updateConversation = /* GraphQL */ `
  mutation UpdateConversation(
    $input: UpdateConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    updateConversation(input: $input, condition: $condition) {
      id
      members
      memberIDs
      toolRentalID
      createdAt
      updatedAt
      conversations {
        nextToken
      }
      messages {
        nextToken
      }
    }
  }
`;
export const deleteConversation = /* GraphQL */ `
  mutation DeleteConversation(
    $input: DeleteConversationInput!
    $condition: ModelConversationConditionInput
  ) {
    deleteConversation(input: $input, condition: $condition) {
      id
      members
      memberIDs
      toolRentalID
      createdAt
      updatedAt
      conversations {
        nextToken
      }
      messages {
        nextToken
      }
    }
  }
`;
export const createMessage = /* GraphQL */ `
  mutation CreateMessage(
    $input: CreateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    createMessage(input: $input, condition: $condition) {
      id
      userID
      receiverID
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      content
      conversationID
      createdAt
      updatedAt
      messageDate
    }
  }
`;
export const updateMessage = /* GraphQL */ `
  mutation UpdateMessage(
    $input: UpdateMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    updateMessage(input: $input, condition: $condition) {
      id
      userID
      receiverID
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      content
      conversationID
      createdAt
      updatedAt
      messageDate
    }
  }
`;
export const deleteMessage = /* GraphQL */ `
  mutation DeleteMessage(
    $input: DeleteMessageInput!
    $condition: ModelMessageConditionInput
  ) {
    deleteMessage(input: $input, condition: $condition) {
      id
      userID
      receiverID
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      content
      conversationID
      createdAt
      updatedAt
      messageDate
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser(
    $input: CreateUserInput!
    $condition: ModelUserConditionInput
  ) {
    createUser(input: $input, condition: $condition) {
      id
      fname
      lname
      zipCode
      email
      number
      latitude
      longitude
      profileImage
      userTools {
        nextToken
      }
      convos {
        nextToken
      }
      stripeCustomerID
      stripeConnectID
      paypalLinkedEmail
      bankAccountLinked
      reviews {
        nextToken
      }
      billingAddressID
      billingAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      shippingAddressID
      shippingAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      UserAddressList {
        nextToken
      }
      toolsRented {
        nextToken
      }
      toolsLended {
        nextToken
      }
      transactions {
        nextToken
      }
      referredByUserID
      isReferralComplete
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser(
    $input: UpdateUserInput!
    $condition: ModelUserConditionInput
  ) {
    updateUser(input: $input, condition: $condition) {
      id
      fname
      lname
      zipCode
      email
      number
      latitude
      longitude
      profileImage
      userTools {
        nextToken
      }
      convos {
        nextToken
      }
      stripeCustomerID
      stripeConnectID
      paypalLinkedEmail
      bankAccountLinked
      reviews {
        nextToken
      }
      billingAddressID
      billingAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      shippingAddressID
      shippingAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      UserAddressList {
        nextToken
      }
      toolsRented {
        nextToken
      }
      toolsLended {
        nextToken
      }
      transactions {
        nextToken
      }
      referredByUserID
      isReferralComplete
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser(
    $input: DeleteUserInput!
    $condition: ModelUserConditionInput
  ) {
    deleteUser(input: $input, condition: $condition) {
      id
      fname
      lname
      zipCode
      email
      number
      latitude
      longitude
      profileImage
      userTools {
        nextToken
      }
      convos {
        nextToken
      }
      stripeCustomerID
      stripeConnectID
      paypalLinkedEmail
      bankAccountLinked
      reviews {
        nextToken
      }
      billingAddressID
      billingAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      shippingAddressID
      shippingAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      UserAddressList {
        nextToken
      }
      toolsRented {
        nextToken
      }
      toolsLended {
        nextToken
      }
      transactions {
        nextToken
      }
      referredByUserID
      isReferralComplete
      createdAt
      updatedAt
    }
  }
`;
export const createUserAddress = /* GraphQL */ `
  mutation CreateUserAddress(
    $input: CreateUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    createUserAddress(input: $input, condition: $condition) {
      id
      userID
      addressLine1
      addressLine2
      city
      state
      postcode
      firstName
      lastName
      phoneNumber
      lat
      lng
      createdAt
      updatedAt
    }
  }
`;
export const updateUserAddress = /* GraphQL */ `
  mutation UpdateUserAddress(
    $input: UpdateUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    updateUserAddress(input: $input, condition: $condition) {
      id
      userID
      addressLine1
      addressLine2
      city
      state
      postcode
      firstName
      lastName
      phoneNumber
      lat
      lng
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserAddress = /* GraphQL */ `
  mutation DeleteUserAddress(
    $input: DeleteUserAddressInput!
    $condition: ModelUserAddressConditionInput
  ) {
    deleteUserAddress(input: $input, condition: $condition) {
      id
      userID
      addressLine1
      addressLine2
      city
      state
      postcode
      firstName
      lastName
      phoneNumber
      lat
      lng
      createdAt
      updatedAt
    }
  }
`;
export const createCustomerTool = /* GraphQL */ `
  mutation CreateCustomerTool(
    $input: CreateCustomerToolInput!
    $condition: ModelCustomerToolConditionInput
  ) {
    createCustomerTool(input: $input, condition: $condition) {
      id
      name
      des
      userID
      imageList
      rate
      toolID
      toolType {
        id
        name
        image
        description
        suggestedRate
        categoryID
        createdAt
        updatedAt
      }
      brand
      condition
      model
      defects
      year
      lat
      long
      reviews {
        nextToken
      }
      addressID
      userAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      createdAt
      userBlockedDays {
        nextToken
      }
      updatedAt
    }
  }
`;
export const updateCustomerTool = /* GraphQL */ `
  mutation UpdateCustomerTool(
    $input: UpdateCustomerToolInput!
    $condition: ModelCustomerToolConditionInput
  ) {
    updateCustomerTool(input: $input, condition: $condition) {
      id
      name
      des
      userID
      imageList
      rate
      toolID
      toolType {
        id
        name
        image
        description
        suggestedRate
        categoryID
        createdAt
        updatedAt
      }
      brand
      condition
      model
      defects
      year
      lat
      long
      reviews {
        nextToken
      }
      addressID
      userAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      createdAt
      userBlockedDays {
        nextToken
      }
      updatedAt
    }
  }
`;
export const deleteCustomerTool = /* GraphQL */ `
  mutation DeleteCustomerTool(
    $input: DeleteCustomerToolInput!
    $condition: ModelCustomerToolConditionInput
  ) {
    deleteCustomerTool(input: $input, condition: $condition) {
      id
      name
      des
      userID
      imageList
      rate
      toolID
      toolType {
        id
        name
        image
        description
        suggestedRate
        categoryID
        createdAt
        updatedAt
      }
      brand
      condition
      model
      defects
      year
      lat
      long
      reviews {
        nextToken
      }
      addressID
      userAddress {
        id
        userID
        addressLine1
        addressLine2
        city
        state
        postcode
        firstName
        lastName
        phoneNumber
        lat
        lng
        createdAt
        updatedAt
      }
      createdAt
      userBlockedDays {
        nextToken
      }
      updatedAt
    }
  }
`;
export const createUserBlockedDays = /* GraphQL */ `
  mutation CreateUserBlockedDays(
    $input: CreateUserBlockedDaysInput!
    $condition: ModelUserBlockedDaysConditionInput
  ) {
    createUserBlockedDays(input: $input, condition: $condition) {
      id
      customerToolID
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const updateUserBlockedDays = /* GraphQL */ `
  mutation UpdateUserBlockedDays(
    $input: UpdateUserBlockedDaysInput!
    $condition: ModelUserBlockedDaysConditionInput
  ) {
    updateUserBlockedDays(input: $input, condition: $condition) {
      id
      customerToolID
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserBlockedDays = /* GraphQL */ `
  mutation DeleteUserBlockedDays(
    $input: DeleteUserBlockedDaysInput!
    $condition: ModelUserBlockedDaysConditionInput
  ) {
    deleteUserBlockedDays(input: $input, condition: $condition) {
      id
      customerToolID
      startDate
      endDate
      createdAt
      updatedAt
    }
  }
`;
export const createTool = /* GraphQL */ `
  mutation CreateTool(
    $input: CreateToolInput!
    $condition: ModelToolConditionInput
  ) {
    createTool(input: $input, condition: $condition) {
      id
      name
      image
      description
      customerTools {
        nextToken
      }
      suggestedRate
      categoryID
      createdAt
      updatedAt
    }
  }
`;
export const updateTool = /* GraphQL */ `
  mutation UpdateTool(
    $input: UpdateToolInput!
    $condition: ModelToolConditionInput
  ) {
    updateTool(input: $input, condition: $condition) {
      id
      name
      image
      description
      customerTools {
        nextToken
      }
      suggestedRate
      categoryID
      createdAt
      updatedAt
    }
  }
`;
export const deleteTool = /* GraphQL */ `
  mutation DeleteTool(
    $input: DeleteToolInput!
    $condition: ModelToolConditionInput
  ) {
    deleteTool(input: $input, condition: $condition) {
      id
      name
      image
      description
      customerTools {
        nextToken
      }
      suggestedRate
      categoryID
      createdAt
      updatedAt
    }
  }
`;
export const createCategory = /* GraphQL */ `
  mutation CreateCategory(
    $input: CreateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    createCategory(input: $input, condition: $condition) {
      id
      image
      name
      tools {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCategory = /* GraphQL */ `
  mutation UpdateCategory(
    $input: UpdateCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    updateCategory(input: $input, condition: $condition) {
      id
      image
      name
      tools {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCategory = /* GraphQL */ `
  mutation DeleteCategory(
    $input: DeleteCategoryInput!
    $condition: ModelCategoryConditionInput
  ) {
    deleteCategory(input: $input, condition: $condition) {
      id
      image
      name
      tools {
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createToolRental = /* GraphQL */ `
  mutation CreateToolRental(
    $input: CreateToolRentalInput!
    $condition: ModelToolRentalConditionInput
  ) {
    createToolRental(input: $input, condition: $condition) {
      id
      buyerID
      sellerID
      bookingType
      bookedStartDate
      bookedEndDate
      daysRented
      totalToolBaseRentPrice
      totalCompleteToolRentPrice
      customerToolID
      tool {
        id
        name
        des
        userID
        imageList
        rate
        toolID
        brand
        condition
        model
        defects
        year
        lat
        long
        addressID
        createdAt
        updatedAt
      }
      bookingNumber
      invoiceJSON
      linkedTransactions {
        nextToken
      }
      conversationID
      conversation {
        id
        members
        memberIDs
        toolRentalID
        createdAt
        updatedAt
      }
      autoPayoutToSeller
      payoutDone
      createdAt
      updatedAt
    }
  }
`;
export const updateToolRental = /* GraphQL */ `
  mutation UpdateToolRental(
    $input: UpdateToolRentalInput!
    $condition: ModelToolRentalConditionInput
  ) {
    updateToolRental(input: $input, condition: $condition) {
      id
      buyerID
      sellerID
      bookingType
      bookedStartDate
      bookedEndDate
      daysRented
      totalToolBaseRentPrice
      totalCompleteToolRentPrice
      customerToolID
      tool {
        id
        name
        des
        userID
        imageList
        rate
        toolID
        brand
        condition
        model
        defects
        year
        lat
        long
        addressID
        createdAt
        updatedAt
      }
      bookingNumber
      invoiceJSON
      linkedTransactions {
        nextToken
      }
      conversationID
      conversation {
        id
        members
        memberIDs
        toolRentalID
        createdAt
        updatedAt
      }
      autoPayoutToSeller
      payoutDone
      createdAt
      updatedAt
    }
  }
`;
export const deleteToolRental = /* GraphQL */ `
  mutation DeleteToolRental(
    $input: DeleteToolRentalInput!
    $condition: ModelToolRentalConditionInput
  ) {
    deleteToolRental(input: $input, condition: $condition) {
      id
      buyerID
      sellerID
      bookingType
      bookedStartDate
      bookedEndDate
      daysRented
      totalToolBaseRentPrice
      totalCompleteToolRentPrice
      customerToolID
      tool {
        id
        name
        des
        userID
        imageList
        rate
        toolID
        brand
        condition
        model
        defects
        year
        lat
        long
        addressID
        createdAt
        updatedAt
      }
      bookingNumber
      invoiceJSON
      linkedTransactions {
        nextToken
      }
      conversationID
      conversation {
        id
        members
        memberIDs
        toolRentalID
        createdAt
        updatedAt
      }
      autoPayoutToSeller
      payoutDone
      createdAt
      updatedAt
    }
  }
`;
export const createPayment = /* GraphQL */ `
  mutation CreatePayment(
    $input: CreatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    createPayment(input: $input, condition: $condition) {
      id
      userID
      amount
      stripePaymentID
      stripeWithdrawalTransferID
      stripeWithdrawalPayoutID
      paypalSenderBatchID
      paypalPaymentID
      paymentType
      promoCodeID
      promoCode {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const updatePayment = /* GraphQL */ `
  mutation UpdatePayment(
    $input: UpdatePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    updatePayment(input: $input, condition: $condition) {
      id
      userID
      amount
      stripePaymentID
      stripeWithdrawalTransferID
      stripeWithdrawalPayoutID
      paypalSenderBatchID
      paypalPaymentID
      paymentType
      promoCodeID
      promoCode {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const deletePayment = /* GraphQL */ `
  mutation DeletePayment(
    $input: DeletePaymentInput!
    $condition: ModelPaymentConditionInput
  ) {
    deletePayment(input: $input, condition: $condition) {
      id
      userID
      amount
      stripePaymentID
      stripeWithdrawalTransferID
      stripeWithdrawalPayoutID
      paypalSenderBatchID
      paypalPaymentID
      paymentType
      promoCodeID
      promoCode {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const createTransaction = /* GraphQL */ `
  mutation CreateTransaction(
    $input: CreateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    createTransaction(input: $input, condition: $condition) {
      id
      amount
      transactionCategory
      transactionType
      transactionStatus
      toolRentalID
      linkedToolRental {
        id
        buyerID
        sellerID
        bookingType
        bookedStartDate
        bookedEndDate
        daysRented
        totalToolBaseRentPrice
        totalCompleteToolRentPrice
        customerToolID
        bookingNumber
        invoiceJSON
        conversationID
        autoPayoutToSeller
        payoutDone
        createdAt
        updatedAt
      }
      paymentID
      linkedPayment {
        id
        userID
        amount
        stripePaymentID
        stripeWithdrawalTransferID
        stripeWithdrawalPayoutID
        paypalSenderBatchID
        paypalPaymentID
        paymentType
        promoCodeID
        date
        createdAt
        updatedAt
      }
      metadata
      userID
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateTransaction = /* GraphQL */ `
  mutation UpdateTransaction(
    $input: UpdateTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    updateTransaction(input: $input, condition: $condition) {
      id
      amount
      transactionCategory
      transactionType
      transactionStatus
      toolRentalID
      linkedToolRental {
        id
        buyerID
        sellerID
        bookingType
        bookedStartDate
        bookedEndDate
        daysRented
        totalToolBaseRentPrice
        totalCompleteToolRentPrice
        customerToolID
        bookingNumber
        invoiceJSON
        conversationID
        autoPayoutToSeller
        payoutDone
        createdAt
        updatedAt
      }
      paymentID
      linkedPayment {
        id
        userID
        amount
        stripePaymentID
        stripeWithdrawalTransferID
        stripeWithdrawalPayoutID
        paypalSenderBatchID
        paypalPaymentID
        paymentType
        promoCodeID
        date
        createdAt
        updatedAt
      }
      metadata
      userID
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteTransaction = /* GraphQL */ `
  mutation DeleteTransaction(
    $input: DeleteTransactionInput!
    $condition: ModelTransactionConditionInput
  ) {
    deleteTransaction(input: $input, condition: $condition) {
      id
      amount
      transactionCategory
      transactionType
      transactionStatus
      toolRentalID
      linkedToolRental {
        id
        buyerID
        sellerID
        bookingType
        bookedStartDate
        bookedEndDate
        daysRented
        totalToolBaseRentPrice
        totalCompleteToolRentPrice
        customerToolID
        bookingNumber
        invoiceJSON
        conversationID
        autoPayoutToSeller
        payoutDone
        createdAt
        updatedAt
      }
      paymentID
      linkedPayment {
        id
        userID
        amount
        stripePaymentID
        stripeWithdrawalTransferID
        stripeWithdrawalPayoutID
        paypalSenderBatchID
        paypalPaymentID
        paymentType
        promoCodeID
        date
        createdAt
        updatedAt
      }
      metadata
      userID
      date
      createdAt
      updatedAt
    }
  }
`;
export const createPaymentIntent = /* GraphQL */ `
  mutation CreatePaymentIntent(
    $input: CreatePaymentIntentInput!
    $condition: ModelPaymentIntentConditionInput
  ) {
    createPaymentIntent(input: $input, condition: $condition) {
      id
      userID
      toolID
      toolOwnerID
      bookedStartDate
      bookedEndDate
      amount
      transferReferralUserID
      transferReferalAmount
      promoCodeID
      createdAt
      updatedAt
    }
  }
`;
export const updatePaymentIntent = /* GraphQL */ `
  mutation UpdatePaymentIntent(
    $input: UpdatePaymentIntentInput!
    $condition: ModelPaymentIntentConditionInput
  ) {
    updatePaymentIntent(input: $input, condition: $condition) {
      id
      userID
      toolID
      toolOwnerID
      bookedStartDate
      bookedEndDate
      amount
      transferReferralUserID
      transferReferalAmount
      promoCodeID
      createdAt
      updatedAt
    }
  }
`;
export const deletePaymentIntent = /* GraphQL */ `
  mutation DeletePaymentIntent(
    $input: DeletePaymentIntentInput!
    $condition: ModelPaymentIntentConditionInput
  ) {
    deletePaymentIntent(input: $input, condition: $condition) {
      id
      userID
      toolID
      toolOwnerID
      bookedStartDate
      bookedEndDate
      amount
      transferReferralUserID
      transferReferalAmount
      promoCodeID
      createdAt
      updatedAt
    }
  }
`;
export const createUserReview = /* GraphQL */ `
  mutation CreateUserReview(
    $input: CreateUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    createUserReview(input: $input, condition: $condition) {
      id
      userID
      rating
      comment
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserReview = /* GraphQL */ `
  mutation UpdateUserReview(
    $input: UpdateUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    updateUserReview(input: $input, condition: $condition) {
      id
      userID
      rating
      comment
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserReview = /* GraphQL */ `
  mutation DeleteUserReview(
    $input: DeleteUserReviewInput!
    $condition: ModelUserReviewConditionInput
  ) {
    deleteUserReview(input: $input, condition: $condition) {
      id
      userID
      rating
      comment
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createToolReview = /* GraphQL */ `
  mutation CreateToolReview(
    $input: CreateToolReviewInput!
    $condition: ModelToolReviewConditionInput
  ) {
    createToolReview(input: $input, condition: $condition) {
      id
      customerToolID
      rating
      comment
      userID
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateToolReview = /* GraphQL */ `
  mutation UpdateToolReview(
    $input: UpdateToolReviewInput!
    $condition: ModelToolReviewConditionInput
  ) {
    updateToolReview(input: $input, condition: $condition) {
      id
      customerToolID
      rating
      comment
      userID
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteToolReview = /* GraphQL */ `
  mutation DeleteToolReview(
    $input: DeleteToolReviewInput!
    $condition: ModelToolReviewConditionInput
  ) {
    deleteToolReview(input: $input, condition: $condition) {
      id
      customerToolID
      rating
      comment
      userID
      user {
        id
        fname
        lname
        zipCode
        email
        number
        latitude
        longitude
        profileImage
        stripeCustomerID
        stripeConnectID
        paypalLinkedEmail
        bankAccountLinked
        billingAddressID
        shippingAddressID
        referredByUserID
        isReferralComplete
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createConstant = /* GraphQL */ `
  mutation CreateConstant(
    $input: CreateConstantInput!
    $condition: ModelConstantConditionInput
  ) {
    createConstant(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateConstant = /* GraphQL */ `
  mutation UpdateConstant(
    $input: UpdateConstantInput!
    $condition: ModelConstantConditionInput
  ) {
    updateConstant(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteConstant = /* GraphQL */ `
  mutation DeleteConstant(
    $input: DeleteConstantInput!
    $condition: ModelConstantConditionInput
  ) {
    deleteConstant(input: $input, condition: $condition) {
      id
      name
      value
      createdAt
      updatedAt
    }
  }
`;
export const createPromoCode = /* GraphQL */ `
  mutation CreatePromoCode(
    $input: CreatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    createPromoCode(input: $input, condition: $condition) {
      id
      code
      type
      applyType
      description
      quantity
      discount
      minRentDays
      maxDiscount
      discountType
      activeDate
      expiryDate
      firstRentalApplicableOnly
      createdAt
      updatedAt
    }
  }
`;
export const updatePromoCode = /* GraphQL */ `
  mutation UpdatePromoCode(
    $input: UpdatePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    updatePromoCode(input: $input, condition: $condition) {
      id
      code
      type
      applyType
      description
      quantity
      discount
      minRentDays
      maxDiscount
      discountType
      activeDate
      expiryDate
      firstRentalApplicableOnly
      createdAt
      updatedAt
    }
  }
`;
export const deletePromoCode = /* GraphQL */ `
  mutation DeletePromoCode(
    $input: DeletePromoCodeInput!
    $condition: ModelPromoCodeConditionInput
  ) {
    deletePromoCode(input: $input, condition: $condition) {
      id
      code
      type
      applyType
      description
      quantity
      discount
      minRentDays
      maxDiscount
      discountType
      activeDate
      expiryDate
      firstRentalApplicableOnly
      createdAt
      updatedAt
    }
  }
`;
export const createUserRedeemedPromoCode = /* GraphQL */ `
  mutation CreateUserRedeemedPromoCode(
    $input: CreateUserRedeemedPromoCodeInput!
    $condition: ModelUserRedeemedPromoCodeConditionInput
  ) {
    createUserRedeemedPromoCode(input: $input, condition: $condition) {
      id
      promoCodeID
      userID
      linkedPromoCode {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const updateUserRedeemedPromoCode = /* GraphQL */ `
  mutation UpdateUserRedeemedPromoCode(
    $input: UpdateUserRedeemedPromoCodeInput!
    $condition: ModelUserRedeemedPromoCodeConditionInput
  ) {
    updateUserRedeemedPromoCode(input: $input, condition: $condition) {
      id
      promoCodeID
      userID
      linkedPromoCode {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserRedeemedPromoCode = /* GraphQL */ `
  mutation DeleteUserRedeemedPromoCode(
    $input: DeleteUserRedeemedPromoCodeInput!
    $condition: ModelUserRedeemedPromoCodeConditionInput
  ) {
    deleteUserRedeemedPromoCode(input: $input, condition: $condition) {
      id
      promoCodeID
      userID
      linkedPromoCode {
        id
        code
        type
        applyType
        description
        quantity
        discount
        minRentDays
        maxDiscount
        discountType
        activeDate
        expiryDate
        firstRentalApplicableOnly
        createdAt
        updatedAt
      }
      date
      createdAt
      updatedAt
    }
  }
`;
export const createToolPriceFactor = /* GraphQL */ `
  mutation CreateToolPriceFactor(
    $input: CreateToolPriceFactorInput!
    $condition: ModelToolPriceFactorConditionInput
  ) {
    createToolPriceFactor(input: $input, condition: $condition) {
      id
      durationDays
      factor
      createdAt
      updatedAt
    }
  }
`;
export const updateToolPriceFactor = /* GraphQL */ `
  mutation UpdateToolPriceFactor(
    $input: UpdateToolPriceFactorInput!
    $condition: ModelToolPriceFactorConditionInput
  ) {
    updateToolPriceFactor(input: $input, condition: $condition) {
      id
      durationDays
      factor
      createdAt
      updatedAt
    }
  }
`;
export const deleteToolPriceFactor = /* GraphQL */ `
  mutation DeleteToolPriceFactor(
    $input: DeleteToolPriceFactorInput!
    $condition: ModelToolPriceFactorConditionInput
  ) {
    deleteToolPriceFactor(input: $input, condition: $condition) {
      id
      durationDays
      factor
      createdAt
      updatedAt
    }
  }
`;
export const createTestModel = /* GraphQL */ `
  mutation CreateTestModel(
    $input: CreateTestModelInput!
    $condition: ModeltestModelConditionInput
  ) {
    createTestModel(input: $input, condition: $condition) {
      id
      testData
      createdAt
      updatedAt
    }
  }
`;
export const updateTestModel = /* GraphQL */ `
  mutation UpdateTestModel(
    $input: UpdateTestModelInput!
    $condition: ModeltestModelConditionInput
  ) {
    updateTestModel(input: $input, condition: $condition) {
      id
      testData
      createdAt
      updatedAt
    }
  }
`;
export const deleteTestModel = /* GraphQL */ `
  mutation DeleteTestModel(
    $input: DeleteTestModelInput!
    $condition: ModeltestModelConditionInput
  ) {
    deleteTestModel(input: $input, condition: $condition) {
      id
      testData
      createdAt
      updatedAt
    }
  }
`;
