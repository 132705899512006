import React from "react";

export default function RentalAgreement() {
    return (
        <div className="py-5 px-5">
            <div className="document__header">
                <h1 className="document__title">Rental Policy</h1>
            </div>
            <div className="document__content typography">
                <h3>Tool Pickup</h3>
                <p>
                    Renter is strongly advised to inspect the tool before pick up and discuss with the Tool Owner any obvious faults/defects, if any. Renter is strongly encouraged to take pictures of the tool from different angles at the time of the pickup to avoid any disputes later.
                </p>

                <h3>Tool Pickup Failure</h3>
                <p>
                    In the event that Renter is not able to pick up the tool at specified date and time due to Tool Owner being absent or any other fault of the Tool Owner, Renter will be refunded in full.
                </p>

                <h3>Booking Cancellation</h3>
                <h4>For Renter:</h4>
                <br />
                <p>
                    For a full refund, the renter must cancel at least 48 hours before
                    the listing’s local time . If the renter cancels less than 48 hours
                    before the booking start time, the first day's rent and Toology
                    service fees are non-refundable. If the renter decides to return the
                    tool early, the rent for the days not used 24 hours after
                    cancellation are fully refunded.
                </p>
                <h4>For Tool Owner:</h4>
                <br />
                <p>
                    You will be charged a fee if you cancel a confirmed reservation. We
                    will deduct that fee from your account balance. The amount deducted
                    will depend on when you accepted the reservation and how soon before
                    reservation start time you cancelled it:
                </p>

                <h3>Tool Return</h3>
                <p>
                    Upon completion of Rental Period, Renter will return the tool, in as good order and condition as when received, to location where the tool was picked up, unless otherwise agreed with tool owner. Moreover, if the tool is not returned before expiration of Rental Period, Renter will be charged an additional Rental Fee.
                </p>

                <h3>Rental Period Extension</h3>
                <p>
                    To extend the Rental Period, Renter must apply for an extension using Toology.ca, if available, prior to the expiration of such Rental Period. Should Renter fail to return the Tool prior to the expiration of the Rental Period, Renter will be charged Additional Fee.
                </p>

                <h3>Additional Rental Fee</h3>
                <p>
                    Should Renter fail to return the Tool prior to the expiration of the Rental Period, additional rental fee will be applied. Renter will be charged the first day rent on a daily recurring basis until the tool is returned or Toology has received the penalty equivalent to the fair market value of the tool. The fair market value of the tool will be solely determined by Toology.
                </p>

                <h3>Payment Disputes</h3>
                <p>
                    Renter must notify Toology in writing of any payment disputes, within thirty (30) days of transaction, or Renter will be deemed to have irrevocably waived its right to dispute such amounts.
                </p>

                <h3>Tool Related Disputes</h3>
                <p>
                    Renter is encouraged to report disputes related to tool pickup failure, tool not being in workable condition, tool not as shown on the listing, or any other dispute using Toology.ca ‘Disputes’ page. Renter is encourages to use the pictures of the tool for dispute resolution to support the claim.
                </p>

                <h3>Tool Damage</h3>
                <p>
                    Renter is expected to use the tool in proper manner and prevent any damage to the tool. Renter is responsible for all loss of and damage to tool caused by theft, abuse, misuse, neglect, or intentional acts. If the tool is damaged due to any cause during the Rental period, Renter will be charged ‘fair market value’ of the tool.
                </p>

                <h3>Damage Protection</h3>
                <p>
                    Damage protection is an optional service offered by Toology that, if selected, relieves Renter of repair charges, replacement charges and Administrative Charges if the tool is damaged during <strong>normal use</strong> during the Rental Period. In the event Renter elects Damage Protection services, the charge for Damage Protection is 15% of the Rental Price and will appear as a separate line-item on the Agreement and on the invoice. Renter must accept or decline the Damage Protection. <span className="border-bottom border-dark">Damage Protection is not insurance.</span>  Damage Protection <strong>does not cover</strong> loss of or damage to the tool caused by theft, abuse, neglect, misuse, or intentional acts.
                </p>

                <h3>Repairs</h3>
                <p>
                    In the event Renter does not elect to purchase Damage Protection, Renter agrees that an estimated Repair Cost will be charged for tool that is returned with damage due to abuse, misuse, neglect, or intentional acts. The Repair Cost will not exceed the fair market value of the tool and applicable fees.
                </p>
            </div>
        </div>
    )
}