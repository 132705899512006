// react
import React, { useContext, useEffect, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { Link } from "react-router-dom";

// data stubs
import theme from "../../data/theme";
import authContext from "../../context/auth/authContext";
import { Form } from "react-bootstrap";
import moment from "moment";
import { PanoramaFishEye, RemoveRedEye } from "@material-ui/icons";
import ChangeAvailability from "../shared/ChangeAvailability";
import ViewListing from "../shared/ViewListing";
import { conditionToText } from "../../api/Helper";
import BuildOutlinedIcon from "@material-ui/icons/BuildOutlined";

export default function AccountListings() {
  const { listMyPostedTools, postedTools } = useContext(authContext);

  const [selectedTool, setSelectedTool] = useState(null);
  const [showChangeAvailability, setShowChangeAvailability] = useState(null);

  useEffect(() => {
    listMyPostedTools();
  }, []);

  const rentals = () => {
    return (
      <div className="card">
        <div className="order-header">
          <h5 className="order-header__title">
            My Listings <BuildOutlinedIcon />
          </h5>
        </div>
        <div className="card-divider" />
        <div className="card-table">
          <div className="table-responsive-sm">
            <table>
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Brand</th>
                  <th>Condition</th>
                  <th>Model</th>
                  <th></th>
                </tr>
              </thead>
              <tbody className="  p-2 pb-4">
                {postedTools?.map((tool, index) => {
                  return (
                    <tr className="m-2 ">
                      <td>{tool?.name}</td>

                      <td>{tool?.brand}</td>
                      <td> {conditionToText(tool?.condition)}</td>
                      <td
                        style={{
                          maxWidth: "200px",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {tool?.model}
                      </td>
                      <td className="d-flex flex-row">
                        <Link
                          to={"/shop/products/" + tool?.id}
                          target="_blank"
                          className="cursor-pointer font-weight-bold"
                        >
                          View
                        </Link>
                        &nbsp; &nbsp; &nbsp;
                        <div
                          className="cursor-pointer font-weight-bold"
                          onClick={() => {
                            setSelectedTool(tool);
                          }}
                        >
                          Edit
                        </div>
                        &nbsp; &nbsp; &nbsp;
                        <div className="cursor-pointer font-weight-bold text-danger">
                          Delete
                        </div>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>{`Posted Tools — ${theme.name}`}</title>
      </Helmet>

      {rentals()}
      {
        <ViewListing
          show={selectedTool != null}
          onClose={() => {
            setSelectedTool(null);
          }}
          tool={selectedTool}
          showChangeAvailability={(tool) => {
            setShowChangeAvailability(tool);
          }}
        />
      }
      <ChangeAvailability
        show={showChangeAvailability !== null}
        onClose={() => {
          setShowChangeAvailability(null);
        }}
        tool={showChangeAvailability}
      />
      <br />
    </React.Fragment>
  );
}
