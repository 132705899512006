// react
import React, { useState, useContext, useEffect, useRef } from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

// third-party
import { Link, useHistory } from "react-router-dom";
import PersonIcon from "@material-ui/icons/Person";

// application
import Indicator from "./Indicator";
import { AccountIcon, Person20Svg } from "../../svg";

import authContext from "../../context/auth/authContext";
import showError from "../../api/Helper";

export default function IndicatorAccount() {
  const [loginEmail, setLoginEmail] = useState("");
  const [loginPassword, setLoginPassword] = useState("");
  const [isSigningin, setIsSigningin] = useState(false);
  const [error, setError] = useState(null);

  // const { accountBalance } = useContext(BalanceContext);
  const { user, balance, login, logout, isLoggedIn } = useContext(authContext);

  const [isLoginLoading, setIsLoginLoading] = useState(false);

  // const TextClipper = (chars, clipSize) => {
  //   if (chars.length >= clipSize) {
  //     let vb = "";
  //     for (let i = 0; i < clipSize; i++) {
  //       vb += chars.charAt(i);
  //     }
  //     chars = `${vb}...`;
  //
  //     return chars;
  //   }
  // };

  const SignIn = (e) => {
    e.preventDefault();
    setError("");
    if (loginEmail === "") {
      setError("Please enter a valid email address");
      return;
    }

    if (loginPassword === "") {
      setError("Please enter a password");
      return;
    }
    setIsLoginLoading(true);

    setIsSigningin(true);

    login(loginEmail, loginPassword)
      .then(() => {})
      .catch((error) => {
        showError("Your username/password seems to be incorrect");
        // console.log(error);
      })
      .finally(() => {
        setError(null);
        setLoginPassword("");
        setLoginEmail("");
        setIsLoginLoading(false);
      });
  };

  const LogOut = () => {
    logout();
  };

  const dropdown = (
    <div className="account-menu">
      {!isLoggedIn ? (
        <>
          <form className="account-menu__form" onSubmit={SignIn}>
            <div className="account-menu__form-title">
              Log In to Your Account
            </div>
            <div className="form-group">
              <label htmlFor="header-signin-email" className="sr-only">
                Email address
              </label>
              <input
                id="header-signin-email"
                type="email"
                className="form-control form-control-sm"
                placeholder="Email address"
                onChange={(e) => setLoginEmail(e.target.value)}
                value={loginEmail}
              />
            </div>
            <div className="form-group">
              <label htmlFor="header-signin-password" className="sr-only">
                Password
              </label>
              <div className="account-menu__form-forgot">
                <input
                  id="header-signin-password"
                  type="password"
                  className="form-control form-control-sm"
                  placeholder="Password"
                  onChange={(e) => setLoginPassword(e.target.value)}
                  value={loginPassword}
                />
                <Link
                  to="/account/login"
                  className="account-menu__form-forgot-link"
                >
                  Forgot?
                </Link>
              </div>
            </div>
            <div className="form-group account-menu__form-button">
              <button
                disabled={isLoginLoading}
                type="submit"
                className="btn btn-primary btn-sm"
              >
                {isLoginLoading ? (
                  <CircularProgress
                    color="white"
                    style={{ height: "18px", width: "18px" }}
                  />
                ) : (
                  "Login"
                )}
              </button>
            </div>
            <div className="account-menu__form-link">
              <Link to="/account/login">Create An Account</Link>
            </div>
          </form>
          {error != null ? (
            <p
              className="m-8 text-red-500"
              style={{
                fontWeight: "100",
                fontSize: "0.8rem",
                width: "80%",
                margin: "auto",
                marginTop: "-5%",
                color: "red",
                textAlign: "center",
              }}
            >
              {error}
            </p>
          ) : (
            ""
          )}
          <div className="account-menu__divider" />
        </>
      ) : (
        <>
          <div className="d-flex flex-row w-75 mx-4 mt-4">
            <PersonIcon
              className="mr-2"
              style={{ fontSize: "0.8rem!important" }}
            />
            <h5 className="">{`${isLoggedIn ? user?.fname : "User"}`}</h5>
            <h5 className="ml-auto">
              <strong>$</strong>
              {`${balance.toFixed(2)}`}
            </h5>
          </div>
          <ul
            onClick={() => {
              ref?.current?.close();
            }}
            className="account-menu__links"
          >
            <li>
              <Link to="/account/dashboard">Dashboard</Link>
            </li>
            <li>
              <Link to="/account/listings">My Listings</Link>
            </li>
            <li>
              <Link to="/account/bookings">Bookings</Link>
            </li>
            <li>
              <Link to="/account/addresses">Addresses</Link>
            </li>
            <li>
              <Link
                to="/account/password"
                style={{ borderBottom: "1px solid lightgray" }}
              >
                Password
              </Link>
            </li>
          </ul>
          <ul className="account-menu__links">
            <li
              onClick={LogOut}
              style={{
                margin: "auto",
                fontWeight: "500",
                marginLeft: "1.3rem",
                cursor: "pointer",
              }}
            >
              Logout
            </li>
          </ul>
        </>
      )}
    </div>
  );

  const ref = useRef(null);

  return (
    <Indicator
      ref={ref}
      url="/account"
      dropdown={dropdown}
      icon={<AccountIcon />}
    />
  );
}
