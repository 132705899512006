import React, { useContext, useEffect, useState } from "react";
import AddressEdit from "./AddressEdit";
import authContext from "../../context/auth/authContext";
import DB from "../../api/DB";
import addressContext from "../../context/address/addressContext";
import { Badge, Button } from "react-bootstrap";
import showError, { showSuccess } from "../../api/Helper";

export default function AddressView({
  userAddress,
  bill = true,
  publish = true,
  onChange,
  deleteAddress,
}) {
  const [openAddressModal, setOpenAddressModal] = useState(false);
  const [address, setAddress] = useState(userAddress);
  const { user, defaultAddress } = useContext(authContext);
  const { addresses, makeAddressDefault } = useContext(addressContext);

  useEffect(() => {
    setAddress(userAddress);
  }, [userAddress]);

  // useEffect(() => {
  //   alert("UPDATEEED");
  // }, [defaultAddress]);

  const setDefaultAddress = () => {
    makeAddressDefault(address)
      .then(() => {
        showSuccess("Address set as default");
      })
      .catch((error) => {
        console.log(error);
        showError("An error occurred setting default address");
      });
  };

  return (
    <div className="">
      {address?.id === defaultAddress && (
        <Badge className="bg-primary text-white mb-2">Default</Badge>
      )}

      {bill && <h6>We will bill to the following address</h6>}

      <div className="">
        {address?.addressLine1 != null && (
          <>
            <strong>
              <div>{`${address?.firstName ?? user?.fname} ${
                address?.lastName ?? user?.lname
              }`}</div>
            </strong>

            <div className="address-card__row">
              {address?.postcode}, {address?.city}
              <br />
              {address?.addressLine2}
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">Phone Number</div>
              <div className="address-card__row-content">
                {address?.phoneNumber ?? user?.number}
              </div>
            </div>
            <div className="address-card__row">
              <div className="address-card__row-title">Email Address</div>
              <div className="address-card__row-content">{user?.email}</div>
            </div>
          </>
        )}

        <div className="address-card__footer d-flex flex-column">
          {address?.id !== defaultAddress && (
            <div
              onClick={() => {
                setDefaultAddress();
              }}
              className="text-primary text-sm cursor-pointer mb-2"
            >
              Set Default
            </div>
          )}
          <section className="d-flex flex-row">
            <div
              className="address-card-edit-btn"
              onClick={() => {
                setOpenAddressModal(true);
              }}
            >
              {address?.addressLine1 == null ? "Add" : "Edit"}
            </div>
            {addresses?.length > 1 &&
              address != null &&
              deleteAddress != null &&
              !address?.id === defaultAddress && (
                <div
                  className="address-card-edit-btn text-danger ml-auto"
                  onClick={() => {
                    deleteAddress();
                  }}
                >
                  Delete
                </div>
              )}
            &nbsp;&nbsp;
          </section>
        </div>
      </div>
      {/* edit address pop upz */}
      <AddressEdit
        //  savedAddress={(address) => {
        //    setAddress(address);
        //    onChange(address);
        //  }}
        // on
        onSave={onChange}
        address={address}
        open={openAddressModal}
        onClose={() => {
          setOpenAddressModal(false);
        }}
        publish={publish}
      />
    </div>
  );
}
