import CloseIcon from "@material-ui/icons/Close";
import { Dialog } from "@material-ui/core";
import React from "react";

import { Button } from "react-bootstrap";

export default function FreeDetails({ open, onClose }) {
  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      className="vw-100 px-lg-4"
      fullWidth
      onKeyDown={(e) => {}}
    >
      <div
        className="w-100 px-lg-4 p-4"
        style={{ minWidth: "100%", minHeight: "40vh" }}
      >
          <div className="d-flex flex-row mt-2">
          {/*<PeopleOutline className="mr-2" />*/}
          {/*<h5>Invite your friends</h5>*/}
          <CloseIcon
            onClick={() => {
              onClose();
            }}
            className="ml-auto cursor-pointer"
          />
        </div>

          <section className="d-flex flex-column justify-content-center align-content-center mt-3">
              <p className="text-center my-3">
                  Enjoy your{" "}
                  <strong style={{ fontSize: "1.3rem", fontWeight: "800" }}>
                      first
                  </strong>{" "}
                  day's rent on us.
              </p>
              <p>Use the code <strong>WELCOMEFREE</strong> on checkout</p>
              <Button
                  onClick={() => onClose()}
                  variant="primary"
                  className="mt-3"
              >
                  Start Renting
              </Button>
          </section>
      </div>
    </Dialog>
  );
}
