import React, { useContext, useState } from "react";

// third-party
import { Helmet } from "react-helmet-async";
import { colors, Dialog } from "@material-ui/core";
import SuccessModal from "../shared/SuccessDialog";

// data stubs
import theme from "../../data/theme";
import Hub, { Auth } from "aws-amplify";

export default function AccountPagePassword() {
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [confirmNewPassword, setConfirmNewPassword] = useState("");

  const [err, setErr] = useState("");
  const [msg, setMsg] = useState("");

  const PasswordChange = (e) => {
    e.preventDefault();

    setMsg("");
    setErr("");

    if (newPassword !== confirmNewPassword) {
      setErr("Passwords do not match");
      return;
    }

    Auth.currentAuthenticatedUser()
      .then((user) => {
        return Auth.changePassword(user, currentPassword, newPassword);
      })
      .then((data) => {
        // console.log(data);
        setMsg("Password Changed Successfully");
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
        setErr("");
      })
      .catch((err) => {
        // console.log(err);
        if (
          err.message.includes(
            "Member must have length greater than or equal to 6"
          )
        ) {
          setErr("Password length must be equal to 6 or greater");
        } else if (err.message.includes("Incorrect username or password")) {
          setErr("Invalid current password");
        }
        setCurrentPassword("");
        setNewPassword("");
        setConfirmNewPassword("");
      });
  };

  return (
    <div className="card">
      <Helmet>
        <title>{`Change Password — ${theme.name}`}</title>
      </Helmet>

      <div className="card-header">
        <h5>Change Password</h5>
      </div>
      <div className="card-divider" />
      <div className="card-body">
        <div className="row no-gutters">
          <form onSubmit={PasswordChange} className="col-12 col-lg-7 col-xl-6">
            <div className="form-group">
              {msg !== "" ? <p style={{ color: "#3b5998" }}>{msg}</p> : null}
              <label htmlFor="password-current">Current Password</label>
              <input
                type="password"
                className="form-control"
                id="password-current"
                placeholder="Current Password"
                required
                value={currentPassword}
                onChange={(e) => setCurrentPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password-new">New Password</label>
              <input
                type="password"
                className="form-control"
                id="password-new"
                placeholder="New Password"
                value={newPassword}
                required
                onChange={(e) => setNewPassword(e.target.value)}
              />
            </div>
            <div className="form-group">
              <label htmlFor="password-confirm">Reenter New Password</label>
              <input
                type="password"
                className="form-control"
                id="password-confirm"
                placeholder="Reenter New Password"
                value={confirmNewPassword}
                required
                onChange={(e) => setConfirmNewPassword(e.target.value)}
              />
            </div>

            <div className="form-group mt-5 mb-0">
              <button type="submit" className="btn btn-primary">
                Change
              </button>
            </div>

            {err !== "" ? (
              <p style={{ marginTop: "2%", color: "red" }}>{err}</p>
            ) : null}
          </form>
        </div>
      </div>
    </div>
  );
}
