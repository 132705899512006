// react
import React, { useContext, useEffect, useState } from "react";

// third-party
import PropTypes from "prop-types";
import { Helmet } from "react-helmet-async";

// application
import PageHeader from "../shared/PageHeader";
import Product from "../shared/Product";
import ProductTabs from "./ProductTabs";
import { url } from "../../services/utils";

// blocks
import BlockLoader from "../blocks/BlockLoader";
import BlockProductsCarousel from "../blocks/BlockProductsCarousel";

// widgets
import WidgetCategories from "../widgets/WidgetCategories";
import WidgetProducts from "../widgets/WidgetProducts";

// data stubs
import categories from "../../data/shopWidgetCategories";
import theme from "../../data/theme";
import toolsContext from "../../context/tools/toolsContext";
import { capitalizeFirstLetter } from "../../api/Helper";
import authContext from "../../context/auth/authContext";

function ShopPageProduct(props) {
  const { productSlug, layout, sidebarPosition } = props;
  const [isLoading, setIsLoading] = useState(true);
  const [product, setProduct] = useState(null);
  const { userLocation } = useContext(authContext);
  const [relatedProducts, setRelatedProducts] = useState([]);
  // const [latestProducts, setLatestProducts] = useState([]);
  const [showPageFooter, setShowPageFooter] = useState(false);

  const { latestTools, getCustomerTool, getRelatedProducts } =
    useContext(toolsContext);

  // Load product.
  useEffect(() => {
    let canceled = false;

    setIsLoading(true);

    getCustomerTool(productSlug).then((product) => {
      if (canceled) {
        return;
      }

      setProduct(product);
      setIsLoading(false);
    });

    return () => {
      canceled = true;
    };
  }, [productSlug, setIsLoading, userLocation]);

  //console.log(product);

  // Load related products.
  useEffect(() => {
    let canceled = false;

    getRelatedProducts(productSlug, { limit: 8 }).then((products) => {
      if (canceled) {
        return;
      }

      setRelatedProducts(products);
    });

    return () => {
      canceled = true;
    };
  }, [productSlug, setRelatedProducts]);

  if (isLoading) {
    return <BlockLoader />;
  }

  const breadcrumb = [
    { title: "Home", url: url.home() },
    { title: "Shop", url: url.catalog() },
    { title: product.name, url: url.product(product) },
  ];

  let content;

  if (layout === "sidebar") {
    const sidebar = (
      <div className="shop-layout__sidebar">
        <div className="block block-sidebar">
          <div className="block-sidebar__item">
            <WidgetCategories categories={categories} location="shop" />
          </div>
          <div className="block-sidebar__item d-none d-lg-block">
            <WidgetProducts title="Latest Products" products={latestTools} />
          </div>
        </div>
      </div>
    );

    content = (
      <div className="">
        <div className={`shop-layout shop-layout--sidebar--${sidebarPosition}`}>
          {sidebarPosition === "start" && sidebar}
          <div className=" shop-layout__content">
            <div className=" block">
              <Product product={product} layout={layout} />
              <ProductTabs products={product} withSidebar />
            </div>

            {relatedProducts.length > 0 && (
              <BlockProductsCarousel
                title="Related Products"
                layout="grid-4-sm"
                products={relatedProducts}
                withSidebar
              />
            )}
          </div>
          {sidebarPosition === "end" && sidebar}
        </div>
      </div>
    );
  } else {
    content = (
      <React.Fragment>
        <div className="block">
          <div className="container">
            <Product
              setShowPageFooter={(val) => {
                setShowPageFooter(val);
              }}
              product={product}
              layout={layout}
            />
            {showPageFooter && <ProductTabs products={product} />}
          </div>
        </div>

        {/*{showPageFooter && relatedProducts.length > 0 && (*/}
        {/*  <BlockProductsCarousel*/}
        {/*    title="Related Products"*/}
        {/*    layout="grid-5"*/}
        {/*    products={relatedProducts}*/}
        {/*  />*/}
        {/*)}*/}
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <Helmet>
        <title>{`${capitalizeFirstLetter(product?.name)} — ${
          theme.name
        }`}</title>
      </Helmet>

      <PageHeader breadcrumb={breadcrumb} />

      {content}
    </React.Fragment>
  );
}

ShopPageProduct.propTypes = {
  /** Product slug. */
  productSlug: PropTypes.string,
  /** one of ['standard', 'sidebar', 'columnar', 'quickview'] (default: 'standard') */
  layout: PropTypes.oneOf(["standard", "sidebar", "columnar", "quickview"]),
  /**
   * sidebar position (default: 'start')
   * one of ['start', 'end']
   * for LTR scripts "start" is "left" and "end" is "right"
   */
  sidebarPosition: PropTypes.oneOf(["start", "end"]),
};

ShopPageProduct.defaultProps = {
  layout: "standard",
  sidebarPosition: "start",
};

export default ShopPageProduct;
