import React, { useContext, useEffect, useState } from "react";

import { Dialog, DialogContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import IconButton from "@material-ui/core/IconButton";
import DataManager from "../../api/DataManager";
import { LocationManager } from "../../api/LocationManager";
import AutoCompleteField from "./AutoCompleteField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import { showSuccess } from "../../api/Helper";
import authContext from "../../context/auth/authContext";
import { Alert, Button, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Person } from "@material-ui/icons";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FormGroup } from "reactstrap";
import DB from "../../api/DB";

export default function AddressEdit({ open, onClose, address, onSave }) {
  const { user } = useContext(authContext);
  const {
    reset,
    control,
    handleSubmit,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm();

  const postcode = useWatch({
    control,
    name: "postcode", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: address?.postcode ?? user?.zipCode, // default value before the render
  });

  useEffect(() => {
    console.log("GOT ADDRESS", address?.firstName);
  }, [address]);

  useEffect(() => {
    setValue("city", postcode?.name ?? address?.city);
    setValue("state", postcode?.state ?? address?.state);
    setValue("lng", postcode?.lon);
    setValue("lat", postcode?.lat);
  }, [postcode]);

  useEffect(() => {
    setValue("postcode", {
      city: address?.name,
      state: address?.state,
      zipcode: address?.postcode,
    });
  }, [address]);

  const onSubmit = (values) => {
    const location = values?.postcode;

    delete values["postcode"];

    values.postcode = location?.zipcode;
    values.id = address?.id;

    onSave(values);
    // reset();
    onClose();
  };

  const queryZipCodes = (query, setValue) => {
    // // let myLocation = zipText
    // //   ? { name: "Current Location", zipcode: zipText }
    // //   : { zipcode: "Current Location" };
    setValue([]);
    if (query === "") {
      return;
    }
    LocationManager.loadZipCodes(query).then((items) => {
      setValue(items);
    });
  };

  return (
    <Dialog
      open={open}
      onClose={() => onClose()}
      className="vw-100 px-lg-4"
      fullWidth
      onKeyDown={(e) => {}}
    >
      <div className="w-100 px-lg-4">
        <div className=" pt-4 d-flex justify-content-between align-items-center">
          {address != null && <h5>Edit Address</h5>}
          {address == null && <h5>Add Address</h5>}
          <IconButton
            onClick={() => {
              onClose();
            }}
          >
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>
        <div className="card-divider mb-4" />

        <div className="mw-100">
          <div className="row no-gutters">
            <div className="form-group" style={{ width: "100%" }}>
              <Form onSubmit={handleSubmit(onSubmit)} className="flex flex-col">
                <Form.Label className="font-weight-bold">
                  First Name <span className="text-danger">*</span>
                </Form.Label>
                <Form.Group className="w-100">
                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        className="w-1/3 mr-2 focus:outline-none border h-12 pr-4 mb-4 w-full"
                        required
                        placeholder="First Name"
                        {...field}
                        isInvalid={!!errors.firstName}
                      />
                    )}
                    defaultValue={address?.firstName ?? user?.fname}
                    name="firstName"
                    control={control}
                    rules={{
                      required: "Please enter your first name",
                    }}
                  />
                  {errors && errors.firstName && (
                    <Alert variant="danger">{errors.firstName.message}</Alert>
                  )}
                  <div className="flex flex-row w-full">
                    <Form.Label className="font-weight-bold">
                      Last Name <span className="text-danger">*</span>
                    </Form.Label>

                    <Controller
                      render={({ field }) => (
                        <Form.Control
                          className="w-1/2 mr-2 focus:outline-none border h-12 pr-4 mb-4 w-full"
                          required
                          placeholder="Last Name"
                          {...field}
                          isInvalid={!!errors.lastName}
                        />
                      )}
                      defaultValue={address?.lastName ?? user?.lname}
                      name="lastName"
                      control={control}
                      rules={{
                        required: "Please enter your last name",
                      }}
                    />
                    {errors && errors.lastName && (
                      <Alert variant="danger">{errors.lastName.message}</Alert>
                    )}
                  </div>
                  <Form.Label className="font-weight-bold">
                    Phone Number <span className="text-danger">*</span>
                  </Form.Label>

                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        className="w-1/2 focus:outline-none border h-12 pr-4 mb-4 w-full"
                        required
                        placeholder="Phone Number"
                        {...field}
                        isInvalid={!!errors.phoneNumber}
                      />
                    )}
                    defaultValue={address?.phoneNumber ?? user?.number}
                    name="phoneNumber"
                    control={control}
                    rules={{
                      required: "Please enter your phone number",
                    }}
                  />
                  {errors && errors.phoneNumber && (
                    <Alert variant="danger">{errors.phoneNumber.message}</Alert>
                  )}
                  <hr />
                  <Form.Label className="font-weight-bold">
                    Address Line 1 <span className="text-danger">*</span>
                  </Form.Label>

                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        className="w-1/2 focus:outline-none border h-12 pr-4 mb-4 w-full"
                        required
                        placeholder="Address Line 1"
                        {...field}
                        isInvalid={!!errors.addressLine1}
                      />
                    )}
                    defaultValue={address?.addressLine1}
                    name="addressLine1"
                    control={control}
                    rules={{
                      required: "Please enter your street address",
                    }}
                  />
                  {errors && errors.addressLine1 && (
                    <Alert variant="danger">
                      {errors.addressLine1.message}
                    </Alert>
                  )}
                  <Form.Label className="font-weight-bold">
                    Address Line 2
                  </Form.Label>

                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        className="w-1/2 focus:outline-none border h-12 pr-4 mb-4 "
                        placeholder="Address Line 2"
                        {...field}
                        // isInvalid={!!errors.addressLine2}
                      />
                    )}
                    defaultValue={address?.addressLine2}
                    name="addressLine2"
                    control={control}
                  />
                  <Form.Label className="font-weight-bold">
                    Postal Code <span className="text-danger">*</span>
                  </Form.Label>

                  <Controller
                    control={control}
                    name="postcode"
                    render={({ field: { onChange, onBlur, value, ref } }) => (
                      <AutoCompleteField
                        textFieldProps={{
                          textTransform: "uppercase",
                          width: "100%",
                          border: "1px solid #CED4DA",
                          padding: "4px",
                        }}
                        innerProps={{ padding: "2px" }}
                        queryOptions={queryZipCodes}
                        getValue={(val) => {
                          return [val?.zipcode, val?.name];
                        }}
                        defaultTextValue={address?.postcode}
                        defaultValue={[
                          {
                            name: address?.postcode,
                            zipcode: address?.postcode,
                          },
                        ]}
                        placeHolder="Select Postal Code"
                        required
                        didSelect={(option, e) => {
                          const value = {
                            ...e,
                            target: { value: option },
                          };
                          onChange(value);
                        }}
                      />
                    )}
                    rules={{
                      required: "Please select your postal code",
                    }}
                  />
                  {errors && errors.postcode && (
                    <Alert variant="danger">{errors.postcode.message}</Alert>
                  )}
                  <br />

                  <Form.Label className="font-weight-bold">
                    Province <span className="text-danger">*</span>
                  </Form.Label>

                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        className="w-1/2 focus:outline-none border h-12 pr-4 mb-4 w-full"
                        required
                        placeholder="Province"
                        {...field}
                        isInvalid={!!errors.state}
                      />
                    )}
                    defaultValue={address?.state}
                    name="state"
                    control={control}
                    rules={{
                      required: "Please enter your province",
                    }}
                  />
                  {errors && errors.state && (
                    <Alert variant="danger">{errors.state.message}</Alert>
                  )}
                  <Form.Label className="font-weight-bold">
                    City <span className="text-danger">*</span>
                  </Form.Label>

                  <Controller
                    render={({ field }) => (
                      <Form.Control
                        className="w-1/2 focus:outline-none border h-12 pr-4 mb-4 w-full"
                        required
                        placeholder="City"
                        {...field}
                        isInvalid={!!errors.city}
                      />
                    )}
                    defaultValue={address?.city}
                    name="city"
                    control={control}
                    rules={{
                      required: "Please enter your city",
                    }}
                  />
                  {errors && errors.city && (
                    <Alert variant="danger">{errors.city.message}</Alert>
                  )}
                </Form.Group>
                <Button
                  className="btn btn-primary"
                  type="submit"
                  style={{ width: "100%" }}
                >
                  Save
                </Button>
              </Form>
            </div>
          </div>
        </div>
      </div>
    </Dialog>
  );
}
