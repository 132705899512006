// react
import React, {
  Component,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";

// application
import BlockHeader from "../shared/BlockHeader";
import ProductCard from "../shared/ProductCard";
import StroykaSlick from "../shared/StroykaSlick";
import { ArrowLeft, ArrowRight } from "@material-ui/icons";
import { Col, Row } from "react-bootstrap";
import BlockLoader from "./BlockLoader";

const slickSettings = {
  "grid-4": {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },
  "grid-4-sm": {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 4,
    slidesToScroll: 4,
    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 474,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },
  "grid-5": {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 5,
    slidesToScroll: 5,
    autoplay: true,
    autoplaySpeed: 4000,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 4,
        },
      },
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 479,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },
  horizontal: {
    dots: false,
    arrows: false,
    infinite: true,
    speed: 400,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  },
};

export default function BlockProductsCarousel(props) {
  // useEffect(() => {
  //   console.log("PRODUCTS ");
  // }, [props]);

  const handleNextClick = () => {
    // console.log("REF ", slickRef);
    if (slickRef) {
      slickRef?.current?.slickNext();
    }
  };

  const handlePrevClick = () => {
    if (slickRef) {
      slickRef?.current?.slickPrev();
    }
  };

  const slickRef = useRef(null);

  const setSlickRef = (ref) => {
    slickRef.current = ref;
  };

  const [productColumns, setProductColumns] = useState([]);

  useEffect(() => {
    // console.log("REFRESHED: ", props.products);
    const columns = [];
    const { rows } = props;

    if (rows > 0) {
      let products = props.products.slice();

      while (products.length > 0) {
        columns.push(products.splice(0, rows));
      }
    }

    // console.log("UPDATED PRODUCTS: ", columns);

    setProductColumns(columns?.reverse());
  }, [props.products]);

  // const columns = );

  if (props.loading) {
    return (
      <div className="w-100">
        <h3 className="text-black-100 mx-auto text-center mb-xl-4">
          {props.title}
        </h3>
        <BlockLoader />
      </div>
    );
  }

  return (
    <div className=" mx-auto w-100 my-auto px-1 px-sm-4">
      <div className="mx-auto">
        <Row className="mx-auto my-auto px-1 px-sm-3">
          {props.rows * 5 < props?.products?.length && (
            <ArrowLeft
              onClick={handlePrevClick}
              className="bg-primary my-auto text-white rounded-circle cursor-pointer slider-nav-button"
              style={{ fontSize: "2.5rem" }}
            />
          )}

          <Col
            className="block-products-carousel__slider"
            style={{ width: "80%" }}
          >
            <div className="block-products-carousel__preloader" />

            <h3 className="text-black-100 mx-auto text-center mb-xl-4">
              {props.title}
            </h3>
            <StroykaSlick ref={setSlickRef} {...slickSettings["grid-5"]}>
              {productColumns?.map((column, index) => {
                return (
                  <div
                    key={index}
                    className="block-products-carousel__column justify-content-center mx-auto pt-0 pt-sm-4"
                  >
                    {column.map((product) => (
                      <div
                        key={product.id}
                        className=" block-products-carousel__cell mb-lg-5 "
                      >
                        <ProductCard product={product} />
                      </div>
                    ))}
                  </div>
                );
              })}
            </StroykaSlick>
          </Col>

          {props.rows * 5 < props?.products?.length && (
            <ArrowRight
              onClick={handleNextClick}
              className="bg-primary my-auto text-white rounded-circle cursor-pointer slider-nav-button"
              style={{ fontSize: "2.5rem" }}
            />
          )}
        </Row>
      </div>
    </div>
  );
}
