import { useContext, useEffect, useReducer, useState } from "react";
import AddressContext from "./addressContext";
import addressReducer from "./addressReducer";

import {
  LOAD_PAYMENT_DETAILS,
  LOAD_USER_ADDRESS,
  LOAD_USER_ADDRESS_LIST,
  SET_ACCOUNT_BALANCE,
  SET_USER_LOCATION,
  UPDATE_ACCOUNT_BALANCE,
  UPDATE_NOTIFICATIONS,
  USER_LOGGED_IN,
} from "../types";
import DB from "../../api/DB";
import showError, { showSuccess } from "../../api/Helper";
import authContext from "../auth/authContext";
import DataManager from "../../api/DataManager";

const AddressState = (props) => {
  const initialState = {
    addresses: [],
    showAddressModal: false,
  };
  const [state, dispatch] = useReducer(addressReducer, initialState);

  const { user, loadUser, updateUserDefaultAddress, defaultAddress } =
    useContext(authContext);

  const listUserAddress = () => {
    DB.listUserAddresses()
      .then((addresses) => {
        dispatch({
          type: LOAD_USER_ADDRESS_LIST,
          payload: {
            addresses: addresses,
            defaultAddress: defaultAddress,
          },
        });
        // console.log("Address List", addresses);
      })
      .catch((error) => {
        // console.log("Address List Failed", error);
      });
  };

  const deleteAddress = (address) => {
    // console.log("DELETING ADDRESS", address);

    DB.deleteUserAddresses(address?.id)
      .then(() => {
        listUserAddress();
        showSuccess("Your address has been deleted");

        // console.log("deleted");
      })
      .catch((error) => {
        showError("Your address could not be deleted");

        // console.log("Error deleting address", error);
      });
  };

  const update = (address) => {
    // console.log("UPDATING ADDRESS", address);

    DB.editUserAddresses(address)
      .then(() => {
        listUserAddress();
        showSuccess("Your address has been saved");

        // console.log("Saved");
      })
      .catch((error) => {
        showError("Your address could not be saved");

        // console.log("Error saving address", error);
      });
  };

  const save = (address) => {
    // console.log("SAVING ADDRESS", address);
    DB.addUserAddresses(address, true)
      .then(() => {
        listUserAddress();
        showSuccess("Your address has been saved");

        // console.log("Saved");
      })
      .catch((error) => {
        showError("Your address could not be saved");

        console.log("Error saving address", error);
      });
  };

  const makeAddressDefault = async (address) => {
    await DB.makeAddressDefault(address);

    //await loadUser();
    updateUserDefaultAddress(address?.id);
    //await listUserAddress();
    // await updateToolsAddress(address);
  };

  return (
    <AddressContext.Provider
      value={{
        addresses: state.addresses,
        showAddressModal: state.showAddressModal,
        listUserAddress,
        save,
        update,
        deleteAddress,
        makeAddressDefault,
      }}
    >
      {props.children}
    </AddressContext.Provider>
  );
};

export default AddressState;
