import ImageUpload from "../../shared/ImageUpload";
import React, { useEffect, useState } from "react";
import { FormGroup } from "reactstrap";

import { Controller, useWatch } from "react-hook-form";
import { Alert, Form, InputGroup, Row } from "react-bootstrap";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Radio from "@material-ui/core/Radio";
import { calculateToolPrice, toolPriceCalculator } from "../../../api/Helper";
import LockIcon from "@material-ui/icons/Lock";

import "../../../scss/posttool/rent-user-tool.scss";
export default function Step3({
  control,
  errors,
  register,
  suggestedToolPrice,
  setValue,
}) {
  const [pricing, setPricing] = useState([]);

  const toolPrice = useWatch({
    control,
    name: "toolPrice", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: suggestedToolPrice, // default value before the render
  });

  const hourlyRate = useWatch({
    control,
    name: "hourlyRate", // without supply name will watch the entire form, or ['firstName', 'lastName'] to watch both
    defaultValue: suggestedToolPrice / 10, // default value before the render
  });

  useEffect(() => {
    setValue("toolPrice", suggestedToolPrice);
  }, [suggestedToolPrice]);

  useEffect(() => {
    toolPriceCalculator(toolPrice ?? suggestedToolPrice, toolPrice).then(
      (pricingList) => {
        setValue("hourlyRate", pricingList?.[0]?.rate);
        setPricing(pricingList);
        // console.log("Loading pricing");
      }
    );

    // console.log("toolPrice" + toolPrice);
  }, [toolPrice]);

  useEffect(() => {
    calculateToolPrice(hourlyRate).then((rate) => {
      toolPriceCalculator(toolPrice ?? suggestedToolPrice, rate).then(
        (pricingList) => {
          setPricing(pricingList);
          // console.log("Loading pricing");
        }
      );
    });
  }, [hourlyRate]);

  const reverseHourlyRate = (val) => {
    //calculateToolPrice(val).then((rate) => setToolPrice(rate));
  };

  const pricingtable = (showRecommended = true, inputDaily = true) => {
    return (
      <table className="">
        <tbody>
          <tr className=" text-primary ">
            <th className="">Duration</th>
            {showRecommended && <th className="">Our Suggested Rent</th>}
            <th className="">Your Rent</th>
          </tr>

          {pricing?.map((data, index) => (
            <tr>
              <td
                className="w-25"
                style={{
                  width: !showRecommended ? "60%!important" : "20%!important",
                }}
              >
                {`${data?.day}:`}
              </td>
              {showRecommended && (
                <td className="w-25">
                  <b>$</b>
                  {data?.rate}
                </td>
              )}
              <td
                className="w-25"
                style={{
                  width: !showRecommended ? "60%!important" : "20%!important",
                }}
              >
                <span
                  className="d-flex"
                  style={{
                    width: !showRecommended ? "60%!important" : "20%!important",
                  }}
                >
                  {index === 0 ? (
                    inputDaily ? (
                      <Controller
                        control={control}
                        name="hourlyRate"
                        render={({ field }) => (
                          <InputGroup className="border-0 w-100">
                            <InputGroup.Prepend>
                              <InputGroup.Text className="bg-primary text-white">
                                <b>$</b>
                              </InputGroup.Text>
                            </InputGroup.Prepend>

                            <Form.Control
                              style={{ textTransform: "uppercase" }}
                              type="number"
                              className="form-control"
                              placeholder=""
                              // value={toolPrice}
                              {...register("hourlyRate")}
                              {...field}
                              // defaultValue={suggestedToolPrice / 10}
                              isInvalid={!!errors.hourlyRate}
                            />
                          </InputGroup>
                        )}
                      />
                    ) : (
                      data?.userRate
                    )
                  ) : (
                    ""
                  )}

                  {index > 0 && (
                    <>
                      {" "}
                      <b>$</b> {data?.userRate?.toFixed(1)}{" "}
                    </>
                  )}
                </span>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  return (
    <FormGroup>
      <div className="post-tool-main-set-tool-rate px-1 d-flex flex-column flex-sm-row justify-content-between">
        <row className="post-tool-add-price">
          <label>Please enter your tool's price</label>
          <Controller
            control={control}
            name="toolPrice"
            render={({ field }) => (
              <InputGroup className="border-0 border-primary bg-light post-tool-add-price">
                <InputGroup.Prepend>
                  <InputGroup.Text className="bg-primary text-white">
                    <b>$</b>
                  </InputGroup.Text>
                </InputGroup.Prepend>
                <Form.Control
                  style={{ textTransform: "uppercase" }}
                  type="number"
                  className="form-control"
                  placeholder=""
                  // value={toolPrice}
                  {...register("toolPrice")}
                  {...field}
                  defaultValue={suggestedToolPrice}
                  isInvalid={!!errors.toolPrice}
                />
              </InputGroup>
            )}
            rules={{
              required: "Please upload at least one photo of your tool",
            }}
          />
          {errors && errors.toolPrice && (
            <Alert variant="danger">{errors.toolPrice.message}</Alert>
          )}
          {suggestedToolPrice && (
            <label>
              Based upon our search the price of your tool is{" "}
              <b>${suggestedToolPrice}</b>
            </label>
          )}
        </row>
        <section className="post-tool-payment-column">
          <div className="checkout-summary">
            {pricingtable(true, true)}
            {/*<Button className="checkout-order-button">Pay</Button>*/}
          </div>
        </section>
      </div>
    </FormGroup>
  );
}
