export const USER_LOGGED_IN = "USER_LOGGED_IN";
export const UPDATE_ACCOUNT_BALANCE = "UPDATE_ACCOUNT_BALANCE";
export const SET_ACCOUNT_BALANCE = "SET_ACCOUNT_BALANCE";
export const UPDATE_NOTIFICATIONS = "UPDATE_NOTIFICATIONS";
export const LOAD_USER_ADDRESS = "LOAD_USER_ADDRESS";
export const LOAD_USER_ADDRESS_LIST = "LOAD_USER_ADDRESS_LIST";
export const SET_USER_LOCATION = "SET_USER_LOCATION";
export const LOAD_PAYMENT_DETAILS = "LOAD_PAYMENT_DETAILS";
export const LOAD_USER_RENTALS = "LOAD_USER_RENTALS";
export const LOAD_USER_LENDINGS = "LOAD_USER_LENDINGS";
export const LOAD_USER_TRANSACTIONS = "LOAD_USER_TRANSACTIONS";
export const UPDATE_USER_DEFAULT_ADDRESS = "UPDATE_USER_DEFAULT_ADDRESS";

export const LOAD_ALL_CATEGORIES = "LOAD_ALL_CATEGORIES";

export const LOAD_ALL_TOOLS = "LOAD_ALL_TOOLS";
export const LOAD_ALL_SUBCATEGORIES = "LOAD_ALL_SUBCATEGORIES";
// export const LOAD_POPULAR_TOOLS = "LOAD_POPULAR_TOOLS";
// export const LOAD_NEARBY_TOOLS = "LOAD_NEARBY_TOOLS";
export const LOAD_SEARCH_RESULTS = "LOAD_SEARCH_RESULTS";

export const REDIRECT_TO_TOOL = "REDIRECT_TO_TOOL";

export const SET_CHECKOUT_INFO = "SET_CHECKOUT_INFO";
export const LOAD_CARDS_ON_FILE = "LOAD_CARDS_ON_FILE";
export const SET_SELECTED_CARD = "SET_SELECTED_CARD";
export const SET_PAYPAL_REF = "SET_PAYPAL_REF";
export const SET_PROCESSING_FEES = "SET_PROCESSING_FEES";

export const SET_INTENT_IDS = "SET_INTENT_IDS";
export const SET_USE_CREDITS = "SET_USE_CREDITS";
export const SET_DAMAGE_PROTECTION = "SET_DAMAGE_PROTECTION";
export const SET_NO_PAYMENT_REQUIRED = "SET_NO_PAYMENT_REQUIRED";
export const SET_ORDER_TOTAL = "SET_ORDER_TOTAL";
export const SET_PROMO_DATA = "SET_PROMO_DATA";
export const APPLY_PROMO_CODE = "APPLY_PROMO_CODE";
export const REMOVE_PROMO_CODE = "REMOVE_PROMO_CODE";

export const LIST_POSTED_TOOLS = "LIST_POSTED_TOOLS";
