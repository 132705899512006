import {
  LOAD_ALL_CATEGORIES,
  LOAD_ALL_SUBCATEGORIES,
  LOAD_ALL_TOOLS,
} from "../types";
import { LocationManager } from "../../api/LocationManager";

const authReducer = (state, action) => {
  switch (action.type) {
    case LOAD_ALL_TOOLS:
      let userLocation = action.payload.userLocation;
      let allTools = action.payload?.tools?.map((tool) => {
        tool.distance =
            (userLocation?.location?.lat && userLocation?.location?.long)
            ? LocationManager.getDistanceFromTool(tool, userLocation)
            : -1;
        return tool;
      });

      return {
        ...state,
        allTools: allTools,
        popularTools: allTools,
        nearByTools: allTools,
        latestTools: allTools,
        isLoading: false,
      };
    case LOAD_ALL_CATEGORIES:
      let cats = action.payload;

      cats = cats?.sort((a, b) => {
        return a?.name > b?.name ? 1 : -1;
      });

      return { ...state, allCategories: cats };

    case LOAD_ALL_SUBCATEGORIES:
      let subCats = action.payload;

      subCats = subCats?.sort((a, b) => {
        return a?.name > b?.name ? 1 : -1;
      });

      return { ...state, allSubCategories: subCats };
    default:
      return state;
  }
};
export default authReducer;
