// react
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";

// third-party
import classNames from "classnames";
import { useHistory, withRouter } from "react-router-dom";

// application
import Suggestions from "./Suggestions";
import { Cross20Svg, DropDownArrow, Pin, Search20Svg } from "../../svg";
import { LocationManager } from "../../api/LocationManager";
import { CachingService } from "../../api/CachingService";
import AutoCompleteField from "../shared/AutoCompleteField";
import LocationOnIcon from "@material-ui/icons/LocationOn";
import MyLocationIcon from "@material-ui/icons/MyLocation";
import authContext from "../../context/auth/authContext";
import showError from "../../api/Helper";
import toolsContext from "../../context/tools/toolsContext";
import RoomIcon from "@material-ui/icons/Room";
function Search(props) {
  const { context, className, inputRef, onClose, location } = props;
  const history = useHistory();
  const [cancelFn, setCancelFn] = useState(() => () => {});
  const [suggestionsOpen, setSuggestionsOpen] = useState(false);
  const [hasSuggestions, setHasSuggestions] = useState(false);
  const [suggestedProducts, setSuggestedProducts] = useState([]);
  const [query, setQuery] = useState("");
  const [category, setCategory] = useState("All Categories");
  const [zip, setZip] = useState("");
  const [zipText, setZipText] = useState("");

  const { setUserLocation, userLocation } = useContext(authContext);
  const { allCategories, getSuggestions } = useContext(toolsContext);

  const [lastSearchEvent, setLastSearchEvent] = useState("");

  const wrapper = useRef(null);
  const close = useCallback(() => {
    if (onClose) {
      onClose();
    }

    setSuggestionsOpen(false);
  }, [onClose]);

  // Close suggestions when the location has been changed.
  useEffect(() => close(), [close, location]);

  // Close suggestions when a click has been made outside component.
  useEffect(() => {
    const onGlobalClick = (event) => {
      if (wrapper.current && !wrapper.current.contains(event.target)) {
        close();
      }
    };

    document.addEventListener("mousedown", onGlobalClick);

    return () => document.removeEventListener("mousedown", onGlobalClick);
  }, [close]);

  // Cancel previous typing.
  useEffect(() => () => cancelFn(), [cancelFn]);

  useEffect(() => {
    console.log("jhj", zip);
    if (zip?.name === "Current Location") {
      console.log("Fetching from browser");

      LocationManager.getCurrentLocation()
        .then((location, zipCode) => {
          console.log("myLocation", location);
          setUserLocation(location, zipCode);
          zipCode = zipCode?.replace(" ", "");
          setZipText(zipCode);
        })
        .catch((error) => {
          setZipText(null);
          // console.log(error);
          showError("We were unable to get your location ");
        });
    } else {
      // console.log("Fetching from zipcde");
      setUserLocation({ lat: zip?.lat, long: zip?.lon }, zip?.zipCode);
    }
  }, [zip]);

  const handleFocus = () => {
    setSuggestionsOpen(true);
  };

  const queryZipCodes = (query, setValue) => {
    let myLocation = zipText
      ? { name: "Current Location", zipcode: zipText }
      : { name: "Current Location", zipcode: "" };
    setValue([myLocation]);

    if (query === "") {
      return;
    }
    LocationManager.loadZipCodes(query).then((items) => {
      setValue([...items, myLocation]);
    });
  };

  const queryCategories = (query, setValue) => {
    setValue([{ name: "All Categories" }, ...allCategories]);
  };

  // useEffect(() => {
  //   if (lastSearchEvent !== "") {
  //     handleChangeQuery(lastSearchEvent);
  //   }
  // }, [userLocation]);

  const handleChangeQuery = (event) => {
    setLastSearchEvent(event);

    let canceled = false;
    let timer;

    const newCancelFn = () => {
      canceled = true;
      clearTimeout(timer);
    };

    const query = event.target.value;

    setQuery(query);

    if (query === "") {
      setHasSuggestions(false);
    } else {
      const options = { limit: 5 };

      if (category?.name !== "[all]") {
        options.category = category;
      }
      options.location = CachingService.currentLocation ?? zip;

      getSuggestions(query, options).then((products) => {
        products = products?.slice(0, Math.min(products?.length, 4));
        //console.log('suggestedProducts111',products)
        if (canceled) {
          return;
        }

        setSuggestedProducts(products);
        setHasSuggestions(products.length > 0);
        setSuggestionsOpen(true);
      });
    }

    setCancelFn(() => newCancelFn);
  };

  const handleBlur = () => {
    setTimeout(() => {
      if (!document.activeElement || document.activeElement === document.body) {
        return;
      }

      // Close suggestions if the focus received an external element.
      if (
        wrapper.current &&
        !wrapper.current.contains(document.activeElement)
      ) {
        close();
      }
    }, 10);
  };

  // Close suggestions when the Escape key has been pressed.
  const handleKeyDown = (event) => {
    if (event.which === 13) {
      onSubmit();
      close();
    }

    // Escape.
    if (event.which === 27) {
      close();
    }
  };

  const rootClasses = classNames(
    `search search--location--${context}`,
    className,
    {
      "search--suggestions-open": suggestionsOpen,
      "search--has-suggestions": hasSuggestions,
    }
  );

  const closeButton =
    context !== "mobile-header" ? (
      ""
    ) : (
      <button
        className="search__button search__button--type--close"
        type="button"
        onClick={close}
      >
        <Cross20Svg />
      </button>
    );

  const onSubmit = (e) => {
    e?.preventDefault();
    // console.log(category?.id);
    history.push(
      "/shop/search/" +
        query +
        (category?.id != null ? `?category=${category?.id}` : "")
    );
    close();
  };

  return (
    <div className={rootClasses} ref={wrapper} onBlur={handleBlur}>
      <div className="search__body">
        <form
          className="search__form"
          onSubmit={(e) => {
            onSubmit(e);
          }}
        >
          <div className="dropdown  search__categories">
            {allCategories?.length !== 0 && (
              <AutoCompleteField
                queryOptions={queryCategories}
                getValue={(val) => {
                  return [val?.name, ""];
                }}
                options={allCategories}
                placeHolder="All Categories"
                didSelect={(option) => {
                  setCategory(option);
                }}
              />
            )}
          </div>

          <div className="search__location">
            {
              <AutoCompleteField
                insertable
                textFieldProps={{ textTransform: "uppercase" }}
                innerProps={{ textTransform: "uppercase", icon: "ss" }}
                queryOptions={queryZipCodes}
                getValue={(val) => {
                  return [val?.zipcode, val?.name];
                }}
                defaultValue={
                  zipText
                    ? [{ name: "Current Location", zipcode: zipText }]
                    : [{ name: "Current Location", zipcode: "" }]
                }
                hideArrow={zip?.zipcode != null}
                changeText={zipText}
                placeHolder="Your Postal Code"
                didSelect={(option) => {
                  //console.log('ghjuyti', option)
                  if (option.name && !option.zipcode) {
                    option.zipcode = option.name;
                  }
                  setZip(option);
                }}
                icon={
                  <LocationOnIcon
                    style={{
                      fontSize: "0.8rem",
                      marginRight: "4px",
                      color: "gray",
                    }}
                  />
                }
              />
            }
            {zip?.zipcode === "Current Location" && (
              <MyLocationIcon
                style={{
                  fontSize: "1.1rem",
                  marginRight: "4px",
                }}
              />
            )}

            {zip?.zipcode !== "Current Location" && zip?.zipcode && (
              <RoomIcon
                style={{
                  fontSize: "1.1rem",
                  marginRight: "4px",
                }}
              />
            )}
          </div>

          <input
            ref={inputRef}
            onChange={handleChangeQuery}
            onFocus={handleFocus}
            onKeyDown={handleKeyDown}
            value={query}
            className="search__input"
            name="search"
            placeholder="Find your tool"
            aria-label="Site search"
            type="text"
            autoComplete="off"
          />
          <button
            className="search__button search__button--type--submit"
            type="submit"
          >
            <Search20Svg />
          </button>
          {closeButton}
          <div className="search__border" />
        </form>

        <Suggestions
          className="search__suggestions"
          context={context}
          products={suggestedProducts}
        />
      </div>
    </div>
  );
}

export default withRouter(Search);
