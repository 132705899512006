// react
import React from "react";
import { Image } from "react-bootstrap";

// third-party
import PropTypes from "prop-types";
import { Link } from "react-router-dom";



export default function SocialLinks(props) {
  const { title, items } = props;

  const linksList = items.map((item, index) => (
    <li key={index} className="footer-links__item">
      <a href={item.url} className="footer-links__link d-flex flex-row align-middle justify-content-center">
          <Image
              className="mr-2"
              style={{ width: "25px", height: "25px", }}
              src={item.iconPath}
              alt="social-icon"
          />
          <p>{item.title}</p>
      </a>
    </li>
  ));

  return (
    <div className="site-footer__widget footer-links">
      <h5 className="footer-links__title">{title}</h5>
      <ul className="footer-links__list">{linksList}</ul>
    </div>
  );
}

SocialLinks.propTypes = {
  /** widget title */
  title: PropTypes.node.isRequired,
  /** array of links */
  items: PropTypes.array,
};

SocialLinks.defaultProps = {
  items: [],
};
