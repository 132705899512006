import React, { useEffect, useState } from "react";
import "../../scss/blocks/_block-pricing.scss";
import DataManager from "../../api/DataManager";
import { pad_with_zeroes, toDay, toolPriceCalculator } from "../../api/Helper";

export default function PricingTable({ title, product, days }) {
  const [pricingFactors, setPricingFactors] = useState([]);

  const [prices, setPrices] = useState([]);

  const getPrice = (val) => {
    //console.log(val % 10);
    return [parseInt(val), pad_with_zeroes((val % 1).toFixed(2) * 10, 2)];
  };

  // useEffect(() => {
  //   DataManager.pricingData().then((factors) => {
  //     //console.log(factors);
  //     setPricingFactors(factors);
  //   });
  // }, []);

  useEffect(() => {
    let productPrice = product?.price ?? "";

    toolPriceCalculator(productPrice, null, days).then((list) => {
      setPrices(list);
    });

    // let days = [1, 3, 14, 30, 60];
    //
    // let priceList = [];
    //
    // days.forEach((day) => {
    //   let factor = 1 - (pricingFactors?.[day - 1]?.factor ?? 0);
    //   let price = parseInt(productPrice) * day * factor;
    //
    //   priceList.push({
    //     duration: toDay(day),
    //     price: price,
    //   });
    // });

    // setPrices(priceList);
  }, [pricingFactors, product]);

  const pricingtable = () => {
    return (
      <table className="">
        <tbody>
          <tr className="text-primary ">
            <th className="">Duration</th>
            <th className="text-center">Your Rent</th>
          </tr>

          {prices?.map((data, index) => (
            <tr>
              <td className="w-50" style={{ width: "60%!important" }}>
                {`${data?.day}:`}
              </td>
              <td
                className="w-50 text-center"
                style={{ width: "60%!important" }}
              >
                <div>
                  <b>{`$ `}</b>
                  {data?.userRate?.toFixed(2)}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    );
  };

  const oldTable = () => {
    return (
      <div>
        <div className="pricing-table-main-container">
          {pricingtable()}
          {prices?.map((item) => (
            <div className="pricing-table-main-item">
              <h1 className="pricing-table-main-item-price-text">
                {`$ ${getPrice(item?.rate)?.[0]}`}
                <h6>.{getPrice(item?.rate)?.[1]}</h6>
              </h1>

              <h6 className="pricing-table-main-item-duration-text">
                {item?.day}
              </h6>
            </div>
          ))}
        </div>
      </div>
    );
  };

  return (
    <div className="pricing-table-main w-md-75">
      <h1 className="pricing-table-main-heading">{title ?? "Our pricing"}</h1>
      <br />
      <div className="pricing-table-main-container">{pricingtable()}</div>
    </div>
  );
}
