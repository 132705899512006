import {
  LIST_POSTED_TOOLS,
  LOAD_PAYMENT_DETAILS,
  LOAD_USER_ADDRESS,
  LOAD_USER_LENDINGS,
  LOAD_USER_RENTALS,
  LOAD_USER_TRANSACTIONS,
  SET_ACCOUNT_BALANCE,
  SET_USER_LOCATION,
  UPDATE_ACCOUNT_BALANCE,
  UPDATE_NOTIFICATIONS,
  UPDATE_USER_DEFAULT_ADDRESS,
  USER_LOGGED_IN,
} from "../types";

const authReducer = (state, action) => {
  switch (action.type) {
    case USER_LOGGED_IN:
      console.log("USER COGNITO", action.payload?.userCognito);

      const number = action?.payload?.userCognito?.attributes?.["phone_number"];

      const userData = action.payload?.user;
      const user = { ...userData, username: userData?.id, number: number };

      // console.log("USER IS LOADED");
      // console.log(user);

      return {
        ...state,
        isLoggedIn: action.payload?.isLoggedIn,
        user: user,
      };

    case SET_ACCOUNT_BALANCE:
      return { ...state, balance: action.payload };
    case UPDATE_ACCOUNT_BALANCE:
      return { ...state, balance: state.balance + action.payload };

    case UPDATE_NOTIFICATIONS:
      return {
        ...state,
        notifications: [...state.notifications, action.payload],
      };

    case LOAD_USER_ADDRESS:
      return {
        ...state,
        address: action.payload,
      };
    case SET_USER_LOCATION:
      // console.log(action.payload);
      return { ...state, userLocation: action.payload };

    case LOAD_PAYMENT_DETAILS:
      return { ...state, billingInfo: action.payload };

    case LOAD_USER_RENTALS:
      return { ...state, toolsRented: action.payload };

    case LOAD_USER_LENDINGS:
      return { ...state, toolsLended: action.payload };

    case LOAD_USER_TRANSACTIONS:
      return { ...state, transactions: action.payload };

    case LIST_POSTED_TOOLS:
      return { ...state, postedTools: action.payload };

    case UPDATE_USER_DEFAULT_ADDRESS:
      return {
        ...state,
        defaultAddress: action.payload,
      };

    default:
      return state;
  }
};
export default authReducer;
