import { useForm, Controller } from "react-hook-form";
import authContext from "../../context/auth/authContext";
import React, { useCallback, useContext, useEffect, useState } from "react";
import { Button, Form, InputGroup, Row, Col } from "react-bootstrap";
import { Person } from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import GoogleIcon from "../../assets/imgs/social/google.png";
import FacebookIcon from "../../assets/imgs/social/facebook-circular-logo.png";
import AmazonIcon from "../../assets/imgs/social/amazon.png";
import showError, { showSuccess } from "../../api/Helper";
import { Auth } from "aws-amplify";
import AuthService from "../../api/AuthService";
import AccountRegisterPage from "./AccountRegisterPage";
import AccountRecoverPage from "./AccountRecoverPage";

import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

export default function AccountLoginPage() {
  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitted },
  } = useForm();

  const {
    login,
    confirmSignUp,
    signUpUser,
    forgotPassword,
    verifyForgotPasswordCode,
  } = useContext(authContext);

  const [isLoading, setIsLoading] = useState(false);
  const [mode, setMode] = useState("login");

  const [userData, setUserData] = useState(null);

  const { id } = useParams();

  useEffect(() => {
    if (id != null) {
      setMode("register");
    }
  }, [id]);

  const openSocial = (type) => {
    Auth.federatedSignIn({ provider: type }).catch((error) => {
      showError(AuthService.errorToString(error));
    });
  };

  const onSubmit = (values) => {
    setIsLoading(true);

    //Account recovery
    if (mode === "forgotPassword") {
      if (userData == null) {
        forgotPassword(values?.email)
          .then(() => {
            setUserData({ email: values?.email });
          })
          .catch((error) => {
            // console.log(error);
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
          });

        return;
      }

      if (values?.password !== values?.confirmPassword) {
        showError("Your passwords don't seem to match");
        return;
      }
      verifyForgotPasswordCode(
        userData?.email,
        values?.confirmCode,
        values?.password
      )
        .then(() => {
          showSuccess("Your password has been updated");
          setMode("login");
          setUserData(null);
        })
        .catch((error) => {
          showError(
            "The verification code you have entered seems to be incorrect"
          );
          setIsLoading(false);
          // console.log(error);
        })
        .finally(() => {
          setIsLoading(false);
        });
      return;
    }

    //Signup
    if (mode === "register") {
      if (values?.password !== values?.confirmPassword) {
        setIsLoading(false);
        showError("Your passwords don't seem to match");
        return;
      }

      //Confirm Account
      if (userData != null) {
        confirmSignUp(userData?.user, values?.confirmCode, userData?.password)
          .then(() => {})
          .catch((error) => {
            // console.log(error);
            showError("Your verification code seems to be incorrect");
            setIsLoading(false);
          })
          .finally(() => {
            setIsLoading(false);
          });
        return;
      }

      //Sign up user
      signUpUser(
        values.email,
        values.password,
        values.number,
        values.fname,
        values.lname,
        id
      )
        .then((user) => {
          setUserData({
            user: user?.userSub,
            password: values.password,
            number: values?.number,
          });
        })
        .catch((error) => {
          console.log(error);
          // showError("Your email or password seems to be incorrect");
          showError("Your account already exists");
          setIsLoading(false);
        })
        .finally(() => {
          setIsLoading(false);
        });

      return;
    }

    //Login user
    login(values.email, values.password)
      .then(() => {})
      .catch((error) => {
        // console.log(error);
        showError("Your email or password seems to be incorrect");
        setIsLoading(false);
      })
      .finally(() => {
        setIsLoading(false);
      });

    // console.log(values);
  };

  const socialSection = () => {
    return (
      <>
        <Row className="justify-content-center align-items-center w-full">
          <Col
            xs={12}
            lg={4}
            style={{ backgroundColor: "#FFFFFF", color: "#7A7A7A" }}
            className="border-0 shadow-sm mr-lg-4 my-2 px-3 py-1 rounded cursor-pointer text-center"
            onClick={() => {
              openSocial("Google");
            }}
          >
            <img
              src={GoogleIcon}
              style={{
                marginRight: "8px",
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
            Google
          </Col>
          <Col
            xs={12}
            lg={4}
            style={{ backgroundColor: "#1673EA" }}
            className="border-0 shadow-sm mr-lg-4 my-2 px-3 py-1 rounded cursor-pointer text-center text-light"
            onClick={() => {
              openSocial("Facebook");
            }}
          >
            {" "}
            <img
              src={FacebookIcon}
              style={{
                marginRight: "8px",
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
            Facebook
          </Col>
          <Col
            xs={12}
            lg={4}
            style={{ backgroundColor: "#FF9900" }}
            className="border-0 shadow-sm mr-lg-4 my-2 px-3 py-1 rounded cursor-pointer text-center"
            onClick={() => {
              openSocial("LoginWithAmazon");
            }}
          >
            <img
              src={AmazonIcon}
              style={{
                marginRight: "8px",
                width: "1.5rem",
                height: "1.5rem",
              }}
            />
            Amazon
          </Col>
        </Row>
      </>
    );
  };

  const LoginForm = () => {
    return (
      <>
        <Form.Group className="border">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="border-0 bg-white">
                <Person className="text-black-50 text-sm" size={20} />
              </InputGroup.Text>
            </InputGroup.Prepend>

            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="email"
                  required
                  placeholder="Email"
                  {...field}
                  isInvalid={!!errors.email}
                />
              )}
              name="email"
              rules={{
                required: "Please enter first name",
              }}
            />
            {errors && errors.email && (
              <Form.Control.Feedback type="invalid">
                {errors.firstName.message}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group className="border">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="border-0 bg-white">
                <LockIcon className="text-black-50 text-sm" size={20} />
              </InputGroup.Text>
            </InputGroup.Prepend>

            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="password"
                  required
                  placeholder="Password"
                  {...field}
                  isInvalid={!!errors.password}
                />
              )}
              name="password"
              rules={{
                required: "Please enter a secure password",
              }}
            />
          </InputGroup>
        </Form.Group>
      </>
    );
  };

  return (
    <Row className="py-lg-5 rounded-sm">
      <Col
        xs={12}
        lg={6}
        className="shadow-lg p-3 mb-5 bg-white rounded-lg align-middle py-xl-5 mt-4 mx-auto"
      >
        <div className="w-75 m-auto">
          <h3 className="w-full text-center text-primary">
            {mode === "login" && "Login"}
            {mode === "register" && "Register"}
            {mode === "forgotPassword" && "Forgot Password"}
          </h3>
          <p className="w-full text-center">
            {mode === "forgotPassword" &&
              userData == null &&
              "Enter your email address to recover your account"}

            {mode === "register" && "Start renting tools today!"}
            {mode === "login" && "Start renting tools today!"}
          </p>

          <br />
          <Form onSubmit={handleSubmit(onSubmit)}>
            {mode === "login" && LoginForm()}

            {mode === "forgotPassword" && (
              <AccountRecoverPage
                confirmAccount={userData != null}
                control={control}
                errors={errors}
                isLoading={isLoading}
              />
            )}

            {mode === "register" && (
              <AccountRegisterPage
                confirmAccount={userData != null}
                control={control}
                errors={errors}
                isLoading={isLoading}
                number={userData?.number}
              />
            )}

            <Button className={"btn-block"} type="submit" disabled={isLoading}>
              {isLoading && (
                <span
                  className="spinner-border spinner-border-sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              {!isLoading && mode === "login" && "Login"}
              {!isLoading && mode === "register" && "Register"}
              {!isLoading && mode === "forgotPassword" && "Verify"}
            </Button>

            <Row className="text-primary text-center d-flex w-full flex mx-sm-1 mt-3 mt-lg-2">
              <Col xs={12} lg={6} className="p-0">
                <h6
                  className="cursor-pointer text-lg-left"
                  onClick={() => {
                    setMode(mode === "login" ? "register" : "login");
                  }}
                >
                  {mode === "login" && "Create an account"}
                  {mode === "register" && "Already have an account"}
                </h6>
              </Col>
              <Col xs={12} lg={6} className="p-0">
                {mode === "login" && (
                  <h6
                    onClick={() => {
                      setMode("forgotPassword");
                    }}
                    className="cursor-pointer text-lg-right"
                  >
                    Forgot password
                  </h6>
                )}
              </Col>
            </Row>
            <br />
            <h5 className="w-full text-center">OR</h5>
            <hr />

            {socialSection()}
          </Form>
        </div>
      </Col>
    </Row>
  );
}
