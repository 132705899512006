/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "ca-central-1",
    "aws_cognito_identity_pool_id": "ca-central-1:85c2befe-7fa3-4a03-9fa5-a507e9e05195",
    "aws_cognito_region": "ca-central-1",
    "aws_user_pools_id": "ca-central-1_RuAOR8Dom",
    "aws_user_pools_web_client_id": "11gd98qnm4rh9bmn44d3jms7rr",
    "oauth": {
        "domain": "toology69c53304-69c53304-release.auth.ca-central-1.amazoncognito.com",
        "scope": [
            "phone",
            "email",
            "openid",
            "profile",
            "aws.cognito.signin.user.admin"
        ],
        "redirectSignIn": "https://www.toology.ca/",
        "redirectSignOut": "https://www.toology.ca/",
        "responseType": "code"
    },
    "federationTarget": "COGNITO_USER_POOLS",
    "aws_appsync_graphqlEndpoint": "https://m5n7z4nlbndndaqjl6ibday6e4.appsync-api.ca-central-1.amazonaws.com/graphql",
    "aws_appsync_region": "ca-central-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-lhs7c4hwybcqpevdlun4b2dihe",
    "aws_cloud_logic_custom": [
        {
            "name": "StripePayoutApi",
            "endpoint": "https://i8v7wmtcwe.execute-api.ca-central-1.amazonaws.com/release",
            "region": "ca-central-1"
        },
        {
            "name": "PaypalPayoutApi",
            "endpoint": "https://535qxdovx7.execute-api.ca-central-1.amazonaws.com/release",
            "region": "ca-central-1"
        },
        {
            "name": "PaymentServiceApi",
            "endpoint": "https://9yhhk1nlc8.execute-api.ca-central-1.amazonaws.com/release",
            "region": "ca-central-1"
        },
        {
            "name": "SendReferralApi",
            "endpoint": "https://wekv3jwo2e.execute-api.ca-central-1.amazonaws.com/release",
            "region": "ca-central-1"
        },
        {
            "name": "AccountUpdateApi",
            "endpoint": "https://7kbov00x8e.execute-api.ca-central-1.amazonaws.com/release",
            "region": "ca-central-1"
        },
        {
            "name": "CancelBookingApi",
            "endpoint": "https://89myguhfm2.execute-api.ca-central-1.amazonaws.com/release",
            "region": "ca-central-1"
        }
    ],
    "aws_user_files_s3_bucket": "toolsreact7e1f37e8e3d54bb7a97fd8acb2a3bc1b60152-release",
    "aws_user_files_s3_bucket_region": "ca-central-1"
};


export default awsmobile;
