// react
import React, { useContext, useState } from "react";

// third-party
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { Link } from "react-router-dom";

// application
import Currency from "./Currency";
import Rating from "./Rating";
import { cartAddItem } from "../../store/cart";
import { Compare16Svg, Quickview16Svg, Wishlist16Svg } from "../../svg";
import { compareAddItem } from "../../store/compare";
import { quickviewOpen } from "../../store/quickview";
import { url } from "../../services/utils";
import { wishlistAddItem } from "../../store/wishlist";

import { TriggerContext } from "../../context/context";
import "../../scss/components/_product-full-width-card.scss";
import { formatDistance } from "../../api/Helper";

function ProductFullWidthCard(props) {
  const { preTrigger, setPreTrigger } = useContext(TriggerContext);

  const { product, layout } = props;
  const containerClasses = classNames("product-card", {
    "product-card--layout--grid product-card--size--sm": layout === "grid-sm",
    "product-card--layout--grid product-card--size--nl": layout === "grid-nl",
    "product-card--layout--grid product-card--size--lg": layout === "grid-lg",
    "product-card--layout--list": layout === "list",
    "product-card--layout--horizontal": layout === "horizontal",
  });

  let badges = [];
  let image;
  let price;
  let features;
  let distance;

  badges = badges.length ? (
    <div className="product-card__badges-list">{badges}</div>
  ) : null;

  if (product.images && product.images.length > 0) {
    image = (
      <div className="product-card__image product-image">
        <Link to={url.product(product)} className="product-image__body">
          <img className="product-image__img" src={product.images[0]} alt="" />
        </Link>
      </div>
    );
  }

  price = (
    <div className="product-card__prices">
      <Currency value={product.hourlyRate} /> / day
    </div>
  );

  if (product.attributes && product.attributes.length) {
    features = (
      <ul className="product-card__features-list">
        {product.attributes
          .filter((x) => x.featured)
          .map((attribute, index) => (
            <li key={index}>{`${attribute.name}: ${attribute.values
              .map((x) => x.name)
              .join(", ")}`}</li>
          ))}
      </ul>
    );
  }

  return (
    <div className={containerClasses}>
      {/*<button*/}
      {/*  type="button"*/}
      {/*  // onClick={run}*/}
      {/*  className={classNames("product-card__quickview", {*/}
      {/*    "product-card__quickview--preload": false,*/}
      {/*  })}*/}
      {/*>*/}
      {/*  <Quickview16Svg />*/}
      {/*</button>*/}

      {badges}
      {image}
      <div className="product-card__info">
        <div className="product-card__name">
          <Link to={url.product(product)}>{product.name}</Link>
        </div>
        <div className="product-card__rating">
          <Rating value={product.rating} />
          <div className=" product-card__rating-legend">{`${product.reviews} Reviews`}</div>
        </div>
        {features}
      </div>
      <div className="product-card__actions">
        <div className="product-card__availability">
          Distance:
          <span className="text-success">
            {product.distance !== -1.0 && formatDistance(product?.distance)}
          </span>
        </div>
        {price}
        <div className="product-card__buttons">
          <Link
            to={url.product(product)}
            type="button"
            onClick={() => setPreTrigger(true)}
            className={classNames("btn btn-primary product-card__addtocart", {
              "btn-loading": false,
            })}
          >
            Book Tool
          </Link>
        </div>
      </div>
    </div>
  );
}

ProductFullWidthCard.propTypes = {
  /**
   * product object
   */
  product: PropTypes.object.isRequired,
  /**
   * product card layout
   * one of ['grid-sm', 'grid-nl', 'grid-lg', 'list', 'horizontal']
   */
  layout: PropTypes.oneOf([
    "grid-sm",
    "grid-nl",
    "grid-lg",
    "list",
    "horizontal",
  ]),
};

const mapStateToProps = () => ({});

const mapDispatchToProps = {
  cartAddItem,
  wishlistAddItem,
  compareAddItem,
  quickviewOpen,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ProductFullWidthCard);
