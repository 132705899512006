import {
  LOAD_PAYMENT_DETAILS,
  LOAD_USER_ADDRESS,
  LOAD_USER_ADDRESS_LIST,
  SET_ACCOUNT_BALANCE,
  SET_USER_LOCATION,
  UPDATE_ACCOUNT_BALANCE,
  UPDATE_NOTIFICATIONS,
  USER_LOGGED_IN,
} from "../types";
import { useContext } from "react";
import authContext from "../auth/authContext";

const addressReducer = (state, action) => {
  switch (action.type) {
    case LOAD_USER_ADDRESS_LIST:
      let addresses = action.payload?.addresses;
      let defaultAddress = action.payload?.defaultAddress;

      addresses?.map((address) => {
        address.isDefault = address.id === defaultAddress;
        return address;
      });

      addresses?.sort((a, b) => {
        if (a?.id === defaultAddress) {
          return 1;
        }

        if (b?.id === defaultAddress) {
          return 0;
        }

        return 0;
      });

      return { ...state, addresses: addresses };
    default:
      return state;
  }
};
export default addressReducer;
