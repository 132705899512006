import { REDIRECT_TO_TOOL } from "../types";

const authReducer = (state, action) => {
  switch (action.type) {
    case REDIRECT_TO_TOOL:
      return { ...state, redirectToTool: action.payload };

    default:
      return state;
  }
};
export default authReducer;
