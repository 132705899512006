import React, {useContext, useEffect} from "react";
import "../../scss/posttool/booking-success.scss";
import moment from "moment";
import { Link } from "react-router-dom";

import { ScreenSizeContext } from "../../context/context";
import { Image, Row, Col, Card, } from "react-bootstrap";

import AddressView from "./AddressView";
import ProductTabHowTo from "../shop/ProductTabHowTo";
import ProductTabs from "../shop/ProductTabs";


export default function BookingSuccess({ bookedTool }) {
  const getDateRange = () => {
    let value = bookedTool?.data;

    let bookingStartDate = value?.[0]?.getTime() ?? 1621288271;
    let bookingEndDate = value?.[1]?.getTime() ?? bookingStartDate;

    return (
        <>
          <b>{moment(new Date(bookingStartDate)).format("ddd, ll")}</b> to <b>{moment(
            new Date(bookingEndDate)
        ).format("ddd, ll")}.</b>
        </>
    );
  };

  const { setHideFooter } = useContext(ScreenSizeContext);

  useEffect(() => {
    setHideFooter(true);

    return () => {
      setHideFooter(false);
    };
  }, []);

  return (
    <div
        className="w-100 h-100 text-center pt-5"
    >
      {/* Banner image */}
      {/*<Row*/}
      {/*    style={{*/}
      {/*      background: `url(/images/about/drill-in-hand.jpg) no-repeat center center`,*/}
      {/*      height: "20rem",*/}
      {/*      objectFit: "cover",*/}
      {/*      width: "100vw",*/}
      {/*    }}*/}
      {/*>*/}
      {/*  <Col*/}
      {/*      className="text-white d-flex flex-col justify-content-center"*/}
      {/*      style={{ height: "100%", }}*/}
      {/*  >*/}
      {/*    <h3 className="text-capitalize my-auto mx-auto text-center">All your DIY needs under one roof</h3>*/}
      {/*  </Col>*/}
      {/*</Row>*/}

      <h1>Your tool has been booked!</h1>
      <div className="booking-sucess-tool-data">
        <Image
          src={bookedTool?.tool?.images?.[0]}
          className="booking-success-tool-image mb-3"
        />
        <Row>
          <Col>
            <p className="booking-success-thankyou">
              Thank your for placing your order
            </p>
            <p className="booking-success-booking-duration">
              You've booked a <b>{bookedTool?.tool?.name ?? "Tool"}</b> from{" "}
              {getDateRange()}
              <br/>
              Have a look at the <Link to="#guides"><b>guides
              </b></Link> below if you need some help in how to use your {bookedTool?.tool?.name ?? "Tool"}.
            </p>

            <p className="booking-success-duration">
              Chat with the tool owner to set up a time to pickup your {bookedTool?.tool?.name ?? "Tool"}.
            </p>
            <Link className="booking-success-nessage-button px-5 py-2" to={"/messages"}>
              Chat with renter
            </Link>
            <br />
            <div className="mt-2"><Link to={"/"}>Return to Homepage</Link></div>
          </Col>
        </Row>
        <br />
        <Row className="mt-3">
          <Col>
            <Card className="rounded">
              <Card.Body className="text-center p-4">
                <h6 className="text-capitalize">Get your tool from it's owner</h6>
                {/*{console.log("lalala", bookedTool?.tool)}*/}
                <p className="my-auto">{bookedTool?.tool?.userAddress?.addressLine1 ?? "Address Line 1"}</p>
                {
                  bookedTool?.tool?.userAddress?.addressLine2 &&
                  <p className="my-auto">{bookedTool?.tool?.userAddress?.addressLine2 ?? "Line 2"}</p>
                }
                <p className="my-auto">{`${bookedTool?.tool?.userAddress?.city ?? "City"} ${bookedTool?.tool?.userAddress?.postcode ?? "Post Code"}`}</p>
                <p className="my-auto">{`${bookedTool?.tool?.userAddress?.state ?? "Province"}`}</p>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </div>
      <Row id="giudes">
        <Col>
          <ProductTabs products={bookedTool?.tool} showReviews={false} withSidebar={false} />
        </Col>
      </Row>
    </div>
  );
}
