import { useForm, Controller } from "react-hook-form";
import authContext from "../../context/auth/authContext";
import React, { useCallback, useContext, useState } from "react";
import { Button, Form, InputGroup, Row } from "react-bootstrap";
import { Person } from "@material-ui/icons";
import LockIcon from "@material-ui/icons/Lock";
import EmailIcon from "@material-ui/icons/Email";

export default function AccountRecoverPage({
  confirmAccount,
  errors,
  control,
}) {
  const verifyAccountInformation = () => {
    return (
      <div>
        <div className="text-center">
          <p>
            We just sent you an email with a verification code. Please enter the
            code to verify your account
          </p>
        </div>

        <br />
        <Form.Group className="border">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="border-0 bg-white">
                <LockIcon className="text-black-50 text-sm" size={20} />
              </InputGroup.Text>
            </InputGroup.Prepend>

            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="number"
                  required
                  placeholder="Verification Code"
                  {...field}
                  isInvalid={!!errors.confirmCode}
                />
              )}
              name="confirmCode"
              rules={{
                required: "Please enter your verification code",
              }}
            />
            {errors && errors.confirmCode && (
              <Form.Control.Feedback type="invalid">
                {errors.confirmCode.message}
              </Form.Control.Feedback>
            )}
          </InputGroup>
        </Form.Group>
        <Form.Group className="border">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="border-0 bg-white">
                <LockIcon className="text-black-50 text-sm" size={20} />
              </InputGroup.Text>
            </InputGroup.Prepend>

            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="password"
                  required
                  placeholder="Password"
                  {...field}
                  isInvalid={!!errors.password}
                />
              )}
              name="password"
              rules={{
                required: "Please enter a secure password",
              }}
            />
          </InputGroup>
        </Form.Group>
        <Form.Group className="border">
          <InputGroup>
            <InputGroup.Prepend>
              <InputGroup.Text className="border-0 bg-white">
                <LockIcon className="text-black-50 text-sm" size={20} />
              </InputGroup.Text>
            </InputGroup.Prepend>

            <Controller
              control={control}
              render={({ field }) => (
                <Form.Control
                  className="border-0"
                  type="password"
                  required
                  placeholder="Confirm Password"
                  {...field}
                  isInvalid={!!errors.confirmPassword}
                />
              )}
              name="confirmPassword"
              rules={{
                required: "Please enter a secure password",
              }}
            />
          </InputGroup>
        </Form.Group>
      </div>
    );
  };

  if (confirmAccount) {
    return verifyAccountInformation();
  }

  return (
    <div>
      <br />
      <Form.Group className="border">
        <InputGroup>
          <InputGroup.Prepend>
            <InputGroup.Text className="border-0 bg-white">
              <EmailIcon className="text-black-50 text-sm" size={20} />
            </InputGroup.Text>
          </InputGroup.Prepend>

          <Controller
            control={control}
            render={({ field }) => (
              <Form.Control
                className="border-0"
                type="email"
                required
                placeholder="Email"
                {...field}
                isInvalid={!!errors.email}
              />
            )}
            name="email"
            rules={{
              required: "Please enter your email address",
            }}
          />
          {errors && errors.fname && (
            <Form.Control.Feedback type="invalid">
              {errors.fname.message}
            </Form.Control.Feedback>
          )}
        </InputGroup>
      </Form.Group>
    </div>
  );
}
