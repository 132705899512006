export default [
  {
    key: "bank",
    title: "Direct bank transfer",
    description:
      "Make your payment directly into our bank account. Please use your Order ID as the payment reference. " +
      "Your order will not be shipped until the funds have cleared in our account.",
  },

  {
    key: "paypal",
    title: "PayPal",
    description:
      "Pay via PayPal; you can pay with your credit card if you don’t have a PayPal account.",
  },
];
