import { useForm, Controller, useWatch } from "react-hook-form";
import { Alert, Button, Form, Image, Row } from "react-bootstrap";
import { CircularProgress, Fade } from "@material-ui/core";
import React, { useContext, useEffect, useRef, useState } from "react";
import Step1 from "./pages/step1";
import Step2 from "./pages/step2";
import imagePattern from "../../assets/imgs/pattern.png";
import Step3 from "./pages/step3";
import Step5 from "./pages/step5";
import Step4 from "./pages/step4";
import toolsContext from "../../context/tools/toolsContext";
import DataManager from "../../api/DataManager";
import showError from "../../api/Helper";
import PostingSuccess from "../shared/PostingSuccess";

import "../../scss/posttool/rent-user-tool.scss";
import { Link, useParams } from "react-router-dom";
import authContext from "../../context/auth/authContext";
import theme from "../../data/theme";
import { Helmet } from "react-helmet-async";
import BlockLoader from "../blocks/BlockLoader";
import UploadedImages from "../shared/UploadedImages";

export default function EditUserTool() {
  const {
    control,
    handleSubmit,
    clearErrors,
    register,
    setValue,
    formState: { errors, isSubmitted },
  } = useForm();
  const [sections, setSections] = useState([{}, {}, {}, {}]);
  const [currentSection, setCurrentSection] = useState(4);

  const [suggestedRate, setSuggestedRate] = useState(null);

  const [data, setData] = useState(null);

  const [isLoading, setIsLoading] = useState(null);

  const { allSubCategories } = useContext(toolsContext);

  const [postedTool, setPostedTool] = useState(null);

  const bottomRef = useRef();

  const { toolID } = useParams();

  const { getCustomerTool } = useContext(toolsContext);
  const { userData } = useContext(authContext);
  const [toolData, setToolData] = useState(null);

  const [uploadedImages, setUploadedImages] = useState();

  const removeImage = (index) => {
    let imageList = uploadedImages;
    imageList.splice(index, 1);
    setUploadedImages([...imageList]);
  };

  useEffect(() => {
    getCustomerTool(toolID).then((tool) => {
      setToolData(tool);
      console.log("Tool :  ", tool);
      console.log("Tool :  ", userData);
      setValue("name", tool?.name);
      setValue("desc", tool?.des);
      setValue("hourlyRate", tool?.price / 10);
      setValue("brand", tool?.brand);
      setValue("model", tool?.model);
      setValue("type", tool?.type ?? tool?.toolID);
      setValue("toolPrice", tool?.price);
      setValue("hourlyRate", (tool?.price ?? 0) / 10);

      // setValue("price", );
      setValue("condition", `${tool?.condition}`);
      setSuggestedRate(tool?.price);
      setUploadedImages(tool?.images);
    });
    //setValue("desc", "ss");
  }, [toolID]);

  const onSubmit = (values) => {
    console.log("values", values);

    // if (currentSection === 5) {
    publishTool(values);
    //   return;
    // }
    // setSections((sections) => [...sections, {}]);
    //
    // setCurrentSection(currentSection + 1);
    // setData(values);
    // clearErrors();
  };

  // useEffect(() => {
  //   bottomRef?.current?.scrollIntoView({ behavior: "smooth" });
  // }, [currentSection]);

  const publishTool = (values) => {
    setIsLoading(true);
    let id = allSubCategories?.filter((tool) => {
      return tool.name === values?.type;
    })?.[0]?.id;

    const address = values["address"];

    delete values["address"];

    const finalData = {
      ...values,
      toolID: id ?? "other",
      lat: address?.lat,
      long: address?.lng,
      addressID: address?.id,
      oldImages: toolData?.imageList,
    };

    DataManager.postTool(finalData, toolData?.id)
      .then((pTool) => {
        // console.log("THE POSTED TOOL", pTool);
        setPostedTool(pTool);
      })
      .catch((error) => {
        console.log(error);
        showError("An error occurred while posting tool");
      })
      .finally(() => {
        setIsLoading(false);
      });
    // console.log("tool finalData");
    // console.log(finalData);
  };

  const getPage = (num) => {
    switch (num) {
      case 0:
        return {
          data: (
            <Step1
              onSelectModel={(price) => setSuggestedRate(price)}
              control={control}
              errors={errors}
              register={register}
              data={toolData}
            />
          ),
          title: "Tell us about your tool",
        };
      case 1:
        return {
          data: (
            <>
              <UploadedImages
                images={uploadedImages}
                removeImage={removeImage}
              />
              <Step2
                control={control}
                errors={errors}
                required={uploadedImages?.length === 0}
              />
            </>
          ),
          title: "Upload Images",
        };
      case 2:
        return {
          data: (
            <Step3
              control={control}
              errors={errors}
              register={register}
              suggestedToolPrice={suggestedRate}
              setValue={setValue}
            />
          ),
          title: "Set a daily rate for your tool",
        };
      case 3:
        return {
          data: <Step4 control={control} errors={errors} setValue={setValue} />,
          title: "Select your tool's location",
        };
      case 4:
        return {
          data: <Step5 data={data} />,
          title: "Review your information",
        };

      default:
        return;
    }
  };

  if (toolData === null) {
    return <BlockLoader />;
  }

  return (
    <div
      className="pt-5 pb-0 bg-white"
      style={{
        backgroundImage: `url(${imagePattern})`,
        backgroundRepeat: "no-repeat",
      }}
    >
      <Helmet>
        <title>{`Edit Tool — ${theme.name}`}</title>
      </Helmet>

      <h3 className="text-primary text-shadow font-weight-bold mx-auto text-center">
        Edit {toolData?.name}
      </h3>
      <br />
      <div className="post-tool-containerr p-4 my-16 shadow-lg mx-0 mx-sm-auto mb-4 bg-white">
        <Form
          className="w-100 d-flex flex-column align-items-center"
          onSubmit={handleSubmit(onSubmit)}
        >
          {sections.map((num, index) => {
            let section = getPage(index);
            return (
              <div key={index} ref={bottomRef} className="w-100">
                <Fade in={true} timeout={1000}>
                  <section className="post-tool-user-section w-100 my-3">
                    <div className="post-tool-user-section-header d-flex flex-row justify-content-between">
                      <h6 className="post-tool-user-section-heading post-tool-user-section-header-item">
                        {section?.title}
                      </h6>
                      <h6 className="post-tool-user-section-header-item post-tool-user-section-step ml-auto">{`Step ${
                        index + 1
                      }`}</h6>
                    </div>

                    {section?.subtitle && (
                      <h1 className="post-tool-user-section-subheading post-tool-user-section-heading ">
                        {section?.subtitle}
                      </h1>
                    )}
                    <div className="mt-3 post-tool-user-section-main">
                      {section?.data}
                    </div>
                  </section>
                </Fade>
              </div>
            );
          })}
          <div />
          {currentSection === 5 && (
            <Form.Group className="w-100 text-left d-flex flex-row">
              <Controller
                control={control}
                render={({ field }) => (
                  <Row className="ml-2">
                    <Form.Check {...field} />
                    By uploading a tool, you agree to Toology's{" "}
                    <Link
                      to="/rental-policy"
                      target="_blank"
                      style={{ marginLeft: "1ch" }}
                    >
                      Rental Policy
                    </Link>
                    .
                  </Row>
                )}
                name="accepted"
                rules={{
                  required: "Please accept the terms and conditions to proceed",
                }}
              />
              {errors && errors.accepted && (
                <Alert variant="danger" type="invalid">
                  {errors.accepted.message}
                </Alert>
              )}
            </Form.Group>
          )}
          <Button type={"submit"} className=" mx-auto ml-auto mb-2">
            {isLoading && (
              <span
                className="spinner-border spinner-border-sm"
                role="status"
                aria-hidden="true"
              />
            )}
            {!isLoading && (currentSection === 5 ? "Publish" : "Update")}
          </Button>
        </Form>
      </div>
    </div>
  );
}
