import React, { useEffect, useState } from "react";
import { Form, Row, Col, } from "react-bootstrap";
import { Link } from "react-router-dom";

import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';

import '../../scss/terms/rental-terms-conditions.scss';

export default function RentalTermsConditions({ onAgree }) {
  const [enableAgree, setEnableAgree] = useState(false);
  const [agreed, setAgreed] = useState(false);

  useEffect(() => {
    onAgree(agreed);
  }, [agreed]);

  return (
    <div className="mb-3">
      <h6>Please carefully review the rental agreement</h6>
      <Row className="ml-1 pt-1">
        <Col>
          <Row>
            <InfoOutlinedIcon fontSize="small" />
            <p style={{ marginLeft: "1ch", }}>Scroll to agree</p>
          </Row>
        </Col>
      </Row>
      <div
        className="overflow-auto border"
        style={{ minHeight: "200px", maxHeight: "200px", overflowX: "hidden", }}
      >
        <div className="py-5 px-5">
          <div className="document__header">
            <h1 className="document__title">Rental Agreement</h1>
            <div className="document__subtitle">
              This Agreement was last modified on 17 June 2021.
            </div>
          </div>
          <div className="document__content typography">
            <ol>
              <li>
                <span>
                  <h5>General: </h5>
                  <p>This Agreement is between K Squared Tech Inc, parent company of Toology.ca, herein after referred as “<strong>Toology</strong>”, and the customer identified on the Invoice ("<strong>Renter</strong>"). Toology will enable the Renter to receive the tool(s) identified in the Invoice from the owner of the tool, (“<strong>Tool Owner</strong>”), in accordance with the terms set forth in this Agreement. Tool Owner will provide the Tool "as is" and in good working condition during the rental period (the "<strong>Rental Period</strong>") and according to the rental subtotal price identified on the Invoice. Renter agrees to return the Tool(s) to the Tool Owner prior to the expiration of the Rental Period, or to pay the Additional Rental Fee described in Clause-8. </p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Payment: </h5>
                  <p>Renter is responsible for all charges due in full at the time of booking at toology.ca, using a payment method available at Toology.ca. Renter consents to the reservation of credit for estimated charges due, and authorizes Toology to process all amounts due, on Renter Card. Renter also authorizes Toology to charge his/her credit card for Additional Rental Fees and/or ‘damage charges’, if applicable, as described in Clause-8 and Clause-10. </p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Tool Inspection at Pickup: </h5>
                  <p>Renter confirms that Renter will inspect the tool before pick up and will discuss with the Tool Owner any obvious faults/defects, if any. Renter is strongly encouraged to take pictures of the tool from different angles at the time of the pickup to avoid any disputes later.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Tool Pickup Failure: </h5>
                  <p>In the event that Renter is not able to pick up the tool at specified date and time due to Tool Owner being absent or any other fault of the Tool Owner, Renter will be refunded in full.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Booking Cancellation: </h5>
                  <p>In the event that Renter cancels the booking prior to 24 hours of agreed pickup time, Renter will be reimbursed the rental fee, however, handling and processing fee will be deducted. In the event that Renter cancels the booking within 24 hours of the scheduled pickup time, a cancellation fee of amount equal to first day rent will be deducted and rest of the booking amount will be reimbursed, if any, minus the handling and processing fee. </p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Tool Return: </h5>
                  <p>Upon completion of Rental Period, Renter will return the tool, in as good order and condition as when received, to location where the tool was picked up, unless otherwise agreed with tool owner. Moreover, if the tool is not returned before expiration of Rental Period, Renter will be charged an “Additional Rental Fee” as described in Clause-8.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Rental Period Extension: </h5>
                  <p>on completion of Rental Period, Renter will return the tool, in as good order and condition as when received, to location where the tool was picked up, unless otherwise agreed with tool owner. Moreover, if the tool is not returned before expiration of Rental Period, Renter will be charged an “Additional Rental Fee” as described in Clause-8.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Additional Rental Fee: </h5>
                  <p>Should Renter fail to return the Tool prior to the expiration of the Rental Period, Renter will be in default of this Agreement. Renter will be charged the first day rent on a daily recurring basis ("<strong>Additional Rental Fee</strong>") until the tool is returned or Toology has received the penalty equivalent to the fair market value of the tool. The fair market value of the tool will be solely determined by Toology. </p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Disputes: </h5>
                  <ol type="a">
                    <li>
                      <span>
                        <h5>Payment Disputes:</h5>
                        <p>Renter must notify Toology in writing of any payment disputes, within thirty (30) days of transaction, or Renter will be deemed to have irrevocably waived its right to dispute such amounts. </p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <h5>Tool Related Disputes:</h5>
                        <p>Renter is encouraged to report disputes related to tool pickup failure, tool not being in workable condition, tool not as shown on the listing, or any other dispute using Toology.ca ‘Disputes’ page. Renter is encourages to use the pictures of the tool for dispute resolution to support the claim.  </p>
                      </span>
                    </li>
                  </ol>
                </span>
              </li>
              <li>
                <span>
                  <h5>Tool Damage/ Protection/ Repair:</h5>
                  <ol type="a">
                    <li>
                      <span>
                        <h5>Tool Damage:</h5>
                        <p>Regardless of fault, Renter is responsible for all loss of and damage to tools (including loss or damage due to normal use, caused by theft, abuse, misuse, neglect, or intentional acts). If the tool is damaged due to any cause during the Rental period, Renter will be charged ‘fair market value’ of the tool, solely determined by Toology. Renter hereby authorizes Toology to charge the payment card on file to recover the fair market value of the tool. </p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <h5>Damage Protection</h5>
                        <p>Damage protection is an optional service offered by Toology that, if selected, relieves Renter of repair charges, replacement charges and Administrative Charges if the tool is damaged during <strong>normal use</strong> during the Rental Period ("<strong>Damage Protection</strong>"). In the event Renter elects Damage Protection services, the charge for Damage Protection is 15% of the Rental Price and will appear as a separate line-item on the Agreement and on the invoice. Renter must accept or decline the Damage Protection. <span className="border-bottom border-dark">Damage Protection is not insurance.</span> Damage Protection does not cover loss of or damage to the tool caused by theft, abuse, neglect, misuse, or intentional acts. Renter expressly acknowledges and agrees that Renter will be responsible for all loss or damage.</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <h5>Repairs:</h5>
                        <p>In the event Renter does not elect to purchase Damage Protection, Renter agrees that an estimated "<strong>Repair Cost</strong>" will be charged for tools that is returned with damage due to abuse, misuse, neglect, or intentional acts. The Repair Cost will not exceed the fair market value of the tool and applicable fees. Renter acknowledges and agrees to pay the Repair Cost and authorizes Toology to charge the Repair Cost to the Renter Card. </p>
                      </span>
                    </li>
                  </ol>
                </span>
              </li>
              <li>
                <span>
                  <h5>Permitted Use/ Restrictions/ Maintenance</h5>
                  <p>Renter agrees that Toology has no control over the manner in which the tool is operated during the Rental Period. Renter warrants that:</p>
                  <ol type="a">
                    <li>
                      <span>
                        <p>Renter will inspect the tool to confirm that it is in good condition, without defects and is suitable for Renter's intended use;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter has received all information requested regarding the operation of the tool;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Toology/ Tool Owner is not responsible for providing operator or other training;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will use the Tool for its customary purpose, in compliance with all operating and safety instructions and immediately discontinue use of the tool if it becomes unsafe or in a state of disrepair;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>The tool will be kept in a secure location;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will maintain possession of the tool and will not sublease or sell the tool or assign this Agreement;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will keep the tool free and clear of all liens, charges and encumbrances;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will use the tool in compliance with all applicable laws and regulations;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will not alter or cover up any decal or insignia on the tool or remove any operational or safety instructions;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will not aid in the vandalism or theft of the tool;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will not rent the tool upon the basis of false or misleading information;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will not operate the tool while intoxicated or under the influence of any substance that impairs Renter's ability;</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will not use the tool in a negligent, illegal, unauthorized or abusive manner, or in any publication; and</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will not remove, operate, or utilize the tool outside of Canada.</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter will maintain proper fuel, oil or lubrication levels in the tool, if applicable; and</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>Renter further agrees to perform routine inspections on the tool, including inspections on leaks, cooling systems, water batteries, cutting edges, and cleaning in accordance with the manufacturer's specifications, if applicable.</p>
                      </span>
                    </li>
                  </ol>
                </span>
              </li>
              <li>
                <span>
                  <h5>Incident Reporting and Investigations:</h5>
                  <p>If the tool is involved in an incident while in the passion of the Renter, Renter is solely responsible for the incident and any incident has no obligation or liability to Toology or the Tool Owner. However, Renter agrees to share the incident report / progress / conclusion with Toology for the record. Toology or Tool Owner will not conduct any investigation of misuse of the tool or incident involving the tool. All liability lies with the Renter.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Limitation of Liability</h5>
                  <p>During the Rental Period, Renter assumes all risks associated with and full responsibility for the possession, custody and operation of the tools, including, but not limited to, rental charges, renter transport, loading and unloading, property damages and destruction, losses, personal injury, and death. Renter expressly assumes all risk of damage to the tool as provided in Clause-10. Failure to return the tool will lead to Renter being charged ‘additional rental fee’ and possible legal action.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>No Warranties</h5>
                  <p>Toology/Tool Owner does not design or manufacture the tool and is not the agent of the manufacturer or any other supplier of the tool. Toology disclaims all representations and warranties, express or implied, with respect to the tool, its durability, condition, merchantability, or fitness for any particular purpose. Renter acknowledges acceptance of the tool "as is" and on a "where is" basis, with "all faults" and without any recourse whatsoever against Toology or Tool owner.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Indemnification</h5>
                  <ol type="a">
                    <li>
                      <span>
                        <p>Except as otherwise provided for in this agreement, to the fullest extent permitted by law, Renter herby indemnifies, releases, and holds Toology and the Tool Owner harmless from and against all claims, losses, expenses (including reasonable attorney’s fees and expenses), liabilities and damages (including personal injury, death, property damage, ost profits, and special, incidental and consequential damages) arising out of (i) all claims against Toology and Tool Owner arising out of Renter’s operation of the tool (ii) All claims against Toology and Tool Owner arising out of Renter’s failure to comply with all terms of this agreement. Renter’s indemnity obligation will survive the expiration of termination of this agreement. If any part of this section/clause is determined invalid by a court of competent jurisdiction, Renter agrees that this clause will be enforceable to the fullest extent permitted by law. To the extent that Renter’s state does not allow the exclusion or limitation of incidental or consequential damages, such limitation may not apply.</p>
                      </span>
                    </li>
                    <li>
                      <span>
                        <p>
                          Notwithstanding any other provision in the agreement to the contrary, Toology and Tool Owner will not be liable to Renter, and Renter waives any claim against Toology and Tool Owner for lost use, lost rofit, lost revenue, lost savings, lost reputation, loss of productivity, indirect, incidental, special, punitive or consequential damages related to the rental, even if such damages were foreseeable or result from a breach of this agreement.
                        </p>
                      </span>
                    </li>
                  </ol>
                </span>
              </li>
              <li>
                <span>
                  <h5>Breach of Terms and conditions;</h5>
                <p>In the event that Toology determines Renter has violated any term or condition of this Agreement, Toology may take all action necessary to secure either:</p>
                <ol type="i">
                  <li>
                    <span>
                      <p>the return of the Tool; or</p>
                    </span>
                  </li>
                  <li>
                    <span>
                      <p>funds equal to the Faire Market Value. In the event Toology must take actions pursuant to this agreement against Renter, Renter will reimburse Toology for all costs incurred including, without limitation, reasonable attorney's fees. Renter expressly agrees and hereby authorizes Toology to charge to the Renter Card, all amounts shown on invoice, and all charges subsequently incurred by Renter under or related to this Agreement, including but not limited to:</p>
                      <ol type="A">
                        <li>
                          <span>
                            <p>extension of the Rental Period;</p>
                          </span>
                        </li>
                        <li>
                          <span>
                            <p>any charges incurred in connection with the recovery of the Tool; </p>
                          </span>
                        </li>
                        <li>
                          <span>
                            <p>any charges incurred for failure to return the tool, including, without limitation, additional rental fees or Fair market value of the tool (less any paid additional rental fees). </p>
                          </span>
                        </li>
                      </ol>
                    </span>
                  </li>
                </ol>
                </span>
              </li>
              <li>
                <span>
                  <h5>Notices:</h5>
                  <p>oology may elect to send any notices to Renter by any means determined by Toology. In particular, if Renter has provided Toology with an email address, Toology may send notices to Renter by email and such email notice by Toology will be valid notices for purposes of this Agreement.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Renter's Independent Status:</h5>
                  <p>The relationship between the Parties under the Agreement is that of independent contractors. Renter is not the agent or authorized representative of Toology or Tool Owner for any purpose.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Governing law:</h5>
                  <p>This Agreement will be construed in accordance with the laws of the Province of Ontario, Canada.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Severability:</h5>
                  <p>If any provision of the Agreement is held to be invalid or unenforceable for any reason, the remaining provisions will continue to be valid and enforceable. If any court finds that any provision of this Agreement is invalid or unenforceable, but by limiting such provision it would be valid and enforceable, then such provision will be deemed to be written, construed, and enforce as so limited.</p>
                </span>
              </li>
              <li>
                <span>
                  <h5>Entire Agreement:</h5>
                  <p>This Agreement represents the entire agreement between Toology and Renter. This Agreement may not be amended or modified except in writing signed by both parties. This Agreement supersedes any prior written or oral agreements between the parties.</p>
                </span>
              </li>
            </ol>
          </div>
        </div>
        <Row className="mx-3 d-flex flex-row">
          <Form.Check
              // disabled={enableAgree}
              value={agreed}
              onChange={(e) => {
                setAgreed(e.target.checked);
              }}
          />
          <p>By renting a tool, I agree to Toology's rental agreement.</p>
        </Row>
      </div>
    </div>
  );
}
