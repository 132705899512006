// react
import React, { useContext, useEffect, useState } from "react";

// third-party
import classNames from "classnames";
import { Link, matchPath, Redirect, Switch, Route } from "react-router-dom";

// components
import AddPaymentMethod from "./AddPaymentMothod";
import ConfirmWithdrawModal from "./ConfirmWithdrawModal";

// scss
import "../../../scss/payouts/GetPaid.scss";
import authContext from "../../../context/auth/authContext";
import { PaymentService } from "../../../api/PaymentService";
import showError, { showSuccess } from "../../../api/Helper";
import PaypalWithdrawalModal from "./PaypalWithdrawal";

// Context

export default function GetPaid() {
  const { user, balance, loadUserBillingInfo, billingInfo } = useContext(
    authContext
  );

  const [setupPaymentMethod, setSetupPaymentMethod] = useState(false);
  const [showConfirmWithdraw, setShowConfirmWithdraw] = useState(false);
  const [showPaypalModal, setShowPaypalModal] = useState(false);

  useEffect(() => {
    loadUserBillingInfo();
  }, []);

  const sendPayment = () => {
    if (user?.stripeCustomerID === null) {
      setSetupPaymentMethod(true);
      return;
    }

    PaymentService.getPaid(balance)
      .then(() => {
        showSuccess("Payment sent");
      })
      .catch((error) => {
        showError("Payment sent failed");
      });
  };

  return (
    <div className="get-paid-root-container">
      <h4>Get Paid</h4>
      <div className="rove-card card-shadow">
        <h5>Balance</h5>

        <hr />

        <div className="balance-card-information">
          <p>
            {`Your balance is `} <b>{`$${balance.toFixed(2)}`}</b>
          </p>

          <button
            disabled={balance === 0}
            className="btn get-paid-button"
            onClick={() => {
              setSetupPaymentMethod(true);
            }}
          >
            Withdraw
          </button>
        </div>
      </div>

      {/*<div className="rove-card card-shadow">*/}
      {/*  <h5>Payment Details</h5>*/}

      {/*  <div className="payment-details-card-information">*/}
      {/*    /!*<hr />*!/*/}
      {/*    <div className="get-paid-methods">*/}
      {/*      <div className="row">*/}
      {/*        <hr />*/}
      {/*        <div className="col-9 col-lg-8 col-12 align-middle">*/}
      {/*          <p>*/}
      {/*            <b>Last Payment</b>*/}
      {/*            <br />*/}
      {/*            <span className="card-extra-information">{`$($AMOUNT) on June 3, 2020 to $PAYMENT_METHOD`}</span>*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex justify-content-end col-3 col-lg-4 col-12 align-middle">*/}
      {/*          <button*/}
      {/*            disabled={balance === 0}*/}
      {/*            className="btn btn-sm get-paid-button"*/}
      {/*            onClick={() => {}}*/}
      {/*          >*/}
      {/*            View Payments*/}
      {/*          </button>*/}
      {/*        </div>*/}
      {/*      </div>*/}

      {/*      <div className="row">*/}
      {/*        <hr />*/}
      {/*        <div className="col-9 col-lg-8 col-12 align-middle">*/}
      {/*          <p>*/}
      {/*            <b>Payment Methods</b>*/}
      {/*            <br />*/}
      {/*            <span className="card-extra-information">{`$($AMOUNT) on June 3, 2020 to $PAYMENT_METHOD`}</span>*/}
      {/*          </p>*/}
      {/*        </div>*/}

      {/*        <div className="d-flex justify-content-end col-3 col-lg-4 col-12 align-middle">*/}
      {/*          <button*/}
      {/*            className="btn btn-sm get-paid-button"*/}
      {/*            onClick={() => {*/}
      {/*              sendPayment();*/}
      {/*            }}*/}
      {/*          >*/}
      {/*            Add Method*/}
      {/*          </button>*/}
      {/*        </div>*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
      <PaypalWithdrawalModal
        show={showPaypalModal}
        onClose={() => {
          setShowPaypalModal(false);
        }}
      />

      <AddPaymentMethod
        bankAccountLinked={billingInfo?.bankAccountLinked}
        paypalLinkedEmail={billingInfo?.paypalLinkedEmail}
        show={setupPaymentMethod}
        onClose={() => {
          setSetupPaymentMethod(false);
        }}
        confirmWithdraw={() => {
          setShowConfirmWithdraw(true);
        }}
        setupPaypal={() => {
          setSetupPaymentMethod(false);

          setShowPaypalModal(true);
        }}
      />

      <ConfirmWithdrawModal
        show={showConfirmWithdraw}
        onClose={() => {
          setShowConfirmWithdraw(false);
        }}
      />
    </div>
  );
}
