import moment from "moment";
import { toast } from "react-toastify";
import DataManager from "./DataManager";
import CheckFilterBuilder from "../fake-server/filters/check";
import RangeFilterBuilder from "../fake-server/filters/price";
import CategoryFilterBuilder from "../fake-server/filters/category";
import React from "react";

export function parseAmplifyConfig(awsConfig) {
  const isLocalhost = Boolean(
    window.location.hostname === "localhost" ||
      // [::1] is the IPv6 localhost address.
      window.location.hostname === "[::1]" ||
      // 127.0.0.1/8 is considered localhost for IPv4.
      window.location.hostname.match(
        /^127(?:\.(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)){3}$/
      )
  );

  // Assuming you have two redirect URIs, and the first is for localhost and second is for production
  const [productionRedirectSignIn, localRedirectSignIn] =
    awsConfig.oauth.redirectSignIn.split(",");

  const [productionRedirectSignOut, localRedirectSignOut] =
    awsConfig.oauth.redirectSignOut.split(",");

  return {
    ...awsConfig,
    oauth: {
      ...awsConfig.oauth,
      redirectSignIn: isLocalhost
        ? localRedirectSignIn
        : productionRedirectSignIn,
      redirectSignOut: isLocalhost
        ? localRedirectSignOut
        : productionRedirectSignOut,
    },
  };
}

export function toTitleCase(str) {
  return str.replace(/\w\S*/g, function (txt) {
    return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase();
  });
}

export function getMaxDate() {
  let d = new Date();
  let year = d.getFullYear();
  let month = d.getMonth();
  let day = d.getDate();
  let c = new Date(year, month + 12, day);

  return c;
}

export function conditionToText(condition) {
  return `${
    condition === 1
      ? `Poor`
      : condition === 2
      ? `Average`
      : condition === 3
      ? `Perfect`
      : `N/A`
  }`;
}

export function getNumberOfDaysFromDate(date1, date2) {
  // console.log(date1)
  //console.log(date2)

  let Difference_In_Time = date2.getTime() - date1.getTime();
  return Difference_In_Time / (1000 * 3600 * 24);
}

export function dateToFromNowDaily(myDate) {
  // get from-now for this date
  let fromNow = moment(myDate).fromNow();

  // ensure the date is displayed with today and yesterday
  return moment(myDate).calendar(null, {
    // when the date is closer, specify custom values
    lastWeek: "dddd, hh:mm a",
    lastDay: "[Yesterday], hh:mm a",
    sameDay: "[Today], hh:mm a",
    nextDay: "[Tomorrow]",
    nextWeek: "dddd",
    // when the date is further away, use from-now functionality
    sameElse: function () {
      return "[" + fromNow + "]";
    },
  });
}

export default function showError(error, autoClose = true, timeout = 2500) {
  return toast.error(error, {
    position: "top-center",
    autoClose: autoClose ? timeout : false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
}

export function showSuccess(success, autoClose = true, timeout = 2500) {
  return toast.success(success, {
    position: "top-center",
    autoClose: autoClose ? timeout : false,
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: false,
    progress: undefined,
  });
}

export function pad_with_zeroes(number, length) {
  var my_string = "" + number;
  while (my_string.length < length) {
    my_string = my_string + "0";
  }

  return my_string;
}

export function toDay(day) {
  switch (day) {
    case 1:
      return "1 day";
    case 3:
      return "3 days";
    case 7:
      return "7 days";
    case 14:
      return "14 days";
    case 30:
      return "30 days";
    case 60:
      return "60 days";
    default:
      return;
  }
}

export function formatDistance(distance) {
  distance = parseFloat(distance);
  // console.log("Tool Distance", distance);

  if (distance < 100) {
    return `${distance?.toFixed(1)} km`;
  }
  if (distance < 200) {
    return "100+ km";
  }

  if (distance < 500) {
    return "200+ km";
  }
  return "500+ km";
}

export function truncate(text, len) {
  if (text?.length > len) {
    return text?.slice(0, len) + "...";
  }
  return text;
}

export async function toolPriceCalculator(
  suggestedPrice,
  userPrice,
  specificDays
) {
  let factors = await DataManager.pricingData();

  let days = specificDays ?? [1, 3, 14, 30, 60];
  // let suffix = ["day", "days", "days", "days"];
  let priceList = [];

  days.forEach((day) => {
    let factor = factors?.[day - 1]?.factor ?? 1;
    let rate = (suggestedPrice ?? 0) * factor;
    let userRate = (userPrice ?? suggestedPrice ?? 0) * factor;
    priceList.push({
      day: day + " day(s)",
      rate: rate.toFixed(1),
      userRate: userRate,
    });
  });

  return priceList;
}

export async function calculateToolPrice(hourlyRate, day = 0) {
  let factors = await DataManager.pricingData();
  let factor = factors?.[day]?.factor ?? 1;
  return hourlyRate / factor;
}

export async function calculateRentPrice(hourlyRate, day = 0) {
  let factors = await DataManager.pricingData();
  let factor = factors?.[day - 1]?.factor ?? 1;
  console.log("FACTOR: ", factor);
  return hourlyRate * factor;
}

export function applyPromoCode(promoCodeData, daysRented, toolRentPrice) {
  let discount;
  switch (promoCodeData.discountType) {
    case "DAYS":
      discount =
        (parseFloat(toolRentPrice) / daysRented) * promoCodeData.discount; //in this case promoCodeData.discount is number of days off the price
      break;

    case "NUMERICAL_AMOUNT":
      discount = promoCodeData.discount;
      break;

    case "PERCENTAGE":
      discount = (toolRentPrice * parseFloat(promoCodeData.discount)) / 100;
      break;
  }
  //1a24fc

  console.log("discount", discount);

  if (promoCodeData.maxDiscount && discount > promoCodeData.maxDiscount) {
    discount = promoCodeData.maxDiscount;
  }

  if (discount > toolRentPrice) {
    discount = toolRentPrice;
  }

  return discount;
}
export function formatDate(date) {
  return moment(date).format("MM/DD/YYYY");
}

export function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

export async function calculateToolRate(hourlyRate, day = 0) {
  let factors = await DataManager.pricingData();
  let factor = factors?.[day]?.factor ?? 1;
  return (hourlyRate * factor).toFixed(2);
}

export async function getProductsList(
  items,
  options = {},
  filterValues = {},
  withCategories = false
) {
  let filters = [
    new CheckFilterBuilder("brand", "Brand"),
    new RangeFilterBuilder("price", "Price"),
    new RangeFilterBuilder("distance", "Distance"),
  ];
  if (withCategories) {
    filters = [new CategoryFilterBuilder("category", "Categories"), ...filters];
  }

  // console.log("options");
  // console.log(options);

  filters.forEach((filter) =>
    filter.makeItems(items, filterValues[filter.slug])
  );

  // console.log("ITEMSX");
  // console.log(items);

  // Calculate items count for filter values.
  // filters.forEach((filter) => filter.calc(filters));

  // Apply filters to products list.
  items = items.filter((product) =>
    filters.reduce((mr, filter) => mr && filter.test(product), true)
  );

  // console.log("ITEMS " + items?.length);

  const page = options.page || 1;
  const limit = options.limit || 12;
  const sort = options.sort || "default";
  const total = items.length;
  const pages = Math.ceil(total / limit);
  const from = (page - 1) * limit + 1;
  const to = Math.max(Math.min(page * limit, total), from);

  items = items.sort((a, b) => {
    if (["name_asc", "name_desc"].includes(sort)) {
      if (a.name === b.name) {
        return 0;
      }

      return (a.name > b.name ? 1 : -1) * (sort === "name_asc" ? 1 : -1);
    }

    return 0;
  });

  const start = (page - 1) * limit;
  const end = start + limit;

  items = items.slice(start, end);

  return new Promise((resolve) => {
    resolve({
      page,
      limit,
      sort,
      total,
      pages,
      from,
      to,
      items,
      filters: filters.map((x) => x.build()),
    });
  });
}
