import ImageUpload from "../../shared/ImageUpload";
import React, { useContext, useEffect, useState } from "react";
import { FormGroup } from "reactstrap";

import { Controller } from "react-hook-form";
import { Alert, Form } from "react-bootstrap";
import AddressView from "../../shared/AddressView";
import authContext from "../../../context/auth/authContext";
import AccountPageEditAddress from "../../account/AccountPageEditAddress";
import AccountPageAddresses from "../../account/AccountPageAddresses";
import addressContext from "../../../context/address/addressContext";
export default function Step4({ control, errors, setValue }) {
  const { addresses } = useContext(addressContext);

  const [selectedAddress, setSelectedAddress] = useState(addresses?.[0]);

  useEffect(() => {
    setSelectedAddress(addresses?.[0]);
    setValue("address", addresses?.[0]);
  }, [addresses]);

  return (
    <FormGroup>
      <p className="text-black-100  font-weight-bold">
        {selectedAddress?.addressLine1 != null &&
          "We have the following address on file"}
        {selectedAddress?.addressLine1 == null && "Add an address below"}
      </p>
      <Controller
        control={control}
        name="address"
        defaultValue={selectedAddress}
        render={({ field: { onChange, onBlur, value, ref } }) => (
          // <h6>We have the following address for you</h6>

          <AccountPageAddresses
            onSelect={(id) => {
              // console.log("ID", id);

              const address = addresses?.filter((a) => {
                return a?.id === id;
              })?.[0];

              onChange(address);
              setSelectedAddress(address);
              // console.log(`selected address: ${JSON.stringify(selectedAddress)}`)
            }}
          />
        )}
        rules={{
          required: "Please select the address of your tool",
        }}
      />
      {selectedAddress === null && errors && errors.address && (
        <Alert variant="danger">{errors.address.message}</Alert>
      )}
    </FormGroup>
  );
}
