import {
  categoriesListData,
  categoriesTreeData,
  prepareCategory,
} from "../database/categories";
import AbstractFilterBuilder from "./abstract";
import { CachingService } from "../../api/CachingService";

export default class CategoryFilterBuilder extends AbstractFilterBuilder {
  value = null;

  items = [];

  // eslint-disable-next-line class-methods-use-this,no-unused-vars
  test(product) {
    if (this.value === null) {
      return true;
    }

    return true;
  }

  makeItems(products, value) {
    this.value = value || null;

    let categories =
      CachingService.categories?.data?.listCategorys?.items ?? [];

    const category = categories.find((x) => x.name === value);
    // const categoryHasProductsFn = (x) => categoryHasProducts(x, productsData);
    const categoryHasProductsFn = () => true;

    if (category) {
      this.items = [prepareCategory(category, 1)].map((x) => ({
        ...x,
        children: x.children.filter(categoryHasProductsFn),
      }));
    } else {
      this.items = categories
        .map((x) => prepareCategory(x))
        .filter(categoryHasProductsFn);
    }
  }

  // eslint-disable-next-line class-methods-use-this
  calc() {}

  build() {
    return {
      type: "category",
      slug: this.slug,
      name: this.name,
      items: this.items,
      value: this.value,
    };
  }
}
