import React from "react";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core/styles";
import { InputBase } from "@material-ui/core";

export default function AutoCompleteField({
  changeText,
  queryOptions,
  getValue,
  defaultValue,
  placeHolder,
  didSelect,
  icon,
  textFieldProps,
  innerProps,
  userOptions,
  inputRef,
  onChange,
  hideArrow,
  defaultTextValue,
  insertable = false,
}) {
  const [value, setValue] = React.useState(defaultValue?.[0]);
  const [inputValue, setInputValue] = React.useState("");
  const [options, setOptions] = React.useState(defaultValue);

  React.useEffect(() => {
    // setValue()
    setInputValue(changeText);
  }, [changeText]);

  React.useEffect(() => {
    setOptions(defaultValue);
  }, [defaultValue]);

  React.useEffect(() => {
    queryOptions(inputValue, (values) => {
      setOptions(values);
    });
  }, [value, inputValue]);

  return (
    <Autocomplete
      freeSolo={hideArrow ?? false}
      ref={inputRef}
      style={{ width: 300, ...textFieldProps }}
      getOptionLabel={(option) => getValue(option)?.[0]}
      disableClearable
      filterOptions={(x) => x}
      options={userOptions ?? options}
      autoComplete
      includeInputInList
      filterSelectedOptions
      value={value}
      clearOnBlur={!insertable}
      onChange={(event, newValue) => {
        // console.log("NEW VALUE", newValue);

        setOptions(newValue ? [newValue, ...options] : options);
        setValue(newValue);
        didSelect(newValue, event);
      }}
      onInputChange={(event, newInputValue) => {
        setInputValue(newInputValue);
      }}
      renderInput={(params) => {
        const { InputLabelProps, InputProps, ...rest } = params;

        return (
          <InputBase
            // required
            inputProps={{ disableUnderline: true, ...textFieldProps }}
            {...params.InputProps}
            {...rest}
            style={{ fontSize: "0.9rem", ...innerProps }}
            fullWidth
            onBlur={(e) => {
              if (insertable) {
                e.preventDefault();
              }
            }}
            placeholder={placeHolder}
            onChange={(e) => {
              const text = e.target.value;
              let newValue = { name: text, id: "Other" };
              if (insertable) {
                const firstOption = options?.[0];
                // alert("a");

                if (
                  firstOption?.name?.toLowerCase() === text?.toLowerCase() ||
                  firstOption?.zipcode?.toLowerCase() === text?.toLowerCase()
                ) {
                  newValue = firstOption;
                  // alert("Found re");
                }

                // setOptions(newValue ? [newValue, ...options] : options);
                // setInputValue(text);
                didSelect(newValue, e);
                // setValue(newValue);
              }
            }}
          />
        );
      }}
      renderOption={(option) => {
        return (
          <div
            className="d-flex flex-row align-items-center"
            onClick={(e) => {
              didSelect(option, e);
            }}
          >
            <div>{icon && icon}</div>
            <div className="my-auto">
              <div style={{ fontWeight: 400, fontSize: "0.8rem" }}>
                {getValue(option)?.[0] ?? ""}
                {getValue(option)?.[0]?.length > 0 ? <br /> : ""}
                {getValue(option)?.[1] ?? ""}
              </div>
            </div>
          </div>
        );
      }}
    />
  );
}
