import toolsContext from "../context/tools/toolsContext";
import {useContext} from "react";

export default [
  {
    type: "link",
    label: "Categories",
    url: "",
    children: [
      {
        type: "link",
        label: "Power Tools",
        url: "",
        children: [
          { type: "link", label: "Engravers", url: "" },
          { type: "link", label: "Wrenches", url: "" },
          { type: "link", label: "Wall Chaser", url: "" },
          { type: "link", label: "Pneumatic Tools", url: "" },
        ],
      },
      {
        type: "link",
        label: "Machine Tools",
        url: "",
        children: [
          { type: "link", label: "Thread Cutting", url: "" },
          { type: "link", label: "Chip Blowers", url: "" },
          { type: "link", label: "Sharpening Machines", url: "" },
          { type: "link", label: "Pipe Cutters", url: "" },
          { type: "link", label: "Slotting machines", url: "" },
          { type: "link", label: "Lathes", url: "" },
        ],
      },
    ],
  },
];
