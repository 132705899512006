import { CachingService } from "./CachingService";
import { API } from "@aws-amplify/api/lib";
import * as customQueries from "../graphql/customQueries";
import StorageManager from "./StorageManager";
import { LocationManager } from "./LocationManager";
import { calculateToolRate } from "./Helper";
import * as queries from "../graphql/queries";
import * as customMutations from "../graphql/customMutations";
import AuthService from "./AuthService";

export default class DB {
  static async getSubCategories() {
    let data = await API.graphql({
      query: customQueries.listTools,
    });
    // console.log(data);
    return data?.data?.listTools?.items;
  }

  static async getBillingInfo() {
    let userID = await AuthService.getUsername();

    let data = await API.graphql({
      query: customQueries.getBillingInfo,
      variables: { id: userID },
    });

    return data?.data?.getUser;
  }

  static async parseTool(tool) {
    if (tool == null) {
      return null;
    }
    tool.images = await StorageManager.loadImages(tool.imageList);
    tool.distance = -1;
    tool.price = parseFloat(tool.rate ?? "0") / 100;
    let toolHourlyRate =
      (await calculateToolRate(tool?.price, 0)) ?? tool.price * 0.1;
    tool.hourlyRate = toolHourlyRate;
    return tool;
  }

  static async loadCustomerTools() {
    let tools = await API.graphql({
      query: customQueries.listCustomerToolWithDetails,
    });

    tools = tools?.data?.listCustomerTools?.items;

    return await Promise.all(
      tools.map((tool) => {
        return this.parseTool(tool);
      })
    );
  }

  static async getCategories() {
    let cats = await API.graphql({
      query: customQueries.listCategoriesWithImages,
    });
    cats = cats.data?.listCategorys?.items;
    console.log("CATSSSSS:", cats);

    cats?.map((cat) => {
      cat.title = cat.name;
      cat.url = "/shop/catalog/" + cat.id;
      cat.categorySlug = cat.url;
      return cat;
    });
    //console.log(CachingService.categories)
    return cats ?? [];
  }
  static async getCustomerTool(id) {
    let tool = await API.graphql({
      query: queries.getCustomerTool,
      variables: { id: id },
    });
    tool = tool?.data?.getCustomerTool;

    return this.parseTool(tool);
  }

  static async getToolsByCategory(id) {
    let cats = await API.graphql({
      query: customQueries.getToolsByCategory,
      variables: { id: id },
    });
    cats = cats?.data?.getCategory?.tools?.items;

    let customerTools = [];

    cats?.forEach((tool) => {
      let toolcustomerTools = tool?.customerTools?.items ?? [];
      customerTools = [...customerTools, ...toolcustomerTools];
    });

    // console.log("TOOLS BY CATS");
    // // console.log(cats)
    // console.log(customerTools);

    return customerTools;
  }

  static async getMyPostedTools() {
    let userID = await AuthService.getUsername();

    let data = await API.graphql({
      query: customQueries.listMyTools,
      variables: { id: userID },
    });

    console.log("Tools Posted");
    console.log(data);
    console.log(data?.data?.getUser?.userTools?.items);

    return data?.data?.getUser?.userTools?.items;
  }

  static async listUserAddresses() {
    let userID = await AuthService.getUsername();

    let data = await API.graphql({
      query: customQueries.listUserAddresses,
      variables: { id: userID },
    });

    // console.log("User Address", data);

    return data?.data?.getUser?.UserAddressList?.items;
  }
  static async addUserAddresses(address, defaultAddress) {
    address["userID"] = await AuthService.getUsername();
    //
    // delete address["lat"];
    // delete address["long"];

    let data = await API.graphql({
      query: customMutations.createUserAddress,
      variables: { input: address },
    });

    data = data?.data?.createUserAddress;
    // console.log("Saved Address", data);
    //
    // return;

    if (defaultAddress) {
      await this.makeAddressDefault(data);
    }
  }

  static async makeAddressDefault(address) {
    let userID = await AuthService.getUsername();
    return API.graphql({
      query: customMutations.updateUser,
      variables: { input: { id: userID, billingAddressID: address?.id } },
    });
  }

  static async editUserAddresses(address) {
    address["userID"] = await AuthService.getUsername();

    // delete address["lat"];
    // delete address["long"];

    // console.log("UPDATING ADDRESS", address);

    return API.graphql({
      query: customMutations.updateUserAddress,
      variables: { input: address },
    });
  }

  static async deleteUserAddresses(id) {
    return API.graphql({
      query: customMutations.deleteUserAddress,
      variables: { input: { id: id } },
    });
  }

  static async getToolsRented() {
    const userID = await AuthService.getUsername();

    const data = await API.graphql({
      query: customQueries.getUserRentals,
      variables: { id: userID },
    });

    return data?.data?.getUser?.toolsRented?.items;
  }

  static async getUserSuccessfulTransactions() {
    const userID = await AuthService.getUsername();

    const data = await API.graphql({
      query: customQueries.getUserSuccessfulTransactions,
      variables: { id: userID },
    });

    return data?.data?.getUser?.transactions?.items;
  }

  static async getToolsLendings() {
    const userID = await AuthService.getUsername();

    console.log("GETTING FROM ID", userID);

    const data = await API.graphql({
      query: customQueries.getUserLendings,
      variables: { id: userID },
    });

    return data?.data?.getUser?.toolsLended?.items;
  }

  static async sendInvite(name, emails) {
    let userID = await AuthService.getUsername();
    const apiName = "SendReferralApi";
    const path = "/SendReferralEmail";
    let myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        userID: userID,
        name: name,
        referralEmails: emails,
      },
    };

    console.log("INIT", myInit);

    return API.post(apiName, path, myInit);
  }

  static async cancelBooking(bookingID) {
    let userID = await AuthService.getUsername();
    const apiName = "CancelBookingApi";
    const path = "/CancelBooking";
    let myInit = {
      // OPTIONAL
      headers: {}, // OPTIONAL
      response: true, // OPTIONAL (return the entire Axios response object instead of only response.data)
      body: {
        userID: userID,
        bookingID: bookingID,
      },
    };

    console.log("INIT", myInit);

    return API.post(apiName, path, myInit);
  }

  static async blockToolDays(bookingData) {
    let userID = await AuthService.getUsername();

    let bookingStartDate = bookingData?.date?.[0]?.getTime();
    let bookingEndDate = bookingData?.date?.[1]?.getTime() ?? bookingStartDate;

    const types = { USER_BLOCKED: "USER_BLOCKED" };

    const inputData = {
      bookingType: types?.USER_BLOCKED,
      customerToolID: bookingData?.toolID,
      totalCompleteToolRentPrice: 10,
      totalToolBaseRentPrice: 10,
      sellerID: userID,
      bookedStartDate: bookingStartDate,
      bookedEndDate: bookingEndDate,
      buyerID: userID,
      daysRented: (bookingEndDate - bookingStartDate) / (3600 * 24) + 1,
    };

    console.log("Input data", inputData);

    let data = await API.graphql({
      query: customMutations.createToolRental,
      variables: {
        input: inputData,
      },
    });

    return data?.data?.createToolRental;
  }

  static updateToolAddress(toolID, addressID) {
    return API.graphql({
      query: customMutations.updateCustomerTool,
      variables: {
        input: { id: toolID, addressID: addressID },
      },
    });
  }
}
